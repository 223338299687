import { ContentContainer } from '@components';
import { Headphones, SvgIcon } from '@icons';
import { CustomerSupportForm } from './components';

export function CustomerSupportScene() {
  return (
    <ContentContainer
      title="Customer Support"
      headerAppend={
        <a href="tel:8443773269" className="flex items-center gap-2">
          <p>(844) DRR-EAMZ</p>
          <SvgIcon
            className="p-3 bg-midnight-20 rounded-full"
            icon={<Headphones />}
          />
        </a>
      }
    >
      <CustomerSupportForm />
    </ContentContainer>
  );
}
