import classNames from 'classnames';

/**
 * @param {object} params
 * @param {import('luxon').DateTime} params.date
 * @param {boolean} params.grayedOut
 * @param {boolean} params.today
 * @param {boolean} params.active
 */
export default function CalendarMonthItem({
  date,
  grayedOut = false,
  today = false,
  active = false,
  metadata,
  compact,
}) {
  return (
    <div
      className={classNames(
        'relative w-full h-full flex items-center justify-center group',
        compact ? '' : 'p-2.5',
      )}
    >
      {metadata?.has_sessions && (
        <span
          className={classNames(
            'absolute left-1/2 -translate-x-1/2 w-1 h-1 rounded-full',
            active ? 'bg-gray-10' : 'bg-orange-50',
            compact ? 'top-[20%]' : 'top-1/4',
          )}
        ></span>
      )}
      <span
        className={classNames(
          'flex items-center justify-center max-h-[4rem] max-w-[4rem] w-full aspect-square box-content text-sm',
          active &&
            (grayedOut ? 'ring ring-orange-30' : 'bg-orange-50 text-gray-10'),
          compact ? '' : 'rounded-full',
          grayedOut && 'text-gray-40',
        )}
      >
        {date.day}
      </span>
      {today && (
        <span
          className={classNames(
            'absolute left-1/2 -translate-x-1/2 rounded-full',
            active ? 'bg-gray-10' : 'bg-red-70',
            compact ? 'bottom-[20%] w-1 h-1' : 'bottom-1/4 w-1.5 h-1.5',
          )}
        ></span>
      )}
    </div>
  );
}
