import RichTextEditor from '@/components/RichTextEditor';
import { useCallback } from 'react';

export default function EditPrivateNoteForm({
  value,
  onChange,
  onSave,
  onCancel,
}) {
  const handleChange = useCallback(
    ({ editor }) => onChange instanceof Function && onChange(editor.getJSON()),
    [onChange],
  );

  return (
    <div className="flex flex-col gap-2">
      <RichTextEditor value={value} onChange={handleChange} />
      <div className="flex gap-2">
        <button className="button-ghost button-xs" onClick={onCancel}>
          Cancel
        </button>
        <button className="button-second button-xs" onClick={onSave}>
          Save
        </button>
      </div>
    </div>
  );
}
