import Label from '../Label';
import Upload from '../Upload';
import Button from '../Button';

function UploadField(props) {
  const {
    value,
    className,
    icons,
    uploadClass,
    mainclass,
    btnValue,
    btnClass,
  } = props;
  return (
    <div className={mainclass}>
      <div className="flex items-center justify-between w-full">
        <Label value={value} className={className} />
        {icons}
      </div>
      <Upload
        mainclass={uploadClass}
        upload={
          <img
            src="/images/upload_icon.png"
            className="w-10 my-3"
            alt="upload"
          />
        }
      />
      <Button value={btnValue} isdefaultclass={false} className={btnClass} />
    </div>
  );
}

export default UploadField;
