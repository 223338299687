const CoreFeaturesTrial = {
  title: 'Core Features',
  features: [
    {
      title: 'Chat',
      includes: true,
    },
    {
      title: 'Offerings',
      includes: true,
    },
    {
      title: 'Appointments',
      includes: true,
    },
    {
      title: 'Payments & Invoices',
      includes: true,
    },
    {
      title: 'Video Meetings',
      includes: true,
    },
    {
      title: 'CRM',
      includes: true,
    },
    {
      title: 'Sales Pages',
      includes: true,
    },
  ]
};

const CoreFeaturesBase = {
  title: 'Core Features',
  features: [
    {
      title: 'Chat',
      includes: true,
    },
    {
      title: 'Offerings',
      includes: true,
    },
    {
      title: 'Appointments',
      includes: true,
    },
    {
      title: 'Payments & Invoices',
      includes: true,
    },
    {
      title: 'Video Meetings',
      includes: true,
    },
    {
      title: 'CRM',
      includes: true,
    },
    {
      title: 'Sales Pages',
      includes: true,
    },
  ]
};

const CoreFeaturesPremium = {
  title: 'Core Features',
  features: [
    {
      title: 'Chat',
      includes: true,
    },
    {
      title: 'Offerings',
      includes: true,
    },
    {
      title: 'Appointments',
      includes: true,
    },
    {
      title: 'Payments & Invoices',
      includes: true,
    },
    {
      title: 'Video Meetings',
      includes: true,
    },
    {
      title: 'CRM',
      includes: true,
    },
    {
      title: 'Sales Pages',
      includes: true,
    },
  ]
};

// const SmartFeatures = {
//   title: 'Smart Features (coming soon)',
//   features: [
//     {
//       title: 'Agendas',
//       includes: true,
//     },
//     {
//       title: 'Reports',
//       includes: true,
//     },
//     {
//       title: 'AI Transcriptions',
//       includes: true,
//     },
//     {
//       title: 'Meeting Summaries',
//       includes: true,
//     },
//     {
//       title: 'Conversational Insights',
//       includes: true,
//     },
//     {
//       title: 'Custom Reminders',
//       includes: true,
//     },
//   ]
// };

const Automations = {
  title: 'Automations',
  features: [
    {
      title: 'Templates',
      includes: true,
    },
    {
      title: 'Contracts',
      includes: true,
    },
    {
      title: 'Subscriptions',
      includes: true,
    },
    {
      title: 'Notifications',
      includes: true,
    },
    {
      title: 'Client Tracking',
      includes: true,
    },
    {
      title: 'Session Rating',
      includes: true,
    },
  ]
};

const Security = {
  title: 'Security',
  features: [
    {
      title: '2-Factor Authentication',
      includes: true,
    },
    {
      title: 'AES-256 Bit Encryption',
      includes: true,
    },
    {
      title: 'SSL/TLS Encryption',
      includes: true,
    },
    {
      title: 'CCPA',
      includes: true,
    },
    {
      title: 'GDPR',
      includes: true,
    },
  ]
};

const Support = {
  title: 'Support',
  features: [
    {
      title: 'In-App Support',
      includes: true,
    },
    {
      title: 'Activation Assistance',
      includes: true,
    },
  ]
};

const MetaDataForTrialPlan = [
  CoreFeaturesTrial,
  Automations,
  Security,
  Support,
];

const MetaDataForBasePlan = [
  CoreFeaturesBase,
  Automations,
  Security,
  Support,
];

const MetaDataForPremiumPlan = [
  CoreFeaturesPremium,
  Automations,
  Security,
  Support,
];

export {
  MetaDataForTrialPlan,
  MetaDataForBasePlan,
  MetaDataForPremiumPlan,
};
