import ConfirmationDialog from '@/components/ConfirmationDialog';
import { useCallback, useRef, useState } from 'react';

export default function useConfirmationDialog() {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const resolveRef = useRef(null);

  const ask = useCallback((title, description) => {
    setTitle(title);
    setDescription(description);
    setShow(true);

    return new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  }, []);

  const resolve = useCallback((result) => {
    setShow(false);
    resolveRef.current(result);
    resolveRef.current = null;
  }, []);
  const onClose = useCallback(() => {
    resolve(false);
  }, [resolve]);
  const onConfirm = useCallback(() => {
    resolve(true);
  }, [resolve]);

  return {
    ask,
    element: () => (
      <ConfirmationDialog
        open={show}
        title={title}
        description={description}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    ),
  };
}
