import Modal from '../Modal';
import LetsGetEmpowered from '../LetsGetEmpowered';
import { useState } from 'react';
import { SubscriptionsStore, UserStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

export const LetsGetEmpoweredPopup = observer(({
  isOpen,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const handleClick = async () => {
    setLoading(true);

    if (UserStore.isCoach) {
      onClose();
      return navigate('settings/coach_profile');
    }

    await UserStore.changeCurrentRole()
      .then( async () => await SubscriptionsStore.getCurrentSubscription())
      .then(() => navigate('settings/coach_profile'))
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      showCloseButton={true}
      shouldCloseOnOverlayClick={false}
      onClose={onClose}
    >
      <LetsGetEmpowered
        className="p-12"
        loading={loading}
        onClick={handleClick}
      />
    </Modal>
  );
});
