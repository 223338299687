// See: https://rgxdb.com/r/3YPXXFO0
// eslint-disable-next-line no-control-regex
import { BaseValidator } from './BaseValidator';

// eslint-disable-next-line no-control-regex
const EMAIL_REGEXP = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/i;

class EmailValidator extends BaseValidator {
   type = 'email';

   validate(value) {
     value = value.trim();

     if (value.length > 320 || value.length === 0) {
       return false;
     }

     if (!EMAIL_REGEXP.test(value)) {
       return false;
     }

     const [
       firstPart = '',
       secondPart = '',
     ] = value.split('@');

     if (firstPart.length > 64 || firstPart.length === 0) {
       return false;
     }

     if (secondPart.length > 254 || secondPart.length === 0) {
       return false;
     }

     const domainParts = secondPart.split('.');

     return !domainParts.some(part => part.length > 63 || part.length === 0);
   }
}

export { EmailValidator };
