import { httpClient } from '@utils';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  MetaDataForBasePlan,
  MetaDataForPremiumPlan,
  MetaDataForTrialPlan,
} from '../scenes/__legacy/PriceSheet/constants/metaData';

class SubscriptionsStore {
  constructor() {
    this.currentSubscription = null;
    this.plans = [];
    this.loading = true;
    makeAutoObservable(this);
  }

  get currentSubscriptionProductId() {
    return this.currentSubscription.plan.product;
  }

  get isCurrentSubscriptionTrial() {
    return this.currentSubscription.status === 'trialing';
  }

  get isCurrentPlanActive() {
    return (
      !!this.currentSubscription &&
      (this.currentSubscription.status === 'active' ||
        this.currentSubscription.status === 'past_due')
    );
  }

  get endPeriod() {
    return this.currentSubscription.current_period_end * 1000;
  }

  get unitAmount() {
    return this.currentSubscription.items.data[0].price.unit_amount;
  }

  get interval() {
    return this.currentSubscription.items.data[0].price.recurring.interval;
  }

  get isCanceledPlan() {
    return this.currentSubscription.cancel_at_period_end;
  }

  get endedDate() {
    return this.currentSubscription.ended_at;
  }

  setPlans(plans) {
    // TODO: refactor checking when choose best way for it, when api will done
    const basePlan = plans.find(
      (plan) => plan.product.metadata['display-name'] === 'Base',
    );

    const trialPlan = {
      id: basePlan.product.id,
      current: this.isCurrentSubscriptionTrial,
      isBestValue: false,
      isTrial: true,
      name: 'Trial',
      description: '30-Day FREE Trial',
      price: null,
      unitLabel: null,
      metaData: MetaDataForTrialPlan,
    };

    const mappedPlans = plans.map((planItem) => {
      const isBase = planItem.product.metadata['display-name'] === 'Base';

      return {
        id: planItem.product.id,
        current:
          !this.isCurrentSubscriptionTrial &&
          planItem.product.id === this.currentSubscriptionProductId,
        isBestValue: isBase,
        isTrial: false,
        name: planItem.product.metadata['display-name'],
        description: isBase ? 'Paid Monthly' : 'Paid Annually',
        price: planItem.price.unit_amount / 100,
        unitLabel: 'Monthly',
        metaData: isBase ? MetaDataForBasePlan : MetaDataForPremiumPlan,
      };
    });

    this.plans = [trialPlan, ...mappedPlans];
  }

  async getCurrentSubscription() {
    this.loading = true;

    try {
      const response = await httpClient.get('/user/subscription', {
        // Suppress toast error message if status is 404
        validateStatus: (status) => [200, 404].includes(status),
      });
      this.currentSubscription = response.status === 200 ? response.data : null;
    } finally {
      this.loading = false;
    }
  }

  async getPlans() {
    this.loading = true;

    try {
      const response = await httpClient.get('/subscriptions');

      runInAction(() => {
        this.setPlans(response.data);
      });
    } finally {
      this.loading = false;
    }
  }

  async updatePlan(id) {
    return httpClient.post('/user/subscription', { product_id: id });
  }

  async cancelSubscription() {
    return httpClient.delete('/user/subscription');
  }
}

export default new SubscriptionsStore();
