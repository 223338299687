import EditPrivateNoteForm from '@/components/EditPrivateNoteForm';
import PrivateNote from '@/components/PrivateNote';
import { PlusIcon } from '@/icons';
import { PrivateNotesService } from '@/services';
import getErrorMessage from '@/utils/getErrorMessage';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const defaultEditor = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
};

export default function PrivateNotesSection({ userId, context = 'user' }) {
  const [notes, setNotes] = useState([]);

  const loadNotes = useCallback(async () => {
    const noteList = await PrivateNotesService.list({
      filters: {
        user_id: { eq: userId },
        context: { eq: context },
      },
    });
    setNotes(noteList);
  }, [context, userId]);

  useEffect(() => {
    loadNotes();
  }, [loadNotes]);

  return (
    <div className="flex flex-col gap-6 bg-gray-10 rounded-2.5xl px-7.5 py-6">
      <h2 className="text-2xl sm:text-3xl text-midnight-100 font-bold">
        Private Notes
      </h2>

      <CreateNewNoteSection
        userId={userId}
        context={context}
        onCreate={loadNotes}
      />

      <div className="flex flex-col gap-2">
        {notes
          .map((note) => (
            <PrivateNoteOrEditForm
              key={note.id}
              note={note}
              onDelete={loadNotes}
              onUpdate={loadNotes}
            />
          ))
          .reverse()}
      </div>
    </div>
  );
}

function PrivateNoteOrEditForm({ note, onDelete, onUpdate }) {
  const [editMode, setEditMode] = useState(false);
  const [editorValue, setEditorValue] = useState(defaultEditor);

  const handleCancel = useCallback(() => {
    setEditMode(false);
    setEditorValue(defaultEditor);
  }, []);
  const handleSave = useCallback(async () => {
    try {
      const updatedNote = await PrivateNotesService.update(
        note.id,
        editorValue,
      );
      handleCancel();
      toast.success('Note updated');
      onUpdate instanceof Function && onUpdate(updatedNote);
    } catch (error) {
      toast.error('Unable to update the note');
      console.error(getErrorMessage(error));
    }
  }, [editorValue, handleCancel, note.id, onUpdate]);

  const handleEdit = useCallback(() => {
    setEditMode(true);
    setEditorValue(Object.assign({}, note.content));
  }, [note.content]);

  const handleDelete = useCallback(async () => {
    try {
      const deletedNote = await PrivateNotesService.remove(note.id);
      toast.success('Note deleted');
      onDelete instanceof Function && onDelete(deletedNote);
    } catch (error) {
      toast.error('Unable to delete the note');
      console.error(getErrorMessage(error));
    }
  }, [note.id, onDelete]);

  return editMode ? (
    <EditPrivateNoteForm
      value={editorValue}
      onChange={setEditorValue}
      onCancel={handleCancel}
      onSave={handleSave}
    />
  ) : (
    <PrivateNote {...note} onEdit={handleEdit} onDelete={handleDelete} />
  );
}

function CreateNewNoteSection({ userId, context, onCreate }) {
  const [showEditor, setShowEditor] = useState(false);
  const [editorValue, setEditorValue] = useState(defaultEditor);

  const handleCancel = useCallback(() => {
    setShowEditor(false);
    setEditorValue(defaultEditor);
  }, []);
  const handleSave = useCallback(async () => {
    try {
      const createdNote = await PrivateNotesService.create({
        user_id: userId,
        context: context,
        content: editorValue,
      });
      handleCancel();
      toast.success('Note created');
      onCreate instanceof Function && onCreate(createdNote);
    } catch (error) {
      toast.error('Unable to create the note');
      console.error(getErrorMessage(error));
    }
  }, [userId, context, editorValue, handleCancel, onCreate]);
  const handleShowEditor = useCallback(() => {
    setShowEditor(true);
  }, []);

  return showEditor ? (
    <EditPrivateNoteForm
      value={editorValue}
      onChange={setEditorValue}
      onCancel={handleCancel}
      onSave={handleSave}
    />
  ) : (
    <button
      type="button"
      className="button-outline button-m justify-center"
      onClick={handleShowEditor}
    >
      <span>Add Note</span>
      <PlusIcon className="fill-current" />
    </button>
  );
}
