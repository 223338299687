import { BaseValidator } from './BaseValidator';

const DISALLOWED_CHARS = [
  '^',
  '<',
  '>',
  '/',
  '\\',
  '?',
  '*',
  ':',
  '"',
  '|',
];

function escapeRegExp(string) {
  /**
   * `$&` means the whole matched string
   * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping}
   */
  return string.replace(/[^\\]/g, '\\$&');
}

const DISALLOWED_CHARS_REGEXP = new RegExp('[' + DISALLOWED_CHARS.map(escapeRegExp).join('') + ']');
const ERROR_MESSAGE = `The following characters are not allowed: ${DISALLOWED_CHARS.join(' ')}`;

const containsDisallowedChars = (str) => DISALLOWED_CHARS_REGEXP.test(str);

class NameValidator extends BaseValidator {
   message = ERROR_MESSAGE;
   type = 'name';

   validate(value) {
     return !containsDisallowedChars(value);
   }
}

export { NameValidator };
