import { EditorProvider } from '@tiptap/react';
import EditorToolbar from './EditorToolbar';
import extensions from './extensions';

export default function RichTextEditor({ value, onChange = () => {} }) {
  return (
    <div>
      <EditorProvider
        slotBefore={<EditorToolbar />}
        editorProps={{
          attributes: {
            class:
              'p-4 border border-midnight-50 border-t-0 rounded-b-md focus:outline-none space-y-[0.75em] text-midnight-100',
          },
        }}
        extensions={extensions}
        content={value}
        onUpdate={onChange}
        injectCSS={false}
      ></EditorProvider>
    </div>
  );
}
