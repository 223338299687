/* eslint-disable no-unused-vars */
import SelectUser from '@/components/SelectUser';
import { MessengerActiveRoomContext } from '@/contexts/MessengerActiveRoomContext';
import { MessengerContext } from '@/contexts/MessengerContext';
import { UserService } from '@/services';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

/**
 * @param {object} params
 * @param {boolean} params.show
 * @param {() => void} params.onClose
 */
export default function InviteParticipantsDialog({ show, onClose }) {
  const { matrixClient } = useContext(MessengerContext);
  const { room } = useContext(MessengerActiveRoomContext);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const addMember = useCallback(async () => {
    if (selectedUsers.length > 0 && room !== null) {
      onClose();
      for (const user of selectedUsers) {
        try {
          matrixClient.invite(room.roomId, `@${user.id}:drreamz.com`);
        } catch (error) {
          console.log(`Add member error`, error);
          toast.error(`Unable to invite ${user.first_name} ${user.last_name}`);
        }
      }
    }
  }, [matrixClient, onClose, room, selectedUsers]);

  async function findUsers(query) {
    const list = await UserService.search(query);
    return list.data;
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog open={show} onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-500/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="flex flex-col gap-4 w-full h-fit max-w-2xl px-[30px] py-12 bg-gray-10 rounded-2.5xl ">
              <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
                Invite Participants
              </Dialog.Title>

              <div className="flex flex-col gap-4">
                <SelectUser
                  value={selectedUsers}
                  onChange={setSelectedUsers}
                  loadData={findUsers}
                  by="id"
                  multiple
                  placeholder="Search by full name, email"
                />

                <button
                  type="button"
                  className="flex justify-center button-main button-s"
                  disabled={selectedUsers.length === 0}
                  onClick={addMember}
                >
                  {selectedUsers.length
                    ? `Invite ${selectedUsers.length} users to this room`
                    : 'Select a users above'}
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
