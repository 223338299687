import { CornerSuccessIcon } from '@/icons';
import { ZoomService } from '@/services';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

const ContentPerStatus = new Map([
  [
    false,
    {
      containerClassNames: 'bg-midnight-20',
      title: (
        <h2 className="text-xl font-bold text-midnight-100">Connect Zoom</h2>
      ),
      description: (
        <p className="text-midnight-90">
          Add your Zoom account to Drreamz platform to use advanced scheduling
          and automation features.
        </p>
      ),
      actions: (
        <a
          href={`https://zoom.us/oauth/authorize?${new URLSearchParams({
            response_type: 'code',
            client_id: process.env.REACT_APP_ZOOM_SDK_KEY,
            redirect_uri: location.origin + '/oauth/zoom',
          })}`}
          target="_blank"
          rel="noreferrer"
          className="button-main button-m self-start"
        >
          Connect Zoom account
        </a>
      ),
    },
  ],
  [
    true,
    {
      containerClassNames: 'bg-white',
      title: (
        <div className="inline-flex items-center gap-3 text-sea-70">
          <CornerSuccessIcon className="fill-current" />
          <h2 className="text-xl font-bold">Zoom is connected!</h2>
        </div>
      ),
      description: null,
      actions: null,
    },
  ],
]);

export function ConnectZoomCard({ status = false, className }) {
  const content = useMemo(() => ContentPerStatus.get(status), [status]);

  return (
    <article
      className={classNames(
        'flex flex-row w-full p-7.5 rounded-2.5xl',
        content.containerClassNames,
        className,
      )}
    >
      <div className="grow flex flex-col gap-9">
        <div className="flex flex-col gap-4">
          {content.title}
          {content.description}
        </div>

        {content.actions}
      </div>

      <img
        src="/images/zoom-logo.svg"
        className="object-cover h-6 self-end"
        alt="Zoom Logo"
      />
    </article>
  );
}

export function useZoomConnectStatus() {
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    async function fetch() {
      const data = await ZoomService.getStatus();
      setStatus(Boolean(data?.access_granted));
    }
    fetch();
  }, []);

  return status;
}
