import { RatingIcon } from '@/icons';

export default function CoachProfileRating({ value }) {
  return (
    <p className="flex flex-row gap-2">
      <RatingIcon className="self-baseline" />
      <span className="text-midnight-60 font-normal text-sm leading-6">
        {value || 100}% Positive Feedback
      </span>
    </p>
  );
}
