import { DownloadIcon, LockIcon } from '@/icons';
import { AcquisitionService, OfferService } from '@/services';
import { UploadService } from '@/services';
import { UserStoreV2 } from '@/stores';
import { humanFileSize } from '@/utils';
import * as mime from 'mime/lite';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

export default function OfferPageContent({ offerId }) {
  const [content, setContent] = useState(undefined);
  const [canDownload, setCanDownload] = useState(false);
  const userStore = UserStoreV2.useState();

  async function downloadAll() {
    window.open(
      UploadService.getDownloadMultipleLink(
        content.map((i) => i.id),
        `offer-content-${offerId}`,
      ),
      '_blank',
    );
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await OfferService.getContent(offerId);
        setContent(data);
      } catch (error) {
        toast.error('Unable to get Content. See console (F12)');
        console.error('Get Content error.', error);
      }
    }
    fetchData();
  }, [offerId]);

  // Load purchase state
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await AcquisitionService.has(offerId);
        setCanDownload(data);
      } catch (error) {
        toast.error('Unable to get purchase status. See console (F12)');
        console.error('Get purchase status error.', error);
      }
    }
    if (userStore.isAuthorized) fetchData();
  }, [userStore, offerId]);

  return (
    content &&
    content.length > 0 && (
      <div className="flex flex-col gap-5 p-10 bg-gray-10 rounded-2.5xl">
        <div className="flex justify-between items-center">
          <h2 className="text-midnight-100 text-4xl font-bold">Content</h2>
          {canDownload ? (
            <button
              type="button"
              className="button-outline button-xs"
              onClick={downloadAll}
            >
              Download all
            </button>
          ) : (
            <span className="text-midnight-60">
              Download available after purchase
            </span>
          )}
        </div>

        <ul className="flex flex-col gap-2.5">
          {content.map((data) => (
            <FileListItem key={data.id} file={data} isLocked={!canDownload} />
          ))}
        </ul>
      </div>
    )
  );
}

function FileListItem({ file, isLocked }) {
  const nameWithoutExt = useMemo(() => {
    // Check the name contains extention
    if (mime.getType(file.name)) {
      return file.name.substring(0, file.name.lastIndexOf('.'));
    } else {
      return file.name;
    }
  }, [file]);

  async function downloadFile() {
    window.open(UploadService.getDownloadLink(file.id), '_blank');
  }

  return (
    <li className="flex justify-between items-center py-3 px-6 bg-midnight-10 rounded-xl">
      <div>
        <p className="text-midnight-100 text-lg">{nameWithoutExt}</p>
        <span className="flex gap-4 text-midnight-60 text-sm">
          <span className="uppercase">{mime.getExtension(file.type)}</span>
          <span>{humanFileSize(file.size).toLowerCase()}</span>
        </span>
      </div>

      {isLocked ? (
        <LockIcon className="fill-midnight-50" aria-label="Lock Icon" />
      ) : (
        <button
          type="button"
          className="button-ghost button-xs button-icon-rect"
          onClick={downloadFile}
        >
          <span className="sr-only">Download file</span>
          <DownloadIcon className="fill-current" aria-hidden="true" />
        </button>
      )}
    </li>
  );
}
