import { useEffect, useState } from 'react';
import moment from 'moment';

export function useNotificationsSort({
  hasData,
  notificationsList,
}) {
  const [notifications, setNotifications] = useState({});

  useEffect(() => {
    if (hasData) {
      const groupsByDate = notificationsList.reduce((groups, notification) => {
        const date = notification.timestamp;

        if (moment().isSame(date, 'day')) {
          (groups.today = groups.today || []).push(notification);
          return groups;
        }

        if (moment().subtract(1, 'days').startOf('day').isSame(date, 'day')) {
          (groups.yesterday = groups.yesterday || []).push(notification);
          return groups;
        }

        (groups.past = groups.past || []).push(notification);
        return groups;
      }, {});

      setNotifications(groupsByDate);
    }
  }, [notificationsList, hasData]);

  return {
    notifications
  };
}
