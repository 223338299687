import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  SvgIcon,
} from '@icons';
import { formatShortDate, noop } from '@utils';
import classNames from 'classnames';
import { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FormControl from '../FormControl';
import './DDatePicker.scss';

const Header = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div className="date-picker__header">
    <button
      type="button"
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
    >
      <SvgIcon icon={<ChevronLeftIcon />} />
    </button>

    <p className="date-picker__title">{formatShortDate(date)}</p>

    <button
      type="button"
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
    >
      <SvgIcon icon={<ChevronRightIcon />} />
    </button>
  </div>
);

const Input = forwardRef(
  (
    {
      className,
      placeholder,
      labelPlacement,
      name,
      value,
      title,
      required,
      disabled,
      onClick,
      baseClassName,
      endAdornment = <SvgIcon icon={<CalendarIcon />} />,
    },
    ref,
  ) => (
    <FormControl
      ref={ref}
      baseClassName={baseClassName}
      className={classNames('date-picker__input', className)}
      name={name}
      label={title}
      placeholder={placeholder}
      labelPlacement={labelPlacement}
      onFocus={onClick}
      value={value}
      required={required}
      disabled={disabled}
      onChange={noop}
      endAdornment={endAdornment}
    />
  ),
);

Input.displayName = 'Input';

export const DDatePicker = ({
  className,
  name,
  label,
  date: dateProp,
  placeholder,
  labelPlacement,
  disabled = false,
  required = false,
  showTimeSelect = false,
  selectsStart = false,
  selectsEnd = false,
  includeDates,
  minDate,
  startDate,
  endDate,
  onChange,
  timeSelectorProps,
  endAdornment,
  inputClassName,
}) => {
  const [date, setDate] = useState(dateProp);

  const handleClose = () => {
    if (date !== dateProp) {
      onChange({
        target: {
          name,
          value: date,
        },
      });
    }
  };

  return (
    <DatePicker
      className={classNames('date-picker', className)}
      renderCustomHeader={Header}
      showTimeSelect={showTimeSelect}
      name={name}
      title={label}
      selected={date}
      disabled={disabled}
      required={required}
      includeDates={includeDates}
      onChange={setDate}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      placeholderText={placeholder}
      customInput={
        <Input
          endAdornment={endAdornment}
          baseClassName={inputClassName}
          labelPlacement={labelPlacement}
          disabled
        />
      }
      shouldCloseOnSelect={false}
      onCalendarClose={handleClose}
      {...timeSelectorProps}
    />
  );
};
