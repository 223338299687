import classNames from 'classnames';
import { SIZE, THEME } from '@constants';
import './SvgIcon.scss';

export const SvgIcon = ({
  className,
  icon,
  theme = THEME.DEFAULT,
  size = SIZE.M,
}) => {
  return (
    <span className={classNames('svg-icon', {
      [`svg-icon--theme-${theme}`]: Boolean(theme),
      [`svg-icon--size-${size}`]: Boolean(size),
    }, className)}>
      {icon}
    </span>
  );
};
