import { makeAutoObservable } from 'mobx';
import { getChat, getChatMessages } from '@services';

class InboxStore {
  constructor() {
    this.sse = null;
    this.chat = null;
    this.messages = [];
    makeAutoObservable(this);
  }

  setMessages(messages) {
    this.messages = messages;
  }

  setChat(chat) {
    this.chat = chat;
  }

  setSse(sse) {
    this.sse = sse;
  }

  subscribeChat(chatId, onError, onMessage) {
    const sse = new EventSource(
      `${process.env.REACT_APP_API_BASE_URL}/chats/${chatId}/listen`,
    );

    sse.onmessage = async (event) => {
      const message = JSON.parse(event.data);
      message.user = { id: message.user_id };
      this.addMessage(message);
      onMessage();
    };

    sse.onerror = () => {
      onError();
    };

    this.setSse(sse);
  }

  unsubscribeChat() {
    if (this.sse) {
      this.sse.close();
    }
  }

  checkPrivateChat(userId) {
    const chat = this.chat;

    if (chat.type === 'private') {
      const member = chat.members.filter((member) => member.id !== userId)[0];
      chat.logo = member.avatar;
      chat.name = `${member.first_name} ${member.last_name}`;
    }

    this.setChat(chat);
  }

  async getChat(chatId) {
    const { data } = await getChat(chatId);
    this.setChat(data);
    await this.getMessages(chatId);
  }

  async getMessages(chatId) {
    const { data } = await getChatMessages(chatId);
    this.setMessages(data);
  }

  addMessage(message) {
    this.messages.push(message);
  }

  clear() {
    this.sse = null;
    this.chat = null;
    this.messages = [];
  }
}

export default new InboxStore();
