import { AttentionIcon, CornerSuccessIcon } from '@/icons';
import { StripeService } from '@/services';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

const ContentPerStatus = new Map([
  [
    'not_started',
    {
      containerClassNames: 'bg-midnight-20',
      title: (
        <h2 className="text-xl font-bold text-midnight-100">
          Create Stripe Connect Account
        </h2>
      ),
      description: (
        <p className="text-midnight-90">
          You can accept payments via our service. You need to connect an
          existing Stripe account or create a new one to sell your offers.
        </p>
      ),
      button: (onClick) => (
        <button
          type="button"
          className="button-second button-m"
          onClick={onClick}
        >
          Get Started
        </button>
      ),
    },
  ],
  [
    'incomplete',
    {
      containerClassNames: 'bg-orange-10 border border-orange-50',
      title: (
        <div className="inline-flex items-center gap-3 text-orange-50">
          <AttentionIcon className="fill-current" />
          <h2 className="text-xl font-bold">
            Your Stripe account is not set up properly
          </h2>
        </div>
      ),
      description: (
        <>
          <p className="text-midnight-90">
            The onboarding process is not completed. Use a button below to
            continue onboarding.
          </p>
          <p className="text-midnight-70 text-sm">
            <span className="font-bold">Note:</span> If you just finished the
            onboarding and see this message - don't worry. The onboarding takes
            up to 5 minutes to process. You can track the progress in your&nbsp;
            <a
              href="https://dashboard.stripe.com/"
              target="_blank"
              rel="noreferrer"
              className="text-orange-50"
            >
              dashboard
            </a>
          </p>
        </>
      ),
      button: (onClick) => (
        <button
          type="button"
          className="button-main button-m"
          onClick={onClick}
        >
          Continue
        </button>
      ),
    },
  ],
  [
    'complete',
    {
      containerClassNames: 'bg-white',
      title: (
        <div className="inline-flex items-center gap-3 text-sea-70">
          <CornerSuccessIcon className="fill-current" />
          <h2 className="text-xl font-bold">
            Your Stripe account is ready to use!
          </h2>
        </div>
      ),
      description: (
        <p className="text-midnight-90">
          Once your customers pay you, payouts and reporting information will be
          available in your dashboard.
        </p>
      ),
      button: () => (
        <a
          href="https://dashboard.stripe.com/"
          target="_blank"
          rel="noreferrer"
          className="button-second button-m"
        >
          Open Stripe Dashboard
        </a>
      ),
    },
  ],
]);

export default function StripeOnboardingCard({
  status = 'not_started',
  className,
}) {
  const content = useMemo(() => ContentPerStatus.get(status), [status]);

  async function goToOnboarding() {
    const link = await StripeService.getOnboardingLing();
    location.assign(link);
  }

  return (
    <div
      className={classNames(
        'flex flex-col gap-9 w-full p-7.5 rounded-2.5xl',
        content.containerClassNames,
        className,
      )}
    >
      <div className="flex flex-col gap-4">
        {content.title}
        {content.description}
      </div>
      <div className="flex justify-between items-center">
        {content.button(goToOnboarding)}
        <img
          className="object-cover h-12"
          src="/images/connect-services/stripe.svg"
          alt="Stripe Connect Logo"
        />
      </div>
    </div>
  );
}

export function useOnboardingStatus() {
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    async function getOnboardingStatus() {
      const status = await StripeService.getStatus();
      setStatus(status);
    }
    getOnboardingStatus();
  }, []);

  return status;
}
