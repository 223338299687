import {
  CoachProfileService,
  OfferingsService,
  StripeService,
  ZoomService,
} from '@services';
import { SubscriptionsStore } from '@stores';
import { useEffect, useState } from 'react';

export default function useGuidelineData() {
  const [hasSubscription, setHasSubscription] = useState(false);
  const [hasCoachProfile, setHasCoachProfile] = useState(false);
  const [completeStripeOnboarding, setCompleteStripeOnboarding] =
    useState(false);
  const [connectZoom, setConnectZoom] = useState(false);
  const [hasOffers, setHasOffers] = useState(false);

  useEffect(() => {
    let ignore = false;
    async function fetchSubscription() {
      if (!SubscriptionsStore.currentSubscription) {
        await SubscriptionsStore.getCurrentSubscription();
      }

      if (!ignore) {
        setHasSubscription(SubscriptionsStore.currentSubscription !== null);
      }
    }
    async function fetchCoachProfile() {
      const profile = await CoachProfileService.get();

      if (!ignore) {
        setHasCoachProfile(profile !== null);
      }
    }
    async function fetchStripeOnboardingStatus() {
      const onboardingStatus = await StripeService.getStatus();

      if (!ignore) {
        setCompleteStripeOnboarding(onboardingStatus === 'complete');
      }
    }
    async function fetchZoomStatus() {
      const data = await ZoomService.getStatus();
      setConnectZoom(data?.access_granted);
    }
    async function fetchOffers() {
      const { data: offers } = await OfferingsService.getAll();

      if (!ignore) {
        setHasOffers(offers.length > 0);
      }
    }

    fetchSubscription();
    fetchCoachProfile();
    fetchStripeOnboardingStatus();
    fetchZoomStatus();
    fetchOffers();

    return () => {
      ignore = true;
    };
  }, []);

  return {
    hasSubscription,
    hasCoachProfile,
    completeStripeOnboarding,
    connectZoom,
    hasOffers,
  };
}
