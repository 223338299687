import { SIZE } from '@constants';
import classNames from 'classnames';
import DAvatar from '../DAvatar';

export const UsersList = ({ className, users }) => {
  return (
    <ul className={classNames('flex flex-wrap gap-12', className)}>
      {users.map((userItem) => (
        <div
          key={userItem.id}
          className="flex flex-col items-center cursor-pointer"
        >
          <DAvatar
            size={SIZE.L}
            avatar={userItem.avatar}
            abbreviation={`${userItem.name}`}
          />

          <div className="text-base mt-3.5 text-midnight-100">
            <p className="max-w-[130px] truncate">{userItem.name}</p>
          </div>
        </div>
      ))}
    </ul>
  );
};
