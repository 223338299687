const ACCESS_TOKEN_KEY = 'matrix_access_token';
const REFRESH_TOKEN_KEY = 'matrix_refresh_token';
const DEVICE_ID_KEY = 'matrix_device_id';
const USER_ID_KEY = 'matrix_user_id';

export let accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
export let refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
export let deviceId = localStorage.getItem(DEVICE_ID_KEY);
export let userId = localStorage.getItem(USER_ID_KEY);

/**
 * @param {{
 *  userId: string
 *  deviceId: string
 *  accessToken: string
 *  refreshToken?: string
 * }} data
 */
export function save(data) {
  console.debug('[Messenger Tokens] Save new tokens.');
  accessToken = data.accessToken;
  refreshToken = data.refreshToken;
  deviceId = data.deviceId;
  userId = data.userId;
  localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken);
  localStorage.setItem(DEVICE_ID_KEY, data.deviceId);
  localStorage.setItem(USER_ID_KEY, data.userId);
}

export function clear() {
  console.debug('[Messenger Tokens] Clear storage.');
  accessToken = null;
  refreshToken = null;
  deviceId = null;
  userId = null;
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(DEVICE_ID_KEY);
  localStorage.removeItem(USER_ID_KEY);
}
