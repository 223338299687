import { FormControl, LandingTemplate, ProgressButton } from '@components';
import { SIZE } from '@constants';
import { ChevronRightIcon, EnvelopeIcon, SvgIcon } from '@icons';
import { getResetPassword } from '@services';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';

const validationSchema = object({
  email: string()
    .required('Email is required')
    .email('Please check your email'),
});

const ForgotPasswordScene = () => {
  const [loading, setLoading] = useState(false);

  if (localStorage.getItem('accessToken')) return <Navigate to="/dashboard" />;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email }) => {
      setLoading(true);

      try {
        await getResetPassword({ email, returnPath: 'reset_password' });

        toast('Check your email for further instructions!', {
          type: 'success',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <LandingTemplate src="/images/forgot_password.svg">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col justify-center h-full max-w-lg mx-auto"
      >
        <div className="flex flex-col justify-center h-full">
          <div>
            <img src="/images/logo_black.svg" className="h-8 mb-1" alt="logo" />
          </div>
          <h1 className="text-3xl text-midnight-100 font-bold mt-4">
            Forgot password
          </h1>
          <p className="text-sm font-normal text-midnight-90 mt-2">
            If you want to reset your password, please enter your email address.
            <br />
            We will send you instructions on how to change your password.
          </p>
          <FormControl
            className="mt-9"
            id="email"
            name="email"
            type="email"
            placeholder="Enter email address"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
            startAdornment={<SvgIcon icon={<EnvelopeIcon />} />}
          />
          <ProgressButton type="submit" className="mt-12" loading={loading}>
            Send Instructions&nbsp;
            <SvgIcon size={SIZE.S} icon={<ChevronRightIcon />} />
          </ProgressButton>
        </div>
      </form>
    </LandingTemplate>
  );
};

export default ForgotPasswordScene;
