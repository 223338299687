import { AppContext } from '@/contexts';
import {
  ConnectZoomCard,
  useZoomConnectStatus,
} from '@components/ConnectZoomCard';
import StripeOnboardingCard, {
  useOnboardingStatus,
} from '@components/StripeOnboardingCard';
import { useContext } from 'react';
import EditPersonalInfoForm from './src/EditPersonalInfoForm';

export default function EditPersonalInfoScene() {
  const appContext = useContext(AppContext);

  const onboardingStatus = useOnboardingStatus();
  const zoomConnectStatus = useZoomConnectStatus();

  return (
    <>
      <div className="personal-info-scene__content flex flex-col gap-8">
        <EditPersonalInfoForm />

        {appContext.isCoach && (
          <>
            <ConnectZoomCard
              status={zoomConnectStatus}
              className={zoomConnectStatus ? '' : 'order-first'}
            />
            <StripeOnboardingCard
              status={onboardingStatus}
              className={onboardingStatus === 'complete' ? '' : 'order-first'}
            />
          </>
        )}
      </div>
    </>
  );
}
