import EditScheduleForm from '@/components/EditScheduleForm';
import { MyScheduleService } from '@/services';
import getErrorMessage from '@/utils/getErrorMessage';
import { Dialog } from '@headlessui/react';
import { SystemZone } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

export default function CreateScheduleDialog({ open, onClose, onCreate }) {
  const [newSchedule, setNewSchedule] = useState({
    name: 'New Schedule',
    timezone: SystemZone.instance.name,
    schedule: Array.from({ length: 7 }, () => ({
      enabled: false,
      intervals: [],
    })),
  });

  const handleClose = useCallback(() => {
    setNewSchedule({
      name: 'New Schedule',
      timezone: SystemZone.instance.name,
      schedule: Array.from({ length: 7 }, () => ({
        enabled: false,
        intervals: [],
      })),
    });
    onClose instanceof Function && onClose();
  }, [onClose]);

  const isValid = useMemo(
    () => newSchedule.name && newSchedule.name != '' && newSchedule.timezone,
    [newSchedule],
  );

  const handleCreate = useCallback(async () => {
    try {
      const schedule = await MyScheduleService.create(newSchedule);
      onCreate instanceof Function && onCreate(schedule);
      window.dispatchEvent(new CustomEvent('drreamz:schedule.created'));
      handleClose();
    } catch (error) {
      toast.error(getErrorMessage(error));
      console.error('Schedule creation error', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, onCreate, newSchedule]);

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-50">
      <div className="fixed inset-0 bg-black-500/80" aria-hidden="true" />

      <div className="fixed inset-0 flex justify-center p-4 overflow-y-auto">
        <Dialog.Panel className="flex flex-col gap-4 w-full h-fit max-w-2xl px-[30px] py-12 bg-gray-10 rounded-2.5xl ">
          <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
            Create new schedule
          </Dialog.Title>
          <EditScheduleForm value={newSchedule} onChange={setNewSchedule} />

          <div className="flex gap-4 items-center justify-center">
            <button
              type="button"
              className="button-outline button-m"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button-main button-m"
              onClick={handleCreate}
              disabled={!isValid}
            >
              Create schedule
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
