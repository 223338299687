import { OfferSessionInfoForm } from '@/components/EditOffer';
import { OfferContext } from '@/contexts';
import { MyOfferService } from '@/services';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import EditOfferTabFallback from './EditOfferTabFallback';

export default function EditOfferSessionInfoScene() {
  const context = useContext(OfferContext);
  const [loading, setLoading] = useState(undefined);
  const [showFallback, setShowFallback] = useState(undefined);

  // Load session info for sessional type offer
  useEffect(() => {
    async function fetchSessionInfo() {
      console.log('Fetch Session Info');
      try {
        setLoading(true);
        const sessioInfo = await MyOfferService.getSessionInfo(context.id);
        sessioInfo && context.fromApiSessionInfo(sessioInfo);
        setLoading(false);
      } catch (error) {
        toast.error('Unable to get session info. See conslole (F12)');
        console.error('Get session info error', error);
      }
    }

    const doLoad =
      context.offerType === 'one-to-one' || context.offerType === 'group';
    doLoad && void fetchSessionInfo();
    setShowFallback(!doLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.id, context.offerType]);

  return showFallback ? (
    <EditOfferTabFallback />
  ) : (
    loading === false && <OfferSessionInfoForm />
  );
}
