import { Link, useLocation } from 'react-router-dom';

export default function NotFoundScene() {
  let location = useLocation();
  const homeUrl = location.pathname.startsWith('/dashboard/coach') ? '/dashboard/coach' : '/dashboard/client';

  return (
    <div className="flex flex-col justify-center items-center">
      <img src="/images/svg-page-not-found.svg" alt="404: Page not found" />
      <div className="mt-2 font-extrabold text-4xl text-midnight-100">
        Page not found
      </div>
      <div className="mt-4 text-midnight-80 text-lg">
        Oops! The page you are looking for does not exist. It might have been
        moved or deleted.
      </div>
      <Link to={homeUrl} className="button-main button-s mt-12">
        Back to Home
      </Link>
    </div>
  );
}
