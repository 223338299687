import { CrossIcon, SearchIcon } from '@/icons';
import nextTick from '@/utils/next-tick';
import { forwardRef, useCallback, useRef, useState } from 'react';
import ActiveRoom from './ActiveRoom';
import NewRoomButton from './NewRoomButton';
import { RoomList } from './RoomList';

export default function Messenger() {
  console.debug('[Messenger] <render call>');
  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef(null);

  const handleSearchClick = useCallback(() => {
    setShowSearch(true);
    nextTick(() => {
      searchInputRef.current.focus();
    });
  }, []);
  const handleSearchClear = useCallback(() => {
    setShowSearch(false);
    setSearchValue('');
  }, []);
  const handleSearchBlur = useCallback(() => {
    if (searchValue.trim() == '') {
      setShowSearch(false);
    }
  }, [searchValue]);

  return (
    <div className="grow h-full grid grid-cols-[20rem_auto] grid-rows-[minmax(0,auto)] divide-x divide-midnight-20 rounded-2.5xl bg-gray-10">
      <div className="h-full flex flex-col divide-y divide-midnight-20">
        <div className="flex items-center gap-2 pl-8 pr-4 py-4">
          {showSearch ? (
            <RoomSearchInput
              ref={searchInputRef}
              value={searchValue}
              onChange={setSearchValue}
              onClear={handleSearchClear}
              onBlur={handleSearchBlur}
            />
          ) : (
            <div className="grow flex items-center justify-between">
              <span className="font-bold text-xl text-midnight-90">
                Message
              </span>

              <div className="flex gap-2">
                <NewRoomButton />

                <button
                  type="button"
                  className="button-clear p-1"
                  title="Search a room"
                  onClick={handleSearchClick}
                >
                  <SearchIcon className="fill-midnight-60" />
                  <span className="sr-only">Search a room</span>
                </button>
              </div>
            </div>
          )}
        </div>

        <RoomList search={searchValue} />
      </div>

      <ActiveRoom />
    </div>
  );
}

const RoomSearchInput = forwardRef(function RoomSearchInput(
  { value, onChange, onClear, onBlur },
  ref,
) {
  return (
    <div className="grow relative">
      <input
        ref={ref}
        value={value}
        type="text"
        placeholder="Search by room name"
        className="w-full input-main rounded-lg pl-2 pr-10 py-1"
        onChange={(event) => onChange(event.target.value)}
        onBlur={onBlur}
      />

      <button
        type="button"
        className="absolute right-0 inset-y-0 button-clear py-1 px-2 m-px ml-0 rounded-l-none"
        title="Clear search"
        onClick={onClear}
      >
        <CrossIcon className="fill-midnight-60 w-5 h-5" />
        <span className="sr-only">Clear search</span>
      </button>
    </div>
  );
});
