import { httpClient } from '@utils';

const getCurrentUserContext = () => {
  return httpClient.get('/user');
};

const updateUser = ({ payload }) => {
  return httpClient.patch('/user', payload);
};

const getUsers = () => {
  return httpClient.get('/users');
};

const getFiles = () => {
  return httpClient.get('/files');
};

const getUserById = (userId) => {
  return httpClient.get(`/users/${userId}`);
};

const checkUserExists = ({ email }) => {
  return httpClient.get('/auth/check-email', { params: { value: email } });
};

export {
  getCurrentUserContext,
  updateUser,
  getUsers,
  getUserById,
  checkUserExists,
  getFiles,
};
