import CoachProfileHeader from './CoachProfileHeader';

export default function CoachProfileHeaderAndAvatar({
  avatar,
  header,
  headerOffset,
}) {
  return (
    <div className="relative">
      <CoachProfileHeader url={header} offset={headerOffset} />

      {/* Avatar */}
      <img
        src={avatar || '/images/default-avatar.svg'}
        className="absolute bottom-0 left-10 translate-y-1/2 rounded-full w-[12.5rem] h-[12.5rem] object-cover"
        aria-hidden="true"
      />
    </div>
  );
}
