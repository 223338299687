import { CategoryIcon } from '@icons';
import { NotificationsStore } from '@stores';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const navigation = [
  {
    text: 'New',
    icon: <CategoryIcon className="fill-transparent stroke-current" />,
    path: 'new',
  },
  {
    text: 'All',
    icon: <CategoryIcon className="fill-transparent stroke-current" />,
    path: 'all',
  },
];

const NotificationsScene = observer(() => {
  useEffect(() => {
    async function load() {
      await NotificationsStore.fetch();
    }
    void load();
  }, []);

  useEffect(() => {
    // Mark new notifications as viewed after 1 sec
    const timeout = setTimeout(async () => {
      await NotificationsStore.markView();
    }, 1000);
    // If the user leaves fasteter than 1 sec - notifications won't be marked as viewed
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="flex flex-col gap-7 px-12 py-7.5">
      <h1 className="text-4.5xl font-bold text-midnight-100">Notifications</h1>

      <nav aria-labelledby="notification-navigation">
        <h2 id="notification-navigation" className="sr-only">
          Notification navigation
        </h2>

        <ul className="relative inline-flex gap-9 items-center">
          <div className="absolute h-0.5 bottom-0 inset-x-0 bg-midnight-20 rounded-full"></div>

          {navigation.map(({ text, path, icon }) => (
            <li key={path}>
              <NavLink to={path} replace>
                {({ isActive }) => (
                  <div className="relative flex flex-col gap-3">
                    <div
                      className={classNames(
                        'flex gap-1.5 items-center cursor-pointer',
                        isActive ? 'text-midnight-100' : 'text-midnight-70',
                      )}
                      data-active={isActive}
                    >
                      {icon}
                      <span>{text}</span>
                    </div>
                    <div
                      className={classNames(
                        'h-0.5 w-full',
                        isActive ? 'bg-midnight-100' : 'bg-transparent',
                      )}
                    ></div>
                  </div>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>

      <div className="flex flex-col gap-4 bg-white rounded-2.5xl p-5">
        <Outlet />
      </div>
    </div>
  );
});

export default NotificationsScene;
