import { forwardRef, useCallback, useRef } from 'react';
import classNames from 'classnames';
import './DButton.scss';
import { useComponentDidMount } from '@hooks';
import { BUTTON_THEME, BUTTON_TYPE, KeyboardCode, SIZE } from '@constants';
import { isNil } from '@utils';
import { Link, useNavigate } from 'react-router-dom';
import { SvgIcon } from '@icons';

const defaultProps = {
  type: 'button',
  tabIndex: 0,
};

// interface Props extends ClassName, DataAttr {
//   theme?: BUTTON_THEME;
//   size?: SIZE;
//   name?: string;
//   type?: BUTTON_TYPE;
//   role?: WAI_ARIA_ROLE;
//   title?: ReactNode;
//   titlePreformatted?: boolean;
//   autoFocus?: boolean;
//   disabled?: boolean;
//   buttonRef?: any;
//   linkTo?: string;
//   target?: string;
//   tabIndex?: number;
//   iconComponent?: IconComponent;
//   rounded: boolean;
//   onClick?: Callback;
//   onMouseEnter?: Callback;
//   onMouseOut?: Callback;
// }

// TODO: create new component IconButton and remove the logic for it from here
const ButtonComponent = ({
  className,
  theme = BUTTON_THEME.PRIMARY,
  size = SIZE.M,
  children,
  name,
  type = BUTTON_TYPE.BUTTON,
  role,
  title,
  titlePreformatted,
  autoFocus,
  disabled,
  buttonRef,
  linkTo,
  target,
  tabIndex = 0,
  iconComponent,
  rounded,
  onClick,
  onMouseEnter,
  onMouseOut,
  ...other
}) => {
  const bRef = useRef();
  const navigate = useNavigate();

  useComponentDidMount(() => {
    if (autoFocus === true) {
      bRef.current?.focus();
    }
  });

  const setRef = useCallback((ref) => {
    bRef.current = ref;

    if (buttonRef) {
      if (typeof buttonRef === 'function') {
        buttonRef(ref);
      } else {
        buttonRef.current = ref;
      }
    }
  }, [buttonRef]);

  const isButton = isNil(linkTo);
  const Component = isButton ? 'button' : Link;
  let props = {};

  if (isButton) {
    props = {
      ...props,
      name,
      type,
      disabled,
    };
  } else {
    props = {
      ...props,
      to: linkTo,
      target,
      'aria-disabled': disabled,
    };
  }

  const onKeyDown = (event) => {
    if (!isButton && event.code === KeyboardCode.Space) {
      event.preventDefault();
      navigate(linkTo);
    }
  };

  const {
    fullWidth,
    opened,
    ...elementProps
  } = other;

  return (
    <Component
      ref={setRef}
      className={classNames('cr-btn', {
        'cr-btn--disabled': disabled,
        'cr-btn--rounded': rounded,
        'cr-btn--icon': Boolean(iconComponent),
        [`cr-btn--theme-${theme}`]: Boolean(theme),
        [`cr-btn--size-${size}`]: Boolean(size),
      }, className)}
      {...props}
      role={role}
      tabIndex={disabled ? -1 : tabIndex}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      onKeyDown={onKeyDown}
      {...elementProps}
    >
      {/* TODO: decide which variant is better. Use both children or add a check */}
      {children}

      {Boolean(iconComponent) && (
        <SvgIcon
          icon={(iconComponent)}
          size={size}
        />
      )}
    </Component>
  );
};

export const DButton = forwardRef((props, ref) => {
  return (
    <ButtonComponent
      {...defaultProps}
      {...props}
      buttonRef={ref}
    />
  );
});

DButton.displayName = 'DButton';

export const SubmitButton = props => (
  <DButton
    type={BUTTON_TYPE.SUBMIT}
    {...props}
  />
);
