import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { LoadingWrapper } from '../LoadingWrapper';
import { CheckboxBase } from './CheckboxBase';
import './styles.scss';

export const CHECKBOX_THEME = {
  DEFAULT: 'default',
  BLACK: 'black',
  SILVER_SAND: 'silver-sand',
};

// interface Props extends ClassName {
//   name?: string;
//   value?: boolean;
//   autoFocus?: boolean;
//   loading?: boolean;
//   focusable?: boolean;
//   disabled?: boolean;
//   hovered?: boolean;
//   indeterminate?: boolean;
//   highlightOnHover?: boolean;
//   theme?: CHECKBOX_THEME;
//   size?: SIZE;
//   label?: React.ReactNode;
//   onChange?: Callback;
//   onBlur?: Callback;
//   onClick?: Callback;
// }

export const DCheckbox = (props) => {
  const inputRef = useRef();

  useEffect(() => {
    updateIndeterminateState();

    // eslint-disable-next-line
  }, [props.indeterminate]);

  const updateIndeterminateState = () => {
    inputRef.current.indeterminate = props.indeterminate;
  };

  const {
    name,
    value,
    autoFocus,
    loading = false,
    focusable,
    disabled,
    hovered,
    indeterminate,
    className,
    theme,
    size,
    label,
    highlightOnHover,
    onClick,
    onChange,
    onBlur,
    ...elementProps
  } = props;

  // rework
  const tabIndex = focusable === false ? -1 : undefined;

  return (
    <LoadingWrapper loading={loading}>
      <label
        className={classNames(
          'checkbox',
          {
            'checkbox--black': theme === CHECKBOX_THEME.BLACK,
            'checkbox--silver-sand': theme === CHECKBOX_THEME.SILVER_SAND,
            'checkbox--labeled': Boolean(label),
            'checkbox--highlight-on-hover': Boolean(highlightOnHover),
            'checkbox--hovered': Boolean(hovered),
          },
          size && `checkbox--size-${size}`,
          className,
        )}
        tabIndex={tabIndex}
        onClick={onClick}
        {...elementProps}
      >
        <CheckboxBase
          ref={inputRef}
          name={name}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
          value={value}
          disabled={disabled || loading}
          onChange={onChange}
          onBlur={onBlur}
        />
        <span />

        {label && <span className="checkbox__label">{label}</span>}
      </label>
    </LoadingWrapper>
  );
};
