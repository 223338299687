import { MessengerContext } from '@/contexts';
import { MessengerActiveRoomContext } from '@/contexts/MessengerActiveRoomContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

export default function InvitedToRoom() {
  const { matrixClient } = useContext(MessengerContext);
  const { room } = useContext(MessengerActiveRoomContext);
  const [busy, setBusy] = useState(false);

  const memberEvent = room.getMember(room.myUserId).events.member;
  const inviteSenderId = memberEvent.getSender();
  const inviter = room.getMember(inviteSenderId);

  async function handleAccept() {
    setBusy(true);
    try {
      const me = room.getMember(room.myUserId);
      const DMInviter = me.getDMInviter();

      await matrixClient.joinRoom(room.roomId);

      // If DMInviter is found - save this room as DM chat
      if (DMInviter) {
        const directEventContent = matrixClient
          .getAccountData('m.direct')
          ?.getContent();

        const chatIds = directEventContent?.[DMInviter] ?? [];
        chatIds.push(room.roomId);
        await matrixClient.setAccountData('m.direct', {
          ...directEventContent,
          [DMInviter]: chatIds,
        });
      }
    } catch (error) {
      console.error('Join room error', error);
      toast.error('Cannot join the room');
    } finally {
      setBusy(false);
    }
  }
  async function handleReject() {
    setBusy(true);
    try {
      await matrixClient.leave(room.roomId);
    } catch (error) {
      console.error('Leave room error', error);
      toast.error('Cannot reject the invitation');
    } finally {
      setBusy(false);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <h2 className="font-bold text-xl text-midnight-90">
        Do you want to join <span className="text-sea-70">{room.name}</span>?
      </h2>

      <p>
        <span className="font-bold">{inviter.name}</span> invited you
      </p>

      <div className="flex gap-4">
        <button
          type="button"
          className="button-main button-m"
          disabled={busy}
          onClick={handleAccept}
        >
          Accept
        </button>
        <button
          type="button"
          className="button-outline button-m"
          disabled={busy}
          onClick={handleReject}
        >
          Reject
        </button>
      </div>
    </div>
  );
}
