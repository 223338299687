import { CheckmarkIcon, ChevronDownIcon } from '@/icons';
import { MyScheduleService } from '@/services';
import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';

export default function SelectSchedule({ value, onChange, label, disabled }) {
  const [schedules, setSchedules] = useState(undefined);

  const loadSchedules = useCallback(async () => {
    const list = await MyScheduleService.list();
    setSchedules(list.data);
  }, []);

  // Load data on first render
  useEffect(() => {
    loadSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reload data when new schedule was created
  useEffect(() => {
    window.addEventListener('drreamz:schedule.created', loadSchedules);
    return () =>
      window.removeEventListener('drreamz:schedule.created', loadSchedules);
  }, [loadSchedules]);

  return (
    <Listbox
      value={value}
      onChange={onChange}
      disabled={disabled || schedules === undefined}
    >
      {label && <Listbox.Label>{label}</Listbox.Label>}

      <div className="relative">
        <Listbox.Button className="relative w-full cursor-default rounded-md py-3 pl-4 pr-10 border border-midnight-50 font-normal focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-50 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-10 disabled:bg-midnight-10 disabled:border-midnight-30 disabled:text-midnight-60">
          {({ value }) => (
            <>
              {value ? (
                <span className="block truncate">
                  {schedules &&
                    schedules.find((item) => item.id === value)?.name}
                </span>
              ) : (
                <span className="block truncate">None</span>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 fill-midnight-60"
                  aria-hidden="true"
                />
              </span>
            </>
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute top-full mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none z-10">
            {schedules && (
              <>
                <Listbox.Option
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active
                        ? 'bg-orange-10 text-midnight-100'
                        : 'text-midnight-100'
                    }`
                  }
                  value={null}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-bold' : 'font-normal'
                        }`}
                      >
                        None
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckmarkIcon
                            className="h-5 w-5 fill-orange-50"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
                {schedules.map((item, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? 'bg-orange-10 text-midnight-100'
                          : 'text-midnight-100'
                      }`
                    }
                    value={item.id}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-bold' : 'font-normal'
                          }`}
                        >
                          {item.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <CheckmarkIcon
                              className="h-5 w-5 fill-orange-50"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </>
            )}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
