import { KeyboardCode } from '@constants';
import { Component, forwardRef } from 'react';

class ControlledTextInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      name: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (value !== prevProps.value) {
      this.setState({ value });
    }
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ value });
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;

    this.saveValue();

    if (onBlur) {
      e.persist();
      onBlur(e);
    }
  };

  handleKeyUp = (e) => {
    const { onKeyUp } = this.props;

    if (e.code === KeyboardCode.Enter) {
      this.saveValue();
    }

    if (onKeyUp) {
      e.persist();
      onKeyUp(e);
    }
  };

  saveValue() {
    const { name, value } = this.state;
    const { value: valueProp, onChange } = this.props;

    if (value !== valueProp) {
      this.setState({ value }, () => {
        onChange?.({
          target: {
            name,
            value,
          },
        });
      });
    }
  }

  render() {
    const { inputRef, ...otherProps } = this.props;

    return (
      <input
        ref={inputRef}
        {...otherProps}
        value={this.state.value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onKeyUp={this.handleKeyUp}
      />
    );
  }
}

export const BaseTextInput = forwardRef((props, ref) => {
  const { immediateChange, ...otherProps } = props;

  if (immediateChange === false) {
    return <ControlledTextInput {...otherProps} inputRef={ref} />;
  }

  return <input {...otherProps} ref={ref} />;
});

BaseTextInput.displayName = 'BaseTextInput';
