import { forwardRef, useCallback } from 'react';
import { noop } from '@utils';

function processEventData({ target: { name, checked } }) {
  return {
    target: {
      value: checked,
      name,
    }
  };
}

// interface Props extends ClassName {
//   name: string;
//   value: boolean;
//   tabIndex: number;
//   autoFocus: boolean;
//   disabled: boolean;
//   onChange?: Callback;
//   onBlur?: Callback;
// }

export const CheckboxBase = forwardRef(({
  className,
  name,
  tabIndex,
  autoFocus,
  value,
  disabled,
  onChange,
  onBlur = noop,
  ...props
}, ref) => {
  const handleChange = useCallback((e) => {
    onChange?.(processEventData(e));
  }, [onChange]);

  const handleBlur = useCallback((e) => {
    onBlur(processEventData(e));
  }, [onBlur]);

  return (
    <input
      ref={ref}
      className={className}
      type="checkbox"
      name={name}
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      checked={value === true}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
});

CheckboxBase.displayName = 'CheckboxBase';
