import { isNil } from '../isNil';

class BaseValidator {
   messageProp;
   message = '';
   type = '';

   constructor({
     message,
   } = {}) {
     if (isNil(message) === false) {
       this.messageProp = message;
     }
   }

   validate() {
     throw new Error('Not implemented');
   }

   getErrorMessage() {
     return isNil(this.messageProp) ? this.message : this.messageProp;
   }

   getErrorType() {
     return this.type;
   }
}

export { BaseValidator };
