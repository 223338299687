import RichTextEditor from '@/components/RichTextEditor';
import {
  SessionInfoDescription,
  SessionInfoFiles,
  SessionInfoTasks,
} from '@/components/SessionInfo';
import {
  ArrowLeftIcon,
  DocumentIcon,
  RoundSuccessIcon,
  TrashIcon,
} from '@/icons';
import { SessionInfoService, UploadService } from '@/services';
import { humanFileSize } from '@/utils';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function SessionEditScene() {
  const { id: session_id } = useParams();
  const [previewMode, setPreviewMode] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(undefined);
  const [updating, setUpdating] = useState(false);

  async function updateSessionInfo() {
    const files = [];
    setUpdating(true);
    try {
      for (const file of sessionInfo.files) {
        if (file.toDelete) {
          await UploadService.remove(file.id);
          continue;
        }
        if (file.toUpload) {
          const { id } = await UploadService.upload(file.toUpload);
          files.push(id);
          continue;
        }
        files.push(file.id);
      }
    } catch (error) {
      toast.error('File uploading error. See console (F12)');
      console.error(error);
      setUpdating(false);
      return;
    }

    try {
      const newInfo = await SessionInfoService.put(session_id, {
        description: sessionInfo.description,
        files: files,
        tasks: sessionInfo.tasks,
      });
      setSessionInfo(newInfo);
      toast.success('All changes saved');
    } catch (error) {
      toast.error('Unable to update session info. See console (F12)');
      console.error(error);
    } finally {
      setUpdating(false);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const info = await SessionInfoService.get(session_id);
        setSessionInfo(
          info || {
            description: '',
            files: [],
            tasks: [],
          },
        );
      } catch (error) {
        toast.error('Session Info retrieving error. See console (F12)');
        console.error(error);
      }
    }
    fetchData();
  }, [session_id]);

  return (
    <div className="flex flex-col gap-8 px-12 py-8 relative">
      <Link
        to={`../${session_id}`}
        className="flex gap-2.5 text-midnight-80 self-start"
      >
        <ArrowLeftIcon className="fill-current" aria-hidden="true" />
        <span className="font-semibold">Session</span>
      </Link>

      <div className="flex flex-col gap-8 px-5 py-6 rounded-2.5xl bg-gray-10">
        <div className="flex justify-between">
          <h1 className="text-4.5xl font-bold text-midnight-100 items-center flex gap-2">
            Edit Session Info
          </h1>
          <div className="flex gap-2 items-center">
            <button
              onClick={updateSessionInfo}
              disabled={updating}
              className="button-main button-s"
            >
              Save
            </button>

            <button
              onClick={() => setPreviewMode(!previewMode)}
              disabled={updating}
              className="button-outline button-s"
            >
              {previewMode ? 'Back to editing' : 'Preview'}
            </button>
          </div>
        </div>

        {sessionInfo &&
          (previewMode ? (
            <SessionInfoPreview sessionInfo={sessionInfo} />
          ) : (
            <SessionInfoEditing
              disabled={updating}
              sessionInfo={sessionInfo}
              onChangeSessionInfo={setSessionInfo}
            />
          ))}
      </div>
    </div>
  );
}

function SessionInfoEditing({ sessionInfo, onChangeSessionInfo, disabled }) {
  const fileInputRef = useRef(null);

  function updateSessionInfo(data) {
    onChangeSessionInfo(Object.assign({}, sessionInfo, data));
  }

  function addTask(event) {
    if (event.target.value !== '') {
      updateSessionInfo({
        tasks: sessionInfo.tasks.concat([event.target.value]),
      });
      event.target.value = '';
    }
  }

  function updateTask(index, text) {
    if (text == '') {
      sessionInfo.tasks.splice(index, 1);
    } else {
      sessionInfo.tasks[index] = text;
    }
    updateSessionInfo({
      tasks: sessionInfo.tasks,
    });
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-bold text-midnight-100">Description</h2>
        <RichTextEditor
          value={sessionInfo.description}
          onChange={({ editor }) =>
            updateSessionInfo({ description: editor.getJSON() })
          }
        />
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <DocumentIcon />
            <h2 className="text-lg font-bold text-midnight-100">Files</h2>
          </div>
        </div>

        <ul className="flex flex-col gap-1">
          <input
            type="file"
            ref={fileInputRef}
            multiple
            className="hidden"
            disabled={disabled}
            onChange={(event) =>
              updateSessionInfo({
                files: sessionInfo.files.concat(
                  Array.from(event.target.files, (i) => ({
                    id: nanoid(),
                    name: i.name,
                    type: i.type,
                    size: i.size,
                    toUpload: i,
                  })),
                ),
              })
            }
          />
          <button
            className="button-second button-m justify-center mb-2"
            disabled={disabled}
            onClick={() => fileInputRef.current.click()}
          >
            Add File
          </button>
          {sessionInfo.files.map((file) => (
            <li
              key={file.id}
              className="flex items-baseline gap-4 px-6 py-2 bg-midnight-10 rounded-xl"
            >
              <p className="text-lg">{file.name}</p>
              <span className="text-midnight-60">
                {humanFileSize(file.size, { dp: 1 })}
              </span>
              <span className="text-midnight-60">{file.type}</span>
              <div className="grow"></div>
              {file.toDelete ? (
                <button
                  type="button"
                  className="self-center flex gap-2 button-ghost py-1 px-3"
                  disabled={disabled}
                  onClick={() => {
                    sessionInfo.files.find(
                      ({ id }) => id === file.id,
                    ).toDelete = false;
                    updateSessionInfo({
                      files: sessionInfo.files,
                    });
                  }}
                >
                  <span className="text-sm font-normal">Restore</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="self-center flex gap-2 button-outline py-1 px-3"
                  disabled={disabled}
                  onClick={() => {
                    if (!file.toUpload) {
                      sessionInfo.files.find(
                        ({ id }) => id === file.id,
                      ).toDelete = true;
                      updateSessionInfo({
                        files: sessionInfo.files,
                      });
                    } else {
                      updateSessionInfo({
                        files: sessionInfo.files.filter(
                          ({ id }) => id !== file.id,
                        ),
                      });
                    }
                  }}
                >
                  <span className="text-sm font-normal">Remove</span>
                  <TrashIcon
                    className="w-4 h-4 fill-current"
                    aria-hidden="true"
                  />
                </button>
              )}
            </li>
          ))}
        </ul>

        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-1">
            <RoundSuccessIcon />
            <h2 className="text-lg font-bold text-midnight-100">Tasks</h2>
          </div>

          <ul className="flex flex-col gap-2">
            {sessionInfo.tasks.map((task, idx) => (
              <li key={idx}>
                <div
                  contentEditable
                  suppressContentEditableWarning
                  disabled={disabled}
                  className="flex w-full bg-midnight-10 rounded-xl px-6 py-3 text-midnight-100 text-lg leading-6 placeholder:text-midnight-40"
                  onBlur={(event) =>
                    updateTask(idx, event.currentTarget.textContent)
                  }
                  onKeyDown={(event) =>
                    event.key == 'Enter' &&
                    updateTask(idx, event.currentTarget.textContent)
                  }
                >
                  {task}
                </div>
              </li>
            ))}

            <input
              type="text"
              placeholder="Add new task"
              className="border border-midnight-60 rounded-xl px-6 py-3 text-midnight-100 text-lg leading-6 placeholder:text-midnight-40"
              disabled={disabled}
              onBlur={addTask}
              onKeyDown={(event) => event.key == 'Enter' && addTask(event)}
            />
          </ul>
        </div>
      </div>
    </>
  );
}
function SessionInfoPreview({ sessionInfo }) {
  return (
    <>
      {sessionInfo.description && (
        <SessionInfoDescription text={sessionInfo.description} />
      )}
      {sessionInfo.files?.length > 0 && (
        <SessionInfoFiles files={sessionInfo.files} />
      )}
      {sessionInfo.tasks?.length > 0 && (
        <SessionInfoTasks tasks={sessionInfo.tasks} />
      )}
    </>
  );
}
