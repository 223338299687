import { THEME, TOOLTIP_PLACEMENT } from '@/constants';
import { InfoIcon } from '@/icons';
import { Link } from 'react-router-dom';
import { Tooltip } from '..';
import GuidelineCheckmark from './GuidelineCheckmark';
import useGuidelineData from './useGuidelineData';
import { LightBulbIcon } from '@heroicons/react/24/solid';

export default function Guideline() {
  const data = useGuidelineData();

  return (
    <div className="p-8 my-8 mx-12 flex flex-col gap-2 bg-gray-10 rounded-2.5xl">
      {/* Header */}
      <div className="flex justify-between">
        <h1 className="text-xl font-bold text-midnight-100">Welcome Checklist</h1>

        <div className="group">
          <Tooltip
            theme={THEME.WHITE}
            placement={TOOLTIP_PLACEMENT.LEFT}
            content="You’re just a few clicks away. Follow these steps to finish setting up your account."
          >
            <InfoIcon className="fill-current" />
          </Tooltip>
        </div>
      </div>

      {/* Guideline Body */}
      <ol className="relative text-midnight-100">
        <li className="flex gap-2" data-complete={data.hasSubscription}>
          <GuidelineCheckmark
            check={data.hasSubscription}
            line={data.hasSubscription && data.hasCoachProfile}
          />
          <div className="pb-6">
            <h2 className="font-bold leading-6 text-midnight-90">
              Become a Member
            </h2>
            {data.hasSubscription || (
              <>
                <p>
                  Subscribe to Drreamz and unlock powerful features inside your
                  new dashboard.
                </p>
                <div className="mt-2">
                  <Link
                    to="/pricing_table"
                    className="button-main button-s w-max"
                  >
                    Pricing Table
                  </Link>
                </div>
              </>
            )}
          </div>
        </li>
        <li className="flex gap-2" data-complete={data.hasCoachProfile}>
          <GuidelineCheckmark
            check={data.hasCoachProfile}
            line={data.hasCoachProfile && data.completeStripeOnboarding}
          />
          <div className="pb-6">
            <h2 className="font-bold leading-6 text-midnight-90">
              Complete Profile Page
            </h2>
            {data.hasCoachProfile || (
              <>
                <p>
                  The coach profile lets your clients know who you are and what
                  you offer. It is separated from the user profile. Your offers
                  will be associated with the coach profile.
                </p>
                <div className="mt-2">
                  <Link
                    to="/dashboard/coach/profile/edit"
                    className="button-main button-s w-max"
                  >
                    Go to Coach Profile
                  </Link>
                </div>
              </>
            )}
          </div>
        </li>
        <li
          className="flex gap-2"
          data-complete={data.completeStripeOnboarding}
        >
          <GuidelineCheckmark
            check={data.completeStripeOnboarding}
            line={data.completeStripeOnboarding && data.connectZoom}
          />
          <div className="pb-6">
            <h2 className="font-bold leading-6 text-midnight-90">
              Connect Stripe
            </h2>
            {data.completeStripeOnboarding || (
              <>
                <p>
                  We are using Stripe Connect to handle payments. You have to
                  complete the onboarding to be able publish and sell your
                  offers.
                </p>
                <div className="mt-2">
                  <Link
                    to="/dashboard/coach/settings/personal_info"
                    className="button-main button-s w-max"
                  >
                    Go to Settings
                  </Link>
                </div>
              </>
            )}
          </div>
        </li>
        <li className="flex gap-2" data-complete={data.connectZoom}>
          <GuidelineCheckmark
            check={data.connectZoom}
            line={data.connectZoom && data.hasOffers}
          />
          <div className="pb-6">
            <h2 className="font-bold leading-6 text-midnight-90">
              Connect Zoom
            </h2>
            {data.connectZoom || (
              <>
                <p>
                  Link your Zoom account with Drreamz to create and share new
                  sessions automatically.
                </p>
                <div className="mt-2">
                  <Link
                    to="/dashboard/coach/settings/personal_info"
                    className="button-main button-s w-max"
                  >
                    Go to Settings
                  </Link>
                </div>
              </>
            )}
          </div>
        </li>
        <li className="flex gap-2" data-complete={data.hasOffers}>
          <GuidelineCheckmark check={data.hasOffers} line={null} />
          <div className="pb-6 w-full">
            <h2 className="font-bold leading-6 text-midnight-90">
              Create First Offer
            </h2>
            {!data.hasOffers ? (
              <p>
                Build custom coaching programs, offers, and packages, and start
                selling today.
              </p>
            ) : (
              <div className="flex items-center gap-2 p-2 border border-sea-70 rounded">
                <LightBulbIcon className="w-4 text-sea-70" aria-hidden="true" />
                <p>
                  Share created offer on your socials. Open offer page and click
                  on a social button.
                </p>
              </div>
            )}
          </div>
        </li>
      </ol>
    </div>
  );
}
