import { OfferCommonInfoForm, OfferPaymentForm } from '@/components/EditOffer';

export default function EditOfferCommonScene() {
  return (
    <>
      <OfferCommonInfoForm />
      <OfferPaymentForm />
    </>
  );
}
