import { OfferSessionRequestForm } from '@/components/EditOffer';
import { OfferContext } from '@/contexts';
import { useContext } from 'react';
import EditOfferTabFallback from './EditOfferTabFallback';

export default function EditOfferScheduleScene() {
  const context = useContext(OfferContext);
  const showFallback = context.offerType !== 'one-to-one';

  return showFallback ? <EditOfferTabFallback /> : <OfferSessionRequestForm />;
}
