import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/zoom`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function createSignature({ meetingId, role = 0 }) {
  const response = await instance.post('/signature', {
    meeting_id: meetingId,
    role,
  });
  return response.data.signature;
}
export async function createZak() {
  const response = await instance.get('/zak');
  return response.data;
}

export async function OAuth(code, redirect_uri) {
  const response = await instance.get('/oauth', {
    params: {
      redirect_uri,
      code,
    },
  });
  return response.data;
}
export async function getStatus() {
  const response = await instance.get('/status');
  return response.data;
}
