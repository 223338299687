import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/user/coach-profile`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function get() {
  const resonse = await instance.get('', {
    validateStatus: (number) => [200, 404].includes(number),
  });
  return resonse.status === 200 ? resonse.data : null;
}

export async function set(data) {
  const resonse = await instance.put('', data);
  return resonse.data;
}
