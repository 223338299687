import { UploadService } from '@/services';
import { humanFileSize } from '@/utils';
import { DocumentIcon, ImageDocumentIcon, VideoDocumentIcon } from '@icons';
import { useCallback, useMemo } from 'react';

function SessionInfoFileCard({ file }) {
  const FileIcon = useMemo(() => {
    switch (file.type?.split('/').at(-1)) {
      case 'webm':
      case 'mkv':
      case 'gif':
      case 'avi':
      case 'wmv':
      case 'mov':
        return <VideoDocumentIcon />;
      case 'svg':
      case 'png':
      case 'jpeg':
        return <ImageDocumentIcon />;
      default:
        return <DocumentIcon />;
    }
  }, [file]);

  return (
    <li className="flex items-center gap-3 px-4 py-2 bg-midnight-10 rounded-xl">
      {FileIcon}
      <div>
        <p className="text-xl">{file.name}</p>
        <span className="text-midnight-60 text-sm">
          {humanFileSize(file.size, { dp: 1 })}
        </span>
      </div>
    </li>
  );
}

export function SessionInfoFiles({ files = [] } = {}) {
  const handleDownloadAll = useCallback(async () => {
    window.open(
      UploadService.getDownloadMultipleLink(
        files.map((i) => i.id),
        'session-files',
      ),
      '_blank',
    );
  }, [files]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <DocumentIcon />
          <h4 className="text-lg font-bold text-midnight-100">Files</h4>
        </div>

        {files.length > 0 && (
          <button
            type="button"
            className="button-outline button-xs"
            onClick={handleDownloadAll}
          >
            Download all
          </button>
        )}
      </div>

      <ul className="flex items-center gap-2">
        {files.map((file, idx) => (
          <SessionInfoFileCard file={file} key={idx} />
        ))}
      </ul>
    </div>
  );
}
