import { makeAutoObservable } from 'mobx';

export class UiStore {
  constructor() {
    this.showLetsGetEmpoweredPopup = false;
    this.showContentContainerBackground = true;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setShowContentContainerBackground() {
    this.showContentContainerBackground = true;
  }

  setHideContentContainerBackground() {
    this.showContentContainerBackground = false;
  }

  setShowLetsGetEmpoweredPopup() {
    this.showLetsGetEmpoweredPopup = true;
  }

  setHideLetsGetEmpoweredPopup() {
    this.showLetsGetEmpoweredPopup = false;
  }
}

export default new UiStore();
