import { httpClient } from '@utils';

const upload = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const resonse = await httpClient.post('/files', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return resonse.data;
};

const getLink = async (file_id) => {
  const resonse = await httpClient.get(`/files/${file_id}/link`);
  return resonse.data;
};

const removeFile = async (file_id) => {
  await httpClient.delete(`/files/${file_id}`);
};

export { upload, getLink, removeFile };
