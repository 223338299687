import StarterKit from '@tiptap/starter-kit';

export default [
  StarterKit.configure({
    heading: {
      HTMLAttributes: {
        class: 'text-midnight-100 font-bold [&:is(h3)]:text-3xl [&:is(h4)]:text-2xl [&:is(h5)]:text-lg'
      },
      levels: [3, 4, 5],
    },
    bulletList: {
      HTMLAttributes: {
        class: 'pl-4 list-disc',
      },
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      HTMLAttributes: {
        class: 'pl-4 list-decimal',
      },
      keepMarks: true,
      keepAttributes: false,
    },
    horizontalRule: {
      HTMLAttributes: {
        class: 'border-midnight-30',
      }
    },
    code: false,
    codeBlock: false,
    blockquote: false,
  }),
];
