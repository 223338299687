import { CrossIcon } from '@/icons';
import { Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

export default function SelectUser({
  value,
  onChange,
  loadData,
  disabled,
  placeholder,
  multiple,
  by,
}) {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    let timeout,
      ignore = false;
    async function findUsers() {
      const data = await loadData(query);

      if (!ignore) {
        setSearchResults(data);
      }
    }

    if (query !== '') {
      timeout = setTimeout(findUsers, 500);
    }

    return () => {
      clearTimeout(timeout);
      ignore = true;
    };
  }, [query, loadData]);

  return (
    <Combobox
      value={value}
      onChange={onChange}
      multiple={multiple}
      by={by}
      disabled={disabled}
    >
      <Combobox.Input
        placeholder={placeholder}
        className="input-main input-m"
        displayValue={(user) =>
          user && !Array.isArray(user)
            ? `${user.first_name} ${user.last_name}`
            : undefined
        }
        onChange={(event) => setQuery(event.target.value)}
      />
      {multiple && value?.length > 0 && (
        <ul className="flex flex-wrap gap-2">
          {value.map((user) => (
            <li
              key={user.id}
              className="flex items-center gap-1 pl-3 pr-1 py-1 text-sm rounded-full bg-midnight-10 border border-midnight-30"
            >
              <span>{`${user.first_name} ${user.last_name}`}</span>
              <CrossIcon className="inline w-5 h-5 fill-midnight-50" />
            </li>
          ))}
        </ul>
      )}
      <Combobox.Options className="flex flex-col gap-1 max-h-60 overflow-auto border border-midnight-50 rounded-md py-2">
        {searchResults.length > 0 ? (
          searchResults.map((user) => (
            <Combobox.Option
              key={user.id}
              value={user}
              className={({ active, selected }) =>
                classNames(
                  'flex gap-4 px-4 py-2 text-sm cursor-pointer',
                  active ? 'bg-orange-10' : 'text-midnight-100',
                  selected && 'font-bold',
                )
              }
            >
              <span>{`${user.first_name} ${user.last_name}`}</span>
              <span className="text-midnight-50">{user.email}</span>
            </Combobox.Option>
          ))
        ) : (
          <div className="py-4 text-midnight-50  text-center">No Results</div>
        )}
      </Combobox.Options>
    </Combobox>
  );
}
