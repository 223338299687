import {
  CoachProfileHeaderAndAvatar,
  CoachProfileRating,
  OfferCard,
} from '@/components';
import extensions from '@/components/RichTextEditor/extensions';
import { MyOfferService } from '@/services';
import { generateHTML } from '@tiptap/html';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default function EditCoachProfilePreview({ value }) {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    async function fetchOffers() {
      try {
        const data = await MyOfferService.list();
        setOffers(data);
      } catch (error) {
        toast.error('Unable to get offers. See console (F12)');
        console.error('Loading offers error.', error);
      }
    }

    fetchOffers();
  }, []);

  return (
    <>
      <CoachProfileHeaderAndAvatar
        avatar={value.avatar}
        header={value.header}
        headerOffset={value.header_offset}
      />

      <div className="h-20">{/* Spacer */}</div>

      <div className="flex flex-col gap-8">
        <div>
          <h1 className="text-midnight-100 text-5xl font-bold">
            <span className="sr-only">Coach Profile of</span>
            {value.name}
          </h1>
          <CoachProfileRating />
        </div>

        <div className="text-midnight-100">
          <h2 className="sr-only">About</h2>
          <div
            className="space-y-[0.75em]"
            dangerouslySetInnerHTML={{
              __html: generateHTML(value.about, extensions),
            }}
          ></div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-midnight-100 text-4xl font-bold">Offers</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
          {offers.map((offer) => (
            <OfferCard
              key={offer.id}
              id={offer.id}
              type={offer.type}
              title={offer.name}
              description={offer.short_description}
              className="h-full"
            />
          ))}
        </div>
      </div>
    </>
  );
}
