import { AppContext } from '@/contexts';
import { CategoryIcon, ImageDocumentIcon, VideoDocumentIcon } from '@icons';
import { WorkspaceService, upload } from '@services';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { WorkspaceFileSection } from '../components';
import { WorkspaceContext } from './WorkspaceContext';

const FileCategories = [
  {
    key: 'images',
    icon: <ImageDocumentIcon className="fill-midnight-60" />,
    title: 'Images',
    validate: (file) => file.type.split('/')[0] === 'image',
  },
  {
    key: 'videos',
    icon: <VideoDocumentIcon className="fill-midnight-60" />,
    title: 'Videos',
    validate: (file) => file.type.split('/')[0] === 'video',
  },
  {
    key: 'other',
    icon: <CategoryIcon className="fill-transparent stroke-midnight-60" />,
    title: 'Others',
    validate: () => true,
  },
];

export default function WorkspaceTabFiles() {
  const appContext = useContext(AppContext);
  const workspace = useContext(WorkspaceContext);
  const [files, setFiles] = useState(undefined);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const canEdit = useMemo(() => appContext.isCoach, [appContext]);

  const fetchFiles = useCallback(async () => {
    const data = await WorkspaceService.listFiles(workspace.id);
    setFiles(data);
  }, [workspace.id]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const groupedFiles = useMemo(() => {
    if (files) {
      const result = new Map(FileCategories.map((c) => [c.key, []]));

      for (const file of files) {
        const category = FileCategories.find((c) => c.validate(file));
        result.get(category.key).push(file);
      }

      return result;
    }
  }, [files]);

  async function addFiles(event) {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setIsFileUploading(true);

      let uploadedFileIds;
      try {
        console.log(files);
        uploadedFileIds = await Promise.all(
          files.map(async (file) => (await upload(file)).id),
        );
        console.log(`Uploaded ${uploadedFileIds.length} successfully`);
      } catch (error) {
        toast.error('File uploading error. See console (F12)');
        console.error('Error during file uploading', error);
        setIsFileUploading(false);
        return;
      }

      try {
        await WorkspaceService.addFiles(workspace.id, uploadedFileIds);
        console.log(`Added ${uploadedFileIds.length} files to the workspace`);
        toast.success('Files was added');
        fetchFiles();
      } catch (error) {
        toast.error('Unable to add a file. See console (F12)');
        console.error('Error during adding a file', error);
        // TODO: Clear uploaded files
      } finally {
        event.target.value = null;
        setIsFileUploading(false);
      }
    }
  }

  async function removeFiles(file) {
    try {
      await WorkspaceService.removeFiles(workspace.id, [file.id]);
      toast.success(`File "${file.name}" removed`);
      fetchFiles();
    } catch (error) {
      toast.error('Unable to remove a file. See console (F12)');
      console.error('Error during file removing', error);
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <div>
        <button
          type="button"
          className="button-main button-s w-max"
          disabled={isFileUploading}
          onClick={() =>
            document.getElementById('workspace-add-files-input').click()
          }
        >
          {isFileUploading ? 'Uploading...' : 'Add Files'}
        </button>

        <label htmlFor="workspace-add-files-input" className="sr-only">
          Select file to upload
        </label>
        <input
          id="workspace-add-files-input"
          type="file"
          accept=".pdf, .doc, .txt, .docx, image/png, image/jpg, image/jpeg, image/*, video/*, .pdf, .doc, .docx"
          multiple
          className="sr-only"
          disabled={isFileUploading}
          onChange={addFiles}
        />
      </div>

      {groupedFiles &&
        FileCategories.map((cartgory) => (
          <WorkspaceFileSection
            key={cartgory.key}
            icon={cartgory.icon}
            title={cartgory.title}
            files={groupedFiles.get(cartgory.key)}
            editable={canEdit}
            onRemove={removeFiles}
          />
        ))}
    </div>
  );
}
