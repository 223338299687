import { makeAutoObservable } from 'mobx';
import {
  addPaymentMethod,
  deletePaymentMethod,
  getPaymentMethods,
} from '@services';

class PaymentsStore {
  constructor() {
    this.paymentMethods = [];
    makeAutoObservable(this);
  }

  setPaymentMethods(methods) {
    this.paymentMethods = methods;
  }

  async getMethods() {
    const { data } = await getPaymentMethods();
    if (data.length) {
      this.setPaymentMethods(data);
    }
  }

  async addMethod(data) {
    await addPaymentMethod(data);
  }

  async deleteMethod(methodId) {
    await deletePaymentMethod(methodId);
  }
}

export default new PaymentsStore();
