import { useCallback } from 'react';
import classNames from 'classnames';
import './styles.scss';

export const SWITCHER_THEME = {
  DEFAULT: 'default',
  SILVER_SAND: 'silver-sand',
};

// interface Props extends ClassName {
//   name?: string;
//   value: boolean;
//   showLabel?: boolean;
//   labelOnText?: string;
//   labelOffText?: string;
//   autoFocus?: boolean;
//   disabled?: boolean;
//   size?: SIZE;
//   theme?: SWITCHER_THEME;
//   onChange?: Callback;
// }

export const Switcher = ({
  className,
  name,
  value,
  autoFocus,
  showLabel,
  labelOnText,
  labelOffText,
  disabled,
  size,
  theme,
  onChange,
  ...otherProps
}) => {
  const handleChange = useCallback(({ target: { name, checked } }) => {
    onChange?.({
      target: {
        name,
        value: checked,
      },
    });
  }, [onChange]);

  return (
    <label
      className={classNames('switcher', {
        'switcher--theme-silver-sand': theme === SWITCHER_THEME.SILVER_SAND,
      }, size && `switcher--size-${size}`, className)}
      {...otherProps}
    >
      <input
        type="checkbox"
        name={name}
        autoFocus={autoFocus}
        checked={value}
        disabled={disabled}
        onChange={handleChange}
      />

      <span/>
    </label>
  );
};
