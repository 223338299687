import { SelectUser } from '@/components';
import { TrashIcon } from '@/icons';
import { Dialog } from '@headlessui/react';
import { UserService, WorkspaceService } from '@services';
import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { WorkspaceContext } from '../../scenes/WorkspaceContext';

export function WorkspaceAddMemberDialog({ isOpen, onClose } = {}) {
  const workspace = useContext(WorkspaceContext);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const addMember = useCallback(async () => {
    if (selectedUsers) {
      try {
        await WorkspaceService.addMembers(
          workspace.id,
          selectedUsers.map((i) => i.id),
        );
        toast.success('Members added');
        onClose();
      } catch (error) {
        console.log(`Add member error`, error);
        toast.success('Unable add member. See console (F12)');
      }
    }
  }, [onClose, selectedUsers, workspace.id]);

  async function findUsers(query) {
    const list = await UserService.search(query);
    return list.data;
  }

  function handleClose() {
    setSelectedUsers([]);
    onClose instanceof Function && onClose();
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} className="relative z-50">
      <div className="fixed inset-0 bg-black-500/80" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="flex flex-col gap-4 w-full max-w-2xl px-[30px] py-12 bg-gray-10 rounded-2.5xl">
          <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
            Add members to this workpsace
          </Dialog.Title>

          <ul className="flex flex-wrap gap-2">
            {selectedUsers.map((user) => (
              <li
                key={user.id}
                className="flex items-center gap-1 p-2 border border-midnight-40 rounded-lg"
              >
                <span>{`${user.first_name} ${user.last_name}`}</span>
                <button
                  type="button"
                  className="button-clear p-1"
                  onClick={() =>
                    setSelectedUsers(
                      selectedUsers.filter(({ id }) => id !== user.id),
                    )
                  }
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              </li>
            ))}
          </ul>

          <SelectUser
            value={selectedUsers}
            onChange={setSelectedUsers}
            loadData={findUsers}
            by="id"
            multiple
            placeholder="Search by full name, email"
          />

          <button
            type="button"
            className="flex justify-center button-main button-s"
            disabled={selectedUsers.length === 0}
            onClick={addMember}
          >
            {selectedUsers.length
              ? `Add ${selectedUsers.length} members to this workspace`
              : 'Select a member above'}
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
