import { httpClient } from '@utils';

const getPaymentMethods = () => {
  return httpClient.get('/payment');
};

const addPaymentMethod = (data) => {
  return httpClient.post('/payment', data);
};

const deletePaymentMethod = (id) => {
  return httpClient.delete(`/payment/${id}`);
};

const setDefaultPaymentMethod = (id) => {
  return httpClient.put(`/payment/default/${id}`);
};

export {
  getPaymentMethods,
  addPaymentMethod,
  deletePaymentMethod,
  setDefaultPaymentMethod,
};
