import { SpinnerIcon, SvgIcon } from '@icons';
import { SIZE, THEME } from '@constants';
import './styles.scss';
import classNames from 'classnames';

export const LoadingSpinner = ({
  className,
  size = SIZE.M,
  theme = THEME.INFO,
}) => {
  return (
    <span className={classNames('loading-wrapper__spinner-wrapper', className)}>
      <SvgIcon
        className="loading-wrapper__spinner"
        icon={<SpinnerIcon/>}
        theme={theme}
        size={size}
      />
    </span>
  );
};

