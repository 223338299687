import { useCallback } from 'react';
import classNames from 'classnames';
import { isNil, noop } from '@utils';
import { useRadioGroup } from '../RadioGroup/useRadioGroup';
import { KeyboardCode } from '@constants';
import { LoadingWrapper } from '@components';
import './styles.scss';

// interface Props {
//   className?: string;
//   name?: string;
//   value: string;
//   checked?: boolean;
//   label?: React.ReactNode;
//   size?: SIZE;
//   uncheckable?: boolean;
//   disabled?: boolean;
//   loading?: boolean;
//   onChange?: Callback;
//   onBlur?: Callback;
//   onClick?: Callback;
// }

export const Radio = ({
  className,
  name: nameProp,
  value,
  checked: checkedProp,
  label,
  size,
  uncheckable: uncheckableProp,
  disabled: disabledProp,
  loading,
  onChange,
  onBlur,
  onClick,
}) => {
  const radioGroup = useRadioGroup();

  let checked = checkedProp;
  let uncheckable = uncheckableProp;
  let name = nameProp;
  let disabled = disabledProp;

  if (radioGroup) {
    if (isNil(checked)) {
      checked = radioGroup.value === value;
    }

    if (isNil(uncheckable)) {
      uncheckable = radioGroup.uncheckable;
    }

    if (isNil(name)) {
      name = radioGroup.name;
    }

    if (disabled !== true) {
      disabled = radioGroup.disabled || radioGroup.loading;
    }
  }

  function createChangeEvent() {
    return {
      target: {
        name,
        value: checked ? undefined : value,
      }
    };
  }

  const handleClick = (event) => {
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick(event);
    }

    if (checked && uncheckable !== true) {
      return;
    }

    if (onChange) {
      onChange(createChangeEvent());
    }

    if (radioGroup?.onChange) {
      radioGroup.onChange(createChangeEvent());
    }
  };

  const handleKeyUp = (event) => {
    if (disabled) {
      return;
    }

    if (checked && uncheckable !== true) {
      return;
    }

    if (event.code === KeyboardCode.Space) {
      if (onChange) {
        onChange(createChangeEvent());
      }

      if (radioGroup?.onChange) {
        radioGroup.onChange(createChangeEvent());
      }
    }
  };

  const handleBlur = useCallback((event) => {
    if (onBlur) {
      onBlur({
        target: {
          value: event.target.value,
          name: event.target.name,
        }
      });
    }
  }, [onBlur]);

  const handlePropagationStop = (event) => {
    event.stopPropagation();
  };

  return (
    <LoadingWrapper loading={loading}>
      <label
        className={classNames('radio', {
          [`radio--size-${size}`]: Boolean(size),
        }, className)}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
      >
        <input
          type="radio"
          name={name}
          checked={checked}
          value={value}
          disabled={disabled || loading}
          onChange={noop}
          onBlur={handleBlur}
          onClick={handlePropagationStop}
        />
        <span/>

        {label && (
          <span className="radio__label">
            {label}
          </span>
        )}
      </label>
    </LoadingWrapper>
  );
};
