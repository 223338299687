import { ChevronDownIcon } from '@/icons';
import { Menu, Transition } from '@headlessui/react';
import { useCurrentEditor } from '@tiptap/react';
import classNames from 'classnames';
import { Fragment } from 'react';

export default function EditorToolbar() {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  function getSelectedNodeType() {
    switch (true) {
      case editor.isActive('heading', { level: 3 }):
        return 'Heading 1';
      case editor.isActive('heading', { level: 4 }):
        return 'Heading 2';
      case editor.isActive('heading', { level: 5 }):
        return 'Heading 3';
      case editor.isActive('paragraph'):
      default:
        return 'Paragraph';
    }
  }

  return (
    <div className="flex flex-row items-stretch rounded-t-md border border-midnight-50 divide-x divide-midnight-50 text-midnight-100">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex justify-between px-4 py-0 w-36 h-full font-normal border-none rounded-none rounded-tl-md  focus:bg-midnight-20">
          <span className="text-sm">{getSelectedNodeType()}</span>
          <ChevronDownIcon className="w-4 fill-current" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-1 w-fit overflow-hidden origin-top-right rounded bg-white shadow-lg focus:outline-none z-10">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() => editor.chain().focus().setParagraph().run()}
                  className={classNames(
                    'w-full px-4 py-1 text-sm whitespace-nowrap font-normal rounded-none',
                    {
                      'bg-orange-50 text-white font-semibold':
                        editor.isActive('paragraph'),
                      'bg-midnight-20': active,
                    },
                  )}
                >
                  Paragraph
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 3 }).run()
                  }
                  className={classNames(
                    'w-full px-4 py-1 text-sm whitespace-nowrap font-normal rounded-none',
                    {
                      'bg-orange-50 text-white font-semibold': editor.isActive(
                        'heading',
                        {
                          level: 3,
                        },
                      ),
                      'bg-midnight-20': active,
                    },
                  )}
                >
                  Heading 1
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 4 }).run()
                  }
                  className={classNames(
                    'w-full px-4 py-1 text-sm whitespace-nowrap font-normal rounded-none',
                    {
                      'bg-orange-50 text-white font-semibold': editor.isActive(
                        'heading',
                        {
                          level: 4,
                        },
                      ),
                      'bg-midnight-20': active,
                    },
                  )}
                >
                  Heading 2
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 5 }).run()
                  }
                  className={classNames(
                    'w-full px-4 py-1 text-sm whitespace-nowrap font-normal rounded-none [&:is(h1)]:text-lg',
                    {
                      'bg-orange-50 text-white font-semibold': editor.isActive(
                        'heading',
                        {
                          level: 5,
                        },
                      ),
                      'bg-midnight-20': active,
                    },
                  )}
                >
                  Heading 3
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>

      <div className="flex flex-row px-3">
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={classNames(
            'px-0.5 py-1 rounded-none border-none focus:bg-midnight-20',
            {
              'bg-midnight-30': editor.isActive('bold'),
            },
          )}
        >
          <img
            src="/icons/editor/inline/bold.svg"
            className="w-5"
            aria-hidden="true"
          />
          <span className="sr-only">Bold</span>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={classNames(
            'px-0.5 py-1 rounded-none border-none focus:bg-midnight-20',
            {
              'bg-midnight-30': editor.isActive('italic'),
            },
          )}
        >
          <img
            src="/icons/editor/inline/italic.svg"
            className="w-5"
            aria-hidden="true"
          />
          <span className="sr-only">Italic</span>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={classNames(
            'px-0.5 py-1 rounded-none border-none focus:bg-midnight-20',
            {
              'bg-midnight-30': editor.isActive('strike'),
            },
          )}
        >
          <img
            src="/icons/editor/inline/strikethrough.svg"
            className="w-5"
            aria-hidden="true"
          />
          <span className="sr-only">Strike</span>
        </button>

        {/* <button
          type="button"
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className="p-0"
        >
          Clear marks
        </button> */}
      </div>

      <div className="flex flex-row gap-1 px-3">
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={classNames(
            'px-0.5 py-1 rounded-none border-none focus:bg-midnight-20',
            {
              'bg-midnight-30': editor.isActive('bulletList'),
            },
          )}
        >
          <img
            src="/icons/editor/list/unordered.svg"
            className="w-5"
            aria-hidden="true"
          />
          <span className="sr-only">Bullet list</span>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={classNames(
            'px-0.5 py-1 rounded-none border-none focus:bg-midnight-20',
            {
              'bg-midnight-30': editor.isActive('orderedList'),
            },
          )}
        >
          <img
            src="/icons/editor/list/ordered.svg"
            className="w-5"
            aria-hidden="true"
          />
          <span className="sr-only">Ordered list</span>
        </button>
      </div>

      <div className="flex flex-row gap-1 px-3">
        <button
          type="button"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          className="px-0.5 py-1 font-normal text-sm rounded-none border-none focus:bg-midnight-20"
        >
          Divider
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().setHardBreak().run()}
          className="px-0.5 py-1 font-normal text-sm rounded-none border-none focus:bg-midnight-20"
        >
          Line break
        </button>
      </div>

      <div className="flex flex-row gap-1 px-3">
        <button
          type="button"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className="px-0.5 py-1 font-normal text-sm rounded-none border-none focus:bg-midnight-20 disabled:text-midnight-30"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="w-5 fill-current"
            aria-hidden="true"
          >
            <path d="M761 1024c113-206 132-520-313-509v253l-384-384 384-384v248c534-13 594 472 313 775z"></path>
          </svg>
          <span className="sr-only">Undo</span>
        </button>
        <button
          type="button"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className="px-0.5 py-1 font-normal text-sm rounded-none border-none focus:bg-midnight-20 disabled:text-midnight-30"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="w-5 fill-current"
            aria-hidden="true"
          >
            <path d="M576 248v-248l384 384-384 384v-253c-446-10-427 303-313 509-280-303-221-789 313-775z"></path>
          </svg>
          <span className="sr-only">Redo</span>
        </button>
      </div>
    </div>
  );
}
