import { checkAcceptIsValid } from '@/utils';
import classNames from 'classnames';
import { useRef } from 'react';

export default function DropFileArea({
  className,
  onFile = () => {},
  ...rest
}) {
  const fileInput = useRef(null);

  function dropHandler(event) {
    // Prevent file from being opened
    event.preventDefault();

    let files = [];

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      files = Array.from(event.dataTransfer.items, (item) =>
        // If dropped items aren't files, reject them
        item.kind === 'file' ? item.getAsFile() : null,
      ).filter((item) => item !== null);
    } else {
      // Use DataTransfer interface to access the file(s)
      files = Array.from(event.dataTransfer.files);
    }

    // If accept is set -> validate file types
    if (rest.accept) {
      files = files.filter((file) =>
        checkAcceptIsValid(rest.accept, file.type),
      );
    }

    onFile(files);
  }

  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center gap-2 p-4 border-midnight-50 border border-dashed rounded-md',
        className,
      )}
      onDragOver={(event) => event.preventDefault()}
      onDrop={dropHandler}
    >
      <img
        src="/images/svg-photo-upload.svg"
        className="translate-x-1"
        aria-hidden="true"
      />

      <div className="flex items-center">
        <button
          type="button"
          className="button-main button-xs"
          disabled={rest.disabled}
          onClick={() => fileInput.current.click()}
        >
          Upload
        </button>
      </div>

      <input
        {...rest}
        type="file"
        className="hidden"
        ref={fileInput}
        onChange={(event) => onFile(Array.from(event.target.files))}
      />
    </div>
  );
}
