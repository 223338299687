import EditPrivateNoteForm from '@/components/EditPrivateNoteForm';
import PrivateNote from '@/components/PrivateNote';
import { PrivateNotesService } from '@/services';
import { useEffect, useState } from 'react';

export function TestingGroundsScene() {
  const [notes, setNotes] = useState([]);
  const [newNote, setnewNote] = useState({
    type: 'doc',
    content: [{ type: 'paragraph' }],
  });

  async function createNote() {
    const createdNote = await PrivateNotesService.create({
      user_id: 'bb007b52-5e23-4eff-b9ce-f17c821a64dc',
      context: 'user',
      content: newNote,
    });
    console.log(createdNote);
  }


  useEffect(() => {
    async function load() {
      const noteList = await PrivateNotesService.list();
      setNotes(noteList);
    }
    load();
  }, []);

  return (
    <>
      <EditPrivateNoteForm
        value={newNote}
        onChange={setnewNote}
        onCreate={createNote}
      />
      <div className="flex flex-col gap-2 p-4" >
        {notes.map((note) => (
          <PrivateNote key={note.id} {...note} />
        ))}
      </div>
    </>
  );
}
