import { ZoomService, SessionService } from '@/services';
import { UserStoreV2 } from '@/stores';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const zoomClient = ZoomMtgEmbedded.createClient();

export function ZoomMeetingScene() {
  const { id: sessionId } = useParams();
  const [session, setSession] = useState(undefined);
  const [joined, setJoined] = useState(false);
  const userStore = UserStoreV2.useState();

  // Load session data
  useEffect(() => {
    async function fetchSession() {
      const data = await SessionService.get(sessionId);
      setSession(data);
    }
    fetchSession();
  }, [sessionId]);

  async function join() {
    if (session && session.zoom_meeting_id) {
      try {
        setJoined(undefined);
        const is_host = session.host_id === userStore.state.user.id;
        console.log(`Proceeed as a ${is_host ? 'host' : 'participant'}`);

        const signature = await ZoomService.createSignature({
          meetingId: session.zoom_meeting_id,
          role: is_host ? 1 : 0,
        });

        const zak = is_host ? await ZoomService.createZak() : undefined;

        await zoomClient.init({
          debug: true,
          zoomAppRoot: document.getElementById('meetingSDKElement'),
          language: 'en-US',
          customize: {
            meetingInfo: [
              'topic',
              'host',
              'mn',
              'pwd',
              'telPwd',
              'invite',
              'participant',
              'dc',
              'enctype',
            ],
            video: {
              isResizable: true,
              viewSizes: {
                default: {
                  width: 1000,
                  height: 600,
                },
                ribbon: {
                  width: 300,
                  height: 700,
                },
              },
            },
          },
        });

        await zoomClient.join({
          sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
          signature: signature,
          meetingNumber: session.zoom_meeting_id,
          password: '',
          userName: `${userStore.state.user.first_name} ${userStore.state.user.last_name}`,
          zak: zak,
          error: () => setJoined(false),
          success: () => setJoined(true),
        });
      } catch (error) {
        console.error(error);
        setJoined(false);
      }
    }
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div id="meetingSDKElement"></div>
      {joined === false && (
        <button className="button-main button-m self-center" onClick={join}>
          Join Meeting
        </button>
      )}
    </div>
  );
}
