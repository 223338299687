import classNames from 'classnames';
import { ChevronRightIcon } from '@icons';
import { Link } from 'react-router-dom';

export const LetsGetEmpowered = ({ className }) => {
  const features = [
    {
      iconSrc: '/images/svg-custom-coaching-program.svg',
      title: (
        <>
          Create custom coaching programs, offers, and packages. Promote, sell
          and host within one simple platform.
        </>
      ),
    },
    {
      iconSrc: '/images/svg-organize-your-clients.svg',
      title: (
        <>
          Organize your clients in one central, secure location to seamlessly
          connect, share, and collaborate across all devices.
        </>
      ),
    },
    {
      iconSrc: '/images/svg-drive-growth.svg',
      title: (
        <>
          Drive growth with smart automation that automatically handles
          scheduling, invoicing, and follow-up with clients.
        </>
      ),
    },
  ];

  return (
    <div className={classNames('', className)}>
      <img src="/images/logo_black.svg" alt="logo" />
      <h2 className="text-midnight-100 mt-4 text-4xl font-bold">
        Great, let's get Empowered
      </h2>
      <p className="text-midnight-90 text-base mt-2">
        Discover the different ways you can take your business to the next
        level.
      </p>

      <ul className="mt-9 flex flex-col gap-5">
        {features.map((feature) => (
          <li key={feature.iconSrc} className="flex items-center gap-4">
            <img src={feature.iconSrc} alt="" />

            <p className="text-base text-midnight-90">{feature.title}</p>
          </li>
        ))}
      </ul>

      <p className="text-midnight-90 text-base mt-9">
        Choose a plan to access your complete coaching toolkit.
      </p>

      <div className="mt-12 flex gap-4">
        <Link to="/pricing_table" className="button-main button-m">
          <span>Choose a Plan</span>
          <ChevronRightIcon className="fill-current w-5 h-5" />
        </Link>
      </div>
    </div>
  );
};
