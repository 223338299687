import { OfferContext } from '@/contexts';
import { useContext, useMemo } from 'react';

export default function OfferPaymentForm() {
  const context = useContext(OfferContext);

  const priceInDollars = useMemo(
    () => context.price && (context.price / 100).toFixed(0),
    [context.price],
  );

  return (
    <div className="flex flex-col gap-8 p-7 bg-gray-10 rounded-2.5xl">
      <label
        htmlFor="offer-price-input"
        className="text-xl leading-normal text-midnight-100 font-bold"
      >
        Offer price
        <span className="text-primary">*</span>
      </label>

      <div className="flex flex-col gap-4">
        <p id="offer-price-input-description" className="text-midnight-100">
          Purchase price in dollars ($). Leave zero to make the offer free
        </p>
        <input
          id="offer-price-input"
          type="number"
          value={priceInDollars}
          required
          placeholder="0.00"
          className="input-main input-m input-custom-invalid number-no-spinner"
          onChange={(event) => context.setPrice(event.target.value * 100)}
          aria-describedby="offer-price-input-description"
        />
      </div>
    </div>
  );
}
