import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});

export async function get(offerId) {
  const response = await instance.get(`/offers/${offerId}`, {
    validateStatus: (status) => [200, 404].includes(status),
  });
  return response.status === 200 ? response.data : null;
}

export async function getTimeslots(offerId, { begin, end }) {
  const response = await instance.get(`/offers/${offerId}/timeslots`, {
    params: { begin, end },
  });
  return response.data;
}

export async function getContent(offerId) {
  const response = await instance.get(`/offers/${offerId}/content`, {
    validateStatus(value) {
      return [200, 404].includes(value);
    },
  });
  return response.status === 200 ? response.data : [];
}

export async function getSchedule(offerId) {
  const response = await instance.get(`/offers/${offerId}/booking-schedule`, {
    validateStatus(value) {
      return [200, 404].includes(value);
    },
  });
  return response.status === 200 ? response.data : null;
}

export async function getSessionInfo(offerId) {
  const response = await instance.get(`/offers/${offerId}/session-info`, {
    validateStatus(value) {
      return [200, 404].includes(value);
    },
  });
  return response.status === 200 ? response.data : null;
}

export async function listCoachOffers(coachId) {
  const response = await instance.get(`/coaches/${coachId}/offers`);
  return response.data;
}

/**
 * Temporary function!
 * Get Stripe product associated with an offer.
 * Used to block offer purchase if there is no product to buy.
 * TODO: Unpurchasable product shouldn't be accessible in the first place. Backend should return only valid offers.
 */
export async function getProduct(offerId) {
  const response = await instance.get(`/sync-offers-product/${offerId}`, {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    validateStatus(value) {
      return [200, 404].includes(value);
    },
  });
  return response.status === 200 ? response.data : null;
}
