import extensions from '@/components/RichTextEditor/extensions';
import { generateHTML } from '@tiptap/html';

export function SessionInfoDescription({ text }) {
  return (
    <>
      <h4 className="sr-only">Description</h4>
      <div
        className="space-y-[0.75em]"
        dangerouslySetInnerHTML={{
          __html: generateHTML(text, extensions),
        }}
      ></div>
    </>
  );
}
