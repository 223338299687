import { MessengerActiveRoomContext } from '@/contexts/MessengerActiveRoomContext';
import { SpinnerIcon } from '@/icons';
import { useContext, useEffect } from 'react';
import InvitedToRoom from './InvitedToRoom';
import JoinedRoom from './JoinedRoom';
import LeftRoom from './LeftRoom';
import NoActiveRoom from './NoActiveRoom';

export default function ActiveRoom() {
  console.debug('[ActiveRoom] <render call>');

  const { roomId, room } = useContext(MessengerActiveRoomContext);

  useEffect(() => {
    console.log(
      'Active room changed',
      room?.name,
      room?.roomId,
      room?.selfMembership,
    );
  }, [room]);

  if (!roomId) {
    return <NoActiveRoom />;
  }

  if (!room) {
    return (
      <div className="grow flex items-center justify-center">
        <SpinnerIcon className="fill-midnight-40 animate-spin" />
        <span className="sr-only">Loading the room...</span>
      </div>
    );
  }

  switch (room.getMyMembership()) {
    case 'join':
      return <JoinedRoom />;
    case 'invite':
      return <InvitedToRoom />;
    case 'leave':
      return <LeftRoom />;
    case 'knock':
    default:
      return (
        <div className="grow flex flex-col font-bold items-center justify-center gap-4 text-xl text-midnight-90">
          <p>Well...</p>
          <p>I don't know how to handle a room of this kind.</p>
          <p>😜</p>
        </div>
      );
  }
}
