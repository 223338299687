import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { NoEnteredData } from '@components';
import { SessionService } from '@services';
import SessionListGroup from './src/SessionListGroup';

const ListSize = 10;

export default function SessionListScene() {
  const [sessions, setSessions] = useState([]);
  const [sessionTotal, setSessionTotal] = useState(undefined);
  const [query, setQuery] = useSearchParams();

  const loadOffset = useMemo(() => +query.get('load_after') || 0, [query]);
  const hasBefore = useMemo(() => loadOffset > 0, [loadOffset]);
  const hasMore = useMemo(
    () => sessionTotal && sessionTotal > loadOffset + ListSize,
    [loadOffset, sessionTotal],
  );

  const [upcomingSessions, pastSessions] = useMemo(() => {
    const now = DateTime.now();
    return sessions.reduce(
      (agg, item) => {
        const arr_idx = DateTime.fromISO(item.timestamp) > now ? 0 : 1;
        agg[arr_idx].push(item);
        return agg;
      },
      [[], []],
    );
  }, [sessions]);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await SessionService.list({
          limit: ListSize,
          offset: loadOffset,
          sortBy: 'timestamp',
          sortOrder: 'desc',
          expand: ['offer'],
        });
        setSessions(result.data);
        setSessionTotal(result.total);
      } catch (err) {
        toast.error('Retrive sessions error. See console (F12)');
        console.error(err);
      }
    }
    fetchData();
  }, [loadOffset]);

  return sessions ? (
    <div className="flex flex-col gap-8 p-5 rounded-2.5xl bg-gray-10">
      {sessions.length > 0 ? (
        <>
          {hasBefore && (
            <button
              type="button"
              className="button-ghost button-s self-center"
              onClick={() =>
                setQuery({ load_after: Math.max(loadOffset - ListSize, 0) })
              }
            >
              Show Previous
            </button>
          )}

          <SessionListGroup title="Upcoming" sessions={upcomingSessions} />
          <SessionListGroup title="Past" sessions={pastSessions} />

          {!!hasMore && (
            <button
              type="button"
              className="button-ghost button-s self-center"
              onClick={() => setQuery({ load_after: loadOffset + ListSize })}
            >
              Show More
            </button>
          )}
        </>
      ) : (
        <NoEnteredData
          title="No sessions"
          customImage={
            <img
              src="/images/svg-schedule-clock-calendar.svg"
              alt="Woman writing on a tablet near to giant hourglass, calendar and clock."
            />
          }
        />
      )}
    </div>
  ) : (
    <span className="sr-only">Loading, please wait</span>
  );
}
