import { NoEnteredData, OfferCard, Share } from '@components';
import { flip, offset, shift, useFloating } from '@floating-ui/react';
import { Menu, Popover } from '@headlessui/react';
import useConfirmationDialog from '@hooks/useConfirmationDialog';
import { MyOfferService } from '@services';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function MyOffersScene() {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await MyOfferService.list();
        setOffers(data);
      } catch (err) {
        toast.error('Error during getting offers. See console (F12)');
        console.error("Get user's offers error", err);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-7 px-12 py-7.5">
      <div className="flex justify-between">
        <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
          Offers
        </h1>
        <Link to="new" className="button-main button-s">
          Create
        </Link>
      </div>
      <div className="flex flex-col gap-4 p-7 bg-gray-10 rounded-2.5xl">
        {offers.length ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
            {offers.map((offer) => (
              <OfferItem key={offer.id} {...offer} />
            ))}
          </div>
        ) : (
          <NoEnteredData
            title="No offers"
            customImage={
              <img
                src="/images/svg-money-loss.svg"
                alt="People are trying to reach flying away balloons with the dollar sign."
              />
            }
          />
        )}
      </div>
    </div>
  );
}

function OfferItem(params) {
  const shareFloating = useFloating({
    middleware: [offset(10), flip(), shift()],
  });
  const actionFloating = useFloating({
    middleware: [offset(10), flip(), shift()],
  });

  const { element: ConfirmationDialog, ask: askConfirmation } =
    useConfirmationDialog();

  async function deleteOffer() {
    const confirm = await askConfirmation(
      'Are you sure you want to delete this offer?',
    );
    if (!confirm) return;

    try {
      await MyOfferService.remove(params.id);
      toast.success('Offer has been deleted');
    } catch (error) {
      toast.error('Unable to delete the offer. See conslole (F12)');
      console.error('Delete offer error', error);
    }
  }

  return (
    <OfferCard
      id={params.id}
      type={params.type}
      title={params.name}
      description={params.short_description}
      className="h-full"
    >
      <ConfirmationDialog />
      <div className="grid grid-cols-2 gap-3">
        <Popover className="relative">
          <Popover.Button
            type="button"
            ref={shareFloating.refs.setReference}
            className="button-ghost button-xs w-full justify-center"
          >
            Share
          </Popover.Button>
          <Popover.Panel
            ref={shareFloating.refs.setFloating}
            style={shareFloating.floatingStyles}
            className="w-max bg-gray-10 rounded-lg shadow-lg z-10"
          >
            <Share.ShareList
              url={`${location.origin}/offers/${params.id}`}
              text={params.name}
              className="grid grid-cols-3 grid-rows-2"
            >
              <Share.ShareFacebook className="shrink-0" />
              <Share.ShareTwitter className="shrink-0" />
              <Share.ShareTelegram className="shrink-0" />
              <Share.ShareInstagram className="shrink-0" />
              <Share.ShareLinkedIn className="shrink-0" />
              <Share.CopyLink className="shrink-0" />
            </Share.ShareList>
          </Popover.Panel>
        </Popover>
        <Menu>
          <Menu.Button
            ref={actionFloating.refs.setReference}
            className="button-outline button-xs justify-center"
          >
            Actions
          </Menu.Button>
          <Menu.Items
            ref={actionFloating.refs.setFloating}
            style={actionFloating.floatingStyles}
            className="flex flex-col py-2 rounded-lg bg-gray-10 shadow-lg focus:outline-none"
          >
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/offers/${params.id}`}
                  target="_blank"
                  className={`${
                    active ? 'bg-orange-10 text-orange-50' : 'text-midnight-100'
                  } rounded-none px-4 py-2 text-xs`}
                >
                  Open public page
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={params.id}
                  className={`${
                    active ? 'bg-orange-10 text-orange-50' : 'text-midnight-100'
                  } rounded-none px-4 py-2 text-xs`}
                >
                  Edit
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={`${
                    active ? 'bg-orange-10 text-orange-50' : 'text-danger'
                  } rounded-none px-4 py-2 text-xs font-normal`}
                  onClick={deleteOffer}
                >
                  Delete
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </OfferCard>
  );
}
