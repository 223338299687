import { useEffect, useRef } from 'react';

export default function useDragOffset(props) {
  const { offset = 0, onOffsetChanged = () => {} } = props;
  const prevOffset = useRef(0);
  const movementDelta = useRef(0);

  const preventSelection = (event) => event.preventDefault();

  useEffect(() => {});

  function onDrag(event) {
    movementDelta.current += event.movementY || 0;
    onOffsetChanged(prevOffset.current + movementDelta.current);
  }
  function stopDrag() {
    prevOffset.current = offset;
    movementDelta.current = 0;

    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('mouseup', stopDrag);
    document.removeEventListener('selectstart', preventSelection);
  }

  function startDrag() {
    prevOffset.current = offset;
    movementDelta.current = 0;

    document.addEventListener('mousemove', onDrag);
    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('selectstart', preventSelection);
  }

  return startDrag;
}
