import classNames from 'classnames';
import './styles.scss';

export const FormHelperText = ({
  className,
  children,
  error,
}) => {
  return (
    <div
      className={classNames('form-helper-text', className, {
        'form-helper-text--error': error,
      })}
    >
      {children}
    </div>
  );
};
