import { useFormik } from 'formik';
import { object, string } from 'yup';
import { SvgIcon, SendIcon } from '@icons';
import { BUTTON_TYPE, SIZE } from '@constants';
import { sendMessageToChat, upload } from '@services';
import ChatInputPaperclip from './ChatInputPaperclip';

const validationSchema = object({
  text: string().required(),
});

const ChatInput = ({ chatId }) => {
  const formik = useFormik({
    initialValues: {
      text: '',
      type: 'text',
    },
    validationSchema,
    onSubmit: async ({ text, type }) => {
      await sendMessageToChat({ chatId, text, type });
      formik.resetForm();
    },
  });

  const onFileAttach = async (file) => {
    if (file) {
      const { data } = await upload(file);
      await sendMessageToChat({ chatId, text: data, type: 'file' });
    }
  };

  return (
    <div className="p-4 w-full">
      <div className="flex flex-row rounded-xl bg-gray-20 py-3 px-5">
        <ChatInputPaperclip onFileAttach={onFileAttach} />
        <form className="w-full" onSubmit={formik.handleSubmit}>
          <input
            disabled={formik.values.type === 'file'}
            autoComplete="off"
            className="bg-gray-20 focus:outline-none mx-2 w-full"
            value={formik.values.type === 'file' ? '' : formik.values.text}
            onChange={formik.handleChange}
            id="text"
            name="text"
            type="text"
            placeholder="Write a message..."
          />
        </form>
        <button type={BUTTON_TYPE.BUTTON} onClick={formik.submitForm}>
          <SvgIcon icon={<SendIcon />} size={SIZE.M} />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
