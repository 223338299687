import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function getStatus() {
  const resonse = await instance.get('/stripe-connect/onboarding/status');
  return resonse.data.status;
}

export async function getOnboardingLing() {
  const resonse = await instance.get('/stripe-connect/onboarding/link', {
    params: {
      // TODO: Make actual refresh page (see Stripe docs)
      refresh_url: `${location.origin}/dashboard/coach/settings/personal_info`,
      return_url: `${location.origin}/dashboard/coach/settings/personal_info`,
    },
  });
  return resonse.data.url;
}

export async function createCheckoutSession(offerId, successUrl, cancelUrl) {
  const response = await instance.post('/stripe/checkout-session', {
    offer_id: offerId,
    success_url: successUrl,
    cancel_url: cancelUrl,
  });

  return response.data;
}
