import { makeAutoObservable } from 'mobx';
import { searchOffers, searchUsers } from '@services';

export class SearchStore {
  constructor() {
    this.otherUsers = [];
    this.coaches = [];
    this.offers = [];
    makeAutoObservable(this);
  }

  setUsers(users) {
    this.coaches = users.filter((user) => user.has_profile);
    this.otherUsers = users.filter((user) => !user.has_profile);
  }

  setOffers(offers) {
    this.offers = offers;
  }

  async search(query) {
    const usersResponse = await searchUsers(query);
    const offersResponse = await searchOffers(query);

    this.setUsers(usersResponse.data);
    this.setOffers(offersResponse.data);
  }
}

export default new SearchStore();
