import { BUTTON_THEME, SIZE } from '@constants';
import { PlusIcon } from '@icons';
import { Button } from '@ui';
import classNames from 'classnames';
import './styles.scss';

export const Avatar = ({
  className,
  size = SIZE.S,
  avatar,
  onChangeAvatar,
  abbreviation,
  color,
}) => {
  return (
    <div
      className={classNames('d-avatar', `d-avatar--size-${size}`, className)}
    >
      {avatar ? (
        <img
          className={classNames(
            'w-full h-full z-1 mx-auto md:mx-0 rounded-full',
            color ? color : 'bg-gray-50',
          )}
          src={avatar}
          alt="Personal Avatar"
        />
      ) : (
        <div
          className={classNames(
            'w-full h-full select-none mx-auto md:mx-0 bg-gray-50 rounded-full flex justify-center items-center',
            color ? color : 'bg-gray-50',
          )}
        >
          {abbreviation}
        </div>
      )}

      {onChangeAvatar && (
        <Button
          className="absolute bottom-0 right-3"
          theme={BUTTON_THEME.SECONDARY}
          size={SIZE.XS}
          rounded={true}
          iconComponent={<PlusIcon />}
          onClick={onChangeAvatar}
        />
      )}
    </div>
  );
};
