import extensions from '@/components/RichTextEditor/extensions';
import { ContactsMap, MessageViaMap } from '@/constants';
import { AppContext } from '@/contexts';
import { BookmarkIcon, ChevronDownIcon } from '@/icons';
import { CoachService, OfferService } from '@/services';
import {
  CoachProfileHeaderAndAvatar,
  CoachProfileRating,
  OfferCard,
  Share,
} from '@components';
import { Menu, Transition } from '@headlessui/react';
import { generateHTML } from '@tiptap/html';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function CoachProfileScene() {
  const { coachId } = useParams();
  const appContext = useContext(AppContext);
  const [coachProfile, setCoachProfile] = useState(undefined);
  const [coachOffers, setCoachOffers] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        const [coachData, offersData] = await Promise.all([
          CoachService.get(coachId),
          OfferService.listCoachOffers(coachId),
        ]);

        setCoachProfile(coachData);
        setCoachOffers(offersData);
      } catch (error) {
        toast.error('Unable to retrieve data. See console (F12)');
        console.error('Get coach profile error.', error);
      }
    }

    void fetchData();
  }, [coachId]);

  return coachProfile ? (
    <div className="py-7.5 px-12">
      <div className="flex flex-col gap-6 bg-gray-10 rounded-2.5xl px-7.5 py-6">
        <CoachProfileHeaderAndAvatar
          avatar={coachProfile.avatar}
          header={coachProfile.header}
          headerOffset={coachProfile.header_offset}
        />

        <div className="md:h-20">
          <div className="flex flex-col-reverse items-end xl:flex-row justify-between gap-2 max-md:pt-20 pl-64">
            <Share.ShareList
              url={`${location.origin}/coaches/${coachId}`}
              text={coachProfile.name}
            >
              <Share.ShareFacebook className="self-center" />
              <Share.ShareTwitter className="self-center" />
              <Share.ShareTelegram className="self-center" />
              <Share.ShareInstagram className="self-center" />
              <Share.ShareLinkedIn className="self-center" />
              <Share.CopyLink className="self-center" />
            </Share.ShareList>

            {appContext.isClient && (
              <div className="flex gap-2">
                <button type="button" className="button-outline button-m button-icon-rect">
                  <BookmarkIcon className="fill-current" aria-hidden="true" />
                  <span className="sr-only">Add to Bookmark</span>
                </button>

                {coachProfile.contacts.length === 0 ? (
                  <button
                    type="button"
                    className="button-second button-m"
                    onClick={() => toast.warn('Not Implemented')}
                  >
                    Message
                  </button>
                ) : (
                  <div className="flex relative">
                    <button
                      type="button"
                      className="button-second button-m rounded-r-none"
                      onClick={() => toast.warn('Not Implemented')}
                    >
                      Message
                    </button>
                    <Menu>
                      <Menu.Button className="button-second button-xs button-icon-rect rounded-l-none">
                        <span className="sr-only">Message Via</span>
                        <ChevronDownIcon
                          className="h-4 w-4 fill-current"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 top-full mt-2 py-2 w-full origin-top-right rounded-lg bg-gray-10 shadow-lg focus:outline-none z-10">
                          {coachProfile.contacts.map(({ type, value }) => (
                            <Menu.Item key={type}>
                              {({ active }) => {
                                const contact = ContactsMap.get(type);
                                return (
                                  <a
                                    href={MessageViaMap.get(type).call(
                                      null,
                                      value,
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`${
                                      active
                                        ? 'bg-sea-10 text-sea-70'
                                        : 'text-midnight-100'
                                    } group flex items-center gap-1 w-full px-4 py-0 rounded-none text-sm`}
                                  >
                                    <img
                                      src={contact.logo}
                                      alt={contact.name}
                                      className="w-8"
                                    />
                                    <span>{contact.name}</span>
                                  </a>
                                );
                              }}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-8">
          <div>
            <h1 className="text-midnight-100 text-5xl font-bold">
              <span className="sr-only">Coach Profile of</span>
              {coachProfile.name}
            </h1>
            <CoachProfileRating value={coachProfile.rating} />
          </div>

          <div className="text-midnight-100">
            <h2 className="sr-only">About</h2>
            <div
              className="space-y-[0.75em]"
              dangerouslySetInnerHTML={{
                __html: generateHTML(coachProfile.about, extensions),
              }}
            ></div>
          </div>
        </div>

        {coachOffers && coachOffers.length > 0 && (
          <div className="flex flex-col gap-4">
            <h2 className="text-midnight-100 text-4xl font-bold">Offers</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
              {coachOffers.map(({ id, type, short_description, name }) => (
                <Link to={`../offers/${id}`} key={id}>
                  <OfferCard
                    id={id}
                    type={type}
                    title={name}
                    description={short_description}
                  />
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : coachProfile === null ? (
    <CoachNotExists />
  ) : null;
}

function CoachNotExists() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 p-7 h-full bg-midnight-10">
      <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
        Oops, this coach doesn't exist 🙄
      </h1>

      <img
        src="/images/svg-question-sign.svg"
        className="w-96"
        alt="Woman walking towards a question sign"
      />
    </div>
  );
}
