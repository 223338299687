import {
  OfferCommonInfoForm,
  OfferContentForm,
  OfferDiscoveryCallForm,
  OfferPaymentForm,
  OfferSessionInfoForm,
  OfferSessionRequestForm,
} from '@/components/EditOffer';
import { OfferContext, useOfferContextData } from '@/contexts';
import { MyOfferService } from '@/services';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import CreateOfferStepsLayout from './CreateOfferStepsLayout';

function getInitialOfferData(type) {
  const common = { offerType: type, price: 0 };
  switch (type) {
    case 'group':
      return Object.assign(common, {
        sessionAmount: 1,
        sessionDuration: 30,
        sessionCapacity: 5,
        allowRequests: true,
        sessionRequestsSchedule: null,
        enableDiscoveryCall: false,
        discoveryCallSchedule: null,
      });
    case 'one-to-one':
      return Object.assign(common, {
        sessionAmount: 1,
        sessionDuration: 30,
        allowRequests: true,
        sessionRequestsSchedule: null,
        enableDiscoveryCall: false,
        discoveryCallSchedule: null,
      });
    case 'digital-content':
      return Object.assign(common, {
        allowRequests: false,
        sessionRequestsSchedule: null,
        enableDiscoveryCall: false,
        discoveryCallSchedule: null,
      });
  }
}

export default function CreateOfferScene({ type: offerType }) {
  const offerContext = useOfferContextData(getInitialOfferData(offerType));
  const [activeStep, setActiveStep] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const stepsWithValidation = useMemo(() => {
    switch (offerType) {
      case 'one-to-one':
        return [
          CommonInfoStep,
          ContentStep,
          SessionInfoStep,
          PriceStep,
          SessionRequestStep,
          DiscoveryCallStep,
        ];
      case 'group':
        return [
          CommonInfoStep,
          ContentStep,
          SessionInfoStep,
          PriceStep,
          DiscoveryCallStep,
        ];
      case 'digital-content':
        return [CommonInfoStep, ContentStep, PriceStep];
    }
  }, [offerType]);

  const steps = useMemo(
    () => stepsWithValidation.map(([step]) => step),
    [stepsWithValidation],
  );

  const stepValidator = useMemo(() => {
    const [, validator] = stepsWithValidation[activeStep];
    return validator && validator.bind(null, offerContext);
  }, [stepsWithValidation, activeStep, offerContext]);

  async function createOffer() {
    try {
      setDisabled(true);
      await MyOfferService.create(offerContext.toFormData());
      toast.success('Offer has been created');
      navigate('/dashboard/coach/offers');
    } catch (error) {
      toast.error('Unable to create new offer. See console (F12)');
      console.error('Offer creation error', error);
    } finally {
      setDisabled(false);
    }
  }

  return (
    <OfferContext.Provider value={offerContext}>
      <CreateOfferStepsLayout
        steps={steps}
        inert={disabled}
        onChangeStep={setActiveStep}
        onCreateOffer={createOffer}
        nextStepValidation={stepValidator}
        className={disabled && 'opacity-70'}
      />
    </OfferContext.Provider>
  );
}

const CommonInfoStep = [
  {
    name: 'Title & Description',
    component: OfferCommonInfoForm,
  },
  (offerContext) =>
    offerContext.commonInfo.title &&
    offerContext.commonInfo.shortDescription &&
    offerContext.commonInfo.longDescription,
];
const ContentStep = [
  { name: 'Content', component: OfferContentForm },
  undefined,
];
const SessionInfoStep = [
  { name: 'Session Info', component: OfferSessionInfoForm },
  (offerContext) =>
    offerContext.sessionAmount >= 1 && offerContext.sessionDuration > 0,
];
const PriceStep = [
  { name: 'Price', component: OfferPaymentForm },
  (offerContext) => offerContext.price >= 0,
];
const SessionRequestStep = [
  { name: 'Session Request', component: OfferSessionRequestForm },
  undefined,
];
const DiscoveryCallStep = [
  { name: 'Discovery Call', component: OfferDiscoveryCallForm },
  undefined,
];
