import { DateTime, Duration } from 'luxon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import LaunchMeetingButton from '@/components/LaunchMeetingButton';
import { ChevronRightIcon } from '@icons';

export default function SessionListItem({ session }) {
  const dt_start = useMemo(
    () => DateTime.fromISO(session.timestamp),
    [session.timestamp],
  );
  const dt_end = useMemo(
    () => dt_start.plus(Duration.fromISO(session.duration)),
    [dt_start, session.duration],
  );

  return (
    <li
      className="flex items-center justify-between py-3 px-6 bg-midnight-10 rounded-xl"
      data-session-id={session.id}
    >
      <div>
        <p className="text-midnight-100 font-bold">{session.offer.name}</p>
        <div className="text-midnight-70 text-sm">
          <time dateTime={dt_start.toISO()}>
            {dt_start.toLocaleString({
              dateStyle: 'medium',
              timeStyle: 'short',
            })}
          </time>
          <span> - </span>
          <time dateTime={dt_end.toISO()}>
            {dt_end.toLocaleString(DateTime.TIME_SIMPLE)}
          </time>
        </div>
      </div>

      <div className="flex gap-8 items-center">
        {session.zoom_meeting_id && dt_start > DateTime.now() && (
          <LaunchMeetingButton
            session_id={session.id}
            meeting_id={session.zoom_meeting_id}
            className="button-xs"
          />
        )}

        <Link
          to={`../${session.id}`}
          className="p-2 button-clear button-s button-icon-rect"
        >
          <span className="sr-only">Session Info</span>
          <ChevronRightIcon className="fill-midnight-60" aria-hidden="true" />
        </Link>
      </div>
    </li>
  );
}
