import { FormControl } from '@/components';
import { FORM_CONTROL_TYPE, THEME } from '@/constants';
import { LockIcon, SvgIcon } from '@/icons';
import { Dialog } from '@headlessui/react';
import { useMemo, useState } from 'react';

export default function ChangePasswordDialog({ open, onClose, onChange }) {
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const isPasswordsMatch = useMemo(
    () => password == passwordRepeat,
    [password, passwordRepeat],
  );

  function resetAndClose() {
    setPassword('');
    setPasswordRepeat('');
    onClose();
  }

  function save() {
    onChange(password);
    resetAndClose();
  }

  return (
    <Dialog open={open} onClose={resetAndClose} className="relative z-50">
      <div className="fixed inset-0 bg-black-500/80" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="flex flex-col gap-6 w-full px-7.5 py-12 max-w-2xl bg-gray-10 rounded-2.5xl max-h-screen overflow-auto">
          <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
            Change the Password
          </Dialog.Title>

          <div className="flex flex-col gap-4">
            <FormControl
              value={password}
              type={FORM_CONTROL_TYPE.PASSWORD}
              minLength={8}
              name="password"
              label="Enter new password"
              placeholder="New password"
              startAdornment={
                <SvgIcon icon={<LockIcon />} theme={THEME.SECONDARY} />
              }
              fullWidth={true}
              required={true}
              error={!isPasswordsMatch}
              onChange={(event) => setPassword(event.target.value)}
            />

            <FormControl
              value={passwordRepeat}
              type={FORM_CONTROL_TYPE.PASSWORD}
              minLength={8}
              name="secondPassword"
              label="Confirm new password"
              placeholder="Confirm password"
              startAdornment={
                <SvgIcon icon={<LockIcon />} theme={THEME.SECONDARY} />
              }
              fullWidth={true}
              required={true}
              error={!isPasswordsMatch}
              helperText={!isPasswordsMatch && 'The entered data must match'}
              onChange={(event) => setPasswordRepeat(event.target.value)}
            />
          </div>

          <div className="flex gap-3">
            <button
              type="button"
              className="button-main button-l flex-1 justify-center"
              onClick={save}
              disabled={password === '' || !isPasswordsMatch}
            >
              Save
            </button>

            <button
              type="button"
              className="button-outline button-l flex-1 justify-center"
              onClick={resetAndClose}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
