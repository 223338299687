import { DateTime } from 'luxon';
import { useMemo } from 'react';
import CalendarMonthItem from './CalendarMonthItem';
import classNames from 'classnames';

/**
 * @param {object} params
 * @param {DateTime} params.selected
 * @param {DateTime} params.active
 * @param {Function} params.onChangeSelected
 * @param {Function} params.onChangeActive
 * @param {object?} params.metadata
 */
export default function CalendarMonth({
  selected,
  active,
  onChangeSelected,
  onChangeActive,
  compact,
  metadata,
}) {
  const start = useMemo(
    () => active.startOf('month').startOf('week'),
    [active],
  );
  // const end = useMemo(() => start.plus({ days: 42 }), [start]);

  // Use milliseconds as a dependency to reduce recalculations
  const startMs = useMemo(() => +start, [start]);

  const dayRows = useMemo(
    () =>
      Array.from({ length: 6 }, (v, n) =>
        Array.from({ length: 7 }, (w, q) => {
          const dt = start.plus({ days: q + n * 7 });
          return {
            key: dt.toISODate(),
            date: dt,
            grayedOut: !active.hasSame(dt, 'month'),
            today: DateTime.now().hasSame(dt, 'day'),
          };
        }),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startMs],
  );

  function setFocusOnDate(date) {
    document
      .querySelector(
        `.calendar-grid-cell[data-datevalue="${date.toISODate()}"]`,
      )
      ?.focus();
  }
  function changeActive(date) {
    onChangeActive && onChangeActive(date);
    setTimeout(() => setFocusOnDate(date), 0);
  }
  function changeSelected(date) {
    if (date !== selected) {
      changeActive(date);
      onChangeSelected && onChangeSelected(date);
    }
  }

  // Keyboard navigation
  function moveVertical(inverse = false) {
    changeActive(active.plus({ week: 1 - 2 * inverse }));
  }
  function moveHorisontal(inverse = false) {
    changeActive(active.plus({ day: 1 - 2 * inverse }));
  }
  async function enter() {
    changeSelected(active);
  }
  function toToday() {
    changeActive(DateTime.now().startOf('day'));
  }
  function toWeekStart() {
    changeActive(active.startOf('week'));
  }
  function toWeekEnd() {
    changeActive(active.endOf('week'));
  }
  function toMonthStart() {
    changeActive(active.startOf('month'));
  }
  function toMonthEnd() {
    changeActive(active.endOf('month'));
  }
  function moveMonth(inverse = false) {
    changeActive(active.plus({ month: 1 - 2 * inverse }));
  }

  /**
   * @param {KeyboardEvent} event
   */
  function handleKeyboard(event) {
    switch (event.key) {
      case 'ArrowUp':
        return moveVertical(true);
      case 'ArrowDown':
        return moveVertical();
      case 'ArrowLeft':
        return moveHorisontal(true);
      case 'ArrowRight':
        return moveHorisontal();
      case 'Home':
        if (event.ctrlKey && !event.altKey) {
          return toMonthStart();
        } else if (!event.ctrlKey && event.altKey) {
          return toToday();
        } else {
          return toWeekStart();
        }
      case 'End':
        if (event.ctrlKey) {
          return toMonthEnd();
        } else {
          return toWeekEnd();
        }
      case 'PageUp':
        return moveMonth(true);
      case 'PageDown':
        return moveMonth();
      case 'Enter':
        return enter();
    }
  }

  function preventDefault(event) {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Home':
      case 'End':
      case 'PageUp':
      case 'PageDown':
      case 'Space':
        event.preventDefault();
        break;
    }
  }

  return (
    <div role="grid" className="table table-fixed w-full border-collapse">
      <WeekDayHeader compact={compact} />
      <div
        role="rowgroup"
        className="table-row-group divide-y divide-midnight-20"
      >
        {dayRows.map((row, idx) => (
          <div
            role="row"
            className="table-row divide-x divide-midnight-20"
            key={idx}
          >
            {row.map((day) => (
              <div
                key={day.key}
                role="gridcell"
                className="calendar-grid-cell table-cell outline-primary outline-offset-1 cursor-pointer "
                aria-selected={selected.hasSame(day.date, 'day') ? true : null}
                aria-current={day.today ? 'date' : null}
                tabIndex={active.hasSame(day.date, 'day') ? 0 : -1}
                data-datevalue={day.key}
                onKeyUp={handleKeyboard}
                onKeyDown={preventDefault}
                onClick={() => changeSelected(day.date)}
              >
                <CalendarMonthItem
                  active={selected.hasSame(day.date, 'day')}
                  date={day.date}
                  grayedOut={day.grayedOut}
                  today={day.today}
                  compact={compact}
                  metadata={metadata[day.key]}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function WeekDayHeader({ compact }) {
  return (
    <div role="row" className="table-row">
      <span
        role="columnheader"
        className={classNames(
          'table-cell capitalize text-sm text-center text-midnight-50',
          compact ? 'pt-1 pb-2' : 'pt-2 pb-7',
        )}
        aria-label="monday"
        aria-readonly="true"
      >
        <span aria-hidden="true">mon</span>
        <span className="sr-only">monday</span>
      </span>
      <span
        role="columnheader"
        className={classNames(
          'table-cell capitalize text-sm text-center text-midnight-50',
          compact ? 'pt-1 pb-2' : 'pt-2 pb-7',
        )}
        aria-label="tuesday"
        aria-readonly="true"
      >
        <span aria-hidden="true">tue</span>
        <span className="sr-only">tuesday</span>
      </span>
      <span
        role="columnheader"
        className={classNames(
          'table-cell capitalize text-sm text-center text-midnight-50',
          compact ? 'pt-1 pb-2' : 'pt-2 pb-7',
        )}
        aria-label="wednesday"
        aria-readonly="true"
      >
        <span aria-hidden="true">wed</span>
        <span className="sr-only">wednesday</span>
      </span>
      <span
        role="columnheader"
        className={classNames(
          'table-cell capitalize text-sm text-center text-midnight-50',
          compact ? 'pt-1 pb-2' : 'pt-2 pb-7',
        )}
        aria-label="thursday"
        aria-readonly="true"
      >
        <span aria-hidden="true">thu</span>
        <span className="sr-only">thursday</span>
      </span>
      <span
        role="columnheader"
        className={classNames(
          'table-cell capitalize text-sm text-center text-midnight-50',
          compact ? 'pt-1 pb-2' : 'pt-2 pb-7',
        )}
        aria-label="friday"
        aria-readonly="true"
      >
        <span aria-hidden="true">fri</span>
        <span className="sr-only">friday</span>
      </span>
      <span
        role="columnheader"
        className={classNames(
          'table-cell capitalize text-sm text-center text-midnight-50',
          compact ? 'pt-1 pb-2' : 'pt-2 pb-7',
        )}
        aria-label="saturday"
        aria-readonly="true"
      >
        <span aria-hidden="true">sat</span>
        <span className="sr-only">saturday</span>
      </span>
      <span
        role="columnheader"
        className={classNames(
          'table-cell capitalize text-sm text-center text-midnight-50',
          compact ? 'pt-1 pb-2' : 'pt-2 pb-7',
        )}
        aria-label="sunday"
        aria-readonly="true"
      >
        <span aria-hidden="true">sun</span>
        <span className="sr-only">sunday</span>
      </span>
    </div>
  );
}
