import { RoundSuccessIcon } from '@icons';

export function SessionInfoTasks({ tasks = [] } = {}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-1">
        <RoundSuccessIcon />
        <h4 className="text-lg font-bold text-midnight-100">Tasks</h4>
      </div>

      <ul className="flex flex-col gap-2">
        {tasks.map((task, idx) => (
          <li key={idx} className="bg-midnight-10 rounded-xl px-6 py-3 text-sm">
            {task}
          </li>
        ))}
      </ul>
    </div>
  );
}
