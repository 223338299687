import { NotificationsService } from '@services';
import { pusher } from '@utils';
import { makeAutoObservable, runInAction } from 'mobx';

class NotificationsStore {
  constructor() {
    this.loading = false;
    this.initialized = false;
    this.pusherChannel = null;
    this.notifications = [];
    this.newNotifications = [];
    makeAutoObservable(this);
  }

  subscribeToNotifications(userId) {
    if (!this.pusherChannel) {
      const channel = pusher.subscribe(userId);

      channel.bind('pusher:subscription_succeeded', () => {
        console.log('Notification subscription succeeded');
      });
      channel.bind('pusher:subscription_error', (data) => {
        console.error('Notification subscription error', data);
      });

      // Common channel event handler
      const newNotificationHandler = async (notification) => {
        console.log('Got new notification', notification);
        runInAction(() => {
          this.notifications.push(notification);
          this.newNotifications.push(notification);
        });
      };

      // Bind the handler for all categories
      for (const category of ['system', 'activity', 'reminder']) {
        channel.bind(category, newNotificationHandler);
      }

      this.pusherChannel = channel;
    }
  }

  unsubscribeNotifications() {
    if (this.pusherChannel) {
      this.pusherChannel.unsubscribe();
    }
  }

  async fetch() {
    if (!this.initialized) {
      const allNotifications = await NotificationsService.list();
      runInAction(() => {
        if (allNotifications) {
          this.notifications = allNotifications;
        }
      });
      const newNotifications = await NotificationsService.listNew();
      runInAction(() => {
        if (newNotifications) {
          this.newNotifications = newNotifications;
        }
      });
      this.initialized = true;
    }
  }

  async markView() {
    if (this.newNotifications.length > 0) {
      const lastNotification =
        this.newNotifications[this.newNotifications.length - 1];
      await NotificationsService.markViewed(lastNotification.id);
      runInAction(() => {
        this.newNotifications = [];
      });
    }
  }
}

export default new NotificationsStore();
