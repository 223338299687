import * as mime from 'mime/lite';

export default function checkAcceptIsValid(accept = '', type = '') {
  const items = accept.split(',');
  let result = false;

  for (const item of items) {
    if (item[0] === '.') {
      // If starts with dot - it's a file extension (.png)
      const _type = mime.getType(item);
      if (_type && _type === type) {
        result = true;
        break;
      }
    }

    // If mime can recognize it - it's an explicit type (image/png)
    const ext = mime.getExtension(item);
    if (ext && item === type) {
      result = true;
      break;
    }

    const match = item.match(/(\w+)\/\*/);
    // If has slash with start - its' a type group (image/*)
    if (match && type.startsWith(match[1])) {
      result = true;
      break;
    }
  }

  return result;
}
