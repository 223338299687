import * as messengerTokens from '@utils/messengerAccess';
import { createClient } from 'matrix-js-sdk';

async function tokenRefreshFunction(refreshToken) {
  console.debug('[Matrix Client] Refreshing access...');
  const refreshResult = await client.refreshToken(refreshToken);
  messengerTokens.save({
    userId: messengerTokens.userId,
    deviceId: messengerTokens.userId,
    accessToken: refreshResult.access_token,
    refreshToken: refreshResult.refresh_token,
  });
  return {
    accessToken: refreshResult.access_token,
    refreshToken: refreshResult.refresh_token,
  };
}

const client = createClient({
  baseUrl: process.env.REACT_APP_MATRIX_HOMESERVER,
  userId: messengerTokens.userId,
  deviceId: messengerTokens.deviceId,
  accessToken: messengerTokens.accessToken,
  refreshToken: messengerTokens.refreshToken,
  tokenRefreshFunction: tokenRefreshFunction,
});

export * from 'matrix-js-sdk';
export default client;
