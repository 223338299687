import { UserStoreV2 } from '@/stores';
import { Navigate, Outlet } from 'react-router-dom';

const AuthLayout = () => {
  const userStore = UserStoreV2.useState();

  return (
    userStore.isInitialized &&
    (userStore.isAuthorized ? <Navigate to="/dashboard" /> : <Outlet />)
  );
};

export default AuthLayout;
