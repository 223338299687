import { httpClient } from '@utils';

const getChats = () => {
  return httpClient.get('/chats');
};

const createChat = ({ type, userId }) => {
  return httpClient.post('/chats', { type, user_id: userId });
};

const getChat = (chatId) => {
  return httpClient.get(`/chats/${chatId}`);
};

const deleteChat = (chatId) => {
  return httpClient.delete(`/chats/${chatId}`);
};

const getChatMessages = (chatId) => {
  return httpClient.get(`/chats/${chatId}/messages`);
};

const sendMessageToChat = ({ chatId, text, type }) => {
  return httpClient.post(`/chats/${chatId}/messages`, { text, type });
};

export {
  getChats,
  createChat,
  getChat,
  deleteChat,
  getChatMessages,
  sendMessageToChat,
};
