import Calendar from '@/components/Calendar';
import { Dialog } from '@headlessui/react';
import { DateTime, Duration } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import SelectTimeslots from '@/components/SelectTimeslots';
import getErrorMessage from '@/utils/getErrorMessage';
import { toast } from 'react-toastify';
import { SessionService } from '@/services';
import { UserStoreV2 } from '@/stores';

export default function DiscoveryCallDialog({ offer_id, open, onClose }) {
  const [date, setDate] = useState(DateTime.now().startOf('day'));
  const [timeslot, setTimeslot] = useState(null);
  const userStore = UserStoreV2.useState();

  const timestamp = useMemo(
    () => timeslot && date.plus(timeslot),
    [date, timeslot],
  );

  function handleClose() {
    setDate(DateTime.now().startOf('day'));
    setTimeslot(null);
    onClose();
  }

  function bookDiscoveryCall() {
    SessionService.create({
      offer_id: offer_id,
      timestamp: timestamp.toISO(),
      participants: [userStore.state.user.id],
      duration: 'PT15M',
      is_discovery_call: true,
    })
      .then(() => {
        toast.success('Success');
      })
      .catch((error) => {
        console.error(error);
        toast.error(getErrorMessage(error));
      });
    handleClose();
  }

  const fetchTimeslots = useCallback(() => {
    let dt = date;
    const duration = Duration.fromObject({ minutes: 10 });

    return Array.from(
      { length: Duration.fromObject({ days: 1 }) / duration },
      () => {
        const result = {
          milliseconds: dt % date,
          text: dt.toLocaleString(DateTime.TIME_SIMPLE),
          disabled: false,
        };
        dt = dt.plus(duration);
        return result;
      },
    );
  }, [date]);

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-50">
      <div className="fixed inset-0 bg-black-500/80" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="flex flex-col gap-6 w-full px-7.5 py-12 max-w-2xl bg-gray-10 rounded-2.5xl max-h-screen overflow-auto">
          <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
            Select Date and Time
          </Dialog.Title>

          <div className="grid max-sm:grid-rows-2 md:grid-cols-2 gap-4 ">
            <Calendar selected={date} onChangeSelected={setDate} compact />

            <div className="flex flex-col h-full">
              <SelectTimeslots
                value={timeslot}
                onChange={setTimeslot}
                loadData={fetchTimeslots}
                className="p-2 rounded-2.5xl grow basis-64 overflow-auto"
              />
            </div>
          </div>

          <button
            type="button"
            className="button-main button-s justify-center"
            disabled={!timestamp}
            onClick={bookDiscoveryCall}
          >
            {timestamp
              ? `Book ${timestamp.toLocaleString(
                  DateTime.DATETIME_SHORT,
                )} for a discovery call`
              : 'Select a timeslot'}
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
