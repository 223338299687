import { ChevronRightIcon, SvgIcon } from '@icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import UsersList from '../UsersList';

export const UsersListWrapper = ({
  className,
  title,
  linkToAllPage,
  toProfileNavigate,
  toInfoNavigate,
  users,
}) => {
  return (
    <div
      className={classNames('mb-8 rounded-2xl p-10 pt-6 bg-gray-10', className)}
    >
      <div className="mb-12">
        <span className="text-midnight-90 text-base font-bold">Bookmarks</span>
      </div>

      <div className="flex justify-between items-center mb-6">
        <span className="text-midnight-90 text-base font-bold">{title}</span>
        {/* to={linkToAllPage} */}
        {linkToAllPage && (
          <Link className="flex items-center" to="/dashboard/coaches">
            <span className="text-sea-70 text-md">See all</span>

            <SvgIcon className="ml-1" icon={<ChevronRightIcon />} />
          </Link>
        )}
      </div>

      <UsersList
        toProfileNavigate={toProfileNavigate}
        toInfoNavigate={toInfoNavigate}
        users={users}
      />
    </div>
  );
};
