import { SIZE } from '@constants';
import { SvgIcon } from '@icons';
import classNames from 'classnames';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import './Tabs.scss';

// All props required!
// type Props = {
//     icon: SvgIcon,
//     name: string,
//     linkTo: string,
//     visible: boolean,
// }

// TODO: add adaptive for tabs
const TabItem = ({ icon, name, linkTo, visible }) => {
  if (!visible) {
    return null;
  }

  let resolved = useResolvedPath(linkTo);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink
      to={linkTo}
      className={classNames('tab-item', match && 'tab-item--active')}
    >
      <SvgIcon
        icon={icon}
        // theme={THEME.SECONDARY}
        size={SIZE.S}
      />

      <span className="tab-item__name">{name}</span>
    </NavLink>
  );
};

export const Tabs = ({ className, tabs }) => {
  return (
    <nav className={classNames('flex justify-center align-center', className)}>
      {tabs.map((tabItem) => (
        <TabItem
          key={tabItem.name}
          name={tabItem.name}
          linkTo={tabItem.to}
          icon={tabItem.icon}
          visible={tabItem.visible}
        />
      ))}
    </nav>
  );
};
