import { refreshAccess } from '@services/AuthServiceV2';

/**
 * It takes axios or its instance and set interceptor
 * to silent refresh the access and repeat previous request
 * @param {import('axios').AxiosStatic | import('axios').AxiosInstance} axios
 */
export default function useSilentRefresh(axios) {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401 && error.config.silent_refresh) {
        // Disable silent_refresh for the next request to avoid infinite loop
        error.config.silent_refresh = false;
        try {
          await refreshAccess();
        } catch (error) {
          console.error('Unable to refresh the access', error);
          return Promise.reject(error);
        }
        return axios(error.config);
      }
      return Promise.reject(error);
    },
  );
  // Enable silent refresh by default
  axios.defaults.silent_refresh = true;
  return axios;
}
