import classNames from 'classnames';

export default function StepBar({ steps, active, label = 'Progress' }) {
  return (
    <nav aria-label={label}>
      <ol className="flex flex-row-reverse relative overflow-hidden rounded-[1.25rem]">
        {steps
          .map((step, idx) => (
            <li
              key={idx}
              className={classNames(
                'relative flex-1 -ml-5 px-10 py-4 rounded-r-[1.25rem] shadow-[3px_0px_6px_#01010112]',
                active === idx ? 'bg-orange-10' : 'bg-[#F2F7FF]',
              )}
              aria-current={active === idx ? 'step' : null}
            >
              <div className="flex flex-col text-start">
                <span
                  className={classNames(
                    'font-normal text-sm',
                    active === idx ? 'text-orange-100' : 'text-midnight-60',
                  )}
                >
                  {`Step ${idx + 1}`}
                </span>
                <span
                  className={classNames(
                    'text-base font-bold',
                    active === idx ? 'text-orange-100' : 'text-midnight-60',
                  )}
                >
                  {step.name}
                </span>
              </div>
            </li>
          ))
          .reverse()}
      </ol>
    </nav>
  );
}
