import { ChevronDownIcon } from '@/icons';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import { DateTime, Duration } from 'luxon';
import { useMemo } from 'react';

/**
 * @param {object} props
 * @param {import('luxon').DurationLikeObject} props.step
 * @returns
 */
export default function TimeInput({
  value,
  onChange,
  step = { minutes: 10 },
  placeholder,
  validator = () => true,
  disabled,
  className,
}) {
  const stepDur = useMemo(() => Duration.fromObject(step), [step]);

  if (stepDur < Duration.fromObject({ minute: 1 })) {
    throw new Error('Step interval cannot be less than 1 minute');
  }
  if (stepDur > Duration.fromObject({ hours: 12 })) {
    throw new Error('Step interval cannot be more than 12 hours');
  }

  const timeSteps = useMemo(() => {
    const dt = DateTime.now().startOf('day');
    return Array.from(
      { length: Duration.fromObject({ day: 1 }) / stepDur },
      (v, i) => ({
        dt: dt.plus(stepDur * i),
        offset: stepDur * i,
        disabled: !validator(stepDur * i),
      }),
    );
  }, [stepDur, validator]);

  return (
    <Listbox
      as="div"
      value={value}
      onChange={onChange}
      className={classNames('relative group', className)}
      disabled={disabled}
    >
      <Listbox.Button
        placeholder={placeholder}
        className="relative py-2 pl-4 pr-10 w-full input-main font-normal"
      >
        <span className="overflow-hidden whitespace-nowrap">
          {timeSteps[value / stepDur]?.dt.toLocaleString({
            timeStyle: 'short',
          })}
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            className="h-5 w-5 fill-midnight-60"
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>
      <Listbox.Options
        className="absolute z-20 mt-2 py-2 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-1 ring-midnight-100 ring-opacity-5 focus:outline-none"
        aria-required="true"
      >
        {timeSteps.map((step) => (
          <Listbox.Option
            key={step.offset}
            value={step.offset}
            disabled={step.disabled}
          >
            {({ active, selected }) => (
              <div
                className={classNames(
                  'relative cursor-default select-none py-2 pl-6 pr-10 text-left',
                  {
                    'bg-midnight-20': active && !selected,
                    'bg-orange-60': active && selected,
                    'bg-primary text-white': selected,
                  },
                )}
              >
                <span
                  className={classNames('whitespace-nowrap', {
                    'font-semibold': selected,
                    'font-normal': !selected,
                  })}
                >
                  {step.dt.toLocaleString({ timeStyle: 'short' })}
                </span>
              </div>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
}
