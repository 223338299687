import { ExternalLinkIcon } from '@/icons';
import { ContentContainer, EnvList } from '@components';
import { UserStoreV2 } from '@stores';
import { stripeDashboardURL } from '@utils/stripe';
import { Link, Navigate, useNavigate } from 'react-router-dom';

export function PricingTableScene() {
  const userStore = UserStoreV2.useState();

  if (
    !process.env.REACT_APP_STRIPE_PRICING_TABLE_ID ||
    !process.env.REACT_APP_STRIPE_PRICING_TABLE_KEY
  ) {
    return <MissingEnvWarning />;
  }

  if (userStore.hasActiveSubscription) return <AlreadyHasSubscriptionPrompt />;

  return (
    userStore.isInitialized &&
    (userStore.isAuthorized ? (
      <ContentContainer title="Pricing Table">
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
          publishable-key={process.env.REACT_APP_STRIPE_PRICING_TABLE_KEY}
          client-reference-id={userStore.state.user.id}
          customer-email={userStore.state.user.email}
        ></stripe-pricing-table>
      </ContentContainer>
    ) : (
      <Navigate to="/auth/sign_in" replace />
    ))
  );
}

function MissingEnvWarning() {
  const missingVars = [
    'REACT_APP_STRIPE_PRICING_TABLE_ID',
    'REACT_APP_STRIPE_PRICING_TABLE_KEY',
  ].filter((v) => process.env[v] === undefined);
  console.warn(
    `Missing some environment variables [${missingVars.join(', ')}]`,
  );
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="border border-yellow-400 rounded-lg px-8 py-4">
        <p className="text-lg font-semibold">
          ⚠ Unable to render the Pricing table.
        </p>
        <p>
          <span className="text-lg">
            Some required environment variables are missing:{' '}
          </span>
          <EnvList value={missingVars} />.
        </p>
      </div>
    </div>
  );
}

function AlreadyHasSubscriptionPrompt() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center gap-4 min-h-screen">
      <h1 className="text-2xl font-bold">You already have a subscription</h1>
      <p>To manage your subscription use Stripe Dashboard.</p>
      <div className="flex gap-2">
        <Link to="/dashboard" className="button-main button-s">
          Back home
        </Link>
        <a
          href={stripeDashboardURL}
          target="_blank"
          className="button-second button-s gap-2"
          onClick={() => navigate('/dashboard')}
          rel="noreferrer"
        >
          <span>Stripe Dashboard</span>
          <ExternalLinkIcon className="fill-white w-4 h-4" />
        </a>
      </div>
    </div>
  );
}
