import { AppContext } from '@/contexts';
import { NoEnteredData } from '@components';
import { WorkspaceService } from '@services';
import { useContext, useEffect, useState } from 'react';
import { WorksapceSessionListItem } from '../components';
import { WorkspaceContext } from './WorkspaceContext';

export default function WorkspaceTabSessions() {
  const appContext = useContext(AppContext);
  const workspace = useContext(WorkspaceContext);
  const [sessions, setSessions] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      const data = await WorkspaceService.listSessions(workspace.id);
      setSessions(data);
    }
    fetchData();
  }, [workspace]);

  return sessions ? (
    sessions.length ? (
      sessions.map((session) => (
        <WorksapceSessionListItem
          key={session.id}
          session={session}
          readonly={appContext.isClient}
        />
      ))
    ) : (
      <div className="flex flex-col gap-8 p-5 rounded-2.5xl bg-gray-10">
        <NoEnteredData
          title="No sessions"
          customImage={
            <img
              src="/images/svg-ufo-kidnap.svg"
              alt="Woman looks through a telescope on a UFO that is kidnapping a cat."
            />
          }
        />
      </div>
    )
  ) : (
    <span className="sr-only">Loading, please wait</span>
  );
}
