export const ContactsMap = new Map([
  [
    'whatsup',
    {
      name: 'WhatsApp',
      logo: '/images/messengers/whatsup.svg',
      disabled: false,
    },
  ],
  [
    'messenger',
    {
      name: 'Messenger',
      logo: '/images/messengers/messenger.svg',
      disabled: true,
    },
  ],
  [
    'telegram',
    {
      name: 'Telegram',
      logo: '/images/messengers/telegram.svg',
      disabled: true,
    },
  ],
]);
