import Pusher from 'pusher-js';

// Set in to true to see all event logs
Pusher.logToConsole = false;

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  userAuthentication: {
    endpoint: `${process.env.REACT_APP_API_BASE_URL}/notifications/auth`,
    transport: 'ajax',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  },
});

export function subscribe(user_id) {
  return pusher.subscribe(`privateUser=${user_id}`);
}
