import { useCallback, useEffect, useState } from 'react';
import { RadioGroupContext } from './RadioGroupContext';
import { isPromise } from '@utils';
import { LoadingWrapper } from '@components';

// interface Props {
//   /**
//    * The name used to reference the value of the control.
//    * If you don't provide this prop, it falls back to a randomly generated name.
//    */
//   className?: string;
//   name?: string;
//   /**
//    * Value of the selected radio button. The DOM API casts this to a string.
//    */
//   value: any;
//   uncheckable?: boolean;
//   disabled?: boolean;
//   loading?: boolean;
//   /**
//    * Callback fired when a radio button is selected.
//    *
//    * @param {object} event The event source of the callback.
//    * Should be like `event.target.value` (string).
//    */
//   onChange: Callback;
// }

export const RadioGroup = ({
  className,
  name,
  value,
  uncheckable,
  disabled = false,
  loading: loadingProp,
  children,
  onChange,
}) => {
  const [loading, setLoading] = useState(loadingProp);

  const handleChange = useCallback(event => {
    const promise = onChange(event);

    if (isPromise(promise)) {
      setLoading(true);
      promise.finally(() => setLoading(false));
    }
  }, [onChange]);

  useEffect(() => {
    setLoading(loadingProp);
  }, [loadingProp]);

  return (
    <RadioGroupContext.Provider
      value={{
        name,
        value,
        uncheckable,
        disabled,
        loading,
        onChange: handleChange,
      }}
    >
      <LoadingWrapper loading={loading}>
        <div
          className={className}
          role="radiogroup"
        >
          {children}
        </div>
      </LoadingWrapper>
    </RadioGroupContext.Provider>
  );
};
