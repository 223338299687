import { BaseValidator } from './BaseValidator';

class EmptyStringValidator extends BaseValidator {
  message = 'Name should be non empty';
  type = 'empty_string';

  validate(value) {
    return value.trim() !== '';
  }
}

export { EmptyStringValidator };
