import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/coaches`,
});

export async function list() {
  const resonse = await instance.get('');
  return resonse.data;
}

export async function get(id) {
  const resonse = await instance.get(`/${id}`, {
    validateStatus: (value) => [200, 404].includes(value),
  });
  return resonse.status === 200 ? resonse.data : null;
}
