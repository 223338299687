export function PrivacyPolicyScene() {
  return (
    <main className="flex flex-col gap-3 max-w-5xl mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold">Privacy Policy</h1>

      <h2 className="text-xl font-bold">Privacy Policy for Drreamz</h2>
      <p>
        We value the privacy and security of our users' personal information.
        This privacy policy outlines how we collect, use, and protect the
        personal information you provide using our website.
      </p>

      <h2 className="text-xl font-bold">Collection of Personal Information</h2>
      <p>
        When you use Drreamz, we collect personal information such as your name,
        email address, and password. We may also collect information about
        events you create or attend through our website.
      </p>

      <h2 className="text-xl font-bold">Use of Personal Information</h2>
      <p>
        We use the personal information that we collect to provide you with
        access to our website and to allow you to create and attend events. We
        may also use your personal information to communicate with you about
        updates and changes to our website or events that you have expressed
        interest in. We will not disclose your personal information to any third
        party without your consent, except as required by law or to enforce our
        terms of service.
      </p>

      <h2 className="text-xl font-bold">Cookies</h2>
      <p>
        We use cookies on our website to improve your experience and personalize
        content and ads. Cookies are small data files that are placed on your
        device when you visit our website. You can control the use of cookies
        through your browser settings.
      </p>

      <h2 className="text-xl font-bold">Location Services</h2>
      <p>
        We may use location services on our website to provide you with
        location-based services or to tailor content to your location. You can
        control the use of location services through your device settings.
      </p>

      <h2 className="text-xl font-bold">Third-Party Websites</h2>
      <p>
        Our website may contain links to third-party websites that are not under
        our control. We are not responsible for the privacy practices of these
        websites. We encourage you to review the privacy policies of these
        websites before providing them with any personal information.
      </p>

      <h2 className="text-xl font-bold">Data Access and Control</h2>
      <p>
        You have the right to access, correct, or delete your personal
        information at any time. You may also request that we restrict or object
        to the processing of your personal information. To exercise these
        rights, please contact us using the information provided at the end of
        this privacy policy.
      </p>

      <h2 className="text-xl font-bold">Protection of Personal Information</h2>
      <p>
        We take the security of your personal information seriously and have
        implemented appropriate technical and organizational measures to protect
        your personal information from unauthorized access, disclosure, or
        destruction. We also regularly review and update our security measures
        to ensure that your personal information remains safe.
      </p>

      <h2 className="text-xl font-bold">Retention of Personal Information</h2>
      <p>
        We will retain your personal information for as long as necessary to
        fulfill the purposes outlined in this privacy policy or as required by
        law. We will also delete your personal information upon your request.
      </p>

      <h2 className="text-xl font-bold">Changes to this Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time to reflect changes
        in our information practices. We encourage you to review this privacy
        policy regularly to stay informed of any changes.
      </p>

      <h2 className="text-xl font-bold">Contact Us</h2>
      <p>
        If you have any questions or concerns about these terms and conditions,
        please contact us at{' '}
        <a href="mailto:support@drreamz.com" className="text-orange-60">support@drreamz.com</a>
      </p>
    </main>
  );
}
