import { humanOfferType } from '@utils';
import classNames from 'classnames';

const OfferCard = ({ type, title, description, className, children }) => {
  return (
    <div
      className={classNames(
        'p-4 flex flex-col gap-3 rounded-lg border border-midnight-50 bg-gray-10',
        className,
      )}
    >
      <div className="flex flex-col">
        <h2 className="text-2xl text-midnight-100 font-bold capitalize truncate">
          {title}
        </h2>
        <div className="text-sm text-midnight-60 capitalize">
          {humanOfferType(type)}
        </div>
      </div>
      <p className="text-sm leading-5 flex-grow text-midnight-90 font-normal capitalize">
        {description}
      </p>
      {children}
    </div>
  );
};

export default OfferCard;
