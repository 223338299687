import { UserStore } from '@stores';
import { observer } from 'mobx-react-lite';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChatHeader, ChatInput } from '../components';
import ChatMessage from '../components/ChatMessage';
import { InboxStore } from '../stores';

const MessagesChatScene = observer(() => {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const bottomRef = useRef(null);

  useEffect(() => {
    async function init() {
      setLoading(true);

      try {
        await InboxStore.getChat(chatId);
        InboxStore.checkPrivateChat(UserStore.currentUser.id);
        InboxStore.subscribeChat(
          chatId,
          () => {
            // toast('Error connecting to chat!', { type: 'error' });
            // navigate('/dashboard/inbox');
          },
          () => {
            setTimeout(() => {
              bottomRef.current?.scrollIntoView();
            }, 0);
          },
        );
        setTimeout(() => {
          bottomRef.current?.scrollIntoView();
        }, 0);
      } finally {
        if (!InboxStore.chat) {
          toast('No such chat!', { type: 'error' });
          navigate('/dashboard/messages');
        }
        setLoading(false);
      }
    }

    void init();

    return () => {
      InboxStore.unsubscribeChat();
      InboxStore.clear();
    };
  }, [chatId, navigate]);

  return loading ? null : (
    <>
      <ChatHeader avatar={InboxStore.chat.logo} name={InboxStore.chat.name} />
      <div className="w-full p-2 grow overflow-y-auto">
        {InboxStore.messages.map((message) => (
          <ChatMessage
            key={nanoid()}
            text={message.text}
            time={moment(message.timestamp).format('h:mm a')}
            messageUserId={message.user.id}
            currentUserId={UserStore.currentUser.id}
            avatar={message.user.avatar}
            type={message.type}
          />
        ))}
        <div ref={bottomRef} />
      </div>
      <ChatInput chatId={InboxStore.chat.id} />
    </>
  );
});

export default MessagesChatScene;
