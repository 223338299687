import CreateNewChatDialog from '@/components/Messenger/CreateNewChatDialog';
import CreateNewRoomDialog from '@/components/Messenger/CreateNewRoomDialog';
import { useState } from 'react';

export default function NoActiveRoom() {
  const [showCreateNewRoomDialog, setShowCreateNewRoomDialog] = useState(false);
  const [showCreateNewChatDialog, setShowCreateNewChatDialog] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center">
      <span className="font-bold text-xl text-midnight-90">Select a chat</span>
      <span className="text-xl text-midnight-90">or</span>

      <div className="grid grid-cols-2 gap-6">
        <button
          type="button"
          className="button-main py-2 px-4 justify-center"
          onClick={() => setShowCreateNewChatDialog(true)}
        >
          Start Chat with a User
        </button>
        <button
          type="button"
          className="button-main py-2 px-4 justify-center"
          onClick={() => setShowCreateNewRoomDialog(true)}
        >
          Create a Group Room
        </button>
      </div>

      <CreateNewRoomDialog
        show={showCreateNewRoomDialog}
        onClose={() => setShowCreateNewRoomDialog(false)}
      />
      <CreateNewChatDialog
        show={showCreateNewChatDialog}
        onClose={() => setShowCreateNewChatDialog(false)}
      />
    </div>
  );
}
