import { SelectUser } from '@/components';
import { AuthService, UserService } from '@/services';
import { UserStoreV2 } from '@/stores';
import { Dialog } from '@headlessui/react';
import { useEffect, useState } from 'react';

export default function DevBackdoor() {
  if (process.env.NODE_ENV === 'development') {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState(null);
    const userStore = UserStoreV2.useState();

    const onClose = () => {
      setOpen(false);
    };

    const login = async () => {
      await AuthService.loginAs(user.id);
      await userStore.load();
    };

    const findUsers = async (query) => {
      const list = await UserService.search(query);
      return list.data;
    };

    useEffect(() => {
      /** @param {KeyboardEvent} event */
      function listner(event) {
        if (event.key === 'Enter' && event.altKey) {
          setOpen(true);
        }
      }
      document.addEventListener('keydown', listner);

      return () => document.removeEventListener('keydown', listner);
    }, []);

    return (
      <Dialog open={open} onClose={onClose} className="relative z-50">
        <div className="fixed inset-0 bg-black-500/80" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="flex flex-col gap-6 w-full px-7.5 py-12 max-w-2xl bg-gray-10 rounded-2.5xl max-h-screen overflow-auto">
            <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
              Login As
            </Dialog.Title>

            <SelectUser
              value={user}
              onChange={setUser}
              loadData={findUsers}
              placeholder="Search by email"
            />

            <button
              type="button"
              className="button-main button-m justify-center"
              disabled={user === undefined}
              onClick={login}
            >
              {user ? `Login as ${user.email}` : 'Select user to login as'}
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    );
  }
}
