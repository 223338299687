import { httpClient } from '@utils';
import * as qs from 'qs';

/**
 * @param {object} data
 * @param {string} data.offer_id
 * @param {string} data.timestamp
 * @param {string} data.duration ISO 8601 duration (e.g. "P3Y6M4DT12H30M5S" - three years, six months, four days, twelve hours, thirty minutes, and five seconds)
 * @param {boolean} data.is_discovery_call
 * @param {Array<string>} data.participants
 */
export async function create(data) {
  const { data: session } = await httpClient.post('/user/sessions', data);
  return session;
}

/**
 * @param {string} sessionId session UUID
 */
export async function get(sessionId, params) {
  const { data } = await httpClient.get(`/user/sessions/${sessionId}`, {
    params,
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return data;
}

export async function getParticipants(sessionId) {
  const { data } = await httpClient.get(
    `/user/sessions/${sessionId}/participants`,
  );
  return data;
}

export async function addParticipants(sessionId, data) {
  const response = await httpClient.put(
    `/user/sessions/${sessionId}/participants`,
    data,
  );
  return response.data;
}

export async function deleteParticipants(sessionId, data) {
  const response = await httpClient.delete(
    `/user/sessions/${sessionId}/participants`,
    { data },
  );
  return response.data;
}

/**
 * @param {{ limit?: number, offset?: number, offerId?: string, timestampAfter: string, timestampBefore: string, expand: Array<'offer'>, sortBy?: string, sortOrder?: 'desc' | 'asc'  }} params
 */
export async function list(params = {}) {
  const { data } = await httpClient.get('/user/sessions', {
    params: {
      limit: params.limit,
      offset: params.offset,
      sort_by: params.sortBy,
      sort_order: params.sortOrder,
      filters: {
        offer_id: { eq: params.offerId },
        timestamp: {
          gte: params.timestampAfter,
          lte: params.timestampBefore,
        },
      },
      expand: params.expand,
    },
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return data;
}

/**
 * @param {string} sessionId session UUID
 * @param {{ link?: string }} data
 * @deprecated Required update!
 */
export async function update(sessionId, data = {}) {
  console.warn('Deprecated method. Update required!'); // ???
  const { data: session } = await httpClient.patch(
    `/user/sessions/${sessionId}`,
    data,
  );
  return session;
}

/**
 * @param {string} sessionId session UUID
 */
export async function remove(sessionId) {
  const { data } = await httpClient.delete(`/user/sessions/${sessionId}`);
  return data;
}
