import { OfferDiscoveryCallForm } from '@/components/EditOffer';
import { OfferContext } from '@/contexts';
import { useContext } from 'react';
import EditOfferTabFallback from './EditOfferTabFallback';

export default function EditOfferDiscoveryCallScene() {
  const context = useContext(OfferContext);
  const showFallback =
    context.offerType !== 'one-to-one' && context.offerType !== 'group';

  return showFallback ? <EditOfferTabFallback /> : <OfferDiscoveryCallForm />;
}
