import { OfferContentForm } from '@/components/EditOffer';
import { OfferContext } from '@/contexts';
import { MyOfferService } from '@/services';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default function EditOfferContentScene() {
  const context = useContext(OfferContext);
  const [loading, setLoading] = useState(undefined);

  // Load offer's content if the offer exists
  useEffect(() => {
    async function fetchContent() {
      console.log('Fetch Content');
      try {
        setLoading(true);
        const contentData = await MyOfferService.getContent(context.id);
        contentData && context.fromApiContent(contentData);
        setLoading(false);
      } catch (error) {
        toast.error('Unable to get offer content. See conslole (F12)');
        console.error('Get offer content error', error);
      }
    }
    if (context.id) {
      void fetchContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.id]);

  return loading === false && <OfferContentForm />;
}
