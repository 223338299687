import { useState, useMemo, useCallback } from 'react';

export default function useControllable(
  externalState,
  setExternalState,
  defaultValue,
) {
  const [innerState, setInnerState] = useState(
    externalState === undefined ? defaultValue : externalState,
  );

  const state = useMemo(() => {
    if (externalState !== undefined) {
      return externalState;
    } else {
      return innerState;
    }
  }, [innerState, externalState]);

  const changeState = useCallback(
    (value) => {
      if (setExternalState !== undefined) {
        setExternalState(value);
      } else {
        setInnerState(value);
      }
    },
    [setExternalState],
  );

  return [state, changeState];
}
