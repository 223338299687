import { AppContext, AppPublicContext } from '@/contexts';
import { UserStoreV2 } from '@/stores';
import { Link, Outlet } from 'react-router-dom';

export default function AppLayout() {
  const userStore = UserStoreV2.useState();

  return (
    <AppContext.Provider value={AppPublicContext}>
      <div className="flex flex-col min-h-screen bg-midnight-10">
        <header className="sticky top-0 flex justify-between px-12 py-3 h-16 bg-white shadow-sm z-30">
          <img
            src="/images/logo-black.svg"
            className="h-full"
            alt="Drreamz Logo"
          />

          {userStore.isInitialized &&
            (userStore.isAuthorized ? (
              <Link to="/dashboard" className="button-outline button-m px-8">
                Dashboard
              </Link>
            ) : (
              <div className="flex gap-3">
                <Link
                  to="/auth/sign_up"
                  className="button-outline button-m px-8"
                >
                  Sign Up
                </Link>
                <Link to="/auth/sign_in" className="button-main button-m px-8">
                  Sign In
                </Link>
              </div>
            ))}
        </header>

        <main className="grow max-w-screen-2xl w-full mx-auto">
          <Outlet />
        </main>
      </div>
    </AppContext.Provider>
  );
}
