import matrixClient from '@utils/matrixClient';
import { createContext, useCallback, useState } from 'react';
export const MATRIX_HOMESERVER = process.env.REACT_APP_MATRIX_HOMESERVER;

export const MessengerStates = {
  NOT_INITIALIZED: 'NOT_INITIALIZED',
  STARTING: 'STARTING',
  START_SUCCESS: 'START_SUCCESS',
  START_FAILED: 'START_FAILED',
  STOPPED: 'STOPPED',
};

export function useMessengerContext() {
  const [state, setState] = useState(MessengerStates.NOT_INITIALIZED);
  /**
   * Additional information about the current state.
   * Usually it contains an error for a failure state and empty for a successful state.
   */
  const [stateInfo, setStateInfo] = useState(undefined);

  const changeState = useCallback((state, info = undefined) => {
    setState(state);
    setStateInfo(info);
  }, []);

  const start = useCallback(async () => {
    console.debug('[Messenger Context] Start requested...');
    changeState(MessengerStates.STARTING);
    try {
      console.debug('[Messenger Context] Check the client is usable...');
      await matrixClient.whoami();
    } catch (error) {
      console.error('[Messenger Context] Test request failed.', error);
      changeState(MessengerStates.START_FAILED, error);
      return false;
    }

    try {
      console.debug('[Messenger Context] Starting the client...');
      await matrixClient.startClient({ initialSyncLimit: 10 });
    } catch (error) {
      console.error('[Messenger Context] Cannot start the client.', error);
      changeState(MessengerStates.START_FAILED, error);
      return false;
    }

    changeState(MessengerStates.START_SUCCESS);
    return true;
  }, [changeState]);

  const stop = useCallback(async () => {
    console.debug('[Messenger Context] Stop the client...');
    matrixClient.stopClient();
    changeState(MessengerStates.STOPPED);
  }, [changeState]);

  return {
    // State
    state,
    stateInfo,
    matrixClient,

    // Mutations
    start,
    stop,
  };
}

/** @type {import("react").Context<ReturnType<typeof useMessengerContext> | null>} */
export const MessengerContext = createContext(null);
