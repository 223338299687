import {
  DialogFailureIcon,
  DialogSuccessIcon,
  DialogTimeoutIcon,
  DialogWaitingIcon,
} from '@/icons';
import classNames from 'classnames';
import { useMemo } from 'react';

const iconMap = new Map([
  ['success', DialogSuccessIcon],
  ['waiting', DialogWaitingIcon],
  ['timeout', DialogTimeoutIcon],
  ['failure', DialogFailureIcon],
]);
const titleMap = new Map([
  ['success', 'Success'],
  ['waiting', 'Waiting'],
  ['timeout', 'Timeout'],
  ['failure', 'Failure'],
]);

export default function StatusMessage({
  status,
  icon,
  title,
  text,
  children,
  className,
}) {
  const IconToDisplay = useMemo(
    () => icon || iconMap.get(status),
    [status, icon],
  );
  const titleToDisplay = useMemo(
    () => title || titleMap.get(status),
    [status, title],
  );

  return (
    <div className={classNames('flex flex-col items-center gap-12', className)}>
      <div className="flex flex-col items-center gap-4 text-center">
        <IconToDisplay />
        <h1 className="text-xl font-bold ">{titleToDisplay}</h1>
        <p className="text-lg text-midnight-90">{text}</p>
      </div>
      {children}
    </div>
  );
}
