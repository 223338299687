import { useCallback, useContext, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import { UserStoreV2 } from '@/stores';
import { FormControl } from '@components';
import { WorkspaceService } from '@services';
import { WorkspaceContext } from './WorkspaceContext';

const SettingsScene = () => {
  const userState = UserStoreV2.useState();
  const workspace = useContext(WorkspaceContext);
  const { element: ConfirmationDialog, ask: askConfirmation } =
    useConfirmationDialog();

  const navigate = useNavigate();
  const [name, setName] = useState(workspace.name || '');

  if (workspace.owner_id !== userState.state.user.id) {
    console.error(
      "Access denied: You can't manage the workspace since you're not the owner",
    );
    return (
      <Navigate to={`/dashboard/client/workspaces/${workspace.id}`} replace />
    );
  }

  const handleSubmit = useCallback(async () => {
    try {
      await WorkspaceService.update(workspace.id, { name });
      navigate('/dashboard/coach/workspaces');
    } catch (err) {
      console.log(err);
      toast.error('Unable to update this workspace. See console (F12)');
    }
  }, [workspace.id, name, navigate]);

  const handleDelete = async () => {
    const confirm = await askConfirmation(
      'Are you sure you want to delete this workspace?',
    );
    if (!confirm) return;

    try {
      await WorkspaceService.remove(workspace.id);
      toast.success('Workspace has been deleted');
      navigate('/dashboard/coach/workspaces');
    } catch (err) {
      console.log(err);
      toast.error('Unable to delete this workspace. See console (F12)');
    }
  };

  return (
    <div className="flex flex-col gap-7 px-12 py-8">
      <div className="flex justify-between">
        <h1 className="text-4.5xl font-bold text-midnight-90">
          Workspace Settings
        </h1>

        <button
          type="button"
          className="button-main button-m"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>

      <div className="flex flex-col gap-6 p-5 rounded-2.5xl bg-gray-10">
        <FormControl
          name="name"
          value={name}
          fullWidth
          required={true}
          placeholder="Enter Title"
          label="Workspace Name"
          onChange={(event) => setName(event.target.value)}
        />
        <div className="flex flex-col gap-4">
          <h2 className="text-red-60 text-xl font-bold">Dangerous Actions</h2>

          <button
            type="button"
            className="self-start button-danger button-m"
            onClick={handleDelete}
          >
            Delete workspace
          </button>
        </div>
      </div>

      <ConfirmationDialog />
    </div>
  );
};

export default SettingsScene;
