import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/user/schedules`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

/**
 * @param {{ limit: number, offset: number }} params
 * @returns {Promise<{ total: number, data: Array<Schedule>}>}
 */
export async function list(params) {
  const resonse = await instance.get('', { params });
  return resonse.data;
}

/**
 * @param {ScheduleEditableData} data
 * @returns {Promise<Schedule>}
 */
export async function create(data) {
  const resonse = await instance.post('', data);
  return resonse.data;
}

/**
 * @param {string} id
 * @param {ScheduleEditableData} data
 * @returns {Promise<Schedule>}
 */
export async function update(id, data) {
  const resonse = await instance.put(`${id}`, data);
  return resonse.data;
}

/**
 * @param {string} id
 * @returns {Promise<Schedule>}
 */
export async function remove(id) {
  const resonse = await instance.put(`${id}`);
  return resonse.data;
}

/**
 * @typedef {object} Schedule
 * @property {string} id
 * @property {string} name
 * @property {string} timezone
 * @property {Array<ScheduleDay>} schedule
 */
/**
 * @typedef {object} ScheduleDay
 * @property {boolean} enabled
 * @property {Array<{ from: number, to: number }>} intervals
 */
/**
 * @typedef {object} ScheduleEditableData
 * @property {string} name
 * @property {string} timezone
 * @property {Array<ScheduleDay>} schedule
 */
