import axios from 'axios';
import { toast } from 'react-toastify';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const handleUnauthorizedError = async (error) => {
  const originalRequest = error.config;
  if (
    error.response.status === 401 &&
    !originalRequest._retry &&
    error.config.url !== '/auth/refresh'
  ) {
    originalRequest._retry = true;

    try {
      const { data } = await httpClient.get('/auth/refresh');
      originalRequest.headers.Authorization = `Bearer ${data.access}`;
      localStorage.setItem('access_token', data.token);
      return httpClient(originalRequest);
    } catch (e) {
      console.log(e);
    }
  }
};

const errorInterceptor = async (error) => {
  switch (error.response.status) {
    case 401:
      console.log(error);
      await handleUnauthorizedError(error);
      break;
    case 400:
      toast(error.response.data.message, {
        type: 'error',
      });
      break;
    case 404:
      if (error.config.url === '/coach') {
        return;
      }
      toast(error.response.data.message, {
        type: 'error',
      });
      break;
    case 500:
      toast('Server error!', {
        type: 'error',
      });
      break;
  }

  throw error;
};

httpClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

httpClient.interceptors.response.use((response) => response, errorInterceptor);

export default httpClient;
