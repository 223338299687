import classNames from 'classnames';
import { DAvatar } from '@components';
import { SIZE } from '@constants';

const ChatMessage = ({
  text,
  messageUserId,
  currentUserId,
  time,
  avatar,
  type,
}) => {
  return (
    <div
      className={classNames('flex flex-row', {
        'justify-end': messageUserId === currentUserId,
      })}
    >
      <div className="flex flex-col justify-end">
        <div className="flex flex-row">
          {messageUserId !== currentUserId && (
            <div className="flex flex-col justify-end">
              <DAvatar avatar={avatar} size={SIZE.S} />
            </div>
          )}
          {type === 'text' && (
            <p
              className={classNames(
                'rounded-xl font-normal text-md p-4 text-midnight-100 break-all',
                { 'bg-midnight-30': messageUserId === currentUserId },
                { 'bg-gray-20': messageUserId !== currentUserId },
                { 'ml-2': messageUserId !== currentUserId },
              )}
            >
              {text}
            </p>
          )}
          {type === 'file' && (
            <div
              className={classNames(
                'rounded-xl font-normal text-md p-4 text-midnight-100 break-all',
                { 'bg-midnight-30': messageUserId === currentUserId },
                { 'bg-gray-20': messageUserId !== currentUserId },
                { 'ml-2': messageUserId !== currentUserId },
              )}
            >
              <img alt="attach" src={text} className="w-20 h-20 rounded-xl" />
            </div>
          )}
        </div>
        <span className="text-right text-sm text-midnight-60 font-normal py-1">
          {time}
        </span>
      </div>
    </div>
  );
};

export default ChatMessage;
