import { formDataReq, httpClient } from '@utils';

export const getAll = () => {
  return httpClient.get('/user/offers');
};

export const getById = (offerId) => {
  return httpClient.get(`/offers/${offerId}`);
};

export const getAllByCoach = (coachId) => {
  return httpClient.get(`/coaches/${coachId}/offers`);
};

export const getBooking = async (offerId) => {
  const { data } = await httpClient.get(
    `/user/offers/${offerId}/booking-schedule`,
  );
  return data;
};

export const updateBooking = async (offerId, schedule) => {
  const { data } = await httpClient.patch(
    `/user/offers/${offerId}/booking-schedule`,
    schedule,
  );
  return data;
};

export const create = (data) => {
  const formData = formDataReq(data);
  return httpClient.post('/user/offers', formData, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
};

export const update = ({ offerId, data }) => {
  return httpClient.patch(`/offers/${offerId}`, data);
};

export const deleteById = (offerId) => {
  return httpClient.delete(`/offers/${offerId}`);
};

export const getSessionInfo = (offerId) => {
  return httpClient.get(`/user/offers/${offerId}/session-info`);
};

export const getContent = (offerId) => {
  return httpClient.get(`/user/offers/${offerId}/content`);
};

export const uploadContent = async (offerId, data) => {
  return httpClient.put(`/user/offers/${offerId}/content`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getComments = (offerId) => {
  return httpClient.get(`/offers/${offerId}/comments`);
};

export const addComment = ({ offerId, data }) => {
  return httpClient.put(`/offers/${offerId}/comments`, data);
};

export const getBookings = (offerId) => {
  return httpClient.get(`/offers/${offerId}/bookings`);
};

export const getTimeslots = async (offerId, { begin, end }) => {
  const { data } = await httpClient.get(`/offers/${offerId}/timeslots`, {
    params: { begin, end },
  });
  return data;
};
