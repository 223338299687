import {
  Button,
  IconLabel,
  Label,
  LandingTemplate,
  RadioLabels,
  RightArrow,
  Tick,
} from '@components';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
const stripePromise = loadStripe(
  'pk_test_51JIcLUDdeXyKLbpcXLiICCScV3MiRkN6bvuizPwWmyD4h2P2iPkRHvL34Jr8TDGwrLKjogpKJiUHVIVaqo0wg2ap00IopZ9qYD',
);

// TODO: make env file

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    // Make sure to change this to your payment completion page
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: 'http://localhost:3000/landing/herewego' },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occured.');
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            <Button
              icon={<RightArrow />}
              className="mt-7 flex space-x-2 bg-green-400 h-12 p-6 text-md items-center text-center justify-center rounded-lg text-white"
              value="Pay Now"
            />
          )}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

const settings = [
  {
    name: 'Annual (Best Value) $529',
    value: 529,
    description: 'First 14 days free',
  },
  {
    name: 'Monthly $49',
    value: 49,
    description: 'First 7 days free',
  },
];

function TryFreeScene() {
  const [clientSecret, setClientSecret] = useState('');
  // eslint-disable-next-line
  const [subscribe, setSubscribe] = useState(settings[0].value);

  const getPaymentIntent = () => {
    // return useFetch
    //   .post('stripe/intent', {
    //     user: '122332',
    //     amount: subscribe * 100,
    //   })
    //   .then((res) => {
    //     setClientSecret(res.data.data.client_secret);
    //   });
    setClientSecret('');
  };

  const { handleSubmit } = useForm({ mode: 'onSubmit' });

  const submit = async () => {
    getPaymentIntent();
  };

  const handleCallback = (value) => {
    setSubscribe(value);
  };

  const appearance = { theme: 'stripe' };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <LandingTemplate>
      {!clientSecret && (
        <form onSubmit={handleSubmit(submit)}>
          <div className="flex flex-col justify-center h-full">
            <img src="/images/logo.png" className="w-16" alt="..." />
            <Label
              value="Try Drreamz for free"
              className="text-5xl pt-1 text-black-200 font-bold"
            />

            <IconLabel
              mainclass="flex space-x-3 mt-3 items-center"
              value="Unlock the full Drreamz experience"
              className="text-gray-700 text-base font-normal leading-6"
              icons={<Tick />}
              icon="hidden"
            />
            <IconLabel
              mainclass="flex space-x-3 mt-3 items-center"
              value="Manage your entire business in one place"
              className="text-gray-700 text-base font-normal leading-6"
              icons={<Tick />}
              icon="hidden"
            />
            <IconLabel
              mainclass="flex space-x-3 mt-3 items-center"
              value="Grow your community and collaboration"
              className="text-gray-700 text-base font-normal leading-6"
              icons={<Tick />}
              icon="hidden"
            />
            <IconLabel
              mainclass="flex space-x-3 mt-3 items-center"
              value="Develop & launch new programs"
              className="text-gray-700 text-base font-normal leading-6"
              icons={<Tick />}
              icon="hidden"
            />
            {settings && (
              <RadioLabels
                settings={settings}
                mainclass="space-y-5 mt-8 mb-10"
                radioMainClass="w-10 flex items-center justify-center"
                className="py-5 px-4"
                avatarMainClass="hidden"
                parentCallback={handleCallback}
              />
            )}

            <div className="flex mt-8 space-x-5 border-l-8 border-gray-620">
              <div className="ml-2">
                <Label
                  value="After free trial, annual subscription automatically renews each year and monthly
       subscription automatically renews each month. If you subscribe before your free
       trial ends, the rest of your free trial period will be forfeited as soon as your
       purchase is confirmed. Eligible for new users only.
       "
                  className="font-normal text-sm text-gray-700 leading-5 "
                />
                <Label
                  value="Terms & Conditions"
                  className="font-medium font-sm leading-6 mt-4"
                />
              </div>
            </div>
            <Button
              icon={<RightArrow />}
              className="mt-7 flex space-x-2 bg-green-400 w-1/2 h-12 p-6 text-md items-center text-center justify-center rounded-lg text-white"
              value="try free and subscribe"
            />
          </div>
        </form>
      )}
      {clientSecret && (
        <div className="flex flex-col justify-center h-full">
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </div>
      )}
    </LandingTemplate>
  );
}
export default TryFreeScene;
