import { ContactInputStatic } from '@/components/ContactInput';
import { MessengerContext } from '@/contexts';
import { EnvelopeIcon, LockIcon, PersonIcon, PhoneIcon } from '@/icons';
import { UserStoreV2 } from '@/stores';
import { AvatarInput } from '@components';
import { useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import ChangePasswordDialog from './ChangePasswordDialog';
import usePersonalInfoReducer from './EditPersonalInfoReducer';

const validationSchema = object({
  first_name: string().required('This field is required'),
  last_name: string().required('This field is required'),
  email: string()
    .trim()
    .email('Invalid email')
    .required('This field is required'),
  phone: string().nullable(),
});

export default function EditPersonalInfoForm() {
  const { matrixClient } = useContext(MessengerContext);
  const userStore = UserStoreV2.useState();
  const [loading, setLoading] = useState(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);
  const [personalInfo, dispatch] = usePersonalInfoReducer(userStore.state.user);

  const validationError = useMemo(() => {
    try {
      validationSchema.validateSync(personalInfo);
      return null;
    } catch (error) {
      return error;
    }
  }, [personalInfo]);

  async function updateProfile() {
    try {
      setLoading(true);
      // Cache avatar cuz userStore.update() mutates the parameter replacing it with the link
      const rawAvatar = personalInfo.avatar;
      await userStore.update(personalInfo);
      toast.success('Profile updated successfully!');

      await matrixClient.setDisplayName(
        `${personalInfo.first_name} ${personalInfo.last_name}`,
      );
      if (rawAvatar instanceof File) {
        try {
          const { content_uri } = await matrixClient.uploadContent(rawAvatar);
          await matrixClient.setAvatarUrl(content_uri);
        } catch (error) {
          toast.error('Cannot update messenger avatar!');
          console.error('Messenger avatar updating error', error);
        }
      } else if (personalInfo.avatar === null) {
        await matrixClient.setAvatarUrl('');
      }
    } catch (error) {
      toast.error('Profile updating error! See console (F12)');
      console.error('Profile updating error', error);
    } finally {
      setLoading(false);
    }
  }

  async function updatePassword(newPassword) {
    try {
      setLoading(true);
      await userStore.update({ password: newPassword });
      toast.success('Password updated successfully!');
    } catch (error) {
      toast.error('Password updating error! See console (F12)');
      console.error('Password updating error', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="pt-6 pb-9 px-8 flex flex-col gap-8 bg-gray-10 rounded-2.5xl">
      <AvatarInput
        value={personalInfo.avatar}
        className="w-30"
        disabled={loading}
        onChange={(value) =>
          dispatch({
            type: 'change_avatar',
            value,
          })
        }
      />

      <div className="flex flex-col gap-4">
        <TextInput
          id="personal-info-first-name-input"
          value={personalInfo.first_name}
          onChange={(event) =>
            dispatch({
              type: 'change_first_name',
              value: event.target.value,
            })
          }
          label="First Name"
          placeholder="First Name"
          required={true}
          disabled={loading}
          icon={<PersonIcon className="fill-midnight-60" />}
          error={
            validationError?.path === 'first_name' && validationError.errors[0]
          }
        />
        <TextInput
          id="personal-info-last-name-input"
          value={personalInfo.last_name}
          onChange={(event) =>
            dispatch({
              type: 'change_last_name',
              value: event.target.value,
            })
          }
          label="Last Name"
          placeholder="Last Name"
          required={true}
          disabled={loading}
          icon={<PersonIcon className="fill-midnight-60" />}
          error={
            validationError?.path === 'last_name' && validationError.errors[0]
          }
        />
        <TextInput
          id="personal-info-email-input"
          value={personalInfo.email}
          onChange={(event) =>
            dispatch({
              type: 'change_email',
              value: event.target.value,
            })
          }
          label="Email Address"
          placeholder="Email Address"
          required={true}
          disabled={loading}
          icon={<EnvelopeIcon className="fill-midnight-60" />}
          error={validationError?.path === 'email' && validationError.errors[0]}
        />
        <TextInput
          id="personal-info-phone-input"
          value={personalInfo.phone}
          onChange={(event) =>
            dispatch({
              type: 'change_phone',
              value: event.target.value,
            })
          }
          label="Phone Number"
          placeholder="Phone Number"
          disabled={loading}
          icon={<PhoneIcon className="fill-midnight-60" />}
        />
        <fieldset className="flex flex-col gap-2.5">
          <legend className="text-midnight-100 font-semibold mb-2.5">
            Contacts
          </legend>
          <ContactInputStatic
            type="whatsup"
            value={
              personalInfo.contacts.find((v) => v.type === 'whatsup')?.value ||
              ''
            }
            onChange={(event) =>
              dispatch({
                type: 'change_contact',
                value: {
                  type: 'whatsup',
                  value: event.target.value,
                },
              })
            }
            placeholder="+1 (555) 987-6543"
          />
          <ContactInputStatic
            type="messenger"
            value={
              personalInfo.contacts.find((v) => v.type === 'messenger')
                ?.value || ''
            }
            onChange={(event) =>
              dispatch({
                type: 'change_contact',
                value: {
                  type: 'messenger',
                  value: event.target.value,
                },
              })
            }
            placeholder="username"
          />
          <ContactInputStatic
            type="telegram"
            value={
              personalInfo.contacts.find((v) => v.type === 'telegram')?.value ||
              ''
            }
            onChange={(event) =>
              dispatch({
                type: 'change_contact',
                value: {
                  type: 'telegram',
                  value: event.target.value,
                },
              })
            }
            placeholder="username"
          />
        </fieldset>
      </div>

      <div className="flex gap-6">
        <button
          type="button"
          className="button-main button-s"
          onClick={updateProfile}
          disabled={loading}
        >
          Save
        </button>

        <button
          type="button"
          className="button-second button-s gap-2"
          onClick={() => setShowChangePasswordDialog(true)}
          disabled={loading}
        >
          <LockIcon className="fill-current" aria-hidden="true" />
          <span>Change password</span>
        </button>
      </div>

      <ChangePasswordDialog
        open={showChangePasswordDialog}
        onClose={() => setShowChangePasswordDialog(false)}
        onChange={updatePassword}
      />
    </div>
  );
}

function TextInput({
  id,
  value,
  onChange,
  label,
  icon,
  placeholder,
  error,
  disabled,
  required,
}) {
  return (
    <div>
      <label htmlFor={id} className="text-midnight-100 font-semibold mb-2.5">
        {label}
      </label>
      <div className="relative">
        <span className="absolute inset-y-0 left-4 flex items-center">
          {icon}
        </span>
        <input
          id={id}
          type="text"
          required={required}
          value={value}
          onChange={onChange}
          className="w-full h-full input-main input-m pl-14"
          placeholder={placeholder}
          disabled={disabled}
          aria-invalid={error}
          aria-errormessage={error ? id + '-error' : null}
        ></input>
      </div>
      {error && (
        <div
          id={id + '-error'}
          className="pl-4 pt-1 text-[0.625rem] text-red-50"
        >
          {error}
        </div>
      )}
    </div>
  );
}
