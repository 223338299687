import { SIZE } from '@constants';
import { ArrowLeftIcon, SvgIcon } from '@icons';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import Loader from '../Loader';
import Tabs from '../Tabs';
import './ContentContainer.scss';

// const privateCoachRoutes = [
//   '/dashboard/sessions',
//   '/dashboard/offerings',
//   '/dashboard/offerings/',
//   '/dashboard/calendar',
//   '/dashboard/clients',
//   '/dashboard/inbox',
// ];

export const ContentContainer = observer(
  ({
    className,
    loading = false,
    showBackground: showBackgroundProp = true,
    title,
    subTitle,
    backTo,
    tabs = [],
    headerAppend,
    children,
    reverseHeaderContent = false,
    ...props
  }) => {
    // const location = useLocation();
    // const isCurrentRoutePrivate = privateCoachRoutes.includes(
    //   location.pathname,
    // );
    let content = children;
    let showBackground = showBackgroundProp;
    let hasAccess = true;

    // if (isCurrentRoutePrivate && UserStore.isCoach) {
    //   const coachHasSubscription =
    //     SubscriptionsStore.isCurrentPlanActive ||
    //     SubscriptionsStore.isCurrentSubscriptionTrial;
    //   hasAccess = UserStore.currentUser.has_profile && coachHasSubscription;

    //   if (!UserStore.currentUser.has_profile) {
    //     showBackground = true;
    //     content = <CompleteProfile />;
    //   } else if (!coachHasSubscription) {
    //     showBackground = true;
    //     content = <UpgradeAccess />;
    //   }
    // }

    return (
      <div
        className={classNames(
          'content-container',
          !showBackground && 'content-container--without-bg',
          className,
        )}
        {...props}
      >
        <div
          className={classNames('content-container__header', {
            'flex-row-reverse': reverseHeaderContent,
          })}
        >
          <div className="content-container__header-content">
            {backTo ? (
              <Link className="content-container__back-link" to={backTo}>
                <SvgIcon
                  className="content-container__back-icon"
                  icon={<ArrowLeftIcon />}
                />

                {title}
              </Link>
            ) : (
              <div className="flex items-center">
                <span className="content-container__title">{title}</span>
                {subTitle && (
                  <span className="whitespace-nowrap text-midnight-70 text-base font-bold ml-6">
                    {subTitle}
                  </span>
                )}
              </div>
            )}

            {loading && (
              <Loader className="content-container__loader" size={SIZE.L} />
            )}
          </div>

          {tabs.length > 0 && hasAccess && <Tabs tabs={tabs} />}

          {Boolean(headerAppend) && headerAppend}
        </div>

        {!loading && (
          <div className="content-container__content">{content}</div>
        )}
      </div>
    );
  },
);
