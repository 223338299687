import { CheckmarkIcon } from '@icons';
import classNames from 'classnames';

export default function GuidelineCheckmark(props) {
  return (
    <div className="relative">
      <div
        className={classNames(
          'absolute w-1 left-2 inset-y-0',
          props.line === null
            ? 'hidden'
            : props.line
            ? 'bg-orange-30'
            : 'bg-midnight-20',
        )}
      ></div>
      <div
        className={classNames(
          'relative h-5 w-5 p-1 rounded-full',
          props.check ? 'bg-orange-50' : 'bg-midnight-20',
        )}
      >
        <CheckmarkIcon
          className={classNames(
            'fill-orange-10 w-full h-full',
            props.check ? '' : 'hidden',
          )}
        />
      </div>
    </div>
  );
}
