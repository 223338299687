import ReactModal from 'react-modal';
import classNames from 'classnames';
import { BUTTON_THEME, SIZE } from '@constants';
import { CrossIcon } from '@icons';
import './Modal.scss';
import { DButton } from '../DButton';

const ModalCloseButton = ({ onClose }) => {
  return (
    <DButton
      className="cr-modal-new__close-button"
      theme={BUTTON_THEME.CLEAR}
      rounded={true}
      iconComponent={(<CrossIcon />)}
      size={SIZE.S}
      onClick={onClose}
    />
  );
};

export const Modal = ({
  className,
  isOpen,
  children,
  showCloseButton = false,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  onRequestClose,
  onAfterOpen,
  onClose,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ReactModal
      className={classNames('cr-modal-new__content', className)}
      overlayClassName="cr-modal-new__overlay"
      bodyOpenClassName="cr-modal-new__body--opened"
      data={{
        id: 'modal_content',
      }}
      isOpen={isOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      onAfterClose={onClose}
      onAfterOpen={onAfterOpen}
    >
      {children}

      {showCloseButton && (
        <ModalCloseButton onClose={onClose ?? onRequestClose}/>
      )}
    </ReactModal>
  );
};
