import App from '@/App';
import Guideline from '@/components/Guideline';
import {
  AcquisitionsHistoryScene,
  AcquisitionsLayout,
  AcquisitionsScene,
  AllTabScene,
  BecomeCoachScreen,
  CheckoutSessionScene,
  CoachProfileScene,
  CreateOfferChooseTypeScene,
  CreateOfferScene,
  CustomerSupportScene,
  DashboardScene,
  EditCoachProfileScene,
  EditOfferCommonScene,
  EditOfferContentScene,
  EditOfferDiscoveryCallScene,
  EditOfferScene,
  EditOfferSessionInfoScene,
  EditOfferSessionRequestScene,
  EditPersonalInfoScene,
  EmpoweredScene,
  ForgotPasswordScene,
  MessagesChatScene,
  MessagesNoChatScene,
  MessagesScene,
  MessengerScene,
  MyClientsScene,
  MyCoachProfileScene,
  MyCoachesListScene,
  MyOffersScene,
  NewTabScene,
  NotFoundScene,
  NotificationsScene,
  NotificationsSettingsScene,
  OfferPageScene,
  PricingTableScene,
  PrivacyPolicyScene,
  ProfileTypeScene,
  ResetPasswordScene,
  ScheduleManagerScene,
  SearchScene,
  Sessions,
  SettingsScene,
  SignInScene,
  SignUpScene,
  TermsConditionsScene,
  UserProfileScene,
  VerifyCodeScene,
  WorkspaceContextProvider,
  WorkspaceCreateScene,
  WorkspaceScene,
  WorkspaceSettingsScene,
  WorkspaceTabFiles,
  WorkspaceTabMembers,
  WorkspaceTabSessions,
  WorkspacesListScene,
  ZoomMeetingScene,
  ZoomOAuthScene,
} from '@scenes';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// Don't move AppLayout import before @scenes or it will cause
// `ReferenceError: Cannot access '__WEBPACK_DEFAULT_EXPORT__' before initialization`.
// Don't know why though. Probably someone messed up with imports.
import { AppLayout, AuthLayout } from '@/layouts';
import { TestingGroundsScene } from '@/scenes/TestingGrounds';

const CoachDashboardRoute = (
  <Route path="coach" element={<AppLayout.Coach />}>
    <Route index element={<Guideline />} />
    <Route path="messenger" element={<MessengerScene />} />
    <Route path="profile" element={<MyCoachProfileScene />} />
    <Route path="profile/edit" element={<EditCoachProfileScene />} />
    <Route path="clients" element={<MyClientsScene />} />
    <Route path="users/:userId" element={<UserProfileScene />} />
    {/* MY OFFERS */}
    <Route path="offers">
      <Route index element={<MyOffersScene />} />
      <Route path="new">
        <Route index element={<CreateOfferChooseTypeScene />} />
        <Route
          path="one-to-one"
          element={<CreateOfferScene type="one-to-one" />}
        />
        <Route path="group" element={<CreateOfferScene type="group" />} />
        <Route
          path="digital-content"
          element={<CreateOfferScene type="digital-content" />}
        />
      </Route>
      <Route path=":offerId" element={<EditOfferScene />}>
        <Route index element={<Navigate to="info" replace />} />
        <Route path="info" element={<EditOfferCommonScene />} />
        <Route path="content" element={<EditOfferContentScene />} />
        <Route path="session-info" element={<EditOfferSessionInfoScene />} />
        <Route
          path="session-requests"
          element={<EditOfferSessionRequestScene />}
        />
        <Route
          path="discovery-call"
          element={<EditOfferDiscoveryCallScene />}
        />
      </Route>
    </Route>
    <Route path="customer_support" element={<CustomerSupportScene />} />
    {/* SETTINGS */}
    <Route path="settings" element={<SettingsScene />}>
      <Route index element={<Navigate to="personal_info" replace />} />
      <Route path="personal_info" element={<EditPersonalInfoScene />} />
      <Route
        path="notifications_settings"
        element={<NotificationsSettingsScene />}
      />
      <Route path="schedule_manager" element={<ScheduleManagerScene />} />
    </Route>
    {/* NOTIFICATIONS */}
    <Route path="notifications" element={<NotificationsScene />}>
      <Route index element={<Navigate to="all" replace />} />
      <Route path="new" element={<NewTabScene />} />
      <Route path="all" element={<AllTabScene />} />
    </Route>
    {/* SESSIONS */}
    <Route path="sessions">
      <Route index element={<Navigate to="list" replace />} />
      <Route element={<Sessions.SessionsLayout />}>
        <Route path="list" element={<Sessions.ListScene />} />
        <Route path="calendar" element={<Sessions.CalendarScene />} />
        <Route
          path="requests"
          element={<Sessions.RequestListReceivedScene />}
        />
      </Route>
      <Route path=":id" element={<Sessions.SessionScene />} />
      <Route
        path=":id/session-info"
        element={<Sessions.SessionInfoEditScene />}
      />
      <Route path=":id/room" element={<ZoomMeetingScene />} />
      <Route path="create" element={<Sessions.CreateScene />} />
    </Route>
    {/* WORKSPACES */}
    <Route path="workspaces">
      <Route index element={<WorkspacesListScene />} />
      <Route path="create" element={<WorkspaceCreateScene />} />
      <Route path=":id" element={<WorkspaceContextProvider />}>
        <Route index element={<Navigate to="sessions" replace />} />
        <Route element={<WorkspaceScene />}>
          <Route index path="sessions" element={<WorkspaceTabSessions />} />
          <Route path="files" element={<WorkspaceTabFiles />} />
          <Route path="members" element={<WorkspaceTabMembers />} />
        </Route>
        <Route path="settings" element={<WorkspaceSettingsScene />} />
      </Route>
    </Route>
    <Route path="search" element={<SearchScene />} />
  </Route>
);

const ClientDashboardRoute = (
  <Route path="client" element={<AppLayout.Client />}>
    <Route path="messenger" element={<MessengerScene />} />
    <Route path="acquisitions" element={<AcquisitionsLayout />}>
      <Route index element={<Navigate to="offers" replace />} />
      <Route path="offers" element={<AcquisitionsScene />} />
      <Route path="history" element={<AcquisitionsHistoryScene />} />
    </Route>
    <Route path="users/:userId" element={<UserProfileScene />} />
    <Route path="coaches" element={<MyCoachesListScene />} />
    <Route path="coaches/:coachId" element={<CoachProfileScene />} />
    <Route path="customer_support" element={<CustomerSupportScene />} />
    {/* SETTINGS */}
    <Route path="settings" element={<SettingsScene />}>
      <Route index element={<Navigate to="personal_info" replace />} />
      <Route path="personal_info" element={<EditPersonalInfoScene />} />
      <Route
        path="notifications_settings"
        element={<NotificationsSettingsScene />}
      />
    </Route>
    {/* NOTIFICATIONS */}
    <Route path="notifications" element={<NotificationsScene />}>
      <Route index element={<Navigate to="all" replace />} />
      <Route path="new" element={<NewTabScene />} />
      <Route path="all" element={<AllTabScene />} />
    </Route>
    {/* OFFERS */}
    <Route path="offers/:offerId" element={<OfferPageScene />} />
    {/* SESSIONS */}
    <Route path="sessions">
      <Route index element={<Navigate to="list" replace />} />
      <Route element={<Sessions.SessionsLayout />}>
        <Route path="list" element={<Sessions.ListScene />} />
        <Route path="calendar" element={<Sessions.CalendarScene />} />
        <Route path="requests" element={<Sessions.RequestListSentScene />} />
      </Route>
      <Route path=":id" element={<Sessions.SessionScene />} />
      <Route path=":id/room" element={<ZoomMeetingScene />} />
    </Route>
    {/* WORKSPACES */}
    <Route path="workspaces">
      <Route index element={<WorkspacesListScene />} />
      <Route path=":id" element={<WorkspaceContextProvider />}>
        <Route index element={<Navigate to="sessions" replace />} />
        <Route element={<WorkspaceScene />}>
          <Route index path="sessions" element={<WorkspaceTabSessions />} />
          <Route path="files" element={<WorkspaceTabFiles />} />
          <Route path="members" element={<WorkspaceTabMembers />} />
        </Route>
      </Route>
    </Route>
    <Route path="search" element={<SearchScene />} />
  </Route>
);

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route
          path="checkout-session-return"
          element={<CheckoutSessionScene />}
        />
        {/* OAUTH */}
        <Route path="oauth/zoom" element={<ZoomOAuthScene />} />
        {/* DASHBOARD */}
        <Route path="dashboard" element={<DashboardScene />}>
          <Route index element={<Navigate to="client" replace />} />

          {CoachDashboardRoute}
          {ClientDashboardRoute}

          {/* MESSAGES */}
          <Route path="messages" element={<MessagesScene />}>
            <Route index element={<MessagesNoChatScene />} />
            <Route path=":chatId" element={<MessagesChatScene />} />
          </Route>
        </Route>

        {/* PUBLIC APP LAYOUT */}
        <Route element={<AppLayout.Public />}>
          <Route path="offers/:offerId" element={<OfferPageScene />} />
          <Route path="coaches/:coachId" element={<CoachProfileScene />} />
        </Route>

        <Route path="profile_type" element={<ProfileTypeScene />} />
        <Route path="empowered" element={<EmpoweredScene />} />
        <Route path="pricing_table" element={<PricingTableScene />} />
        <Route path="become_coach" element={<BecomeCoachScreen />} />
        <Route path="privacy_policy" element={<PrivacyPolicyScene />} />
        <Route path="terms_conditions" element={<TermsConditionsScene />} />

        <Route path="testing-grounds" element={<TestingGroundsScene />} />

        <Route path="auth" element={<AuthLayout />}>
          <Route index path="sign_in" element={<SignInScene />} />
          <Route path="sign_up" element={<SignUpScene />} />
          <Route path="forgot_password" element={<ForgotPasswordScene />} />
          <Route path="verify_code" element={<VerifyCodeScene />} />
          <Route path="reset_password" element={<ResetPasswordScene />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundScene />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
