import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/notifications`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function list() {
  const response = await instance.get('');
  return response.data;
}

export async function listNew() {
  const response = await instance.get('', {
    params: {
      filters: {
        viewed: {
          eq: false,
        },
      },
    },
  });
  return response.data;
}
export async function markViewed(notification_id) {
  const response = await instance.put(
    `/notifications/${notification_id}/viewed`,
  );
  return response.data;
}
