import { MATRIX_HOMESERVER } from '@/contexts';
import { MessageIcon } from '@/icons';

/**
 * @param {object} params
 * @param {import("matrix-js-sdk").Room} params.room
 * @param {number} [params.size]
 */
export default function RoomAvatar({ room, size = 40 }) {
  const roomAvatar =
    room &&
    (room.getAvatarUrl(MATRIX_HOMESERVER, size, size, 'crop') ||
      room
        .getAvatarFallbackMember()
        ?.getAvatarUrl(MATRIX_HOMESERVER, 40, 40, 'crop'));

  return roomAvatar ? (
    <img
      src={roomAvatar}
      alt="Room Avatar"
      className="shrink-0 w-10 h-10 rounded-full bg-midnight-30"
    />
  ) : (
    <div className="shrink-0 w-10 h-10 flex items-center justify-center rounded-full bg-midnight-30">
      <MessageIcon className="fill-midnight-50" />
    </div>
  );
}
