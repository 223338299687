import { useDragOffset } from '@hooks';
import classNames from 'classnames';
import { useState } from 'react';

export default function MovableImage({
  url,
  onRemove,
  offset,
  onChangeOffset,
}) {
  const [editMode, setEditMode] = useState(false);

  const startDrag = useDragOffset({
    offset: offset,
    onOffsetChanged: onChangeOffset,
  });

  /** @param {PointerEvent} event */
  const enterEditMode = (event) => {
    if (!editMode) {
      setEditMode(true);
      event.stopPropagation();
    }
  };
  /** @param {PointerEvent} event */
  const exitEditMode = (event) => {
    if (editMode) {
      setEditMode(false);
      event.stopPropagation();
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url('${url}')`,
        backgroundPositionY: offset === 0 && !editMode ? 'center' : offset,
      }}
      className={classNames(
        'object-cover w-full h-72 rounded-xl relative bg-center bg-cover',
        editMode && 'cursor-move',
      )}
      onMouseDown={() => {
        if (editMode) startDrag();
      }}
    >
      <div className="absolute right-3 bottom-3 flex gap-4">
        {editMode ? (
          <button
            type="button"
            className="button-ghost button-s"
            onClick={exitEditMode}
          >
            Done
          </button>
        ) : (
          <>
            <button
              type="button"
              className="button-ghost button-s"
              onClick={enterEditMode}
            >
              Move
            </button>
            {onRemove && (
              <button
                type="button"
                className="button-ghost button-s"
                onClick={onRemove}
              >
                Remove
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
