import { FormControl, Loader } from '@components';
import { FORM_CONTROL_TYPE, THEME } from '@constants';
import { httpClient } from '@utils';
import { useState } from 'react';

export default function NotificationsSettingsCard({
  title,
  preferenceType,
  preferencesValue,
}) {
  const [values, setValues] = useState(preferencesValue);
  const [loading, setLoading] = useState(false);

  const handleCheck = async ({ target: { name, value } }) => {
    setLoading(true);

    const payload = {
      [preferenceType]: {
        [name]: value,
      },
    };

    try {
      const response = await httpClient.patch(
        '/notifications/preferences',
        payload,
      );

      setValues({
        ...values,
        [name]: response.data[preferenceType][name],
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="md:bg-gray-10 p-7 pb-12 md:rounded-lg rounded-2xl mt-6 first:mt-0">
      <div className="flex">
        <h3 className="text-xl font-bold leading-9 text-midnight-100">
          {title}
        </h3>

        {loading && <Loader className="ml-4" />}
      </div>
      <div className="mt-9 flex flex-col items-start">
        <FormControl
          label="Email"
          name="email"
          labelPlacement="right"
          loading={loading}
          value={values.email}
          theme={THEME.SECONDARY}
          type={FORM_CONTROL_TYPE.SWITCHER}
          onChange={handleCheck}
        />

        <FormControl
          className="mt-4"
          label="Push"
          name="push"
          labelPlacement="right"
          loading={loading}
          value={values.push}
          theme={THEME.SECONDARY}
          type={FORM_CONTROL_TYPE.SWITCHER}
          onChange={handleCheck}
        />
      </div>
    </div>
  );
}
