export function TermsConditionsScene() {
  return (
    <main className="flex flex-col gap-3 max-w-5xl mx-auto px-4 py-10">
      <h1 className="text-2xl font-bold">Terms and Conditions</h1>
      <p>
        Welcome to Drreamz. By accessing our website and using our services, you
        agree to be bound by these terms and conditions.
      </p>

      <h2 className="text-xl font-bold">
        Acceptance of Terms by using Drreamz
      </h2>
      <p>
        You agree to these terms and conditions, and all applicable laws and
        regulations. If you do not agree to these terms and conditions, please
        do not use our website.
      </p>

      <h2 className="text-xl font-bold">Eligibility</h2>
      <p>
        You must be at least 18 years old to use our website. By using our
        website, you represent and warrant that you are at least 18 years old
        and have the legal capacity to enter into this agreement.
      </p>

      <h2 className="text-xl font-bold">Registration and Account Security</h2>
      <p>
        You may be required to register for an account to access certain
        features of our website. When you register, you must provide accurate
        and complete information. You are responsible for maintaining the
        confidentiality of your account information, including your username and
        password.
      </p>

      <h2 className="text-xl font-bold">User Content</h2>
      <p>
        You are solely responsible for the content that you post on our website.
        By posting content on our website, you grant us a non-exclusive,
        royalty-free, perpetual, and worldwide license to use, modify, and
        distribute such content.
      </p>

      <h2 className="text-xl font-bold">Prohibited Activities</h2>
      <p>You may not use our website to:</p>
      <ul className="pl-6 list-disc">
        <li>
          Impersonate any person or entity, or falsely state or otherwise
          misrepresent your affiliation with a person or entity
        </li>
        <li>
          Collect or store personal information about other users without their
          consent
        </li>
        <li>Transmit any viruses or other malicious software</li>
        <li>
          Use any device or software to interfere with or disrupt the proper
          functioning of our website
        </li>
        <li>
          Engage in any activity that violates any applicable law or regulation
        </li>
      </ul>

      <h2 className="text-xl font-bold">Intellectual Property</h2>
      <p>
        All content on our website, including text, graphics, logos, and images,
        is the property of Drreamz or its licensors and is protected by
        copyright and other intellectual property laws. You may not use any
        content on our website without our express written permission.
      </p>

      <h2 className="text-xl font-bold">Disclaimer of Warranties</h2>
      <p>
        Our website is provided on an "as is" and "as available" basis. We make
        no warranties or representations, express or implied, about the
        accuracy, reliability, completeness, or timeliness of our website or the
        content therein.
      </p>

      <h2 className="text-xl font-bold">Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, we shall not be liable for any
        direct, indirect, incidental, special, or consequential damages arising
        out of or in connection with the use or inability to use our website or
        the content therein.
      </p>

      <h2 className="text-xl font-bold">Indemnification</h2>
      <p>
        You agree to indemnify and hold Drreamz and its affiliates, officers,
        agents, and employees harmless from any claim or demand, including
        reasonable attorneys' fees, made by any third party due to or arising
        out of your use of our website, your violation of these terms and
        conditions, or your violation of any law or regulation.
      </p>

      <h2 className="text-xl font-bold">Termination</h2>
      <p>
        We may terminate your access to our website at any time, without cause
        or notice, which may result in the forfeiture and destruction of all
        information associated with you. All provisions of these terms and
        conditions that by their nature should survive termination shall survive
        termination, including, without limitation, ownership provisions,
        warranty disclaimers, indemnity, and limitations of liability.
      </p>

      <h2 className="text-xl font-bold">Governing Law</h2>
      <p>
        These terms and conditions shall be governed by and construed in
        accordance with the laws of the United States of America. Any dispute
        arising out of or in connection with these terms and conditions shall be
        subject to the exclusive jurisdiction of the courts of the state of
        California.
      </p>

      <h2 className="text-xl font-bold">Changes to These Terms</h2>
      <p>
        We reserve the right to update or modify these terms and conditions at
        any time without prior notice. Your continued use of our website
        following any such changes constitutes your acceptance of the new terms
        and conditions.
      </p>

      <h2 className="text-xl font-bold">Contact Us</h2>
      <p>
        If you have any questions or concerns about these terms and conditions,
        please contact us at{' '}
        <a href="mailto:support@drreamz.com" className="text-orange-60">
          support@drreamz.com
        </a>
      </p>
    </main>
  );
}
