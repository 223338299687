export default function humanOfferType(type) {
  switch (type) {
    case 'one-to-one':
      return 'Personal';
    case 'group':
      return 'Group';
    case 'digital-content':
      return 'Digital Products';
    default:
      return type;
  }
}
