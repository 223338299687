import { PlusIcon, SwapIcon, TrashIcon } from '@icons';
import classNames from 'classnames';
import { useMemo, useRef } from 'react';

export default function AvatarInput({
  value,
  onChange,
  className,
  disabled,
  ...rest
}) {
  const avatarInput = useRef(null);
  const avatarPreview = useMemo(
    () => (value instanceof File ? URL.createObjectURL(value) : value),
    [value],
  );

  const removeAvatarInputChange = () => {
    onChange(null);
  };

  const handleAvatarInputChange = (event) => {
    const image = event.target.files[0];
    image && onChange(image);
  };

  return (
    <div className={classNames('', className)} {...rest}>
      <div className="relative inline-block">
        <img
          className="rounded-full w-full aspect-square object-cover"
          src={avatarPreview || '/images/default-avatar.svg'}
        />
        <div className="absolute bottom-0 right-0 flex gap-1">
          {avatarPreview ? (
            <>
              <button
                type="button"
                className="p-1 rounded-full bg-sea-70 text-gray-10 hover:bg-sea-60 focus:bg-sea-80 active:bg-sea-90 disabled:bg-sea-10 disabled:text-sea-70"
                disabled={disabled}
                onClick={removeAvatarInputChange}
              >
                <TrashIcon
                  className="w-5 h-5 fill-current"
                  aria-hidden="true"
                />
                <span className="sr-only">Remove</span>
              </button>
              <button
                type="button"
                className="p-1 rounded-full bg-sea-70 text-gray-10 hover:bg-sea-60 focus:bg-sea-80 active:bg-sea-90 disabled:bg-sea-10 disabled:text-sea-70"
                disabled={disabled}
                onClick={() => avatarInput.current.click()}
              >
                <SwapIcon className="w-5 h-5 fill-current" aria-hidden="true" />
                <span className="sr-only">Replace</span>
              </button>
            </>
          ) : (
            <button
              type="button"
              className="p-1 rounded-full bg-sea-70 text-gray-10 hover:bg-sea-60 focus:bg-sea-80 active:bg-sea-90 disabled:bg-sea-10 disabled:text-sea-70"
              disabled={disabled}
              onClick={() => avatarInput.current.click()}
            >
              <PlusIcon className="w-5 h-5 fill-current" aria-hidden="true" />
              <span className="sr-only">Add</span>
            </button>
          )}
        </div>
        <input
          type="file"
          className="hidden"
          accept="image/png, image/jpg, image/jpeg"
          disabled={disabled}
          ref={avatarInput}
          onChange={handleAvatarInputChange}
        />
      </div>
    </div>
  );
}
