import { NoEnteredData } from '@/components';
import { UploadService } from '@/services';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, DownloadIcon, TrashIcon } from '@icons';
import { humanFileSize } from '@utils';
import classNames from 'classnames';
import { useCallback } from 'react';

export function WorkspaceFileSection({
  files = [],
  editable = false,
  icon,
  title,
  onRemove = () => {},
} = {}) {
  const handleDownload = useCallback((id) => {
    window.open(UploadService.getDownloadLink(id), '_blank');
  }, []);

  return (
    <Disclosure
      as="div"
      className="flex flex-col justify-between cursor-pointer rounded-2.5xl select-none bg-gray-10"
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between px-5 py-6 rounded-2.5xl bg-gray-10  focus:outline-none focus-visible:ring focus-visible:ring-orange-50 focus-visible:ring-opacity-75">
            <div className="flex items-center gap-1">
              {icon}
              <div className="flex gap-4 items-baseline">
                <p className="text-xl font-bold">{title}</p>
                <span className="text-midnight-70 font-normal">
                  {`${files.length} items`}
                </span>
              </div>
            </div>

            <ChevronDownIcon
              className={classNames(
                'w-6 h-6 fill-midnight-60',
                open && 'transform rotate-180',
              )}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-2 pb-6">
            {(files.length && (
              <ul className="flex flex-col gap-3">
                {files.map((file) => (
                  <li
                    key={file.id}
                    className="flex justify-between items-center py-3 px-6 bg-midnight-10 rounded-xl"
                  >
                    <div>
                      <p className="text-midnight-100 font-bold text-xl">
                        {file.name.split('.').slice(0, -1).join('.')}
                      </p>
                      <span className="text-midnight-60 text-sm">
                        <span className="uppercase">
                          {file.type.split('/')[1]}
                        </span>{' '}
                        <span>{humanFileSize(file.size, { dp: 1 })}</span>
                      </span>
                    </div>
                    <div className="gap-6 flex">
                      <button
                        type="button"
                        className="button-outline button-xs button-icon-rect"
                      >
                        <DownloadIcon
                          className="fill-current w-4 h-4"
                          aria-hidden="true"
                          onClick={() => handleDownload(file.id)}
                        />
                        <span className="sr-only">Download file</span>
                      </button>
                      {editable && (
                        <button
                          type="button"
                          className="button-outline button-xs button-icon-rect"
                        >
                          <TrashIcon
                            aria-hidden="true"
                            className="fill-current w-4 h-4"
                            onClick={() => onRemove(file)}
                          />
                          <span className="sr-only">Remove file</span>
                        </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            )) || <NoEnteredData title="No Files" />}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
