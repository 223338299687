import { useRef } from 'react';
import { nanoid } from 'nanoid';
import SessionListItem from './SessionListItem';

export default function SessionListGroup({ id: idProp, title, sessions = [] }) {
  const id = useRef(idProp || nanoid());
  if (!title) {
    return new Error('Title is required property');
  }

  if (sessions.length > 0) {
    return (
      <>
        <p
          id={`session-group-${id.current}`}
          className="pl-2.5 text-midnight-60"
        >
          {title}
        </p>
        <ul
          className="flex flex-col gap-5"
          aria-labelledby={`session-group-${id.current}`}
        >
          {sessions.map((session) => (
            <SessionListItem key={session.id} session={session} />
          ))}
        </ul>
      </>
    );
  }
}
