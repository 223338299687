import { observer } from 'mobx-react-lite';
import { SearchStore } from '@stores';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';

const OffersResults = observer(() => {
  return SearchStore.offers.length ? (
    <>
      {SearchStore.offers.map(({ id, name }) => (
        <Link key={nanoid()} to={`/dashboard/offerings/${id}`} className="my-2">
          <div className="mt-6 p-6 bg-gray-10 text-midnight-100 text-lg font-bold rounded-2xl hover:text-midnight-80">
            {name}
          </div>
        </Link>
      ))}
    </>
  ) : null;
});

export default OffersResults;
