import { DButton } from '@components';
import { BUTTON_THEME, SIZE } from '@constants';
import { UserStore } from '@stores';
import { noop } from '@utils';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Rating from 'react-rating';
import DAvatar from '../DAvatar';
import { ReactComponent as FullRatingStar } from './svg-full-rating-star.svg';
import { ReactComponent as RatingStar } from './svg-rating-star.svg';

export const RateCoachPopupContent = observer(({ coach }) => {
  const [rating, setRating] = useState();

  const handleRatingChange = (value) => {
    if (value === rating) {
      return;
    }

    setRating(value);
    // TODO: create method to update rating of the coach
    console.log('rating: ', rating);
  };

  const handleCoachPageClick = () => {
    // TODO: create redirect to coach page by coach id
    noop();
  };

  return (
    <div className="flex flex-col justify-start items-center px-6 md:px-24 py-14">
      <h3 className="text-midnight-100 text-4xl font-bold">
        Hi {UserStore.fullName}
      </h3>
      <p className="mt-4 text-lg text-midnight-90 text-center">
        How was your consultation with
        <br />
        {coach.name}?
      </p>

      <hr className="mt-6 h-px bg-midnight-100 w-full md:w-96" />

      <div className="mt-5 flex flex-col items-center">
        <DAvatar
          avatar={coach.avatar}
          abbreviation={coach.name[0]}
          size={SIZE.L}
        />

        <p className="mt-1.5 font-bold text-midnight-100 text-xl">
          {coach.name}
        </p>
        <p className="mt-1 text-midnight-90 text-lg">{coach.email}</p>
      </div>

      <Rating
        className="mt-5"
        emptySymbol={<RatingStar />}
        fullSymbol={<FullRatingStar />}
        onChange={handleRatingChange}
      />

      <DButton
        className="mt-9"
        theme={BUTTON_THEME.SECONDARY}
        onClick={handleCoachPageClick}
      >
        Coach Page
      </DButton>
    </div>
  );
});
