import { ClientEvent, SyncState } from '@utils/matrixClient';
import {
  createContext,
  useCallback,
  useMemo,
  useSyncExternalStore,
} from 'react';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars -- used in jsdoc
import { useMessengerContext } from './MessengerContext';
import nextTick from '@/utils/next-tick';

let roomCached = null;

/**
 * @param {ReturnType<typeof useMessengerContext>} messengerContext
 */
export function useMessengerActiveRoomContext(messengerContext) {
  const { matrixClient } = messengerContext;
  const [searchParams, setSearchParams] = useSearchParams();

  const roomId = useMemo(() => searchParams.get('roomId'), [searchParams]);

  const roomSubscribe = useCallback(
    (listener) => {
      function updateRoom() {
        let newRoomValue = null;
        if (roomId) {
          console.log('[ActiveRoomContext] update room %s', roomId);
          const tmpRoom = matrixClient.getRoom(roomId);
          if (tmpRoom) {
            // Matrix client mutates rooms instead of creation new ones.
            // Therefore React won't re-render them because it's the same object.
            // To fix this: warp the room in Proxy to make an illusion of new object.
            newRoomValue = new Proxy(tmpRoom, {});
          }
        }
        roomCached = newRoomValue;
        listener();
      }

      /** @type {import("matrix-js-sdk").ClientEventHandlerMap[ClientEvent.Sync]} */
      function syncListener(state) {
        switch (state) {
          case SyncState.Prepared:
          case SyncState.Syncing:
            console.debug('[ActiveRoomContext] <syncListener>');
            updateRoom();
            break;
        }
      }

      // Bind listener on a custom event for manual dispatch without side-effects
      matrixClient.on('Drreamz.ActiveRoom.update', updateRoom);
      matrixClient.on(ClientEvent.Sync, syncListener);
      return () => {
        matrixClient.off('Drreamz.ActiveRoom.update', updateRoom);
        matrixClient.off(ClientEvent.Sync, syncListener);
      };
    },
    [matrixClient, roomId],
  );

  /** @type {import("matrix-js-sdk").Room | null} */
  const room = useSyncExternalStore(roomSubscribe, () => {
    return roomCached;
  });

  const setRoom = useCallback(
    /** @param {import("matrix-js-sdk").Room | string} roomOrId */
    (roomOrId) => {
      const id = typeof roomOrId == 'string' ? roomOrId : roomOrId.roomId;
      setSearchParams((params) => {
        params.set('roomId', id);
        return params;
      });
      // Wait when searchParams are updated to have relevant roomId
      nextTick(() => matrixClient.emit('Drreamz.ActiveRoom.update'));
    },
    [matrixClient, setSearchParams],
  );

  return useMemo(
    () => ({
      // State
      roomId,
      room,
      // Mutations
      setRoom,
    }),
    [room, roomId, setRoom],
  );
}

/** @type {import("react").Context<ReturnType<typeof useMessengerActiveRoomContext> | null>} */
export const MessengerActiveRoomContext = createContext(null);
