import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DAvatar, Share, Tooltip } from '@/components';
import extensions from '@/components/RichTextEditor/extensions';
import { SIZE, THEME, TOOLTIP_PLACEMENT } from '@/constants';
import { InfoIcon } from '@/icons';
import { CoachService, OfferService, StripeService } from '@/services';
import { UserStoreV2 } from '@/stores';
import { humanOfferType } from '@/utils';
import { generateHTML } from '@tiptap/html';
import OfferPageContent from './src/OfferPageContent';
import OfferPageCover from './src/OfferPageCover';
import OfferPageSessionInfo from './src/OfferPageSessionInfo';
import InPlaceAuth from '@/components/InPlaceAuth';
import DiscoveryCallDialog from '@/components/DiscoveryCallDialog';

export default function OfferPageScene() {
  const { offerId } = useParams();
  const [offer, setOffer] = useState(undefined);
  const [coachProfile, setCoachProfile] = useState(undefined);
  const [hasProduct, setHasProduct] = useState(undefined);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showDiscoveryCallDialog, setShowDiscoveryCallDialog] = useState(false);
  const userStore = UserStoreV2.useState();

  if (!offerId) {
    throw new Error('You have to provide an offer ID');
  }

  const priceText = useMemo(
    () =>
      offer && offer.payment_amount > 0
        ? '$' + (offer.payment_amount / 100).toFixed(2)
        : 'Free',
    [offer],
  );

  const handleBuyOffer = useCallback(async () => {
    try {
      const session = await StripeService.createCheckoutSession(
        offerId,
        `${location.origin}/checkout-session-return?session_id={CHECKOUT_SESSION_ID}`,
        `${location.origin}/dashboard/client/offers/${offerId}`,
      );
      open(session.url, '_blank');
    } catch (error) {
      toast.error('Unable to create checkout session. See console (F12)');
      console.error('Unable to create checkout session.', error);
    }
  }, [offerId]);

  const handleBuyOfferSafe = useCallback(async () => {
    if (!userStore.isAuthorized) {
      setShowLoginDialog(true);
      return;
    }
    await handleBuyOffer();
  }, [handleBuyOffer, userStore]);

  const handleDiscoveryCall = useCallback(async () => {
    if (!userStore.isAuthorized) {
      setShowLoginDialog(true);
      return;
    }
    setShowDiscoveryCallDialog(true);
  }, [userStore]);

  useEffect(() => {
    async function fetchData() {
      const offerData = await OfferService.get(offerId);
      setOffer(offerData);
    }

    void fetchData();
  }, [offerId]);

  useEffect(() => {
    async function fetchData() {
      const product = await OfferService.getProduct(offerId);
      setHasProduct(product !== null);
    }

    void fetchData();
  }, [offerId]);

  useEffect(() => {
    async function fetchData() {
      if (offer) {
        const coach = await CoachService.get(offer.coach_id);
        setCoachProfile(coach);
      }
    }

    void fetchData();
  }, [offer]);

  if (offer === null)
    return (
      <div className="flex flex-col justify-center items-center gap-4 p-7 h-full bg-midnight-10">
        <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
          Oops, this offer page doesn't exist 🙄
        </h1>

        <img
          src="/images/svg-question-sign.svg"
          className="w-96"
          alt="Woman walking towards a question sign"
        />
      </div>
    );

  return (
    offer && (
      <div className="flex flex-col gap-7 px-12 py-7.5 bg-midnight-10">
        <div className="flex flex-col gap-5 p-7 bg-gray-10 rounded-2.5xl grow">
          <OfferPageCover url={offer.cover} offset={offer.cover_offset} />

          <div className="flex justify-between items-start">
            <div className="flex flex-col-reverse gap-2.5">
              <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
                {offer.name}
              </h1>
              <p className="text-lg text-midnight-50">
                {humanOfferType(offer.type)}
              </p>
            </div>

            {offer.enable_discovery_call && (
              <button
                type="button"
                className="hidden md:block button-main button-l"
                onClick={handleDiscoveryCall}
              >
                Discovery Call
              </button>
            )}
          </div>

          <div className="text-midnight-100">
            <h2 className="sr-only">Description</h2>
            <div
              className="space-y-[0.75em]"
              dangerouslySetInnerHTML={{
                __html: generateHTML(offer.long_description, extensions),
              }}
            ></div>
          </div>

          {['one-to-one', 'group'].includes(offer.type) && (
            <OfferPageSessionInfo offerId={offer.id} />
          )}

          {coachProfile && (
            <>
              <hr />
              <Link
                to={`../coaches/${coachProfile.user_id}`}
                className="flex items-center gap-4 self-start"
              >
                <DAvatar size={SIZE.S} avatar={coachProfile.avatar} />
                <h3 className="font-bold text-lg">{coachProfile.name}</h3>
              </Link>
            </>
          )}

          <hr />
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
            <Share.ShareList
              url={`${location.origin}/offers/${offer.id}`}
              text={offer.name}
            >
              <Share.ShareFacebook />
              <Share.ShareTwitter />
              <Share.ShareTelegram />
              <Share.ShareInstagram />
              <Share.ShareLinkedIn />
              <Share.CopyLink />
            </Share.ShareList>

            <div className="flex items-center gap-4">
              <p className="text-4.5xl font-bold text-midnight-100">
                {priceText}
              </p>
              {!hasProduct && (
                <Tooltip
                  theme={THEME.WHITE}
                  placement={TOOLTIP_PLACEMENT.LEFT}
                  content={
                    <p>
                      You cannot buy this offer as it doesn't have an associated
                      Stripe product.
                    </p>
                  }
                >
                  <InfoIcon className="fill-current" />
                </Tooltip>
              )}
              <button
                type="button"
                className="button-main button-m"
                disabled={!hasProduct}
                onClick={handleBuyOfferSafe}
              >
                Buy Offer
              </button>
              {offer.enable_discovery_call && (
                <button
                  type="button"
                  className="button-main button-m"
                  onClick={handleDiscoveryCall}
                >
                  Discovery Call
                </button>
              )}
            </div>
          </div>
        </div>

        <OfferPageContent offerId={offer.id} />

        <InPlaceAuth
          open={showLoginDialog}
          onClose={() => setShowLoginDialog(false)}
          title="Login to continue"
          onLogin={handleBuyOffer}
        />

        <DiscoveryCallDialog
          offer_id={offer.id}
          open={showDiscoveryCallDialog}
          onClose={() => setShowDiscoveryCallDialog(false)}
        />
      </div>
    )
  );
}
