import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';
import './assets/styles/index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const App = () => (
  <>
    <Outlet />
    <ToastContainer />
  </>
);

export default App;
