import { TimeInput } from '@/components';
import { CheckmarkIcon, PlusIcon, TrashIcon } from '@/icons';
import classNames from 'classnames';
import { useRef } from 'react';

export default function ScheduleEditorWeekday({
  value,
  onChange,
  weekdayIndex,
  disabled,
  className,
}) {
  const checkboxRef = useRef(null);

  function handleChange(data) {
    onChange(Object.assign({}, value, data));
  }

  function addTimeslot() {
    handleChange({
      intervals: [...value.intervals, { from: 0, to: 0 }],
    });
  }
  function changeTimeslot(index, data) {
    handleChange({
      intervals: value.intervals.map((v, i) => (i === index ? data : v)),
    });
  }
  function removeTimeslot(index) {
    const newIntercals = [...value.intervals];
    newIntercals.splice(index, 1);
    handleChange({
      intervals: newIntercals,
    });
  }

  return (
    <div className={classNames('flex flex-col gap-3', className)}>
      <div className="flex items-center gap-3">
        <input
          id={`schedule-weekday-${weekdayIndex}-enable`}
          ref={checkboxRef}
          name="comments"
          type="checkbox"
          defaultChecked={value.enabled}
          disabled={disabled}
          className="sr-only peer"
          onChange={(event) => handleChange({ enabled: event.target.checked })}
        />
        <div
          className={classNames(
            'w-5 h-5 rounded border peer-focus:ring-2 ring-primary ring-offset-2',
            value.enabled ? 'bg-primary border-primary' : 'border-midnight-100',
            { 'cursor-pointer': !disabled },
          )}
          onClick={() => checkboxRef.current.click()}
        >
          <CheckmarkIcon className="w-full h-full fill-white" />
        </div>
        <label htmlFor={`schedule-weekday-${weekdayIndex}-enable`}>
          {WeekdayNames[weekdayIndex]}
        </label>
      </div>

      {value.intervals.length > 0 && (
        <ul className="flex flex-col gap-3 pl-8">
          {value.intervals.map((v, i) => (
            <Timeslot
              key={i}
              value={v}
              disabled={!value.enabled || disabled}
              onChange={(value) => changeTimeslot(i, value)}
              onDelete={() => removeTimeslot(i)}
            />
          ))}
        </ul>
      )}

      {value.enabled && !disabled && (
        <button
          type="button"
          className="button-clear p-0 pr-2 text-midnight-50 font-normal self-start"
          onClick={addTimeslot}
        >
          <PlusIcon className="fill-current mr-1" />
          <span>Add time slot</span>
        </button>
      )}
    </div>
  );
}

function Timeslot({ value, onChange = () => {}, onDelete, disabled }) {
  function changeFrom(newValue) {
    onChange({ from: newValue, to: Math.max(value.to, newValue) });
  }
  function changeTo(newValue) {
    onChange({ from: Math.min(value.from, newValue), to: newValue });
  }

  return (
    <li className="flex gap-4 items-center">
      <span className="text-midnight-50">From</span>
      <TimeInput
        value={value.from}
        onChange={changeFrom}
        step={{ minutes: 10 }}
        placeholder="__:__"
        disabled={disabled}
        className="basis-28"
      />
      <span className="text-midnight-50">to</span>
      <TimeInput
        value={value.to}
        onChange={changeTo}
        step={{ minutes: 10 }}
        placeholder="__:__"
        disabled={disabled}
        className="basis-28"
      />

      <button
        type="button"
        className="button-clear button-xs button-icon-rect"
        onClick={onDelete}
        disabled={disabled}
      >
        <TrashIcon className="fill-current" />
        <span className="sr-only">Remove Timeslot</span>
      </button>
    </li>
  );
}

const WeekdayNames = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
