import { useReducer } from 'react';

/**
 * @typedef ReducerState
 * @property {string} email
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} [avatar]
 * @property {string} phone
 * @property {Contact[]} contacts
 */
/**
 * @typedef ReducerAction
 * @property {'change_avatar' | 'change_first_name' | 'change_last_name' |'change_email' |'change_phone' | 'change_contact'} type
 * @property {any} value
 */
/**
 * @typedef Contact
 * @property {string} type
 * @property {string} value
 */

/**
 * @type {import('react').Reducer<ReducerState, ReducerAction>}
 */
export function personalInfoReducer(state, action) {
  switch (action.type) {
    case 'change_avatar': {
      return Object.assign({}, state, { avatar: action.value });
    }
    case 'change_first_name': {
      return Object.assign({}, state, { first_name: action.value });
    }
    case 'change_last_name': {
      return Object.assign({}, state, { last_name: action.value });
    }
    case 'change_email': {
      return Object.assign({}, state, { email: action.value });
    }
    case 'change_phone': {
      return Object.assign({}, state, { phone: action.value });
    }
    case 'change_contact': {
      const targetIndex = state.contacts.findIndex(
        (contact) => contact.type == action.value.type,
      );
      if (targetIndex === -1) {
        // Add contact
        return Object.assign({}, state, {
          contacts: [...state.contacts, action.value],
        });
      } else if (action.value.value.trim()) {
        // Replace contact
        return Object.assign({}, state, {
          contacts: state.contacts.map((contact) =>
            contact.type == action.value.type ? action.value : contact,
          ),
        });
      } else {
        // Delete contact
        const newContacts = [...state.contacts];
        newContacts.splice(targetIndex, 1);
        return Object.assign({}, state, {
          contacts: newContacts,
        });
      }
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

/**
 * @param {ReducerState} initialState
 */
export default function usePersonalInfoReducer(initialState) {
  return useReducer(personalInfoReducer, initialState);
}
