import { ChevronDownIcon } from '@/icons';
import { UserService } from '@/services';
import { DAvatar } from '@components';
import { ContactsMap, MessageViaMap, SIZE } from '@constants';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PrivateNotesSection from './PrivateNotesSection';

export default function UserProfileScene() {
  const { userId } = useParams();
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    async function fetchUser() {
      const data = await UserService.get(userId);
      setUser(data);
    }
    fetchUser();
  }, [userId]);

  return (
    user && (
      <div className="flex flex-col gap-7 px-12 py-7.5 h-full">
        <div className="flex flex-col gap-6 bg-gray-10 rounded-2.5xl px-7.5 py-6">
          <div className="flex flex-col gap-y-4 md:flex-row md:justify-between">
            <div className="flex flex-row gap-8">
              <DAvatar
                avatar={user.avatar}
                size={SIZE.L}
                abbreviation={user.first_name[0] + user.last_name[0]}
              />
              <div className="flex flex-col">
                <h1 className="text-3xl sm:text-4.5xl text-midnight-100 font-bold">
                  {user.first_name} {user.last_name}
                </h1>
                <span className="font-normal text-lg">{user.email}</span>
                {user.phone && (
                  <span className="font-normal text-lg">{user.phone}</span>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-y-2 md:block md:gap-x-2">
              {user.contacts.length === 0 ? (
                <button
                  type="button"
                  className="button-second button-m justify-center"
                  onClick={() => toast.warn('Not Implemented')}
                >
                  Message
                </button>
              ) : (
                <div className="flex relative">
                  <button
                    type="button"
                    className="button-second button-m rounded-r-none grow justify-center"
                    onClick={() => toast.warn('Not Implemented')}
                  >
                    Message
                  </button>
                  <Menu>
                    <Menu.Button className="button-second button-xs button-icon-rect rounded-l-none">
                      <span className="sr-only">Message Via</span>
                      <ChevronDownIcon
                        className="h-4 w-4 fill-current"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 top-full mt-2 py-2 w-full origin-top-right rounded-lg bg-gray-10 shadow-lg focus:outline-none z-10">
                        {user.contacts.map(({ type, value }) => (
                          <Menu.Item key={type}>
                            {({ active }) => {
                              const contact = ContactsMap.get(type);
                              return (
                                <a
                                  href={MessageViaMap.get(type).call(
                                    null,
                                    value,
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={`${
                                    active
                                      ? 'bg-sea-10 text-sea-70'
                                      : 'text-midnight-100'
                                  } group flex items-center gap-1 w-full px-4 py-0 rounded-none text-sm`}
                                >
                                  <img
                                    src={contact.logo}
                                    alt={contact.name}
                                    className="w-8"
                                  />
                                  <span>{contact.name}</span>
                                </a>
                              );
                            }}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </div>

        <PrivateNotesSection userId={userId} context="user" />
      </div>
    )
  );
}
