import { AppContext } from '@/contexts';
import { ContentContainer, NoEnteredData } from '@components';
import { WorkspaceService } from '@services';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const WorkspacesListScene = () => {
  const appContext = useContext(AppContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data: workspaces } = await WorkspaceService.list({
        expand: ['offer'],
      });

      setData(
        workspaces.map((workspace) => ({
          ...workspace,
          offerName: workspace.offer.name,
        })),
      );
      setLoading(false);
    }
    fetchData();
  }, []);

  const node = useRef();

  return (
    <div ref={node}>
      <ContentContainer
        title="Workspaces"
        loading={loading}
        headerAppend={
          appContext.isCoach && (
            <Link to="create" className="button-main button-s">
              Create
            </Link>
          )
        }
      >
        {data.length ? (
          <ul className="flex flex-col gap-5">
            {data.map((workspace) => (
              <li key={workspace.id}>
                <Link
                  to={workspace.id}
                  className="flex items-baseline gap-4 py-3 px-6 bg-midnight-10 rounded-xl"
                >
                  <h2 className="text-midnight-100 leading-6 font-bold">
                    {workspace.name || 'Workspace'}
                  </h2>
                  <span className="text-sm text-midnight-70">
                    {workspace.offerName}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <NoEnteredData
            title="No workspaces"
            customImage={
              <img
                src="/images/svg-box-surprise.svg"
                alt="Man jumps out of a box and woman gets exciting 😏😏😏"
              />
            }
          />
        )}
      </ContentContainer>
    </div>
  );
};

export default WorkspacesListScene;
