import { Switch as HeadlessSwitch } from '@headlessui/react';
import classNames from 'classnames';

export default function Switch({ value, onChange, className, children }) {
  return (
    <HeadlessSwitch
      checked={value}
      onChange={onChange}
      className={classNames(
        'relative inline-flex flex-shrink-0 h-6 w-11 p-0 border rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-2 ring-orange-20',
        value ? 'bg-primary border-primary' : 'bg-gray-10 border-midnight-50',
        className,
      )}
    >
      {children && <span className="sr-only">{children}</span>}
      <span
        aria-hidden="true"
        className={classNames(
          value ? 'translate-x-5 bg-gray-10' : 'translate-x-0.5 bg-midnight-50',
          'pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200',
        )}
      />
    </HeadlessSwitch>
  );
}
