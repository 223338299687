import { observer } from 'mobx-react-lite';
import { NotificationsStore } from '@stores';
import { NotificationsContent } from '../../components/NotificationsContent/NotificationsContent';

export const AllTabScene = observer(() => {
  return (
    <NotificationsContent
      pageName="notifications"
      notificationsList={NotificationsStore.notifications}
    />
  );
});
