import { useTempState } from '@/hooks';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { ClipboardDocumentIcon } from '@heroicons/react/24/solid';
import { createContext, useContext, useState } from 'react';

function useTooltip() {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return {
    isOpen,
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
  };
}

const ShareContext = createContext({
  url: undefined,
  text: undefined,
});

export function ShareList({ className = '', url, text, children }) {
  return (
    <ShareContext.Provider value={{ url, text }}>
      <div className={`flex gap-4 ${className}`}>{children}</div>
    </ShareContext.Provider>
  );
}

export function ShareFacebook({ className = '', url }) {
  const context = useContext(ShareContext);
  const tooltip = useTooltip();

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        className={`button-clear ${className}`}
        href={`https://www.facebook.com/sharer/sharer.php?u=${
          url || context.url
        }`}
        ref={tooltip.refs.setReference}
        {...tooltip.getReferenceProps()}
      >
        <img
          className="w-12 h-12"
          src="/images/share/facebook.svg"
          alt="Facebook Logo"
        />
      </a>
      {tooltip.isOpen && (
        <div
          ref={tooltip.refs.setFloating}
          style={tooltip.floatingStyles}
          className="px-2 py-0.5 rounded bg-white border border-midnight-40 text-sm"
          {...tooltip.getFloatingProps()}
        >
          Share on Facebook
        </div>
      )}
    </>
  );
}
export function ShareTwitter({ className = '', url, text }) {
  const context = useContext(ShareContext);
  const tooltip = useTooltip();

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        className={`button-clear ${className}`}
        href={`https://twitter.com/intent/tweet?url=${
          url || context.url
        }&text=${text || context.text}`}
        ref={tooltip.refs.setReference}
        {...tooltip.getReferenceProps()}
      >
        <img
          className="w-12 h-12"
          src="/images/share/twitter.svg"
          alt="Twitter Logo"
        />
      </a>
      {tooltip.isOpen && (
        <div
          ref={tooltip.refs.setFloating}
          style={tooltip.floatingStyles}
          className="px-2 py-0.5 rounded bg-white border border-midnight-40 text-sm"
          {...tooltip.getFloatingProps()}
        >
          Share on Twitter
        </div>
      )}
    </>
  );
}
export function ShareTelegram({ className = '', url }) {
  const context = useContext(ShareContext);
  const tooltip = useTooltip();

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        className={`button-clear ${className}`}
        href={`https://t.me/share/url?url=${url || context.url}`}
        ref={tooltip.refs.setReference}
        {...tooltip.getReferenceProps()}
      >
        <img
          className="w-12 h-12"
          src="/images/share/telegram.svg"
          alt="Telegram Logo"
        />
      </a>
      {tooltip.isOpen && (
        <div
          ref={tooltip.refs.setFloating}
          style={tooltip.floatingStyles}
          className="px-2 py-0.5 rounded bg-white border border-midnight-40 text-sm"
          {...tooltip.getFloatingProps()}
        >
          Share on Telegram
        </div>
      )}
    </>
  );
}
export function ShareInstagram({ className = '', url }) {
  const context = useContext(ShareContext);
  const tooltip = useTooltip();

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        className={`button-clear ${className}`}
        href={`https://www.instagram.com?url=${url || context.url}`}
        ref={tooltip.refs.setReference}
        {...tooltip.getReferenceProps()}
      >
        <img
          className="w-12 h-12"
          src="/images/share/instagram.svg"
          alt="Instagram Logo"
        />
      </a>
      {tooltip.isOpen && (
        <div
          ref={tooltip.refs.setFloating}
          style={tooltip.floatingStyles}
          className="px-2 py-0.5 rounded bg-white border border-midnight-40 text-sm"
          {...tooltip.getFloatingProps()}
        >
          Share on Instagram
        </div>
      )}
    </>
  );
}
export function ShareLinkedIn({ className = '', url }) {
  const context = useContext(ShareContext);
  const tooltip = useTooltip();

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        className={`button-clear ${className}`}
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${
          url || context.url
        }`}
        ref={tooltip.refs.setReference}
        {...tooltip.getReferenceProps()}
      >
        <img
          className="w-12 h-12"
          src="/images/share/linkedin.svg"
          alt="LinkedIn Logo"
        />
      </a>
      {tooltip.isOpen && (
        <div
          ref={tooltip.refs.setFloating}
          style={tooltip.floatingStyles}
          className="px-2 py-0.5 rounded bg-white border border-midnight-40 text-sm"
          {...tooltip.getFloatingProps()}
        >
          Share on LinkedIn
        </div>
      )}
    </>
  );
}

export function CopyLink({ className = '', url }) {
  const context = useContext(ShareContext);
  const tooltip = useTooltip();
  const [tooltipText, setTooltipText] = useTempState('Copy a link', 1500);

  function handleClick() {
    navigator.clipboard.writeText(url || context.url);
    setTooltipText('Copied!');
  }

  return (
    <>
      <button
        type="button"
        className={`button-clear p-3 ${className}`}
        onClick={handleClick}
        ref={tooltip.refs.setReference}
        {...tooltip.getReferenceProps()}
      >
        <ClipboardDocumentIcon className="w-6" aria-hidden="true" />
      </button>
      {tooltip.isOpen && (
        <div
          ref={tooltip.refs.setFloating}
          style={tooltip.floatingStyles}
          className="px-2 py-0.5 rounded bg-white border border-midnight-40 text-sm"
          {...tooltip.getFloatingProps()}
        >
          {tooltipText}
        </div>
      )}
    </>
  );
}
