import { useCallback, useState } from 'react';

/**
 * @param {React.JSX.Element} dialog
 * @returns {[React.JSX.Element, { open: () => void, close: () => void }]}
 */
export default function useDialogControl(DialogElement, defaultShow = false) {
  const [showDialog, setShowDialog] = useState(defaultShow);

  const handleShow = useCallback(() => {
    setShowDialog(true);
  }, []);
  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, []);

  return [
    () => <DialogElement open={showDialog} onClose={handleClose} />,
    { open: handleShow, close: handleClose },
  ];
}
