import { Switch } from '@/components';
import CreateScheduleDialog from '@/components/CreateScheduleDialog';
import SelectSchedule from '@/components/SelectSchedule';
import { OfferContext } from '@/contexts';
import { PlusIcon } from '@/icons';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import { useContext, useState } from 'react';

export default function OfferSessionRequestForm() {
  const context = useContext(OfferContext);
  const [showScheduleEditor, setShowScheduleEditor] = useState(false);

  return (
    <div className="flex flex-col gap-8 p-7 bg-gray-10 rounded-2.5xl">
      <HeadlessSwitch.Group as="div" className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <HeadlessSwitch.Label className="text-xl leading-normal text-midnight-100 font-bold">
            Allow Session Request
          </HeadlessSwitch.Label>
          <Switch
            value={context.allowRequests}
            onChange={context.setAllowRequests}
          />
        </div>
        <HeadlessSwitch.Description>
          Enable this feature to allow your client to send a session request.
          Clients can only send requests for the times in your Accessibility
          Schedule.
        </HeadlessSwitch.Description>
      </HeadlessSwitch.Group>

      <div className="flex gap-2">
        <div className="flex flex-col gap-8 grow">
          <SelectSchedule
            label={
              <span className="text-xl leading-normal text-midnight-100 font-bold">
                Schedule
              </span>
            }
            value={context.sessionRequestsSchedule}
            onChange={context.setSessionRequestsSchedule}
          />
        </div>
        <button
          type="button"
          className="button-outline button-s self-end"
          onClick={() => setShowScheduleEditor(true)}
        >
          <span>Create new schedule</span>
          <PlusIcon className="h-5 fill-current" aria-hidden="true" />
        </button>
      </div>

      <CreateScheduleDialog
        open={showScheduleEditor}
        onClose={() => setShowScheduleEditor(false)}
        onCreate={(schedule) => context.setSessionRequestsSchedule(schedule.id)}
      />
    </div>
  );
}
