import LaunchMeetingButton from '@/components/LaunchMeetingButton';
import { AppContext } from '@/contexts';
import { Listbox, Popover } from '@headlessui/react';
import { ArrowLeftIcon, EditIcon } from '@icons';
import { WorkspaceService } from '@services';
import { humanOfferType } from '@utils';
import classNames from 'classnames';
import { DateTime, Duration } from 'luxon';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  SessionSceneContext,
  useSessionSceneData,
} from './SessionSceneContext';
import SessionInfoSection from './src/SessionInfoSection';
import SessionParticipantsSection from './src/SessionParticipantsSection';

export default function SessionScene() {
  const { id: session_id } = useParams();
  const appContext = useContext(AppContext);

  const sessionSceneData = useSessionSceneData(session_id);

  const session = useMemo(() => sessionSceneData.session, [sessionSceneData]);
  const workspaceInfo = useMemo(
    () => sessionSceneData.workspaceInfo,
    [sessionSceneData],
  );

  const dt_start = useMemo(
    () => DateTime.fromISO(session?.timestamp),
    [session],
  );
  const dt_end = useMemo(
    () => dt_start.plus(Duration.fromISO(session?.duration)),
    [session, dt_start],
  );

  const canEdit = useMemo(() => appContext.isCoach, [appContext]);
  // Allow to reschedule if it's an upcoming session
  const canReschedule = useMemo(() => dt_start > DateTime.now(), [dt_start]);

  async function changeWorkspace(workspace) {
    if (workspaceInfo) {
      await WorkspaceService.removeSessions(workspaceInfo.id, [session_id]);
    }
    await WorkspaceService.addSessions(workspace.id, [session_id]);
    sessionSceneData.setWorkspaceInfo(workspace);
  }

  return (
    <SessionSceneContext.Provider value={sessionSceneData}>
      {session && (
        <div className="flex flex-col gap-8 px-12 py-8 relative">
          <div className="flex flex-col gap-8">
            <Link to=".." className="flex gap-2.5 text-midnight-80 self-start">
              <ArrowLeftIcon className="fill-current" aria-hidden="true" />
              <span className="font-semibold">Sessions</span>
            </Link>

            {/* Session */}
            <div className="flex flex-col gap-8 px-5 py-6 bg-gray-10 rounded-2.5xl">
              <div className="flex justify-between">
                <h1 className="text-4.5xl font-bold text-midnight-100">
                  Session Info
                </h1>

                <div className="flex gap-2">
                  {canReschedule && (
                    <button
                      type="button"
                      className="button-ghost button-s"
                      onClick={() => toast.warn('Not Implemented 🗿')}
                    >
                      Reschedule
                    </button>
                  )}

                  <LaunchMeetingButton
                    session_id={session?.id}
                    meeting_id={session?.zoom_meeting_id}
                    className="button-s"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-2">
                <div className="flex flex-auto flex-col gap-1.5">
                  <p className="text-xl text-midnight-100 font-bold">Offer</p>
                  {session?.offer ? (
                    <span className="flex gap-4">
                      <span className="text-midnight-100">
                        {session?.offer?.name}
                      </span>
                      <span className="text-midnight-60">
                        {humanOfferType(session?.offer?.type)}
                      </span>
                    </span>
                  ) : (
                    <span className="sr-only">Loading info</span>
                  )}
                </div>

                <div className="flex flex-auto flex-col gap-1.5">
                  <p className="text-xl text-midnight-100 font-bold">Date</p>
                  {session ? (
                    <span className="text-midnight-100">
                      <time dateTime={dt_start.toISO()}>
                        {dt_start.toLocaleString({
                          dateStyle: 'short',
                          timeStyle: 'short',
                        })}
                      </time>
                      <span> – </span>
                      <time dateTime={dt_end.toISO()}>
                        {dt_end.toLocaleString({ timeStyle: 'short' })}
                      </time>
                    </span>
                  ) : (
                    <span className="sr-only">Loading info</span>
                  )}
                </div>

                <div className="flex flex-auto flex-col gap-1.5 relative">
                  <p className="text-xl text-midnight-100 font-bold">
                    Workspace
                  </p>
                  {workspaceInfo !== undefined ? (
                    <div className="flex gap-2 items-center">
                      <span>
                        {workspaceInfo?.id
                          ? workspaceInfo.name || 'Workspace'
                          : 'None'}
                      </span>
                      {canEdit && (
                        <EditWorkspacePopover
                          value={workspaceInfo}
                          onChange={changeWorkspace}
                        />
                      )}
                    </div>
                  ) : (
                    <span className="sr-only">Loading info</span>
                  )}
                </div>

                <div className="flex flex-auto flex-col gap-1.5 relative">
                  <p className="text-xl text-midnight-100 font-bold">
                    Zoom Meeting
                  </p>
                  {session ? (
                    <div className="flex gap-2 items-center">
                      <span>{session.zoom_meeting_id || 'None'}</span>
                      {canEdit && (
                        <EditZoomPopover
                          value={session.zoom_meeting_id}
                          onChange={() => {
                            toast.warn('Not Implemented 🗿');
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <span className="sr-only">Loading info</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <>
            <SessionParticipantsSection
              sessionId={session_id}
              defaultOpen={true}
            />

            <SessionInfoSection sessionId={session_id} />
          </>
        </div>
      )}
    </SessionSceneContext.Provider>
  );
}

function EditWorkspacePopover({ value, onChange }) {
  const [workspaces, setWorkspaces] = useState([]);
  const sessionSceneContext = useContext(SessionSceneContext);

  useEffect(() => {
    async function fetchWorkspacesList() {
      const list = await WorkspaceService.list({
        filters: {
          offer_id: {
            eq: sessionSceneContext.session.offer_id,
          },
        },
      });
      setWorkspaces(list.data);
    }
    fetchWorkspacesList();
  }, [sessionSceneContext.session.offer_id]);

  return (
    <Listbox value={value} onChange={onChange}>
      <Listbox.Button className="flex items-center p-0 button-clear fill-midnight-60 hover:fill-midnight-90 focus:fill-midnight-90">
        <EditIcon className="w-5 h-5 fill-inherit" aria-hidden="true" />
        <span className="sr-only">Edit</span>
      </Listbox.Button>
      <Listbox.Options className="absolute inset-x-0 top-full flex flex-col gap-1 mt-2 py-4 max-h-60 overflow-auto rounded-xl bg-white shadow">
        {workspaces.map((ws) => (
          <Listbox.Option
            key={ws.id}
            value={ws}
            className={({ active, selected }) =>
              classNames(
                'relative cursor-default select-none py-1.5 px-6',
                active ? 'bg-midnight-20' : 'text-midnight-100',
                selected && 'font-bold',
              )
            }
          >
            {ws.name || 'Workspace'}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
}
function EditZoomPopover({ value, onChange }) {
  return (
    <Popover>
      <Popover.Button className="flex items-center p-0 button-clear fill-midnight-60 hover:fill-midnight-90 focus:fill-midnight-90">
        <EditIcon className="w-5 h-5 fill-inherit" aria-hidden="true" />
        <span className="sr-only">Edit</span>
      </Popover.Button>

      <Popover.Panel className="absolute mt-2 inset-x-0 p-4 min-h-40 rounded-xl bg-white shadow">
        {({ close }) => (
          <div className="flex flex-col">
            <label htmlFor="zoom-meeting-id-input">
              Past Zoom meeting ID here
            </label>
            <input
              id="zoom-meeting-id-input"
              className="input-main input-m spin number-no-spinner"
              type="number"
              defaultValue={value}
              onBlur={onChange}
              onKeyDown={(event) =>
                event.key == 'Enter' && (event.preventDefault() || close())
              }
            />
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}
