import StatusMessage from '@/components/StatusMessage';
import { Link } from 'react-router-dom';

export function CheckoutSessionScene() {
  return (
    <StatusMessage
      className="min-h-screen justify-center max-w-lg mx-auto"
      status="success"
      title="Thanks for your purchase"
      text="The offer will be added on your account after we get a fulfillment from Stripe. It may take a couple of minutes."
    >
      <Link
        to="/dashboard/client/acquisitions/offers"
        className="button-main button-m"
      >
        Got it!
      </Link>
    </StatusMessage>
  );
}
