import extensions from '@/components/RichTextEditor/extensions';
import { EditIcon, TrashIcon } from '@/icons';
import { generateHTML } from '@tiptap/html';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/TooltipFloating';

export default function PrivateNote({
  id,
  content,
  timestamp,
  onEdit,
  onDelete,
}) {
  const dt = useMemo(() => DateTime.fromISO(timestamp), [timestamp]);
  const timeString = useMemo(() => dt.toLocaleString(DateTime.DATE_MED), [dt]);

  return (
    <div
      className="flex flex-col gap-4 bg-midnight-10 py-3 px-6 rounded-xl"
      data-note-id={id}
    >
      <div
        className="space-y-[0.75em]"
        dangerouslySetInnerHTML={{
          __html: generateHTML(content, extensions),
        }}
      ></div>
      <div className="flex justify-between items-end">
        <time dateTime={timestamp} className="text-midnight-70">
          {timeString}
        </time>
        <div className="flex flex-row justify-end">
          <Tooltip placement="bottom">
            <TooltipTrigger
              className="button-clear button-xs button-icon-rect"
              onClick={onEdit}
            >
              <EditIcon className="fill-current" />
            </TooltipTrigger>

            <TooltipContent>
              <span className="py-1 px-3 bg-white rounded border border-midnight-40 text-sm">
                Edit
              </span>
            </TooltipContent>
          </Tooltip>

          <Tooltip placement="bottom">
            <TooltipTrigger
              className="button-clear button-xs button-icon-rect"
              onClick={onDelete}
            >
              <TrashIcon className="fill-current" />
            </TooltipTrigger>
            <TooltipContent>
              <span className="py-1 px-3 bg-white rounded border border-midnight-40 text-sm">
                Delete
              </span>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
