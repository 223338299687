import {
  getCurrentUserContext,
  login as loginRequest,
  register,
  updateUser,
  upload,
} from '@services';
import { makeAutoObservable } from 'mobx';

export class UserStore {
  constructor() {
    this.currentUser = null;
    this.accessToken = localStorage.getItem('accessToken') || null;
    this.refreshToken = localStorage.getItem('refreshToken') || null;
    this.initials = null;
    makeAutoObservable(this);
  }

  get fullName() {
    return `${this.currentUser.first_name} ${this.currentUser.last_name}`;
  }

  get isCoach() {
    return this.currentUser.role === 'coach';
  }

  get isClient() {
    return this.currentUser.role === 'client';
  }

  get hasProfile() {
    return this.currentUser.has_profile;
  }

  setHasCoachProfile(value) {
    return (this.currentUser.has_profile = value);
  }

  get abbreviation() {
    return this.currentUser.first_name[0] + this.currentUser.last_name[0];
  }

  get registerUserValueFromStorage() {
    const userData = localStorage.getItem('UserData');
    return JSON.parse(userData);
  }

  setRegisterUserValues(values) {
    localStorage.setItem('UserData', JSON.stringify(values));
  }

  clearStorage() {
    localStorage.removeItem('UserData');
  }

  setCurrentUser(user) {
    this.currentUser = user;
  }

  setAccessToken(token) {
    localStorage.setItem('accessToken', token);
    this.accessToken = token;
  }

  logout() {
    localStorage.removeItem('accessToken');

    this.currentUser = null;
  }

  async login({ email, password }) {
    const { data } = await loginRequest({ email, password });
    const { token } = data;

    this.setAccessToken(token);
  }

  async register(values) {
    const { data } = await register(values);
    const { token } = data;
    this.setAccessToken(token);
    await this.getUserContext();
  }

  async getUserContext() {
    const { data } = await getCurrentUserContext();
    const { role, ...userData } = data;
    this.setCurrentUser({
      ...userData,
      role: localStorage.getItem('role') ?? 'client',
    });
    this.setInitials({
      name: this.currentUser.first_name,
      surname: this.currentUser.last_name,
    });
  }

  async changeCurrentRole(role = '') {
    let newRole;
    if (role) {
      newRole = role;
    } else {
      newRole = this.isClient ? 'coach' : 'client';
    }
    this.currentUser.role = newRole;
    localStorage.setItem('role', newRole);
  }

  async updateUser(payload) {
    const newUserData = {
      email: payload.email,
      phone: payload.phone,
      first_name: payload.firstName,
      last_name: payload.lastName,
    };

    try {
      const response = await updateUser({
        payload: newUserData,
      });

      this.setCurrentUser({ ...this.currentUser, ...response.data });
      this.setInitials({
        name: this.currentUser.first_name,
        surname: this.currentUser.last_name,
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }

  async updateAvatar(avatarFile) {
    try {
      const uploadAvatarResponse = await upload(avatarFile);

      const payload = {
        avatar: uploadAvatarResponse.link,
      };

      const response = await updateUser({ payload });

      this.setCurrentUser({
        ...this.currentUser,
        ...response.data,
      });
    } catch (error) {
      console.log('error: ', error);
    }
  }

  async updatePassword(newPassword) {
    const payload = {
      password: newPassword,
    };

    try {
      await updateUser({ payload });
    } catch (error) {
      console.log('error: ', error);
    }
  }

  setInitials({ name, surname }) {
    this.initials = `${name} ${Array.from(surname)[0]}.`;
  }
}

export default new UserStore();
