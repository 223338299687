import { humanOfferType } from '@/utils';
import { NoEnteredData, UserGrid, UserGridItem } from '@components';
import { AcquisitionService } from '@services';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export function MyClientsScene() {
  const [clientsPreOffer, setClients] = useState([]);

  useEffect(() => {
    const load = async () => {
      try {
        const list = await AcquisitionService.myClients();
        setClients(list.data);
      } catch (error) {
        toast.error('Unable to retrieve data. See console (F12)');
        console.error("Get user's clients error.", error);
      }
    };

    void load();
  }, []);

  return (
    <div className="flex flex-col gap-7 px-12 py-7.5">
      <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
        Clients
      </h1>

      <div className="flex flex-col gap-10 p-7 bg-gray-10 rounded-2.5xl">
        {clientsPreOffer.length > 0 ? (
          clientsPreOffer.map((offer) => (
            <div key={offer.id} className="flex flex-col gap-1">
              <div className="flex gap-4 text-lg">
                <h2 className="text-midnight-100 font-bold">{offer.name}</h2>
                <p className="text-midnight-60">{humanOfferType(offer.type)}</p>
              </div>
              <UserGrid className="grid-cols-2 sm:grid-cols-4 md:grid-cols-5 2xl:grid-cols-8">
                {offer.users.map((user) => (
                  <Link key={user.id} to={`../users/${user.id}`}>
                    <UserGridItem
                      name={`${user.first_name} ${user.last_name}`}
                      avatar={user.avatar}
                      abbreviation={user.first_name[0] + user.last_name[0]}
                    />
                  </Link>
                ))}
              </UserGrid>
            </div>
          ))
        ) : (
          <NoEnteredData
            title="You don't have any clients yet"
            customImage={
              <img
                src="/images/svg-search-user-card.svg"
                alt="Magnifying glass over user card"
              />
            }
          />
        )}
      </div>
    </div>
  );
}
