import ConfirmationDialog from '@/components/ConfirmationDialog';
import InviteParticipantsDialog from '@/components/Messenger/InviteParticipantsDialog';
import ManageRoomDialog from '@/components/Messenger/ManageRoomDialog';
import MessageInput from '@/components/Messenger/MessageInput';
import RoomOptionsMenu from '@/components/Messenger/RoomOptionsMenu';
import RoomTimeline from '@/components/Messenger/RoomTimeline';
import { MessengerActiveRoomContext } from '@/contexts/MessengerActiveRoomContext';
import {
  MATRIX_HOMESERVER,
  MessengerContext,
} from '@/contexts/MessengerContext';
import useDialogControl from '@/hooks/useDialogControl';
import { SpinnerIcon } from '@/icons';
import getBlurhash from '@/utils/get-blurhash';
import nextTick from '@/utils/next-tick';
import { Direction, MsgType } from 'matrix-js-sdk';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import RoomAvatar from '../RoomAvatar';

export default function JoinedRoom() {
  const { matrixClient } = useContext(MessengerContext);
  const { roomId, room } = useContext(MessengerActiveRoomContext);

  const timeline = room.getLiveTimeline();

  const chatContainer = useRef(null);
  const [isTimelinePaginating, setIsTimelinePaginating] = useState(false);
  const [showManageRoomDialog, setShowManageRoomDialog] = useState(false);
  const [showInviteParticipantsDialog, setShowInviteParticipantsDialog] =
    useState(false);
  const [ConfirmLeaveDialog, confirmLeaveControls] = useDialogControl(
    (binds) => (
      <ConfirmationDialog
        title="Leave the room"
        description="Are you sure want to leave this room?"
        onConfirm={() => {
          matrixClient.leave(roomId);
          binds.onClose();
        }}
        {...binds}
      />
    ),
  );

  const sendMessage = useCallback(
    async (message) => {
      try {
        await matrixClient.sendTextMessage(roomId, message);
      } catch (error) {
        console.error('Send text message error:', error);
        toast.error('Cannot send a message');
      }
    },
    [matrixClient, roomId],
  );

  const sendFile = useCallback(
    /** @param {File} file */
    async (file) => {
      const mediaType = file.type.split('/')[0]; // take type group e.g. image/png -> image
      switch (mediaType) {
        case 'image': {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          await new Promise((r, j) => {
            img.onload = r;
            img.onerror = j;
          });

          const blurhash = getBlurhash(img);

          const info = {
            size: file.size,
            h: img.height,
            w: img.width,
            mimetype: file.type,
            'xyz.amorgan.blurhash': blurhash,
          };

          const { content_uri } = await matrixClient.uploadContent(file);
          await matrixClient.sendImageMessage(
            roomId,
            content_uri,
            info,
            file.name,
          );
          break;
        }
        case 'video': {
          const video = document.createElement('video');
          video.preload = 'metadata';
          video.src = URL.createObjectURL(file);
          await new Promise((r, j) => {
            video.onloadedmetadata = r;
            video.onerror = j;
          });

          const info = {
            duration: Math.floor(video.duration * 1000),
            h: video.videoHeight,
            mimetype: file.type,
            size: file.size,
            w: video.videoWidth,
          };

          const { content_uri } = await matrixClient.uploadContent(file);

          await matrixClient.sendMessage(roomId, {
            msgtype: MsgType.Video,
            url: content_uri,
            body: file.name,
            info,
          });
          break;
        }
        case 'audio': {
          const audio = new Audio(URL.createObjectURL(file));
          await new Promise((r, j) => {
            audio.onloadedmetadata = r;
            audio.onerror = j;
          });

          const info = {
            duration: Math.floor(audio.duration * 1000),
            mimetype: file.type,
            size: file.size,
          };

          const { content_uri } = await matrixClient.uploadContent(file);

          await matrixClient.sendMessage(roomId, {
            msgtype: MsgType.Audio,
            url: content_uri,
            body: file.name,
            info,
          });
          break;
        }
        default: {
          const info = {
            mimetype: file.type,
            size: file.size,
          };

          const { content_uri } = await matrixClient.uploadContent(file);

          await matrixClient.sendMessage(roomId, {
            msgtype: MsgType.File,
            url: content_uri,
            body: file.name,
            info,
          });
          break;
        }
      }
    },
    [matrixClient, roomId],
  );

  // Set timeout to mark all as read
  useEffect(() => {
    if (room.getUnreadNotificationCount() > 0) {
      const timeout = setTimeout(() => {
        console.debug('[JoinedRoom] Mark last event as read.');
        const lastEvent = room.getLastLiveEvent();
        if (!lastEvent) {
          console.error('Cannot get last event to mark as read');
        }
        matrixClient.setRoomReadMarkers(
          room.roomId,
          lastEvent.getId(),
          lastEvent,
        );
      }, 1500);
      return () => clearTimeout(timeout);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  // Scroll to the timeline to the bottom when a room have been opened
  useEffect(() => {
    // Wait for render to complete
    nextTick(() => {
      if (chatContainer.current) {
        chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
      }
    });
  }, [room.roomId]);

  const handleTimelineScroll = useCallback(
    async (event) => {
      if (
        timeline.getPaginationToken(Direction.Backward) &&
        event.target.scrollTop < 150 &&
        !isTimelinePaginating
      ) {
        setIsTimelinePaginating(true);
        await matrixClient.paginateEventTimeline(timeline, {
          backwards: true,
        });
        setIsTimelinePaginating(false);
      }
    },
    [isTimelinePaginating, matrixClient, timeline],
  );

  return (
    timeline && (
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center px-8 pt-3 pb-3.5">
          <div className="flex gap-3">
            <RoomAvatar room={room} />
            <div className="grow truncate">
              <span className="font-bold leading-6">{room.name}</span>
            </div>
          </div>

          <RoomOptionsMenu
            onManage={() => setShowManageRoomDialog(true)}
            onInvite={() => setShowInviteParticipantsDialog(true)}
            onLeave={confirmLeaveControls.open}
            onLog={() => console.log(room)}
          />
        </div>
        <div
          ref={chatContainer}
          className="grow p-8 h-full overflow-y-auto border-t border-midnight-20"
          onScroll={handleTimelineScroll}
        >
          <div className="h-6 max-w-lg py-2 box-content flex justify-center">
            {isTimelinePaginating && (
              <SpinnerIcon className="animate-spin fill-midnight-50" />
            )}
          </div>

          <RoomTimeline timeline={timeline} className="h-fit" />
        </div>
        <div className="p-8 pt-2">
          <MessageInput onFile={sendFile} onMessage={sendMessage} />
        </div>
        <ManageRoomDialog
          room={room}
          show={showManageRoomDialog}
          onClose={() => setShowManageRoomDialog(false)}
        />
        <InviteParticipantsDialog
          show={showInviteParticipantsDialog}
          onClose={() => setShowInviteParticipantsDialog(false)}
        />
        <ConfirmLeaveDialog />
      </div>
    )
  );
}
