import { CoachProfileService, UploadService } from '@/services';
import { sha1 } from 'object-hash';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import EditCoachProfileControls from './src/EditCoachProfileControls';
import EditCoachProfileForm from './src/EditCoachProfileForm';
import EditCoachProfilePreview from './src/EditCoachProfilePreview';

export default function EditCoachProfileScene() {
  const [initialProfile, setInitialProfile] = useState(undefined);
  const [currentProfile, setCurrentProfile] = useState(undefined);
  const [previewMode, setPreviewMode] = useState(false);

  const initialProfileHash = useMemo(
    () => initialProfile && sha1(initialProfile),
    [initialProfile],
  );
  const currentProfileHash = useMemo(
    () => currentProfile && sha1(currentProfile),
    [currentProfile],
  );
  const hasChanges = useMemo(
    () => initialProfileHash !== currentProfileHash,
    [initialProfileHash, currentProfileHash],
  );

  const previewProfile = useMemo(
    () =>
      currentProfile && {
        name: currentProfile.name,
        about: currentProfile.about,
        avatar:
          currentProfile.avatar instanceof File
            ? URL.createObjectURL(currentProfile.avatar)
            : currentProfile.avatar,
        header:
          currentProfile.header instanceof File
            ? URL.createObjectURL(currentProfile.header)
            : currentProfile.header,
        header_offset: currentProfile.header_offset,
        contacts: [],
      },
    [currentProfile],
  );

  useEffect(() => {
    async function fetchData() {
      const profile = (await CoachProfileService.get()) ?? {
        name: '',
        about: {
          type: 'doc',
          content: [{ type: 'paragraph' }],
        },
        avatar: null,
        header_offset: 0,
        header: null,
        contacts: [],
      };
      setInitialProfile(profile);
      setCurrentProfile(profile);
    }

    fetchData();
  }, []);

  async function saveProfile() {
    let data = currentProfile;
    if (data.avatar instanceof File) {
      try {
        const file = await UploadService.upload(data.avatar);
        data.avatar = file.link;
      } catch (error) {
        data.avatar = initialProfile.avatar;
        toast.error('Avatar uploading error. See console (F12)');
        console.error('Avatar uploading error', error);
      }
    }

    if (data.header instanceof File) {
      try {
        const file = await UploadService.upload(data.header);
        data.header = file.link;
      } catch (error) {
        data.header = initialProfile.header;
        toast.error('Header uploading error. See console (F12)');
        console.error('Header uploading error', error);
      }
    }

    try {
      await CoachProfileService.set(data);
      setInitialProfile(data);
      setCurrentProfile(data);
      toast.success('Profile updated successfully!');
    } catch (error) {
      toast.error('Unable to update profile. See console (F12)');
      console.error('Profile updating error', error);
    }
  }

  return (
    currentProfile && (
      <div className="py-7.5 px-12">
        <div className="flex flex-col gap-6 bg-gray-10 rounded-2.5xl px-7.5 py-6">
          <EditCoachProfileControls
            onSave={saveProfile}
            hasChanges={hasChanges}
            previewMode={previewMode}
            onChangePreviewMode={() => setPreviewMode(!previewMode)}
          />
          {previewMode ? (
            <EditCoachProfilePreview value={previewProfile} />
          ) : (
            <EditCoachProfileForm
              value={currentProfile}
              onChange={setCurrentProfile}
            />
          )}
        </div>
      </div>
    )
  );
}
