import { CoachProfileHeader, DButton } from '@components';
import { BUTTON_THEME, SIZE } from '@constants';
import { useDragOffset } from '@hooks';
import { useMemo, useRef, useState } from 'react';

const EditCoachProfileBanner = ({
  banner,
  offset,
  onChangeBanner,
  onChangeOffset,
}) => {
  const input = useRef(null);
  const [editMode, setEditMode] = useState(false);

  const bannerUrl = useMemo(
    () => (banner instanceof File ? URL.createObjectURL(banner) : banner),
    [banner],
  );

  const startDrag = useDragOffset({
    offset: offset,
    onOffsetChanged: onChangeOffset,
  });

  const handleInputChange = (event) => {
    const image = event.target.files[0];
    if (image) {
      onChangeBanner(image);
    }
  };

  /** @param {PointerEvent} event */
  const enterEditMode = (event) => {
    if (!editMode) {
      setEditMode(true);
      event.stopPropagation();
    }
  };
  /** @param {PointerEvent} event */
  const exitEditMode = (event) => {
    if (editMode) {
      setEditMode(false);
      event.stopPropagation();
    }
  };

  return (
    <div
      className="relative"
      onMouseDown={() => {
        if (editMode) startDrag();
      }}
    >
      <CoachProfileHeader url={bannerUrl} offset={offset} />
      <input
        type="file"
        className="hidden"
        accept="image/png, image/jpg, image/jpeg"
        ref={input}
        onChange={handleInputChange}
      />
      <div className="absolute right-3 bottom-3 flex gap-4">
        {bannerUrl ? (
          // Controls if has image
          editMode ? (
            <DButton
              theme={BUTTON_THEME.GHOST}
              size={SIZE.S}
              onClick={exitEditMode}
            >
              Done
            </DButton>
          ) : (
            <>
              <DButton
                theme={BUTTON_THEME.GHOST}
                size={SIZE.S}
                onClick={enterEditMode}
              >
                Move
              </DButton>
              <DButton
                theme={BUTTON_THEME.GHOST}
                size={SIZE.S}
                onClick={() => input.current.click()}
              >
                Replace
              </DButton>
            </>
          )
        ) : (
          // Controls if no image
          <DButton
            theme={BUTTON_THEME.GHOST}
            size={SIZE.S}
            onClick={() => input.current.click()}
          >
            Set cover
          </DButton>
        )}
      </div>
    </div>
  );
};

export default EditCoachProfileBanner;
