import { ScheduleEditor } from '@/components';
import { CheckmarkIcon, ChevronDownIcon } from '@/icons';
import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';

export default function EditScheduleForm({ value, onChange, disabled }) {
  /** @type {ReturnType<typeof useState<import('timezones.json').Timezone>>} */
  const [timezone, setTimezone] = useState(null);
  /** @type {ReturnType<typeof useState<Array<import('timezones.json').Timezone>>} */
  const [timezones, setTimezones] = useState([]);

  const onChangeName = useCallback(
    (event) => {
      onChange({
        ...value,
        name: event.target.value,
      });
    },
    [onChange, value],
  );
  const onChangeTimezone = useCallback(
    (newValue) => {
      setTimezone(newValue);
      onChange({
        ...value,
        timezone: newValue.utc[0],
      });
    },
    [onChange, value],
  );
  const onChangeSchedule = useCallback(
    (newValue) => {
      onChange({
        ...value,
        schedule: newValue,
      });
    },
    [onChange, value],
  );

  // Load timezones
  useEffect(() => {
    async function LoadTimezones() {
      const module = await import('timezones.json');
      setTimezones(module.default);
    }
    LoadTimezones();
  }, []);

  // Initialize timezone variable
  useEffect(() => {
    // Use local zone as fallback
    if (timezones) {
      const tz = timezones.find((v) => v.utc.includes(value.timezone));
      tz && setTimezone(tz);
    }
    // Trigger when timezones are loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezones]);

  return (
    <div className="flex flex-col gap-8">
      <label
        htmlFor="schedule-form-name-input"
        className="text-xl leading-normal text-midnight-100 font-bold"
      >
        Schedule Name
        <span className="text-primary">*</span>
      </label>
      <input
        id="schedule-form-name-input"
        type="text"
        className="input-main input-m"
        value={value.name}
        onChange={onChangeName}
        disabled={disabled}
      />

      <Listbox
        value={timezone}
        as="div"
        className="relative flex flex-col gap-8"
        onChange={onChangeTimezone}
        disabled={disabled}
      >
        <Listbox.Label className="text-xl leading-normal text-midnight-100 font-bold">
          Timezone
          <span className="text-primary">*</span>
        </Listbox.Label>
        <Listbox.Button
          placeholder="Filter by offer"
          className="relative w-full cursor-default rounded-md py-3 pl-4 pr-10 border border-midnight-50 font-normal focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-50 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-10"
        >
          {timezone ? (
            <span className="block truncate text-midnight-100">
              {timezone.value}
            </span>
          ) : (
            <span className="block truncate text-midnight-40">
              Select timezone
            </span>
          )}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 fill-midnight-60"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute top-full mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none z-10">
            {timezones &&
              timezones.map((item, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active
                        ? 'bg-orange-10 text-midnight-100'
                        : 'text-midnight-100'
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-bold' : 'font-normal'
                        }`}
                      >
                        {item.text}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckmarkIcon
                            className="h-5 w-5 fill-orange-50"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Transition>
      </Listbox>

      <label
        id="offer-schedule-editor-label"
        className="text-xl leading-normal text-midnight-100 font-bold"
      >
        Accessibility Schedule
        <span className="text-primary">*</span>
      </label>

      <ScheduleEditor
        value={value.schedule}
        onChange={onChangeSchedule}
        aria-labelledby="offer-schedule-editor-label"
        disabled={disabled}
      />
    </div>
  );
}
