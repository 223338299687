import { NotificationsContentWrapper } from '../NotificationsContentWrapper/NotificationsContentWrapper';
import { useNotificationsSort } from '../../hooks/useNotificationsSort';
import { NotificationsList } from '../NotificationsList/NotificationsList';
import { NoEnteredData } from '@components';

export const NotificationsContent = ({
  pageName,
  notificationsList,
}) => {
  const hasData = notificationsList.length > 0;

  const { notifications } = useNotificationsSort({
    hasData,
    notificationsList,
  });

  return (
    <NotificationsContentWrapper>
      <>
        {hasData ? (
          <NotificationsList notifications={notifications} />
        ) : (
          <NoEnteredData
            title={`No ${pageName}`}
            pageName={pageName}
          />
        )}
      </>
    </NotificationsContentWrapper>
  );
};
