import ScheduleEditorWeekday from './ScheduleEditorWeekday';

export default function ScheduleEditor({ value, onChange, disabled, ...rest }) {
  function handleChange(index, item) {
    onChange(value.map((v, i) => (i === index ? item : v)));
  }

  return (
    <div className="flex flex-col divide-y divide-midnight-20" {...rest}>
      {value.map((v, i) => (
        <ScheduleEditorWeekday
          key={i}
          weekdayIndex={i}
          value={v}
          onChange={(data) => handleChange(i, data)}
          className="py-3"
          disabled={disabled}
        />
      ))}
    </div>
  );
}
