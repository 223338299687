import { Dialog } from '@headlessui/react';

export default function ConfirmationDialog({
  title = 'Confirm the action',
  description,
  open,
  onClose,
  onConfirm,
}) {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black-500/80" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="flex flex-col gap-6 w-full px-7.5 py-12 max-w-2xl bg-gray-10 rounded-2.5xl max-h-screen overflow-auto">
          <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
            {title}
          </Dialog.Title>

          {description && (
            <Dialog.Description>{description}</Dialog.Description>
          )}

          <div className="flex gap-4 justify-center">
            <button
              type="button"
              className="button-main button-m"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button-outline button-m"
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
