import { NoEnteredData, UserGrid, UserGridItem } from '@components';
import { AcquisitionService } from '@services';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function MyCoachesListScene() {
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const list = await AcquisitionService.myCoaches();
        setCoaches(list.data);
      } catch (error) {
        toast.error('Unable to retrieve data. See console (F12)');
        console.error("Get user's coaches error.", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-7 px-12 py-7.5 h-full">
      <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
        Coaches
      </h1>
      <div className="flex flex-col gap-4 bg-white rounded-2.5xl p-5">
        {coaches.length > 0 ? (
          <UserGrid className="grid-cols-2 sm:grid-cols-4 md:grid-cols-5 2xl:grid-cols-8">
            {coaches.map((coach) => (
              <Link key={coach.user_id} to={coach.user_id}>
                <UserGridItem
                  name={coach.name}
                  avatar={coach.avatar}
                  abbreviation={coach.name
                    ?.split(' ')
                    .map((i) => i[0])
                    .join('')
                    .toUpperCase()}
                />
              </Link>
            ))}
          </UserGrid>
        ) : (
          <NoEnteredData
            title="You have no coaches yet. They will appear here after purchasing the offer."
            customImage={
              <img
                src="/images/svg-search-user-card.svg"
                alt="Magnifying glass over user card"
              />
            }
          />
        )}
      </div>
    </div>
  );
}
