import { Listbox, Transition } from '@headlessui/react';
import { CheckmarkIcon, ChevronDownIcon } from '@icons';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';

export default function SelectOffer({
  value,
  onChange,
  loadData,
  disabled,
  placeholder = 'Select an offer',
  className,
  allowNull = false,
}) {
  const [offers, setOffers] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await loadData();
        setOffers(data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [loadData]);

  return (
    <Listbox
      value={value}
      as="div"
      className={classNames('relative', className)}
      onChange={onChange}
      disabled={disabled}
    >
      <Listbox.Button
        placeholder="Filter by offer"
        className="relative w-full cursor-default rounded-md py-3 pl-4 pr-10 border border-midnight-50 font-normal focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-50 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-10"
      >
        {value ? (
          <span className="block truncate text-midnight-100">{value.name}</span>
        ) : (
          <span className="block truncate text-midnight-40">{placeholder}</span>
        )}
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon
            className="h-5 w-5 fill-midnight-60"
            aria-hidden="true"
          />
        </span>
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none">
          {offers &&
            (offers.length > 0 ? (
              <>
                {allowNull && (
                  <Listbox.Option
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? 'bg-orange-10 text-midnight-100'
                          : 'text-midnight-100'
                      }`
                    }
                    value={null}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-bold' : 'font-normal'
                          }`}
                        >
                          None
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <CheckmarkIcon
                              className="h-5 w-5 fill-orange-50"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                )}
                {offers.map((offer) => (
                  <Listbox.Option
                    key={offer.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? 'bg-orange-10 text-midnight-100'
                          : 'text-midnight-100'
                      }`
                    }
                    value={offer}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-bold' : 'font-normal'
                          }`}
                        >
                          {offer.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <CheckmarkIcon
                              className="h-5 w-5 fill-orange-50"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </>
            ) : (
              <Listbox.Option
                className="relative cursor-default select-none py-4 pl-10 pr-4 text-midnight-60 text-center"
                value="no_data"
                disabled
              >
                No available offers
              </Listbox.Option>
            ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
}
