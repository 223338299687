import { BUTTON_THEME, BUTTON_TYPE, KeyboardCode, SIZE } from '@constants';
import { useComponentDidMount } from '@hooks';
import { ChevronDownIcon, SvgIcon } from '@icons';
import { isNil } from '@utils';
import classNames from 'classnames';
import { forwardRef, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './styles.scss';

const defaultProps = {
  type: 'button',
  tabIndex: 0,
};

const ButtonComponent = ({
  className,
  theme = BUTTON_THEME.PRIMARY,
  size = SIZE.M,
  children,
  name,
  type = BUTTON_TYPE.BUTTON,
  role,
  title,
  titlePreformatted,
  autoFocus,
  disabled,
  buttonRef,
  linkTo,
  target,
  tabIndex = 0,
  iconComponent,
  rounded,
  onClick,
  onMouseEnter,
  onMouseOut,
  leftIconComponent,
  ...other
}) => {
  const bRef = useRef();
  const navigate = useNavigate();

  useComponentDidMount(() => {
    if (autoFocus === true) {
      bRef.current?.focus();
    }
  });

  const setRef = useCallback(
    (ref) => {
      bRef.current = ref;

      if (buttonRef) {
        if (typeof buttonRef === 'function') {
          buttonRef(ref);
        } else {
          buttonRef.current = ref;
        }
      }
    },
    [buttonRef],
  );

  const isButton = isNil(linkTo);
  const Component = isButton ? 'button' : Link;
  let props = {};

  if (isButton) {
    props = {
      ...props,
      name,
      type,
      disabled,
    };
  } else {
    props = {
      ...props,
      to: linkTo,
      target,
      'aria-disabled': disabled,
    };
  }

  const onKeyDown = (event) => {
    if (!isButton && event.code === KeyboardCode.Space) {
      event.preventDefault();
      navigate(linkTo);
    }
  };

  const { fullWidth, opened, ...elementProps } = other;

  return (
    <Component
      ref={setRef}
      className={classNames(
        'cr-btn',
        {
          'cr-btn--disabled': disabled,
          'cr-btn--rounded': rounded,
          'cr-btn--icon': Boolean(iconComponent),
          [`cr-btn--theme-${theme}`]: Boolean(theme),
          [`cr-btn--size-${size}`]: Boolean(size),
        },
        className,
      )}
      {...props}
      role={role}
      tabIndex={disabled ? -1 : tabIndex}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      onKeyDown={onKeyDown}
      {...elementProps}
    >
      {/* TODO: decide which variant is better. Use both children or add a check */}
      {Boolean(leftIconComponent) && (
        <SvgIcon icon={leftIconComponent} className="mr-1" size={size} />
      )}
      {children}

      {Boolean(iconComponent) && <SvgIcon icon={iconComponent} size={size} />}
    </Component>
  );
};

export const Button = forwardRef((props, ref) => {
  return <ButtonComponent {...defaultProps} {...props} buttonRef={ref} />;
});

Button.displayName = 'Button';

export const SubmitButton = (props) => (
  <Button type={BUTTON_TYPE.SUBMIT} {...props} />
);

export const ChevronButton = ({ onClick, ...props }) => (
  <div
    onClick={onClick}
    className="cursor-pointer flex items-center cr-chevron-btn"
  >
    <Button {...props} />
    <SvgIcon
      size={props.size}
      className={classNames('bg-white cr-btn--icon')}
      icon={<ChevronDownIcon />}
    />
  </div>
);
