import { CheckmarkIcon } from '@/icons';
import { OfferService } from '@/services';
import { Duration } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

export default function OfferPageSessionInfo({ offerId }) {
  const [sessionInfo, setSessionInfo] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await OfferService.getSessionInfo(offerId);
        setSessionInfo(data);
      } catch (error) {
        toast.error('Unable to get Session Info. See console (F12)');
        console.error('Get Session Info error.', error);
      }
    }

    void fetchData();
  }, [offerId]);

  const durationText = useMemo(
    () =>
      sessionInfo &&
      Duration.fromISO(sessionInfo.session_duration).toHuman({
        unitDisplay: 'short',
      }),
    [sessionInfo],
  );

  return (
    sessionInfo && (
      <>
        <h2 className="sr-only">Session Info</h2>
        <ul className="flex flex-col gap-4">
          <li className="flex gap-3 items-center">
            <CheckmarkIcon className="fill-midnight-60" aria-hidden="true" />
            <span className="text-midnight-90 text-lg font-normal">
              {sessionInfo.session_amount} sessions
            </span>
          </li>
          <li className="flex gap-3 items-center">
            <CheckmarkIcon className="fill-midnight-60" aria-hidden="true" />
            <span className="text-midnight-90 text-lg font-normal">
              {sessionInfo.session_capacity} clients per session
            </span>
          </li>
          <li className="flex gap-3 items-center">
            <CheckmarkIcon className="fill-midnight-60" aria-hidden="true" />
            <span className="text-midnight-90 text-lg font-normal">
              {durationText} duration
            </span>
          </li>
        </ul>
      </>
    )
  );
}
