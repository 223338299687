import { AppContext } from '@/contexts';
import Tooltip from '@/shared/ui/Tooltip';
import { TOOLTIP_PLACEMENT } from '@constants';
import { BellIcon, MenuIcon, SessionsIcon, SettingsIcon } from '@icons';
import { NotificationsStoreV2 } from '@stores';
import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import SearchForm from './SearchForm';
import UserProfileDropdown from './UserProfileDropdown';

export default function AppHeader({ onBurgerClick, showBurger }) {
  const notificationsStore = NotificationsStoreV2.useState();
  const appContextValue = useContext(AppContext);

  return (
    <header className="sticky top-0 shadow-sm w-full bg-white h-16 flex justify-between px-4 py-2 z-30">
      <div className="flex space-x-2">
        <Tooltip
          placement={TOOLTIP_PLACEMENT.BOTTOM_START}
          content="Open sidebar"
        >
          <button
            type="button"
            className={`button-clear button-m button-icon-rect rotate-180 ${
              showBurger || 'lg:hidden'
            }`}
            onClick={onBurgerClick}
          >
            <MenuIcon className="fill-current" aria-hidden="true" />
            <span className="sr-only">Open sidebar</span>
          </button>
        </Tooltip>
        <div className="hidden lg:flex items-center">
          <SearchForm />
        </div>
      </div>
      {/* TODO: rework this part for mobile and functional */}
      <div className="lg:hidden">needs mobile search</div>
      <div className="hidden lg:flex justify-between">
        <div className="flex">
          {appContextValue.isCoach && (
            <Link
              to="/dashboard/coach"
              className="button-outline button-s gap-2 px-6 mr-8"
            >
              <span className="whitespace-nowrap">Welcome Checklist</span>
              <SessionsIcon className="fill-current" aria-hidden="true" />
            </Link>
          )}

          <NavLink
            to="notifications"
            className="relative mx-1 px-3 rounded-full border border-transparent focus:outline-none focus:bg-midnight-20 hover:bg-midnight-30 cursor-pointer flex flex-col justify-center"
          >
            {({ isActive }) => (
              <div>
                <span className="sr-only">Notifications</span>
                {notificationsStore.state.newNotifications.length > 0 && (
                  <div className="absolute top-1 right-1.5 rounded-full py-1 px-1.5 text-2xs bg-red-60 text-white">
                    <span>
                      {notificationsStore.state.newNotifications.length}
                      <span className="sr-only"> new notifications</span>
                    </span>
                  </div>
                )}
                <BellIcon
                  className={isActive ? 'fill-orange-50' : 'fill-midnight-100'}
                  aria-hidden="true"
                />
              </div>
            )}
          </NavLink>

          <NavLink
            to="settings"
            className="relative mx-1 px-3 rounded-full border border-transparent focus:outline-none focus:bg-midnight-20 hover:bg-midnight-30 cursor-pointer flex flex-col justify-center"
          >
            {({ isActive }) => (
              <div>
                <span className="sr-only">Settings</span>
                <SettingsIcon
                  className={isActive ? 'fill-orange-50' : 'fill-midnight-100'}
                  aria-hidden="true"
                />
              </div>
            )}
          </NavLink>
        </div>
        <div className="w-0.5 h-full bg-midnight-20 mx-9" />
        <UserProfileDropdown />
      </div>
    </header>
  );
}
