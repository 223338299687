import { NoEnteredData, OfferCard } from '@components';
import { AcquisitionService } from '@services';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function AcquisitionScene() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const acquisitions = await AcquisitionService.offers();
        setData(acquisitions);
      } catch (err) {
        toast.error('Unable to get acquisitions. See conslole (F12)');
        console.error('Get acquisition list error', err);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-4 p-7 bg-gray-10 rounded-2.5xl">
      {data.length ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 w-full">
          {data.map((offer) => (
            <Link
              key={offer.id}
              to={`/dashboard/client/offers/${offer.id}`}
              className="rounded-lg focus-visible:outline-none focus-visible:ring ring-primary ring-offset-2"
            >
              <OfferCard
                id={offer.id}
                type={offer.type}
                title={offer.name}
                description={offer.short_description}
              />
            </Link>
          ))}
        </div>
      ) : (
        <NoEnteredData
          title="No acquisitions"
          customImage={
            <img
              src="/images/svg-woman-shopping.svg"
              alt="Woman with shopping cart"
            />
          }
        />
      )}
    </div>
  );
}
