import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '@/contexts';
import { UserStoreV2 } from '@/stores';
import { stripeDashboardURL } from '@/utils/stripe';
import { SIZE } from '@constants';
import {
  BellIcon,
  ChevronDownIcon,
  DiscountIcon,
  LogoutIcon,
  PersonIcon,
  SessionsIcon,
  SwapIcon,
} from '@icons';
import Avatar from '@ui/Avatar';

export default function UserProfileDropdown() {
  const userStore = UserStoreV2.useState();
  const appContext = useContext(AppContext);

  const data = useMemo(
    () => ({
      hasSubscription: userStore.hasActiveSubscription,
      firstName: userStore.state.user.first_name,
      lastName: userStore.state.user.last_name,
      email: userStore.state.user.email,
      avatar: userStore.state.user.avatar,
    }),
    [userStore],
  );

  async function handleLogout() {
    await userStore.logout();
  }

  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className="inline-flex w-full h-full px-2 py-0 justify-center rounded-md focus:outline-none focus-visible:bg-midnight-20 hover:bg-midnight-20">
        {({ open }) => (
          <div className="flex items-center gap-2">
            <div className="flex flex-col items-end">
              <span className="text-midnight-100 text-base font-bold leading-6">
                {`${data.firstName} ${data.lastName[0]}.`}
              </span>
              <span className="text-sm font-normal text-midnight-60">
                {data.email}
              </span>
            </div>

            <Avatar
              avatar={data.avatar}
              abbreviation={data.firstName[0] + data.lastName[0]}
              size={SIZE.XS}
            />
            <ChevronDownIcon
              className={classNames(
                'w-4 h-4 fill-midnight-60 transition-transform',
                open && 'rotate-180',
              )}
            />
          </div>
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-4 w-fit overflow-hidden origin-top-right rounded bg-white shadow-lg focus:outline-none">
          {/* Dropdown Header */}
          <div className="flex gap-3 px-8 py-4 bg-midnight-20">
            <Avatar
              className="shrink-0"
              avatar={data.avatar}
              abbreviation={data.firstName[0] + data.lastName[0]}
              size={SIZE.S}
            />

            <div>
              <div className="text-midnight-100 font-bold leading-6">{`${data.firstName} ${data.lastName[0]}.`}</div>
              <div className="text-midnight-60 text-sm">{data.email}</div>
            </div>
          </div>
          {/* Dropdown Items */}
          <div className="flex flex-col py-3">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="settings/personal_info"
                  className={`flex items-center gap-2 w-full pl-8 pr-2 py-2 rounded-none ${
                    active
                      ? 'bg-midnight-20 text-midnight-100 font-semibold'
                      : 'text-midnight-60'
                  }`}
                >
                  <PersonIcon className="fill-current" aria-hidden="true" />
                  <span>Personal Info</span>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="settings/notifications_settings"
                  className={`flex items-center gap-2 w-full pl-8 pr-2 py-2 rounded-none ${
                    active
                      ? 'bg-midnight-20 text-midnight-100 font-semibold'
                      : 'text-midnight-60'
                  }`}
                >
                  <BellIcon className="fill-current" aria-hidden="true" />
                  <span>Notifications Settings</span>
                </Link>
              )}
            </Menu.Item>

            {data.hasSubscription && (
              <>
                {appContext.isCoach && (
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href={stripeDashboardURL}
                        target="_blank"
                        rel="noreferrer"
                        className={`${
                          active
                            ? 'bg-midnight-20 text-midnight-100 font-semibold'
                            : 'text-midnight-60'
                        } flex items-center gap-2 w-full pl-8 pr-2 py-2 rounded-none`}
                      >
                        <DiscountIcon
                          className="fill-current"
                          aria-hidden="true"
                        />
                        <span>Manage Subscription</span>
                      </a>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => {
                    return (
                      <Link
                        to={
                          appContext.isCoach
                            ? '/dashboard/client'
                            : '/dashboard/coach'
                        }
                        className={`flex items-center gap-2 w-full pl-8 pr-2 py-2 rounded-none ${
                          active
                            ? 'bg-midnight-20 text-midnight-100 font-semibold'
                            : 'text-midnight-60'
                        }`}
                      >
                        <SwapIcon className="fill-current" aria-hidden="true" />
                        <span>
                          {appContext.isCoach
                            ? 'Switch to Client'
                            : 'Switch to Coach'}
                        </span>
                      </Link>
                    );
                  }}
                </Menu.Item>
              </>
            )}
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={`${
                    active
                      ? 'bg-midnight-20 text-midnight-100 font-semibold'
                      : 'text-midnight-60 font-normal'
                  } flex items-center gap-2 w-full pl-8 pr-2 py-2 rounded-none`}
                  onClick={handleLogout}
                >
                  <LogoutIcon className="fill-current" aria-hidden="true" />
                  <span>Log Out</span>
                </button>
              )}
            </Menu.Item>
            {!data.hasSubscription && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/become_coach"
                    className={`${
                      active
                        ? 'bg-orange-50 text-midnight-100 font-semibold'
                        : 'bg-orange-10 text-orange-50 font-normal'
                    } flex items-center gap-2 w-full pl-8 pr-2 py-2 mt-4 rounded-none`}
                  >
                    <SessionsIcon className="fill-current" aria-hidden="true" />
                    <span>Become a Coach</span>
                  </Link>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
