import { createContext, useEffect, useMemo, useState } from 'react';

/**
 * @param {object} params
 * @param {Array<{ name: string, component: JSX.Element }>} params.steps
 * @param {number} params.currentStep
 * @param {Function?} params.onChangeStep
 */
export function useStepsContextData({
  steps = [],
  currentStep = 0,
  onChangeStep,
} = {}) {
  const [currentStepIndex, setCurrentStepIndex] = useState(currentStep);

  useEffect(() => {
    onChangeStep instanceof Function && onChangeStep(currentStepIndex);
  }, [currentStepIndex, onChangeStep]);

  return {
    steps,
    currentStepIndex,

    currentStep: useMemo(
      () => steps[currentStepIndex],
      [steps, currentStepIndex],
    ),

    nextStep() {
      setCurrentStepIndex(currentStepIndex + 1);
    },

    prevStep() {
      setCurrentStepIndex(Math.max(this.currentStepIndex - 1, 0));
    },
  };
}

/**
 * @type {React.Context<ReturnType<typeof useStepsContextData>>}
 */
export const StepsContext = createContext();
