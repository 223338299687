import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

export default function SelectTimeslots({
  value,
  onChange,
  loadData,
  multiple,
  className,
  ...rest
}) {
  const [slots, setSlots] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await loadData();
        setSlots(data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [loadData]);

  return (
    <Listbox
      value={value}
      onChange={onChange}
      multiple={multiple}
      {...rest}
    >
      <Listbox.Options
        static
        className={classNames(
          'flex flex-col gap-2.5 bg-midnight-10',
          className,
        )}
      >
        {slots &&
          (slots.length > 0 ? (
            slots.map((slot) => (
              <Listbox.Option
                key={slot.milliseconds}
                value={slot.milliseconds}
                data-milliseconds={slot.milliseconds}
                disabled={slot.disabled}
                className={({ active, selected, disabled }) =>
                  classNames(
                    'flex items-center py-3 px-8 rounded-2.5xl border cursor-pointer select-none',
                    active && 'ring',
                    disabled
                      ? 'bg-midnight-10 text-midnight-40 border-midnight-20'
                      : selected
                      ? 'bg-orange-10 text-primary border-primary'
                      : 'bg-gray-10 text-midnight-100 border-midnight-20',
                  )
                }
              >
                {({ selected }) => (
                  <>
                    <span className="grow text-center">{slot.text}</span>
                    {multiple && (
                      <div
                        className={classNames(
                          'w-5 h-5 rounded border',
                          selected
                            ? 'bg-primary border-primary'
                            : 'border-midnight-20',
                        )}
                        aria-hidden="true"
                      >
                        {selected && (
                          <CheckIcon className="w-full text-white" />
                        )}
                      </div>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))
          ) : (
            <Listbox.Option
              className="relative cursor-default select-none py-4 pl-10 pr-4 text-midnight-60 text-center"
              value="no_data"
              disabled
            >
              No available time
            </Listbox.Option>
          ))}
      </Listbox.Options>
    </Listbox>
  );
}
