let _BearerToken = localStorage.getItem('access_token');

export const BearerToken = {
  get() {
    return _BearerToken;
  },
  set(value) {
    _BearerToken = value;
    localStorage.setItem('access_token', value);
  },
};

/**
 * @param {import('axios').AxiosStatic | import('axios').AxiosInstance} axios
 */
export default function useBearerToken(axios) {
  axios.defaults.useBearer = true;

  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${_BearerToken}`;
      return config;
    },
    null,
    {
      synchronous: true,
      runWhen: (config) => _BearerToken && config.useBearer == true,
    },
  );

  return axios;
}
