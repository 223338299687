import { EditIcon, EyeIcon } from '@/icons';
import { Link } from 'react-router-dom';

export default function EditCoachProfileControls({
  onSave,
  hasChanges,
  previewMode,
  onChangePreviewMode,
}) {

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-4">
        <Link to="./.." className="button-outline button-m">
          Back
        </Link>
        <button
          type="button"
          className="button-main button-m"
          disabled={!hasChanges}
          onClick={onSave}
        >
          Save
        </button>
      </div>

      <div className="flex items-center gap-4">
        <button
          type="button"
          className="button-ghost button-m gap-2"
          onClick={onChangePreviewMode}
        >
          {previewMode ? (
            <>
              <EditIcon className="fill-current" />
              <span>Back to Editing</span>
            </>
          ) : (
            <>
              <EyeIcon className="fill-current" />
              <span>Preview as Client</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
}