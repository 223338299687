import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/files`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function list({ limit, offset, filters } = {}) {
  const resonse = await instance.get('', {
    params: {
      limit,
      offset,
      filters,
    },
  });

  return resonse.data;
}

export async function upload(file) {
  const formData = new FormData();
  formData.append('file', file);

  const resonse = await instance.post('', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return resonse.data;
}

export async function get(file_id) {
  const resonse = await instance.get(`/${file_id}`);
  return resonse.data;
}

export async function update(file_id, file) {
  const formData = new FormData();
  formData.append('file', file);

  const resonse = await instance.patch(`/${file_id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return resonse.data;
}

export async function remove(file_id) {
  const resonse = await instance.delete(`/${file_id}`);
  return resonse.data;
}

export async function getLink(file_id) {
  const resonse = await instance.get(`/${file_id}/link`);
  return resonse.data;
}

export function getDownloadLink(file_id) {
  return `${process.env.REACT_APP_API_BASE_URL}/files/${file_id}/download`;
}
export function getDownloadMultipleLink(file_ids = [], archive_name) {
  const searchParams = new URLSearchParams();

  for (const file_id of file_ids) {
    searchParams.append('files', file_id);
  }
  archive_name && searchParams.append('name', archive_name);
  return `${process.env.REACT_APP_API_BASE_URL}/files/download?${searchParams}`;
}
