import { DAvatar } from '@components';
import { SIZE } from '@constants';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const ChatItem = ({ avatar, name, latestMessage, time, isActive, chatId }) => {
  const renderLatestMessage = () => {
    if (latestMessage?.type === 'text') {
      return latestMessage.text;
    }
    if (latestMessage?.type === 'file') {
      return 'Attachment';
    }

    return null;
  };

  return (
    <Link
      to={`/dashboard/messages/${chatId}`}
      className={classNames(
        'py-4 px-8 flex flex-row hover:cursor-pointer',
        {
          'bg-midnight-20': isActive,
        },
        { 'hover:bg-gray-20': !isActive },
      )}
    >
      <div className="">
        <DAvatar avatar={avatar} size={SIZE.M} />
      </div>
      <div className="truncate flex-grow ml-2">
        <div className="flex flex-row justify-between">
          <h3 className="text-midnight-100 font-bold text-lg">{name}</h3>
          <span className="text-midnight-50 font-normal text-md">{time}</span>
        </div>
        <p className="text-midnight-60 font-normal text-md truncate">
          {renderLatestMessage()}
        </p>
      </div>
    </Link>
  );
};

export default ChatItem;
