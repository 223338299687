/**
 * @param {object} params
 * @param {import("matrix-js-sdk").MatrixEvent} params.event
 */
export default function RoomMemberEvent({ event }) {
  const content = event.getContent();
  switch (content.membership) {
    case 'join':
      return (
        <li className="text-sm text-midnight-50 text-center">
          {content.displayname} joined the room
        </li>
      );
    case 'invite':
      return (
        <li className="text-sm text-midnight-50 text-center">
          {content.displayname} invited by {event.sender.name}
        </li>
      );
    case 'leave':
      return (
        <li className="text-sm text-midnight-50 text-center">
          {content.displayname} left the room
        </li>
      );
    default:
      return (
        <li className="text-sm text-midnight-50 text-center">
          User {content.displayname}; Action: {content.membership}
        </li>
      );
  }
}
