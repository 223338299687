import { NotificationsContent } from '../../components/NotificationsContent/NotificationsContent';
import { NotificationsStore } from '@stores';

// Use unobserved component to show notifications even though they are marked as viewed.
export const NewTabScene = () => {
  return (
    <NotificationsContent
      pageName="notifications"
      notificationsList={NotificationsStore.newNotifications}
    />
  );
};
