import { ContactsMap } from '@/constants';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';

/**
 * @param {object} params
 * @param {string} params.type key from ContactsMap (see constants)
 * @param {object} params.value
 * @param {Function} params.onChange
 * @param {string} params.placeholder
 */
export default function ContactInputStatic({
  id = nanoid(),
  type,
  value,
  onChange,
  placeholder,
}) {
  const contant = useMemo(() => ContactsMap.get(type), [type]);

  return (
    <div className="relative ounded-md shadow-sm">
      <label
        htmlFor={id}
        className="absolute inset-y-0 left-0 flex items-center"
      >
        <div className="h-full cursor-default py-0 pl-4">
          <img src={contant.logo} alt={contant.name} className="h-full w-8" />
        </div>
      </label>
      <input
        id={id}
        type="text"
        className="input-main input-m block w-full pl-14"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}
