import { httpClient } from '@utils';

/**
 * @param {string} sessionId session UUID
 */
export async function get(sessionId) {
  const { data, status } = await httpClient.get(
    `/user/sessions/${sessionId}/info`,
    {
      validateStatus: (number) => [200, 404].includes(number),
    },
  );
  return status === 200 ? data : null;
}

/**
 * @param {string} sessionId session UUID
 * @param {{ description: string, files: Array<string>, tasks: Array<string> }} data
 */
export async function put(sessionId, data) {
  const { data: sessionInfo } = await httpClient.put(
    `/user/sessions/${sessionId}/info`,
    data,
  );
  return sessionInfo;
}
/**
 * @param {string} sessionId session UUID
 */
export async function remove(sessionId) {
  const { data } = await httpClient.delete(`/user/sessions/${sessionId}/info`);
  return data;
}
