import { ContentContainer } from '@components';
import { ChatsStore, UserStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ChatSidebar } from './components';

const MessagesScene = observer(() => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        await ChatsStore.getChats();
        ChatsStore.prepareChatsForUser(UserStore.currentUser.id);
      } finally {
        setLoading(false);
      }
    }

    void fetchData();
  }, []);

  return (
    <ContentContainer
      className="h-full"
      title="Messages"
      loading={loading}
      showBackground={false}
    >
      <div className="w-full rounded-xl bg-gray-10 lg:flex min-h-[600px] xl:min-h-[800px] max-h-[800px]">
        <div className="lg:w-1/3">
          <ChatSidebar />
        </div>
        <div className="lg:w-2/3 flex flex-col">
          <Outlet />
        </div>
      </div>
    </ContentContainer>
  );
});

export default MessagesScene;
