import { getUserById, OfferingsService } from '@/services';
import { makeAutoObservable } from 'mobx';

class OfferingsStore {
  constructor() {
    this.offerings = [];
    this.comments = [];
    this.offer = null;
    this.offerCoach = null;
    makeAutoObservable(this);
  }

  setOfferings(offerings) {
    this.offerings = offerings;
  }

  setComments(comments) {
    this.comments = comments;
  }

  setOffer(offer) {
    this.offer = offer;
  }

  setOfferCoach(coach) {
    this.offerCoach = coach;
  }

  get coachName() {
    return `${this.offerCoach?.first_name} ${this.offerCoach?.last_name}`;
  }

  async getOffer(offerId) {
    const { data } = await OfferingsService.getById(offerId);
    this.setOffer(data);
  }

  async getOfferCoach() {
    const { data } = await getUserById(this.offer.user_id);
    this.setOfferCoach(data);
  }

  async getCoachOffers() {
    const { data } = await OfferingsService.getAllByCoach(this.offer.coach_id);
    this.setOfferCoach(data);
  }

  async getOfferComments(offerId) {
    const { data } = await OfferingsService.getComments(offerId);
    this.setComments(data);
  }

  async getOfferingsList() {
    const { data } = await OfferingsService.getAll();
    this.setOfferings(data);
  }
}

export default new OfferingsStore();
