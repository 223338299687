import { PaperclipIcon, SendIcon } from '@/icons';
import nextTick from '@/utils/next-tick';
import { useCallback, useRef, useState } from 'react';

export default function MessageInput({ onMessage, onFile }) {
  const textareaRef = useRef(null);
  const [newMessage, setNewMessage] = useState('');

  const resetInput = useCallback(() => {
    setNewMessage('');
    nextTick(() => {
      textareaRef.current.style.height = 0;
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + 'px';
    });
  }, []);

  const submitMessage = useCallback(() => {
    const trimmed = newMessage.trim();
    if (trimmed != '') {
      onMessage(trimmed);
      resetInput();
    }
  }, [newMessage, onMessage, resetInput]);

  const selectUploads = useCallback(async () => {
    const el = document.createElement('input');
    el.type = 'file';
    el.click();
    const [file] = await new Promise((r) => {
      el.addEventListener('change', (event) => r([...event.target.files]));
    });

    if (file) {
      onFile(file);
    }
  }, [onFile]);

  const handleInputKeyDown = useCallback(
    /** @param {KeyboardEvent} event  */
    (event) => {
      switch (event.code) {
        case 'Enter':
        case 'NumpadEnter':
          if (!event.shiftKey) {
            event.preventDefault();
            submitMessage();
          }
          break;
        default:
          break;
      }
    },
    [submitMessage],
  );

  return (
    <div className="flex gap-2 px-5 py-3 rounded-xl bg-gray-20">
      <button
        type="button"
        title="Attach files"
        className="p-0 focus-visible:ring"
        onClick={selectUploads}
      >
        <PaperclipIcon />
      </button>
      <textarea
        ref={textareaRef}
        autoFocus
        rows="1"
        value={newMessage}
        className="grow resize-none bg-transparent placeholder:text-midnight-60 focus:outline-none"
        placeholder="Write a message..."
        onChange={(event) => setNewMessage(event.target.value)}
        onKeyDown={handleInputKeyDown}
        onInput={(event) => {
          // Auto resize
          event.target.style.height = 0;
          event.target.style.height = event.target.scrollHeight + 'px';
        }}
      />
      <button
        type="button"
        title="Send message"
        tabIndex="-1"
        className="p-0"
        onClick={submitMessage}
      >
        <SendIcon />
      </button>
    </div>
  );
}
