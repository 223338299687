import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function list({ limit, offset, offerId } = {}) {
  const respoinse = await instance.get('/user/acquisitions', {
    params: {
      limit,
      offset,
      filters: {
        offer_id: {
          eq: offerId,
        },
      },
    },
  });
  return respoinse.data;
}

export async function has(offerId) {
  const { data } = await instance.get(`/user/acquisitions/has/${offerId}`);
  return data;
}

export async function offers() {
  const respoinse = await instance.get('/user/acquisitions/offers');
  return respoinse.data;
}

export async function myCoaches() {
  const respoinse = await instance.get('/user/coaches');
  return respoinse.data;
}

export async function myClients() {
  const respoinse = await instance.get('/user/clients');
  return respoinse.data;
}
