import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { KeyboardCode } from '@constants';
import { LoadingWrapper } from '../LoadingWrapper';
import './styles.scss';

const processValueToRender = (value) => value === null ? '' : value;

// export interface NumberProps {
//   min?: number;
//   max?: number;
//   step?: number;
//   hideArrowButtons?: boolean;
// }
//
// interface Props extends ClassName, NumberProps {
//   name?: string;
//   value: number;
//   loading?: boolean;
//   disabled?: boolean;
//   placeholder?: string;
//   onChange?: Callback;
// }

export const NumberInput = ({
  className,
  name,
  value,
  min,
  max,
  step,
  placeholder,
  loading,
  disabled,
  hideArrowButtons,
  onChange,
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(processValueToRender(value));
  }, [value]);

  const handleChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  function saveValue() {
    let newValue = inputValue;

    if (inputValue !== '') {
      newValue = Number(inputValue);

      if (min !== undefined && newValue < min) {
        newValue = min;
      }

      if (max !== undefined && newValue > max) {
        newValue = max;
      }
    }

    setInputValue(newValue);

    if (value !== newValue) {
      onChange?.({
        target: {
          name,
          value: newValue
        }
      });
    }
  }

  const handleBlur = () => {
    saveValue();
  };

  const handleKeyUp = (e) => {
    if (e.code === KeyboardCode.Enter) {
      saveValue();
    }
  };

  return (
    <LoadingWrapper loading={loading}>
      <input
        className={classNames('cr-number-input', hideArrowButtons && 'cr-number-input--hide-arrow-buttons', className)}
        type="number"
        name={name}
        value={inputValue}
        min={min}
        max={max}
        step={step}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        {...otherProps}
      />
    </LoadingWrapper>
  );
};
