const NoResults = () => {
  return (
    <div className="bg-gray-10 rounded-xl p-4">
      <img
        alt="No search results"
        className="mx-auto"
        src="/images/no_search_results.svg"
      />
      <p className="text-midnight-100 text-lg font-normal text-center my-10">
        No results
      </p>
    </div>
  );
};

export default NoResults;
