import { MessengerContext } from '@/contexts/MessengerContext';
import { Dialog, Transition } from '@headlessui/react';
import { useFormik } from 'formik';
import { Fragment, useContext } from 'react';
import { toast } from 'react-toastify';

export default function CreateNewRoomDialog({ show, onClose }) {
  const { matrixClient } = useContext(MessengerContext);
  const formik = useFormik({
    initialValues: {
      roomName: '',
    },
    onSubmit: async (values) => {
      onClose();
      try {
        await matrixClient.createRoom({
          name: values.roomName,
          visibility: 'private',
          is_direct: false,
        });
      } catch (error) {
        toast.error('Unable to create a room.');
        console.error('Room creation error', error);
      }
    },
  });

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog open={show} onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-500/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="flex flex-col gap-4 w-full h-fit max-w-2xl px-[30px] py-12 bg-gray-10 rounded-2.5xl ">
              <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
                Create Room
              </Dialog.Title>

              <form
                className="flex flex-col gap-4"
                onSubmit={formik.handleSubmit}
              >
                <label
                  htmlFor="messanger-create-room-name-input"
                  className="text-xl leading-normal text-midnight-100 font-bold"
                >
                  Room Name
                  <span className="text-primary">*</span>
                </label>
                <input
                  id="messanger-create-room-name-input"
                  type="text"
                  name="roomName"
                  required
                  className="input-main input-m"
                  onChange={formik.handleChange}
                  value={formik.values.roomName}
                />

                <button
                  type="submit"
                  className="button-main button-m self-center"
                >
                  Create
                </button>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
