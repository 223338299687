import StepBar from '@/components/StepBar';
import { StepsContext, useStepsContextData } from '@/contexts';
import classNames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CreateOfferStepsLayout({
  steps,
  onChangeStep,
  onCreateOffer,
  nextStepValidation,
  inert = false,
  className,
}) {
  const stepContext = useStepsContextData({
    steps,
    onChangeStep,
  });
  const root = useRef(null);

  const navigate = useNavigate();

  const ActiveStepComponent = useMemo(
    () => stepContext.currentStep.component,
    [stepContext.currentStep],
  );

  useEffect(() => {
    const current = root.current;
    inert && current.setAttribute('inert', true);
    return () => current.removeAttribute('inert');
  }, [root, inert]);

  return (
    <StepsContext.Provider value={stepContext}>
      <div
        ref={root}
        className={classNames(
          'flex flex-col gap-7 px-12 py-7.5 min-h-full',
          className,
        )}
      >
        <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
          Create New Offer
        </h1>

        <StepBar
          steps={stepContext.steps}
          active={stepContext.currentStepIndex}
          label="Offer Creation Progress"
        />

        <ActiveStepComponent />

        <div className="flex grow items-end justify-end gap-6">
          <button
            type="button"
            className="button-outline button-m"
            onClick={() =>
              stepContext.currentStepIndex === 0
                ? navigate('..') // Go back to type select scene
                : stepContext.prevStep()
            }
          >
            Back
          </button>

          {stepContext.currentStepIndex === stepContext.steps.length - 1 ? (
            <button
              type="button"
              className="button-main button-m"
              onClick={onCreateOffer}
            >
              Create Offer
            </button>
          ) : (
            <button
              type="button"
              className="button-main button-m"
              disabled={
                nextStepValidation instanceof Function && !nextStepValidation()
              }
              onClick={stepContext.nextStep}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </StepsContext.Provider>
  );
}
