import { SIZE } from '@constants';
import DAvatar from '@components/DAvatar';
import classNames from 'classnames';

export function UserGrid({ children, className }) {
  return (
    <div
      className={classNames(
        'grid gap-4',
        className,
      )}
    >
      {children}
    </div>
  );
}

export function UserGridItem({ name, avatar, abbreviation, className }) {
  return (
    <div className={classNames('flex flex-col gap-3 items-center', className)}>
      <DAvatar size={SIZE.L} avatar={avatar} abbreviation={abbreviation} />

      <span className="text-lg text-midnight-100 truncate">{name}</span>
    </div>
  );
}
