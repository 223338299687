import { FORM_CONTROL_TYPE } from '@constants';
import { isNil } from '@utils';
import classNames from 'classnames';
import { forwardRef, useState } from 'react';
import BaseTextInput from '../BaseTextInput';
import { DCheckbox } from '../DCheckbox/DCheckbox';
import FormControlLabel from '../FormControlLabel';
import FormHelperText from '../FormHelperText';
import { LoadingSpinner } from '../LoadingWrapper';
import NumberInput from '../NumberInput';
import Switcher from '../Switcher';
import TextareaInput from '../TextareaInput';
import './styles.scss';

const defaultProps = {
  type: FORM_CONTROL_TYPE.TEXT,
  fullWidth: false,
  disabled: false,
  readOnly: false,
  required: false,
  error: false,
};

// type DefaultProps = typeof defaultProps;

// type Props = ClassName & DataAttr & DefaultProps & {
//   value?: string | number | boolean;
//   name?: string;
//
//   placeholder?: string;
//   helperText?: React.ReactNode;
//
//   size?: SIZE;
//
//   minLength?: number;
//   maxLength?: number;
//   pattern?: string;
//
//   inputRef?: any;
//   containerRef?: any;
//
//   label?: React.ReactNode;
//   labelComponent?: React.ElementType;
//
//   startAdornment?: React.ReactNode;
//   endAdornment?: React.ReactNode;
//
//   labelPlacement?: "top" | "right" | "left";
//
//   // Flags
//   loading?: boolean;
//
//   /**
//   * For <BaseTextInput>. Default is `true`
//   */
//   immediateChange?: boolean;
//
//   autoComplete?: FORM_CONTROL_AUTOCOMPLETE;
//   /**
//    * If `true`, the input will be focused during the first mount.
//    */
//   autoFocus?: boolean;
//
//   clearable?: boolean;
//   indeterminate?: boolean;
//
//   // For type `number`
//   numberComponentProps?: NumberProps;
//
//   textareaComponentProps?: TextareaProps;
//
//   // Callbacks
//   onChange?: Callback;
//   onFocus?: Callback;
//   onBlur?: Callback;
//   onKeyDown?: Callback;
//   onKeyUp?: Callback;
// };

const FormControlComponent = (props) => {
  const [focused, setFocused] = useState(false);

  const getControl = () => {
    const { type } = props;

    switch (type) {
      case FORM_CONTROL_TYPE.TEXTAREA:
        return renderTextareaInput();
      case FORM_CONTROL_TYPE.NUMBER:
        return renderNumberInput();
      case FORM_CONTROL_TYPE.CHECKBOX:
        return renderCheckboxInput();
      case FORM_CONTROL_TYPE.SWITCHER:
        return renderSwitcherInput();
      default:
        return renderTextInput();
    }
  };

  const renderTextareaInput = () => {
    const {
      dataId,
      inputRef,
      name,
      placeholder,
      value,

      autoFocus,
      disabled,
      readOnly,
      required,
      textareaComponentProps,
      labelPlacement,

      onChange,
      onBlur,
      onKeyDown,
      onKeyUp,

      ...rest
    } = props;

    const {
      className,
      type,
      fullWidth,
      error,
      helperText,
      size,
      minLength,
      maxLength,
      pattern,
      containerRef,
      label,
      labelComponent,
      startAdornment,
      endAdornment,
      loading,
      immediateChange,
      autoComplete,
      clearable,
      indeterminate,
      numberComponentProps,
      onFocus,
      ...elementProps
    } = rest;

    return (
      <TextareaInput
        inputRef={inputRef}
        dataId={dataId}
        placeholder={placeholder}
        name={name}
        value={value}
        autoFocus={autoFocus}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        loading={loading}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        {...textareaComponentProps}
        {...elementProps}
      />
    );
  };

  const renderNumberInput = () => {
    const {
      name,
      value,
      numberComponentProps,
      placeholder,

      disabled,
      loading,
      labelPlacement,

      onChange,

      ...rest
    } = props;

    const {
      className,
      type,
      fullWidth,
      readOnly,
      required,
      error,
      helperText,
      size,
      minLength,
      maxLength,
      pattern,
      inputRef,
      containerRef,
      label,
      labelComponent,
      startAdornment,
      endAdornment,
      immediateChange,
      autoComplete,
      autoFocus,
      clearable,
      indeterminate,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      ...elementProps
    } = rest;

    return (
      <NumberInput
        {...numberComponentProps}
        name={name}
        value={value}
        placeholder={placeholder}
        loading={loading}
        disabled={disabled}
        onChange={onChange}
        {...elementProps}
      />
    );
  };

  const renderCheckboxInput = () => {
    const {
      name,
      value,
      label,
      size,

      autoFocus,
      disabled,
      loading,
      indeterminate,
      labelPlacement,

      onChange,
      onBlur,

      ...rest
    } = props;

    const {
      className,
      type,
      fullWidth,
      readOnly,
      required,
      error,
      placeholder,
      helperText,
      minLength,
      maxLength,
      pattern,
      inputRef,
      containerRef,
      labelComponent,
      startAdornment,
      endAdornment,
      immediateChange,
      autoComplete,
      clearable,
      numberComponentProps,
      onFocus,
      onKeyDown,
      onKeyUp,
      ...elementProps
    } = rest;

    return (
      <DCheckbox
        name={name}
        value={value}
        label={label}
        size={size}
        autoFocus={autoFocus}
        loading={loading}
        disabled={disabled}
        indeterminate={indeterminate}
        onChange={onChange}
        onBlur={onBlur}
        {...elementProps}
      />
    );
  };

  const renderSwitcherInput = () => {
    const {
      name,
      value,
      size,

      autoFocus,
      disabled,
      loading,
      labelPlacement,

      onChange,

      ...rest
    } = props;

    const {
      className,
      type,
      fullWidth,
      readOnly,
      required,
      error,
      placeholder,
      helperText,
      minLength,
      maxLength,
      pattern,
      inputRef,
      containerRef,
      label,
      labelComponent,
      startAdornment,
      endAdornment,
      immediateChange,
      autoComplete,
      clearable,
      indeterminate,
      numberComponentProps,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      ...elementProps
    } = rest;

    return (
      <Switcher
        name={name}
        value={value}
        size={size}
        showLabel={true}
        labelOnText="On"
        labelOffText="Off"
        autoFocus={autoFocus}
        disabled={disabled || loading}
        onChange={onChange}
        {...elementProps}
      />
    );
  };

  const renderTextInput = () => {
    const {
      name,
      placeholder,
      type,
      value,
      minLength,
      maxLength,
      pattern,
      inputRef,

      autoComplete,
      immediateChange,

      startAdornment,
      endAdornment,

      autoFocus,
      disabled,
      loading,
      readOnly,
      required,
      labelPlacement,

      onChange,
      onKeyDown,
      onKeyUp,

      ...rest
    } = props;

    const {
      className,
      fullWidth,
      error,
      helperText,
      size,
      containerRef,
      label,
      labelComponent,
      clearable,
      indeterminate,
      numberComponentProps,
      onFocus,
      onBlur,
      baseClassName,
      ...elementProps
    } = rest;

    const showInputStartAdornment = Boolean(startAdornment);
    const showInputEndAdornment = Boolean(endAdornment);

    return (
      <div className="cr-form-control__input-container loading-wrapper">
        {showInputStartAdornment && (
          <div className="cr-form-control__input-start-adornment">
            {startAdornment}
          </div>
        )}

        <BaseTextInput
          ref={inputRef}
          className={classNames('cr-form-control__input', baseClassName)}
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          minLength={minLength}
          maxLength={maxLength}
          pattern={pattern}
          immediateChange={immediateChange}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          required={required}
          disabled={disabled || loading}
          readOnly={readOnly}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          {...elementProps}
        />

        {showInputEndAdornment && (
          <div className="cr-form-control__input-end-adornment">
            {endAdornment}
          </div>
        )}

        {loading && <LoadingSpinner />}
      </div>
    );
  };

  const handleFocus = (e) => {
    setFocused((state) => (state ? null : true));

    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  const handleBlur = (e) => {
    setFocused((state) => (state ? false : null));

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const {
    className,
    helperText,
    label,
    type,
    containerRef,
    size,

    labelComponent,

    disabled,
    loading,
    readOnly,
    required,
    error,
    fullWidth,
    labelPlacement,
  } = props;

  const control = getControl();
  const showLabel =
    isNil(label) === false && type !== FORM_CONTROL_TYPE.CHECKBOX;
  let component = labelComponent;

  if (!labelComponent) {
    if (
      type === FORM_CONTROL_TYPE.SELECT ||
      type === FORM_CONTROL_TYPE.SWITCHER
    ) {
      component = 'div';
    }
  }

  return (
    <div
      ref={containerRef}
      className={classNames(
        'cr-form-control',
        {
          [`cr-form-control--size-${size}`]: Boolean(size),
          'cr-form-control--focused': focused,
          'cr-form-control--disabled': disabled || loading,
          'cr-form-control--readonly': readOnly,
          'cr-form-control--required': required,
          'cr-form-control--error': error,
          'cr-form-control--full-width': fullWidth,
        },
        className,
      )}
    >
      {showLabel ? (
        <FormControlLabel
          label={label}
          control={control}
          labelPlacement={labelPlacement}
          component={component}
        />
      ) : (
        control
      )}

      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  );
};

export const FormControl = forwardRef((props, ref) => (
  <FormControlComponent {...defaultProps} {...props} containerRef={ref} />
));

FormControl.displayName = 'FormControl';
