import { httpClient } from '@utils';

const searchUsers = (name) => {
  return httpClient.get('/search/users', {
    params: {
      name,
    },
  });
};

const searchOffers = (name) => {
  return httpClient.get('/search/offers', {
    params: {
      name,
    },
  });
};

export { searchUsers, searchOffers };
