import { DotsIcon } from '@/icons';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export default function RoomOptionsMenu({
  onManage,
  onInvite,
  onLeave,
  onLog,
}) {
  return (
    <Menu>
      <div className="relative">
        <Menu.Button className="p-0 button-clear rounded-full">
          <DotsIcon className="fill-midnight-60" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 top-full mt-2 py-2 w-max origin-top-right rounded-lg bg-gray-10 shadow-lg focus:outline-none z-10">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-orange-10' : 'text-midnight-100'
                  } flex w-full items-center rounded-none px-4 py-1 text-sm`}
                  onClick={onManage}
                >
                  Manage Room
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-orange-10' : 'text-midnight-100'
                  } flex w-full items-center rounded-none px-4 py-1 text-sm`}
                  onClick={onInvite}
                >
                  Invite Participants
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-danger/10 text-danger' : 'text-danger'
                  } flex w-full items-center rounded-none px-4 py-1 text-sm`}
                  onClick={onLeave}
                >
                  Leave Room
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-orange-10' : 'text-midnight-100'
                  } flex w-full items-center rounded-none px-4 py-1 text-sm`}
                  onClick={onLog}
                >
                  Log Room Data
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </div>
    </Menu>
  );
}
