import { UsersListWrapper } from '@components';
import { SearchStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

const UsersResults = observer(() => {
  const navigate = useNavigate();

  const handleToInfoNavigate = (role, id) => {
    navigate(`/dashboard/${role}/${id}/info`);
  };

  const handleToProfileNavigate = (role, id) => {
    console.log('route: ', `/dashboard/${role}/${id}`);
    navigate(`/dashboard/${role}/${id}`);
  };

  return (
    <>
      {SearchStore.coaches.length > 0 && (
        <UsersListWrapper
          title="Coaches"
          users={SearchStore.coaches}
          toProfileNavigate={(id) => handleToProfileNavigate('coaches', id)}
          toInfoNavigate={(id) => handleToInfoNavigate('coaches', id)}
        />
      )}

      {SearchStore.otherUsers.length > 0 && (
        <UsersListWrapper
          title="Other Users"
          users={SearchStore.otherUsers}
          toInfoNavigate={(id) => handleToInfoNavigate('clients', id)}
        />
      )}
    </>
  );
});

export default UsersResults;
