import { AppContext } from '@/contexts';
import { CategoryIcon } from '@icons';
import classNames from 'classnames';
import { useContext } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';

export default function SessionsLayout() {
  const appContext = useContext(AppContext);

  const navigation = [
    {
      text: 'List',
      icon: <CategoryIcon className="fill-transparent stroke-current" />,
      path: 'list',
    },
    {
      text: 'Calendar',
      icon: <CategoryIcon className="fill-transparent stroke-current" />,
      path: 'calendar',
    },
    {
      text: 'Requests',
      icon: <CategoryIcon className="fill-transparent stroke-current" />,
      path: 'requests',
    },
  ];

  return (
    <div className="flex flex-col gap-7 px-12 py-7.5">
      <div className="flex gap-4 justify-between">
        <h1 className="text-4.5xl font-bold text-midnight-90">Sessions</h1>
        {appContext.isCoach && (
          <Link to="create" className="button-main button-m">
            New Session
          </Link>
        )}
      </div>

      <nav aria-labelledby="session-manager-navigation">
        <h2 id="session-manager-navigation" className="sr-only">
          Session navigation
        </h2>
        <ul className="relative inline-flex gap-9 items-center">
          <div className="absolute h-0.5 bottom-0 inset-x-0 bg-midnight-20 rounded-full"></div>

          {navigation.map(({ text, path, icon }) => (
            <li key={path}>
              <NavLink to={path} replace>
                {({ isActive }) => (
                  <div className="relative flex flex-col gap-3">
                    <div
                      className={classNames(
                        'flex gap-1.5 items-center cursor-pointer',
                        isActive ? 'text-midnight-100' : 'text-midnight-70',
                      )}
                      data-active={isActive}
                    >
                      {icon}
                      <span>{text}</span>
                    </div>
                    <div
                      className={classNames(
                        'h-0.5 w-full',
                        isActive ? 'bg-midnight-100' : 'bg-transparent',
                      )}
                    ></div>
                  </div>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>

      <Outlet />
    </div>
  );
}
