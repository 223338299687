import {
  LandingTemplate,
  ProgressButton,
  Radio,
  RadioGroup,
} from '@components';
import { SIZE } from '@constants';
import { ChevronRightIcon, SvgIcon } from '@icons';
import { UserStore } from '@stores';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ProfileTypeScene() {
  const settings = [
    {
      name: 'I am a Coach',
      value: 'coach',
      description:
        'Discover the different ways you can take your business to the next level.',
    },
    {
      name: 'I am looking for a Coach',
      value: 'client',
      description:
        'Discover the different ways you can take your life to the next level.',
    },
  ];
  const [selectedRole, setSelectedRole] = useState('client');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      UserStore.clearStorage();
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      navigate(selectedRole === 'coach' ? '/empowered' : '/dashboard');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = ({ target: { value } }) => {
    setSelectedRole(value);
  };

  return (
    <LandingTemplate src="/images/svg-select-role-registration.svg">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-center h-full max-w-lg mx-auto"
      >
        <div>
          <div>
            <img src="/images/logo_black.svg" className="h-8 mb-1" alt="logo" />
          </div>
          <h1 className="text-3xl text-midnight-100 font-bold my-4">
            Choose your path
          </h1>

          <RadioGroup
            className="flex flex-col mt-9"
            value={selectedRole}
            onChange={handleChange}
          >
            {settings.map((setting) => (
              <Radio
                key={setting.value}
                className={classNames(
                  'flex items-center last:mt-6 p-4 rounded-3xl bg-orange-10',
                  setting.value === selectedRole && 'border-2 border-orange-60',
                )}
                value={setting.value}
                label={
                  <div>
                    <p className="font-bold text-midnight-100 text-xl">
                      {setting.name}
                    </p>
                    <p className="mt-1.5 text-sm pr-24">
                      {setting.description}
                    </p>
                  </div>
                }
              />
            ))}
          </RadioGroup>

          <ProgressButton className="mt-12" type="submit" loading={loading}>
            Continue
            <SvgIcon icon={<ChevronRightIcon />} size={SIZE.S} />
          </ProgressButton>
        </div>
      </form>
    </LandingTemplate>
  );
}
