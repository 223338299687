import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken, { BearerToken } from '@utils/use-bearer';
import * as messengerAccess from '@utils/messengerAccess';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/auth`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function login({ email, password }) {
  const response = await instance.post('/login', { email, password });
  BearerToken.set(response.data.token);
  const matrixAccess = response.data.matrix;
  if (matrixAccess) {
    messengerAccess.save({
      userId: matrixAccess.user_id,
      deviceId: matrixAccess.device_id,
      accessToken: matrixAccess.access_token,
      refreshToken: matrixAccess.refresh_token,
    });
  } else {
    console.error('No matrix access received');
  }
  return response.data;
}

export async function logout() {
  const response = await instance.post('/logout');
  BearerToken.set(undefined);
  messengerAccess.clear();
  return response.data;
}

export async function register({ email, password, first_name, last_name }) {
  const response = await instance.post('/register', {
    email,
    password,
    first_name,
    last_name,
  });
  BearerToken.set(response.data.token);
  const matrixAccess = response.data.matrix;
  if (matrixAccess) {
    messengerAccess.save({
      userId: matrixAccess.user_id,
      deviceId: matrixAccess.device_id,
      accessToken: matrixAccess.access_token,
      refreshToken: matrixAccess.refresh_token,
    });
  } else {
    console.error('No matrix access received');
  }
  return response.data;
}

export async function refreshAccess() {
  const response = await instance.get('/refresh', { silent_refresh: false });
  BearerToken.set(response.data.token);
  return response.data;
}

export async function PasswordResetRequest({ email, returnPath }) {
  const response = await instance.post('/reset-password/request', {
    email,
    return_path: returnPath,
  });
  return response.data;
}

export async function PasswordResetConfirm({ password, resetToken }) {
  const response = await instance.post('/reset-password/confirm', {
    password,
    reset_token: resetToken,
  });
  return response.data;
}

export async function OtpRequest({ email }) {
  const response = await instance.post('/otp/send', { email });
  return response.data;
}

export async function OtpConfirm({ email, code }) {
  const response = await instance.post('/otp/confirm', {
    email,
    code,
  });
  return response.data;
}

export async function CheckEmail(email) {
  const response = await instance.get('/check-email', {
    params: { value: email },
  });
  return response.data;
}

export async function loginAs(userId) {
  if (process.env.NODE_ENV !== 'development') {
    throw new Error('Not allowed in production mode');
  }

  const response = await instance.get(`/dev/login-as/${userId}`, {
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  });
  BearerToken.set(response.data.token);
  return response.data;
}
