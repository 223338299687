import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/user/offers`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function list() {
  const response = await instance.get('');
  return response.data;
}

export async function create(formData) {
  const response = await instance.post('', formData);
  return response.data;
}

export async function get(offerId) {
  const response = await instance.get(`/${offerId}`, {
    validateStatus: (status) => [200, 404].includes(status),
  });
  return response.status === 200 ? response.data : null;
}

/**
 * @param {string} offerId
 * @param {FormData} data
 */
export async function update(offerId, data) {
  const response = await instance.patch(`/${offerId}`, data);
  return response.data;
}

export async function remove(offerId) {
  const response = await instance.delete(`/${offerId}`);
  return response.data;
}

export async function getSessionInfo(offerId) {
  const response = await instance.get(`/${offerId}/session-info`);
  return response.data;
}

/**
 * @param {string} offerId
 * @param {UpdateOfferSessionInfo} data
 */
export async function updateSessionInfo(offerId, data) {
  const response = await instance.patch(`/${offerId}/session-info`, data);
  return response.data;
}

export async function getContent(offerId) {
  const response = await instance.get(`/${offerId}/content`);
  return response.data;
}

/**
 * @param {string} offerId
 * @param {Array<File>} filesToUpload
 * @param {Array<string>} fileIds
 */
export async function setContent(offerId, filesToUpload = [], fileIds = []) {
  const fd = new FormData();
  filesToUpload.map((file) => fd.append('file', file));
  fileIds.map((id) => fd.append('file_id', id));
  const response = await instance.put(`/${offerId}/content`, fd);
  return response.data;
}

/**
 * @typedef {object} UpdateOfferSessionInfo
 * @property {number?} session_amount
 * @property {number?} session_capacity
 * @property {string?} session_duration
 */
