import { UserStoreV2 } from '@/stores';
import { FormControl, ProgressButton } from '@components';
import { FORM_CONTROL_TYPE, SIZE } from '@constants';
import { Dialog } from '@headlessui/react';
import { ChevronRightIcon, EnvelopeIcon, LockIcon, SvgIcon } from '@icons';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';

const validationSchema = object({
  email: string()
    .required('Email is required')
    .email('Please check your email'),
  password: string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
});

export default function InPlaceAuth({ open, onClose, title, onLogin }) {
  const [loading, setLoading] = useState(false);
  const userStore = UserStoreV2.useState();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email, password }) => {
      await handleSubmit({ email, password });
    },
  });

  const disabledForm = !(formik.isValid && formik.dirty);

  const handleSubmit = async ({ email, password }) => {
    setLoading(true);

    try {
      await userStore.login(email, password);
      onClose();
      if (onLogin instanceof Function) {
        onLogin();
      }
    } catch (error) {
      if (error.response?.status === 400) {
        toast.error('Incorrect email or password!');
      } else {
        toast.error('Login error. See console (F12)');
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black-500/80" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="flex flex-col justify-center gap-4 p-3 border border-midnight-40 bg-white rounded">
          {title && <Dialog.Title className="self-center font-semibold">{title}</Dialog.Title>}
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-3 h-full"
          >
            <FormControl
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={!!formik.errors.email}
              helperText={formik.errors.email}
              startAdornment={<SvgIcon icon={<EnvelopeIcon />} />}
            />

            <div className="flex flex-col">
              <FormControl
                id="password"
                name="password"
                type={FORM_CONTROL_TYPE.PASSWORD}
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={!!formik.errors.password}
                helperText={formik.errors.password}
                startAdornment={<SvgIcon icon={<LockIcon />} />}
              />
              <Link
                to="/auth/forgot_password"
                className="self-end text-xs text-sea-70 hover:underline"
              >
                Forgot Password?
              </Link>
            </div>

            <ProgressButton
              type="submit"
              loading={loading}
              disabled={disabledForm}
            >
              Sign in&nbsp;
              <SvgIcon size={SIZE.S} icon={<ChevronRightIcon />} />
            </ProgressButton>
          </form>

          <span className="flex justify-center font-normal text-sm text-midnight-90 mb-3">
            No account?
            <Link
              to="/auth/sign_up"
              className="text-sea-70 hover:underline font-bold ml-1"
            >
              Create one
            </Link>
          </span>
        </Dialog.Panel>
      </div>
    </Dialog >
  );
}
