import { Tooltip } from '@/components';
import StripeOnboardingCard, {
  useOnboardingStatus,
} from '@/components/StripeOnboardingCard';
import { THEME, TOOLTIP_PLACEMENT } from '@/constants';
import { RadioGroup } from '@headlessui/react';
import { CheckmarkIcon, InfoIcon } from '@icons';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CreateOfferChooseTypeScene() {
  const [offerType, setOfferType] = useState(null);
  const onboardingStatus = useOnboardingStatus();
  const navigate = useNavigate();

  return (
    onboardingStatus !== null &&
    (onboardingStatus !== 'complete' ? (
      <div className="flex flex-col gap-8 px-12 py-7.5">
        <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
          No Stripe Connect Account
        </h1>

        <p>
          Our platform uses Stripe to process payments. You need to setup a
          Stripe Connect account in order to go live and receive payouts.
        </p>

        <StripeOnboardingCard status={onboardingStatus} />
      </div>
    ) : (
      <div className="flex flex-col gap-8 px-12 py-7.5">
        <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
          Choose Offer Type
        </h1>

        <RadioGroup
          value={offerType}
          onChange={setOfferType}
          className="grid grid-cols-3 gap-8"
        >
          <RadioGroup.Label className="sr-only">Offer Type</RadioGroup.Label>
          {OfferTypeCards.map((offerType) => (
            <OfferTypeOption
              {...offerType}
              key={offerType.type}
              value={offerType.type}
            />
          ))}
        </RadioGroup>

        <button
          type="button"
          className="button-main button-m self-end"
          disabled={!offerType}
          onClick={() => navigate(offerType)}
        >
          Next
        </button>
      </div>
    ))
  );
}

function OfferTypeOption({ title, features, imgName, value }) {
  return (
    <RadioGroup.Option
      value={value}
      className={({ checked }) =>
        classNames(
          'flex flex-col py-6 px-5 border rounded-2.5xl bg-gray-10 text-midnight-100 focus:outline-none ring-primary ring-offset-1 focus:ring',
          checked ? 'border-orange-50' : 'border-transparent',
        )
      }
    >
      {({ checked }) => (
        <>
          <div className="flex items-center justify-between">
            <p className="text-mdd font-bold">{title}</p>
            <div
              className={classNames(
                'flex justify-center items-center w-5 h-5 rounded-full border',
                checked ? 'border-primary' : 'border-midnight-90',
              )}
            >
              {checked && (
                <div className="w-2 h-2 rounded-full bg-primary"></div>
              )}
            </div>
          </div>

          <img
            src={imgName}
            className="max-h-52 self-center"
            aria-hidden="true"
          />

          <div className="flex flex-col gap-3">
            {features.map((feature, featureIdx) => (
              <div key={featureIdx} className="flex flex-col gap-1 items-start">
                <p className="text-sm font-bold">{feature.title}</p>
                <ul className="flex flex-col">
                  {feature.list.map((item, itemIdx) => (
                    <li
                      key={itemIdx}
                      className="flex items-center gap-3 text-sm text-midnight-90"
                    >
                      <CheckmarkIcon
                        className="w-4 h-4 fill-midnight-60"
                        aria-hidden="true"
                      />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </>
      )}
    </RadioGroup.Option>
  );
}

const OfferTypeCards = [
  {
    title: 'Personal Offer',
    type: 'one-to-one',
    imgName: '/images/offer/personal.svg',
    features: [
      {
        title: 'Use Cases',
        list: ['Discovery Calls', '1-on-1 Packages', 'VIP Days'],
      },
    ],
  },
  {
    title: 'Group Offer',
    type: 'group',
    imgName: '/images/offer/group.svg',
    features: [
      {
        title: 'Use Cases',
        list: [
          'Workshops',
          'Challenges',
          'Masterminds',
          'Memberships',
          'Webinars',
          'Group Packages',
        ],
      },
    ],
  },
  {
    title: 'Digital Products',
    type: 'digital-content',
    imgName: '/images/offer/digital.svg',
    features: [
      {
        title: 'Use Cases',
        list: [
          <>
            Downloadable PDFs
            <Tooltip
              theme={THEME.WHITE}
              placement={TOOLTIP_PLACEMENT.BOTTOM}
              content="E-Books, Guides, Resource Lists, Checklists, Templates, Printable Journals, Digital Planners, Workbooks, and more..."
            >
              <InfoIcon className="fill-current w-5" />
            </Tooltip>
          </>,
          <>
            Online Courses{' '}
            <Tooltip
              theme={THEME.WHITE}
              placement={TOOLTIP_PLACEMENT.BOTTOM}
              content="Video Lectures, Practical Exercises, Drip Content, Self-Paced, Downloads"
            >
              <InfoIcon className="fill-current w-5" />
            </Tooltip>
          </>,
          <>
            Audio Downloads
            <Tooltip
              theme={THEME.WHITE}
              placement={TOOLTIP_PLACEMENT.BOTTOM}
              content="Audiobooks, Guided Meditations, Positive Affirmations, Guided Journaling Exercises, and more..."
            >
              <InfoIcon className="fill-current w-5" />
            </Tooltip>
          </>,
        ],
      },
    ],
  },
];
