import classNames from 'classnames';
import { DateTime } from 'luxon';
import RoomMemberEvent from './RoomMemberEvent';
import RoomMessageEvent from './RoomMessageEvent';

/**
 * @param {object} params
 * @param {import("matrix-js-sdk").EventTimeline} params.timeline
 */
export default function RoomTimeline({ timeline, className }) {
  const events = timeline.getEvents();

  const renderedEvents = events.map((event) => {
    const eventId = event.getId();
    const eventType = event.getType();
    switch (eventType) {
      case 'm.room.create':
        return (
          <li
            key={eventId}
            data-timestamp={event.localTimestamp}
            className="text-sm text-midnight-50 text-center"
          >
            the room has been created
          </li>
        );
      case 'm.room.message':
        return (
          <RoomMessageEvent
            key={eventId}
            data-timestamp={event.localTimestamp}
            event={event}
          />
        );
      case 'm.room.member': {
        return (
          <RoomMemberEvent
            key={eventId}
            data-timestamp={event.localTimestamp}
            event={event}
          />
        );
      }
      case 'm.room.name':
        return (
          <li
            key={eventId}
            data-timestamp={event.localTimestamp}
            className="text-sm text-midnight-50 text-center"
          >
            {event.sender.name} changed the room name to{' '}
            {event.getContent().name}
          </li>
        );
      case 'm.room.avatar':
        return (
          <li
            key={eventId}
            data-timestamp={event.localTimestamp}
            className="text-sm text-midnight-50 text-center"
          >
            {event.sender.name} {event.getContent().url ? 'changed' : 'removed'}{' '}
            the room avatar
          </li>
        );
      case 'm.room.encrypted':
        return (
          <li
            key={eventId}
            data-timestamp={event.localTimestamp}
            className="p-3 bg-midnight-10 text-midnight-50 text-sm rounded-xl rounded-bl-sm self-start"
          >
            Encrypted Message
          </li>
        );
      case 'm.room.power_levels':
      case 'm.room.join_rules':
      case 'm.room.history_visibility':
      case 'm.room.guest_access':
      case 'm.reaction':
        // Do not render anything
        return;
      default:
        return (
          <li
            key={eventId}
            data-timestamp={event.localTimestamp}
            className="text-sm text-midnight-50 text-center"
          >
            Unhandled event {eventType}
          </li>
        );
    }
  });

  const renderedEventsDividedByDate = renderedEvents.reduce(
    (agg, item, index, array) => {
      agg.push(item);
      const nextEvent = array[index + 1];

      // idk why but item may be undefined during timeline pagination
      if (item && nextEvent) {
        const dtNext = DateTime.fromMillis(nextEvent.props['data-timestamp']);
        const dtCurrent = DateTime.fromMillis(item.props['data-timestamp']);
        if (!dtCurrent.hasSame(dtNext, 'day')) {
          agg.push(
            <span
              key={dtNext.toMillis()}
              data-timestamp={item.localTimestamp}
              className="self-center my-2 px-4 text-xs bg-midnight-30 text-midnight-100 rounded-full text-center"
            >
              {dtNext.startOf('day').toLocaleString(DateTime.DATE_MED)}
            </span>,
          );
        }
      }
      return agg;
    },
    [],
  );

  return (
    <ol className={classNames('flex flex-col gap-1 max-w-lg', className)}>
      {renderedEventsDividedByDate}
    </ol>
  );
}
