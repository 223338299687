import {
  CoachProfileHeaderAndAvatar,
  CoachProfileRating,
  Share,
} from '@/components';
import { CoachService } from '@/services';
import { UserStoreV2 } from '@/stores';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { generateHTML } from '@tiptap/html';
import extensions from '@/components/RichTextEditor/extensions';
import { Link, useNavigate } from 'react-router-dom';

export function MyCoachProfileScene() {
  const [coachProfile, setCoachProfile] = useState(undefined);
  const userStore = UserStoreV2.useState();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const profile = await CoachService.get(userStore.state.user.id);
        if (!profile) {
          navigate('edit', { state: { no_profile: true } });
        } else {
          setCoachProfile(profile);
        }
      } catch (error) {
        toast.error('Unable to retrieve data. See console (F12)');
        console.error('Get coach profile error.', error);
      }
    }

    void fetchData();
  }, [userStore.state.user.id, navigate]);

  return coachProfile ? (
    <div className="py-7.5 px-12">
      <div className="flex flex-col gap-6 bg-gray-10 rounded-2.5xl px-7.5 py-6">
        <CoachProfileHeaderAndAvatar
          avatar={coachProfile.avatar}
          header={coachProfile.header}
          headerOffset={coachProfile.header_offset}
        />

        <div className="md:h-20">
          <div className="flex flex-col-reverse items-end xl:flex-row justify-between gap-2 max-md:pt-20 pl-64">
            <Share.ShareList
              url={`${location.origin}/coaches/${userStore.state.user.id}`}
              text={coachProfile.name}
            >
              <Share.ShareFacebook className="self-center" />
              <Share.ShareTwitter className="self-center" />
              <Share.ShareTelegram className="self-center" />
              <Share.ShareInstagram className="self-center" />
              <Share.ShareLinkedIn className="self-center" />
              <Share.CopyLink className="self-center" />
            </Share.ShareList>

            <div className="flex gap-2">
              <Link to="edit" className="button-outline button-s">
                Edit
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-8">
          <div>
            <h1 className="text-midnight-100 text-5xl font-bold">
              <span className="sr-only">Coach Profile of</span>
              {coachProfile.name}
            </h1>
            <CoachProfileRating value={coachProfile.rating} />
          </div>

          <div className="text-midnight-100">
            <h2 className="sr-only">About</h2>
            <div
              className="space-y-[0.75em]"
              dangerouslySetInnerHTML={{
                __html: generateHTML(coachProfile.about, extensions),
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  ) : coachProfile === null ? (
    <CoachNotExists />
  ) : null;
}

function CoachNotExists() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 p-7 h-full bg-midnight-10">
      TODO: REPLACE WITH CREATION
      <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
        Oops, this coach doesn't exist 🙄
      </h1>
      <img
        src="/images/svg-question-sign.svg"
        className="w-96"
        alt="Woman walking towards a question sign"
      />
    </div>
  );
}
