import { useState, useEffect, useCallback, forwardRef } from 'react';
import classNames from 'classnames';
import { useIsMounted } from '../../hooks/useIsMounted';
import { isPromise } from '@utils';
import { LoadingWrapper } from '../LoadingWrapper';
import { DButton } from '../DButton';
import { BUTTON_THEME, BUTTON_TYPE, SIZE } from '@constants';
import './styles.scss';

// interface Props extends ClassName {
//   type?: BUTTON_TYPE.BUTTON | BUTTON_TYPE.SUBMIT;
//   title?: string;
//   titlePreformatted?: boolean;
//   role?: WAI_ARIA_ROLE;
//   theme?: THEME;
//   size?: SIZE;
//   autoFocus?: boolean;
//   disabled?: boolean;
//   loading?: boolean;
//   unsetLoadingAfterPromise?: boolean;
//   onClick?: Callback<Promise<any> | any>;
// }

const ProgressButton = forwardRef(({
  className,
  children,
  type = BUTTON_TYPE.BUTTON,
  title,
  titlePreformatted,
  size = SIZE.M,
  role,
  theme = BUTTON_THEME.PRIMARY,
  autoFocus,
  disabled,
  iconComponent,
  loading: _loading,
  unsetLoadingAfterPromise = true,
  onClick,
  ...restProps
}, ref) => {
  const [loading, setLoading] = useState(_loading);
  const mountedRef = useIsMounted();

  useEffect(() => {
    setLoading(_loading);
  }, [_loading]);

  const handleClick = useCallback((e) => {
    if (!onClick) {
      return;
    }

    const callback = onClick(e);
    if (isPromise(callback)) {
      setLoading(true);

      callback
        .finally(() => {
          if (mountedRef.current && unsetLoadingAfterPromise) {
            setLoading(false);
          }
        });
    }
  }, [mountedRef, onClick, unsetLoadingAfterPromise]);

  return (
    <LoadingWrapper loading={loading}>
      <DButton
        ref={ref}
        className={classNames('progress-button', className)}
        theme={theme}
        size={size}
        type={type}
        role={role}
        title={title}
        titlePreformatted={titlePreformatted}
        iconComponent={iconComponent}
        autoFocus={autoFocus}
        disabled={disabled}
        onClick={handleClick}
        {...restProps}
      >
        {children}
      </DButton>
    </LoadingWrapper>
  );
});

ProgressButton.displayName = 'ProgressButton';

export default ProgressButton;
