import useControllable from '@/hooks/useControllable';
import classNames from 'classnames';
import CalendarMonth from './CalendarMonth';
import CalendarTimeControl from './CalendarTimeControl';
import { DateTime } from 'luxon';

export { CalendarMonth, CalendarTimeControl };

export default function Calendar({
  selected: _selected,
  active: _active,
  onChangeSelected: _onChangeSelected,
  onChangeActive: _onChangeActive,
  className,
  compact = false,
  metadata = {},
}) {
  const [active, onChangeActive] = useControllable(
    _active,
    _onChangeActive,
    _selected || DateTime.now(),
    'active',
  );
  const [selected, onChangeSelected] = useControllable(
    _selected,
    _onChangeSelected,
    DateTime.now(),
    'select',
  );

  return (
    <div
      className={classNames(
        'flex flex-col bg-midnight-10 rounded-2.5xl group',
        compact ? 'px-2 py-4 gap-2' : 'px-5 py-7.5 gap-10',
        className,
      )}
    >
      <CalendarTimeControl
        value={active}
        onChange={onChangeActive}
        scope="month"
        compact={compact}
      />
      <CalendarMonth
        selected={selected}
        active={active}
        onChangeSelected={onChangeSelected}
        onChangeActive={onChangeActive}
        compact={compact}
        metadata={metadata}
      />
    </div>
  );
}
