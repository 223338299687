import { AppContext } from '@/contexts';
import { BellIcon, CalendarIcon, PersonIcon } from '@icons';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

export function SettingsScene() {
  const appContext = useContext(AppContext);

  const navigation = useMemo(
    () => [
      {
        text: 'Schedule Management',
        path: 'schedule_manager',
        icon: <CalendarIcon className="fill-current" />,
        visible: appContext.isCoach,
      },
      {
        text: 'Personal Info',
        path: 'personal_info',
        icon: <PersonIcon className="fill-current" />,
        visible: true,
      },
      {
        text: 'Notifications',
        path: 'notifications_settings',
        icon: <BellIcon className="fill-current" />,
        visible: true,
      },
    ],
    [appContext],
  );

  return (
    <div className="py-7.5 px-12 flex flex-col gap-8">
      <div className="flex justify-between items-baseline">
        <h1 className="text-4.5xl font-bold text-midnight-90">Settings</h1>

        <nav aria-labelledby="settings-navigation">
          <h2 id="settings-navigation" className="sr-only">
            Settings navigation
          </h2>
          <ul className="relative inline-flex gap-9 items-center">
            <div className="absolute h-0.5 bottom-0 inset-x-0 bg-midnight-20 rounded-full"></div>

            {navigation.map(
              ({ text, path, icon, visible }) =>
                visible && (
                  <li key={path}>
                    <NavLink to={path} replace>
                      {({ isActive }) => (
                        <div className="relative flex flex-col gap-3">
                          <div
                            className={classNames(
                              'flex gap-1.5 items-center cursor-pointer',
                              isActive
                                ? 'text-midnight-100'
                                : 'text-midnight-70',
                            )}
                            data-active={isActive}
                          >
                            {icon}
                            <span>{text}</span>
                          </div>
                          <div
                            className={classNames(
                              'h-0.5 w-full',
                              isActive ? 'bg-midnight-100' : 'bg-transparent',
                            )}
                          ></div>
                        </div>
                      )}
                    </NavLink>
                  </li>
                )
            )}
          </ul>
        </nav>
      </div>

      <Outlet />
    </div>
  );
}
