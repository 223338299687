import FormControl from '../FormControl';
import { useFormik } from 'formik';
import { SearchIcon, SvgIcon } from '@icons';
import './SearchForm.scss';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

const validationSchema = object({
  q: string().required(),
});

const SearchForm = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: { q: '' },
    validationSchema,
    onSubmit: ({ q }) => {
      navigate(`search?q=${q}`);
      formik.resetForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl
        className="search-form-input lg:w-80"
        id="q"
        name="q"
        value={formik.values.q}
        onChange={formik.handleChange}
        immediateChange={true}
        placeholder="Search anything"
        startAdornment={<SvgIcon icon={<SearchIcon />} />}
      />
    </form>
  );
};

export default SearchForm;
