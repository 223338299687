import { Loader } from '@components';
import { UiStore } from '@stores';
import { httpClient } from '@utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NotificationsSettingsCard } from './components';

const PreferenceFeatures = [
  {
    type: 'system',
    title: 'Account updates',
  },
  {
    type: 'activity',
    title: 'Profile Activity',
  },
  {
    type: 'reminder',
    title: 'Session Reminders',
  },
];

export function NotificationsSettingsScene() {
  const [preferences, setPreferences] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    UiStore.setHideContentContainerBackground();
    setLoading(true);

    const load = async () => {
      try {
        const response = await httpClient.get('/notifications/preferences');
        setPreferences(response.data);
      } catch (error) {
        toast(error.message, { type: 'error' });
        navigate('/dashboard/settings');
      } finally {
        setLoading(false);
      }
    };

    void load();

    return () => {
      UiStore.setShowContentContainerBackground();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {PreferenceFeatures.map((preference) => (
            <NotificationsSettingsCard
              key={preference.type}
              title={preference.title}
              preferenceType={preference.type}
              preferencesValue={preferences[preference.type]}
            />
          ))}
        </>
      )}
    </>
  );
}
