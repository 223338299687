import { ContactInputStatic } from '@/components/ContactInput';
import RichTextEditor from '@/components/RichTextEditor';
import { AvatarInput, FormControl } from '@components';
import { FORM_CONTROL_TYPE } from '@constants';
import { useMemo } from 'react';
import { object, string } from 'yup';
import EditCoachProfileFormBanner from './EditCoachProfileFormBanner';

const validationSchema = object({
  name: string().required('Name field is required'),
  about: object().required('About field is required'),
  header: string().nullable(),
  avatar: string().nullable(),
});
export default function EditCoachProfileForm({ value, onChange }) {
  const validationError = useMemo(() => {
    try {
      validationSchema.validateSync(value);
      return null;
    } catch (error) {
      return error;
    }
  }, [value]);

  function handleChange(data) {
    onChange(Object.assign({}, value, data));
  }

  function handleContactChange(data) {
    const targetIndex = value.contacts.findIndex(
      (contact) => contact.type == data.type,
    );
    if (targetIndex === -1) {
      // Add contact
      onChange(
        Object.assign({}, value, {
          contacts: [...value.contacts, data],
        }),
      );
    } else if (data.value.trim()) {
      // Replace contact
      onChange(
        Object.assign({}, value, {
          contacts: value.contacts.map((contact) =>
            contact.type == data.type ? data : contact,
          ),
        }),
      );
    } else {
      // Delete contact
      const newContacts = [...value.contacts];
      newContacts.splice(targetIndex, 1);
      onChange(
        Object.assign({}, value, {
          contacts: newContacts,
        }),
      );
    }
  }

  return (
    <form className="flex flex-col gap-8">
      <div className="relative mb-[6.25rem]">
        <EditCoachProfileFormBanner
          banner={value.header}
          offset={value.header_offset}
          onChangeBanner={(value) => handleChange({ header: value })}
          onChangeOffset={(value) => handleChange({ header_offset: value })}
        />
        <AvatarInput
          value={value.avatar}
          onChange={(value) => handleChange({ avatar: value })}
          className="absolute bottom-0 left-10 translate-y-1/2 w-[12.5rem]"
        />
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col">
          <label htmlFor="coach-name-input" className="text-midnight-60">
            Coach name
          </label>
          <FormControl
            id="coach-name-input"
            name="name"
            value={value.name}
            onChange={(event) => handleChange({ name: event.target.value })}
            error={validationError?.path === 'name'}
            helperText={validationError?.errors[0]}
            type={FORM_CONTROL_TYPE.TEXT}
            className="w-max"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="coach-name-input" className="text-midnight-60">
            About
          </label>
          <RichTextEditor
            value={value.about}
            onChange={({ editor }) => handleChange({ about: editor.getJSON() })}
          />
        </div>
        <fieldset className="flex flex-col gap-2.5">
          <legend className="text-midnight-60">Contacts</legend>
          <ContactInputStatic
            type="whatsup"
            value={
              value.contacts.find((v) => v.type === 'whatsup')?.value || ''
            }
            onChange={(event) =>
              handleContactChange({
                type: 'whatsup',
                value: event.target.value,
              })
            }
            placeholder="+1 (555) 987-6543"
          />
          <ContactInputStatic
            type="messenger"
            value={
              value.contacts.find((v) => v.type === 'messenger')?.value || ''
            }
            onChange={(event) =>
              handleContactChange({
                type: 'messenger',
                value: event.target.value,
              })
            }
            placeholder="username"
          />
          <ContactInputStatic
            type="telegram"
            value={
              value.contacts.find((v) => v.type === 'telegram')?.value || ''
            }
            onChange={(event) =>
              handleContactChange({
                type: 'telegram',
                value: event.target.value,
              })
            }
            placeholder="username"
          />
        </fieldset>
      </div>
    </form>
  );
}
