import LandingLeftPanel from '../LandingLeftPanel';
import WideScreenPageContainer from '../../WideScreenPageContainer';

const LandingTemplate = ({ src, children }) => {
  return (
    <WideScreenPageContainer>
      <div className="flex justify-center">
        <LandingLeftPanel src={src} />
        <div className="2 md:px-10 lg:px-20 xl:px-3 p-4 min-h-screen w-full md:w-1/2">
          {children}
        </div>
      </div>
    </WideScreenPageContainer>
  );
};

export default LandingTemplate;
