import { Duration } from 'luxon';
import { createContext, useState } from 'react';

/**
 * @param {object} params
 * @param {string?} params.id
 * @param {OfferType} params.offerType
 * @param {OfferCommonInfo} params.commonInfo
 * @param {OfferContent} params.content
 * @param {number} params.price
 * @param {number} params.sessionAmount
 * @param {string} params.sessionDuration
 * @param {number} params.sessionCapacity
 * @param {boolean} params.allowRequests
 * @param {boolean} params.enableDiscoveryCall
 * @param {string?} params.discoveryCallSchedule
 */
export function useOfferContextData({
  id: _id,
  offerType: _offerType,
  commonInfo: _commonInfo,
  content: _content = [],
  price: _price,
  sessionAmount: _sessionAmount,
  sessionDuration: _sessionDuration,
  sessionCapacity: _sessionCapacity,
  allowRequests: _allowRequests,
  sessionRequestsSchedule: _sessionRequestsSchedule,
  enableDiscoveryCall: _enableDiscoveryCall,
  discoveryCallSchedule: _discoveryCallSchedule,
} = {}) {
  const [id, setId] = useState(_id);
  const [offerType, setOfferType] = useState(_offerType);
  const [commonInfo, setCommonInfo] = useState(
    Object.assign(
      {
        title: '',
        shortDescription: '',
        longDescription: '',
        contract: null,
        cover: null,
        coverOffset: null,
      },
      _commonInfo,
    ),
  );
  const [content, setContent] = useState(_content);
  const [price, setPrice] = useState(_price);
  const [sessionAmount, setSessionAmount] = useState(_sessionAmount);
  const [sessionDuration, setSessionDuration] = useState(_sessionDuration);
  const [sessionCapacity, setSessionCapacity] = useState(_sessionCapacity);
  const [allowRequests, setAllowRequests] = useState(_allowRequests);
  const [sessionRequestsSchedule, setSessionRequestsSchedule] = useState(
    _sessionRequestsSchedule,
  );
  const [enableDiscoveryCall, setEnableDiscoveryCall] =
    useState(_enableDiscoveryCall);
  const [discoveryCallSchedule, setDiscoveryCallSchedule] = useState(
    _discoveryCallSchedule,
  );

  /**
   * Initialize main info using data from API
   * @param {OfferFromAPI} offer
   */
  function fromApi(offer) {
    setId(offer.id);
    setOfferType(offer.type);
    setCommonInfo({
      title: offer.name,
      shortDescription: offer.short_description,
      longDescription: offer.long_description,
      contract: offer.contract,
      cover: offer.cover,
      coverOffset: offer.cover_offset,
    });
    setPrice(offer.payment_amount);
    setAllowRequests(offer.allow_session_request);
    setSessionRequestsSchedule(offer.session_request_schedule_id);
    setEnableDiscoveryCall(offer.enable_discovery_call);
    setDiscoveryCallSchedule(offer.discovery_call_schedule_id);
  }
  /**
   * Initialize content using data from API
   * @param {Array<OfferContentItem>} content
   */
  function fromApiContent(content) {
    setContent(content);
  }
  /**
   * Initialize session info using data from API
   */
  function fromApiSessionInfo(sessionInfo) {
    setSessionAmount(sessionInfo.session_amount);
    setSessionDuration(
      Duration.fromISO(sessionInfo.session_duration).as('minutes'),
    );
    setSessionCapacity(sessionInfo.session_capacity);
  }

  function toFormDataInfo(data = new FormData()) {
    data.set('type', offerType);
    data.set('name', commonInfo.title);
    data.set('short_description', commonInfo.shortDescription);
    data.set('long_description', JSON.stringify(commonInfo.longDescription));
    data.set('payment_amount', price);
    if (commonInfo.contract instanceof File) {
      data.set('contract', commonInfo.contract);
    } else if (commonInfo.contract === null) {
      data.set('contract', null);
    }
    if (commonInfo.cover instanceof File) {
      data.set('cover', commonInfo.cover);
    } else if (commonInfo.cover === null) {
      data.set('cover', null);
    }
    commonInfo.coverOffset && data.set('cover_offset', commonInfo.coverOffset);
    data.set('allow_session_request', allowRequests);
    data.set('session_request_schedule_id', sessionRequestsSchedule);
    data.set('enable_discovery_call', enableDiscoveryCall);
    data.set('discovery_call_schedule_id', discoveryCallSchedule);
    return data;
  }
  function toFormDataContent(data = new FormData()) {
    for (const file of content) {
      data.append('content', file);
    }
    return data;
  }

  function toObjectSessionInfo() {
    return {
      session_amount: sessionAmount,
      session_duration: Duration.fromObject({
        minutes: sessionDuration,
      }).toISO(),
      session_capacity: offerType === 'group' ? sessionCapacity : 1,
    };
  }

  function toFormDataSessionInfo(data = new FormData()) {
    data.set('session_info', JSON.stringify(toObjectSessionInfo()));
    return data;
  }

  function toFormData(data = new FormData()) {
    toFormDataInfo(data);
    toFormDataContent(data);
    // Do not set session_info for digital content type
    offerType !== 'digital-content' && toFormDataSessionInfo(data);
    return data;
  }

  return {
    id,

    offerType,
    setOfferType,

    commonInfo,
    setCommonInfo,

    content,
    setContent,

    price,
    setPrice,

    sessionAmount,
    setSessionAmount,
    sessionDuration,
    setSessionDuration,
    sessionCapacity,
    setSessionCapacity,

    allowRequests,
    setAllowRequests,
    sessionRequestsSchedule,
    setSessionRequestsSchedule,

    enableDiscoveryCall,
    setEnableDiscoveryCall,
    discoveryCallSchedule,
    setDiscoveryCallSchedule,

    fromApi,
    fromApiContent,
    fromApiSessionInfo,
    toFormData,
    toFormDataInfo,
    toFormDataContent,
    toFormDataSessionInfo,
    toObjectSessionInfo,
  };
}

/** @type {React.Context<ReturnType<typeof useOfferContextData>>} */
export const OfferContext = createContext();

/**
 * @typedef {'one-to-one' | 'group' | 'digital-content'} OfferType
 */
/**
 * @typedef {object} OfferCommonInfo
 * @property {string} title
 * @property {string} shortDescription
 * @property {string} longDescription
 * @property {(string | File)?} contract
 * @property {(string | File)?} cover
 * @property {number?} coverOffset
 */
/**
 * @typedef {Array<OfferContentItem | File>} OfferContent
 */
/**
 * @typedef {object} OfferContentItem
 * @property {string} id
 * @property {string} owner_id
 * @property {string} name
 * @property {string} type
 * @property {number} size
 */

/**
 * @typedef {object} OfferFromAPI
 * @property {boolean} enabled
 * @property {string} id
 * @property {string} coach_id
 * @property {OfferType} type
 * @property {string} name
 * @property {string} short_description
 * @property {string} long_description
 * @property {string} cover
 * @property {number} cover_offset
 * @property {string} contract
 * @property {number} payment_amount
 * @property {boolean} allow_session_request
 * @property {string?} session_request_schedule_id
 * @property {boolean} enable_discovery_call
 * @property {string?} discovery_call_schedule_id
 */
