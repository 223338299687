import { makeAutoObservable } from 'mobx';
import { getChats } from '@services';

class MessagesStore {
  constructor() {
    this.chats = [];
    this.currentChat = null;
    makeAutoObservable(this);
  }

  setChats(chats) {
    this.chats = chats;
  }

  async getChatsList(lastMessage = true) {
    const { data } = await getChats(lastMessage);
    this.setChats(data);
  }
}

export default new MessagesStore();
