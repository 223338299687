import { Children, cloneElement, Fragment, isValidElement } from 'react';
import classNames from 'classnames';
import { LoadingSpinner } from './LoadingSpinner';
import './styles.scss';

const ELEMENT_TYPE = {
  INPUT: 'input',
  TEXTAREA: 'textarea',
};

export const LoadingWrapper = ({
  children,
  loading = false,
}) => {
  if (
    Children.count(children) > 1
  ) {
    return (
      <div className="loading-wrapper">
        {children}

        {loading && <LoadingSpinner/>}
      </div>
    );
  }

  if (!isValidElement(children)) {
    return null;
  }

  const disabled = children.props.disabled || loading;

  if (children.type === ELEMENT_TYPE.INPUT || children.type === ELEMENT_TYPE.TEXTAREA) {
    return (
      <div className="loading-wrapper">
        {cloneElement(children, { disabled })}

        {loading && <LoadingSpinner/>}
      </div>
    );
  }

  return cloneElement(children, {
    className: classNames('loading-wrapper', children.props.className),
    children: (
      <Fragment>
        {children.props.children}

        {loading && <LoadingSpinner/>}
      </Fragment>
    ),
    disabled,
  });
};
