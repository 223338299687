import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function getCurrent() {
  const response = await instance.get('/user/subscription', {
    validateStatus: (number) => [200, 404].includes(number),
  });

  return response.status === 200 ? response.data : null;
}
