import { SvgIcon, PaperclipIcon } from '@icons';
import { SIZE } from '@constants';
import { useRef } from 'react';

const ChatInputPaperclip = ({ onFileAttach }) => {
  const ref = useRef(null);

  const onKeyDown = (event) => {
    console.log(event);
  };

  const onInputChange = (event) => {
    if (event.target.files[0]) {
      onFileAttach(event.target.files[0]);
    }
  };

  const onPaperclipClick = () => {
    ref.current.click();
  };

  return (
    <div className="flex flex-col justify-center">
      <input
        ref={ref}
        onChange={onInputChange}
        type="file"
        className="hidden"
        onKeyDown={onKeyDown}
      />
      <button onClick={onPaperclipClick}>
        <SvgIcon icon={<PaperclipIcon />} size={SIZE.M} />
      </button>
    </div>
  );
};

export default ChatInputPaperclip;
