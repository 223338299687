import {
  SessionInfoDescription,
  SessionInfoFiles,
  SessionInfoTasks,
} from '@/components/SessionInfo';
import { AppContext } from '@/contexts';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import { EditIcon, PlusIcon, TrashIcon } from '@/icons';
import { SessionInfoService } from '@services';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function SessionInfoSection({ sessionId }) {
  const appContext = useContext(AppContext);
  const [sessionInfo, setSessionInfo] = useState(null);
  const { element: ConfirmationDialog, ask: askConfirmation } =
    useConfirmationDialog();

  const editable = useMemo(() => appContext.isCoach, [appContext]);

  async function deleteInfo() {
    const confirm = await askConfirmation(
      'Are you sure you want to delete the sesson info?',
    );
    if (!confirm) return;

    try {
      await SessionInfoService.remove(sessionId);
      setSessionInfo(null);
    } catch (error) {
      toast.error('Delete Info error. See console (F12)');
      console.error(error);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const info = await SessionInfoService.get(sessionId);
        setSessionInfo(info);
      } catch (error) {
        toast.error('Session Info retrieving error. See console (F12)');
        console.error(error);
      }
    }
    fetchData();
  }, [sessionId]);

  return sessionInfo ? (
    <div className="flex flex-col gap-8 px-5 py-6 rounded-2.5xl bg-gray-10">
      <div className="flex justify-between">
        <h2 className="text-2xl font-bold text-midnight-100">Description</h2>

        {editable && (
          <div className="flex items-center gap-2.5">
            <Link
              to="session-info"
              className="button-ghost button-s flex gap-1 items-center"
            >
              <span>Edit</span>
              <EditIcon
                className="w-5 h-5 fill-midnight-60"
                aria-hidden="true"
              />
            </Link>
            <button
              type="button"
              className="button-ghost button-s button-icon-rect flex gap-1 items-center"
              onClick={deleteInfo}
            >
              <span className="sr-only">Delete Info</span>
              <TrashIcon
                className="w-5 h-5 fill-midnight-60"
                aria-hidden="true"
              />
            </button>
          </div>
        )}
      </div>

      <SessionInfoDescription text={sessionInfo.description} />
      {sessionInfo.files.length > 0 && (
        <SessionInfoFiles files={sessionInfo.files} />
      )}
      {sessionInfo.tasks.length > 0 && (
        <SessionInfoTasks tasks={sessionInfo.tasks} />
      )}

      <ConfirmationDialog />
    </div>
  ) : (
    sessionInfo === null && editable && (
      <Link
        to="session-info"
        type="button"
        className="flex items-center justify-center gap-4 h-28 p-5 rounded-2.5xl border border-dashed border-orange-50 text-orange-50 focus:border-orange-70 focus:text-orange-70 hover:border-orange-40 hover:text-orange-40"
      >
        <PlusIcon className="fill-current" />
        <span className="font-bold text-2xl">Add Description</span>
      </Link>
    )
  );
}
