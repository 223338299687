import { AppContext } from '@/contexts';
import { MenuIcon } from '@icons';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

export default function Drawer({ show, onShowChange, children }) {
  const appContext = useContext(AppContext);

  return (
    show && (
      <div className="flex flex-col flex-shrink-0 left-0 right-0 top-0 h-screen lg:w-80 bg-midnight-100 lg:rounded-r-4xl fixed lg:sticky z-40">
        <div className="flex justify-between px-6 py-3 border-b border-midnight-80 h-16">
          <Link
            className="cursor-pointer align-bottom"
            to={`/dashboard/${appContext.isCoach ? 'coach' : 'client'}`}
          >
            <img src="/images/logo.svg" className="h-full" aria-hidden="true" />
            <span className="sr-only">Drreamz Homepage</span>
          </Link>

          <button
            onClick={onShowChange}
            className="p-5 inline-flex text-midnight-60 hover:button-clear hover:p-5"
          >
            <MenuIcon aria-hidden="true" className="fill-current" />
            <span className="sr-only">
              {show ? 'Hide Sidebar' : 'Show Sidebar'}
            </span>
          </button>
        </div>

        <nav
          className="py-5 flex-1 flex flex-col grow overflow-y-auto"
          aria-label="Main navigation"
        >
          <ul className="px-2 flex flex-col grow gap-5">{children}</ul>
        </nav>

        <div className="flex items-baseline gap-4 border-t mt-auto py-5 border-midnight-80 mx-4 group">
          <span className="text-midnight-10 md:text-sm text-xs">
            &copy; Drreamz LLC 2023
          </span>

          <span className="text-midnight-70 md:text-sm text-xs opacity-0 group-hover:opacity-100">
            v{process.env.REACT_APP_VERSION}
          </span>
        </div>
      </div>
    )
  );
}
