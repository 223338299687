import { useSyncExternalStore } from 'react';
import { NotificationsService } from '@services';
import { pusher } from '@utils';
import { snapshot as getUserStore, subscribe as onUserStoreUpdate } from './UserStoreV2';

class NotificationsStore {
  /** @type {NotificationsStore} */
  static instance = null;
  /** @type {Promise<any>} */
  static _initialization = null;
  /** @type {Promise<any>} */
  static _loading = null;
  static _pusherChannel = null;
  static _targetUser = undefined;
  static _removeUserStoreListner = null;

  static update(state) {
    NotificationsStore.instance = new NotificationsStore(state);
    // Trigger React synchronization
    window.dispatchEvent(new Event('notification-store-update'));
  }

  constructor({
    notifications = [],
    newNotifications = [],
    initialized = false,
  } = {}) {
    this.state = {
      notifications,
      newNotifications,
      initialized,
    };
  }

  get isInitialized() {
    return this.state.initialized;
  }

  get isSubscribed() {
    return NotificationsStore._pusherChannel !== null;
  }

  async subscribe() {
    if (!NotificationsStore._pusherChannel) {
      this._targetUser = getUserStore().state.user.id;
      if (!this._targetUser) return;

      const channel = pusher.subscribe(this._targetUser);

      channel.bind('pusher:subscription_succeeded', () => {
        console.log('Notification subscription succeeded');
      });
      channel.bind('pusher:subscription_error', (data) => {
        console.error('Notification subscription error', data);
      });

      // Common channel event handler
      const newNotificationHandler = async (notification) => {
        console.log('Got new notification', notification);
        const notifications = [...this.notifications, notification];
        const newNotifications = [...this.newNotifications, notification];

        NotificationsStore.update({
          notifications,
          newNotifications,
          initialized: true,
        });
      };

      // Bind the handler for all categories
      for (const category of ['system', 'activity', 'reminder']) {
        channel.bind(category, newNotificationHandler);
      }

      NotificationsStore._pusherChannel = channel;

      NotificationsStore._removeUserListner = onUserStoreUpdate(() => {
        if (this._targetUser !== undefined) {
          const userStore = getUserStore();
          if (userStore.state.user.id === undefined) {
            // Unsubscribe on logout
            this.unsubscribe();
            NotificationsStore.update({
              initialized: false,
            });
          } else if (userStore.state.user.id !== this._targetUser) {
            // If the target user changed - resubscribe on new user
            this.unsubscribe();
            this.subscribe();
          }
        }
      });
    }
  }

  unsubscribe() {
    if (NotificationsStore._pusherChannel) {
      NotificationsStore._pusherChannel.unbind_all();
      NotificationsStore._pusherChannel.unsubscribe();
      NotificationsStore._pusherChannel = null;
      NotificationsStore._removeUserListner();
      console.log('Notification subscription canceled');
    }
  }

  /**
   * Load the data. Calling this method will override the current state.
   * Use this method only if you want perform to force an update.
   *
   * It's guaranteed that there's only one loading process at a time.
   * You can await the method to wait until the loading is complete.
   * @returns {Promise<void>}
   */
  async load() {
    if (NotificationsStore._loading) return NotificationsStore._loading;

    async function fetch() {
      try {
        const notifications = await NotificationsService.list();
        const newNotifications = await NotificationsService.listNew();
        NotificationsStore.update({
          notifications,
          newNotifications,
          initialized: true,
        });
      } catch (error) {
        NotificationsStore.update({ initialized: true });
      }
    }

    NotificationsStore._loading = fetch();
    await NotificationsStore._loading;
    NotificationsStore._loading = null;
  }

  clear() {
    NotificationsStore.update({
      initialized: true,
    });
  }

  /**
   * Use this method to initialize the store state.
   * It's safe for multiple calls, the loading is triggered only once.
   *
   * You can await the method to wait until the initialization is complete.
   * @returns {Promise<void>}
   */
  async init() {
    if (!this.isInitialized && !NotificationsStore._initialization) {
      NotificationsStore._initialization = Promise.all([
        this.load(),
        this.subscribe(),
      ]);
      await NotificationsStore._initialization;
      NotificationsStore._initialization = null;
    }
  }
}

// Create first store instance
NotificationsStore.instance = new NotificationsStore();

function subscribe(listener) {
  window.addEventListener('notification-store-update', listener);
  return () =>
    window.removeEventListener('notification-store-update', listener);
}
function snapshot() {
  return NotificationsStore.instance;
}

export function useState(options = { noInit: false }) {
  if (!options.noInit) NotificationsStore.instance.init();
  return useSyncExternalStore(subscribe, snapshot);
}
