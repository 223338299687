
export default function CoachProfileHeader({ url, offset = 0 }) {
  return url ? (
    <div
      style={{
        backgroundImage: `url('${url}')`,
        backgroundPositionY: offset === 0 ? 'center' : offset,
        backgroundPositionX: '50%',
        backgroundSize: '1920px',
      }}
      className="rounded-xl w-full h-[300px] object-cover bg-midnight-20 bg-no-repeat"
    />
  ) : (
    <div className="rounded-xl w-full h-[300px] bg-midnight-20"></div>
  );
}
