import { NotificationItem } from '../NotificationItem/NotificationItem';

const Title = ({ title }) => (
  <div className="text-sm text-midnight-60">{title}</div>
);

export const NotificationsList = ({ notifications }) => {
  return (
    <>
      {Boolean(notifications.today) && (
        <>
          <Title title="Today" />

          {notifications.today.reverse().map((notificationItem) => (
            <NotificationItem
              key={notificationItem.id}
              notification={notificationItem}
            />
          ))}
        </>
      )}

      {Boolean(notifications.yesterday) && (
        <>
          <Title title="Yesterday" />

          {notifications.yesterday.reverse().map((notificationItem) => (
            <NotificationItem
              key={notificationItem.id}
              notification={notificationItem}
            />
          ))}
        </>
      )}

      {Boolean(notifications.past) && (
        <>
          <Title title="Past" />

          {notifications.past.reverse().map((notificationItem) => (
            <NotificationItem
              key={notificationItem.id}
              notification={notificationItem}
            />
          ))}
        </>
      )}
    </>
  );
};
