import { OfferContext, useOfferContextData } from '@/contexts';
import useConfirmationDialog from '@/hooks/useConfirmationDialog';
import { CategoryIcon } from '@/icons';
import { MyOfferService, UploadService } from '@/services';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

const navigation = [
  {
    text: 'Main Info',
    path: 'info',
    display: true,
  },
  {
    text: 'Content',
    path: 'content',
    display: true,
  },
  {
    text: 'Session Info',
    path: 'session-info',
    display: ['one-to-one', 'group'],
  },
  {
    text: 'Session Request',
    path: 'session-requests',
    display: ['one-to-one'],
  },
  {
    text: 'Discovery Call',
    path: 'discovery-call',
    display: ['one-to-one', 'group'],
  },
];

export default function EditOfferScene() {
  const { offerId } = useParams();
  const offerContext = useOfferContextData();
  const [noOffer, setNoOffer] = useState(undefined);
  const navigate = useNavigate();

  const { element: ConfirmationDialog, ask: askConfirmation } =
    useConfirmationDialog();

  async function updateOffer() {
    try {
      console.log('Updating offer common info');
      const data = await MyOfferService.update(
        offerId,
        offerContext.toFormDataInfo(),
      );
      offerContext.fromApi(data);
    } catch (error) {
      toast.error('Unable to update the offer. See conslole (F12)');
      console.error('Update offer error', error);
      return;
    }

    // Update content if has changes
    if (offerContext.content.some((i) => i.toDelete || i instanceof File)) {
      try {
        console.log('Updating offer content');
        const data = await MyOfferService.setContent(
          offerId,
          offerContext.content.filter((item) => item instanceof File),
          offerContext.content.reduce((agg, item) => {
            if (!item.toDelete && !(item instanceof File)) agg.push(item.id);
            return agg;
          }, []),
        );
        offerContext.fromApiContent(data);
      } catch (error) {
        toast.error('Unable to update offer content. See conslole (F12)');
        console.error('Update offer content error', error);
        return;
      }
    }

    if (
      offerContext.offerType === 'one-to-one' ||
      offerContext.offerType === 'group'
    ) {
      // Update session info
      try {
        console.log('Updating session info');
        const data = await MyOfferService.updateSessionInfo(
          offerId,
          offerContext.toObjectSessionInfo(),
        );
        offerContext.fromApiSessionInfo(data);
      } catch (error) {
        toast.error('Unable to update session info. See conslole (F12)');
        console.error('Update session info error', error);
        return;
      }
    }
    toast.success('Offer has been updated');
  }

  async function deleteOffer() {
    const confirm = await askConfirmation(
      'Are you sure you want to delete this offer?',
    );
    if (!confirm) return;

    try {
      await MyOfferService.remove(offerId);
      toast.success('Offer has been deleted');
      navigate('..'); // Navigate to list of my offers
    } catch (error) {
      toast.error('Unable to delete the offer. See conslole (F12)');
      console.error('Delete offer error', error);
    }
  }

  // Load common offer info
  useEffect(() => {
    async function fetchData() {
      console.log('Fetch Offer');
      try {
        setNoOffer(undefined);
        const offerData = await MyOfferService.get(offerId);
        if (offerData) {
          // Replace contract URL with file info
          if (offerData.contract) {
            offerData.contract = await UploadService.get(
              offerData.contract.split('/').at(-1),
            );
          }
          offerContext.fromApi(offerData);
        }
        setNoOffer(offerData === null);
      } catch (error) {
        toast.error('Unable to get the offer. See conslole (F12)');
        console.error('Get offer error', error);
      }
    }
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  if (noOffer === true) return <NoOffer />;

  return (
    <OfferContext.Provider value={offerContext}>
      {/* Show when data is loaded */}
      {offerContext.id && (
        <div className="flex flex-col gap-7 px-12 py-7.5 h-full">
          <div className="flex gap-4 justify-between">
            <h1 className="text-4.5xl font-bold text-midnight-90">
              Edit Offer
            </h1>

            <OfferNavigation />
          </div>

          <Outlet />

          <div className="flex grow items-end justify-between gap-6">
            <button
              type="button"
              className="button-danger button-m"
              onClick={deleteOffer}
            >
              Delete Offer
            </button>

            <div className="flex gap-6">
              <Link to=".." className="button-outline button-m">
                Back
              </Link>

              <button
                type="button"
                className="button-main button-m"
                onClick={updateOffer}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      <ConfirmationDialog />
    </OfferContext.Provider>
  );
}

function OfferNavigation() {
  const context = useContext(OfferContext);

  function checkDisplay(display) {
    switch (display.constructor) {
      case Boolean:
        return display;
      case Array:
        return display.includes(context.offerType);
      default:
        return false;
    }
  }

  return (
    <nav aria-labelledby="offer-navigation">
      <h2 id="offer-navigation" className="sr-only">
        Offer navigation
      </h2>

      <ul className="relative inline-flex gap-9 items-center">
        <div className="absolute h-0.5 bottom-0 inset-x-0 bg-midnight-20 rounded-full"></div>

        {navigation.map(
          ({ text, path, display }) =>
            checkDisplay(display) && (
              <li key={path}>
                <NavLink to={path} replace>
                  {({ isActive }) => (
                    <div className="relative flex flex-col gap-3">
                      <div
                        className={classNames(
                          'flex gap-1.5 items-center cursor-pointer',
                          isActive ? 'text-midnight-100' : 'text-midnight-70',
                        )}
                        data-active={isActive}
                      >
                        <CategoryIcon className="fill-transparent stroke-current" />
                        <span>{text}</span>
                      </div>
                      <div
                        className={classNames(
                          'h-0.5 w-full',
                          isActive ? 'bg-midnight-100' : 'bg-transparent',
                        )}
                      ></div>
                    </div>
                  )}
                </NavLink>
              </li>
            ),
        )}
      </ul>
    </nav>
  );
}

function NoOffer() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 p-7 h-full bg-midnight-10">
      <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
        Oops, this offer doesn't exist 🙄
      </h1>

      <img
        src="/images/svg-question-sign.svg"
        className="w-96"
        alt="Woman walking towards a question sign"
      />
    </div>
  );
}
