import DropFileArea from '@/components/DropFileArea';
import MovableImage from '@/components/MovableImage';
import RichTextEditor from '@/components/RichTextEditor';
import { OfferContext } from '@/contexts';
import { TrashIcon } from '@/icons';
import { humanFileSize } from '@/utils';
import { useContext, useMemo } from 'react';

export default function OfferCommonInfoForm() {
  const context = useContext(OfferContext);

  function changeInfo(data) {
    context.setCommonInfo(Object.assign({}, context.commonInfo, data));
  }

  const coverPreviewURL = useMemo(
    () =>
      context.commonInfo.cover && context.commonInfo.cover instanceof File
        ? URL.createObjectURL(context.commonInfo.cover)
        : context.commonInfo.cover,
    [context.commonInfo.cover],
  );

  return (
    <>
      <div className="flex flex-col gap-8 p-7 bg-gray-10 rounded-2.5xl">
        <label
          htmlFor="offer-title-input"
          className="text-xl leading-normal text-midnight-100 font-bold"
        >
          Title
          <span className="text-primary">*</span>
        </label>
        <input
          id="offer-title-input"
          type="text"
          value={context.commonInfo.title}
          required
          placeholder="Enter Offer Title"
          className="input-main input-m input-custom-invalid"
          onChange={(event) => changeInfo({ title: event.target.value })}
        />

        <p className="text-xl leading-normal text-midnight-100 font-bold">
          Offering Description
        </p>
        <div className="flex flex-col gap-4">
          <label htmlFor="offer-short-description-input" className="flex gap-2">
            <span className="text-midnight-100">
              Short Description<span className="text-primary">*</span>
            </span>
            <span className="text-midnight-60">(200 characters max)</span>
          </label>
          <textarea
            id="offer-short-description-input"
            value={context.commonInfo.shortDescription}
            required
            placeholder="Displayed on the offer card"
            className="input-main input-m input-custom-invalid"
            maxLength="200"
            onChange={(event) =>
              changeInfo({ shortDescription: event.target.value })
            }
          />
        </div>

        <div className="flex flex-col gap-4">
          <p className="flex gap-2">
            <span className="text-midnight-100">
              Long Description<span className="text-primary">*</span>
            </span>
            <span className="text-midnight-60">(Unlimited characters)</span>
          </p>
          <RichTextEditor
            value={context.commonInfo.longDescription}
            onChange={({ editor }) => changeInfo({ longDescription: editor.getJSON() })}
          />
        </div>
      </div>

      <div className="flex flex-col gap-8 p-7 bg-gray-10 rounded-2.5xl">
        <p className="text-xl leading-normal text-midnight-100 font-bold">
          Contract
        </p>

        {context.commonInfo.contract ? (
          <div className="flex items-center justify-between px-6 py-3 bg-midnight-10 rounded-xl">
            <span>{context.commonInfo.contract.name}</span>

            <div className="flex items-center gap-2.5">
              <span className="text-sm text-midnight-60">
                {humanFileSize(context.commonInfo.contract.size)}
              </span>
              <button
                type="button"
                className="button-clear p-1"
                onClick={() => changeInfo({ contract: null })}
              >
                <TrashIcon
                  className="w-5 h-5 fill-current"
                  aria-hidden="true"
                />
                <span className="sr-only">Remove file</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <span className="text-midnight-100">
              Up to 5Mb. Acceptable formats: pdf, doc, txt, docx.
            </span>
            <DropFileArea
              accept=".pdf, .doc, .txt, .doc"
              className="h-40"
              onFile={(files) => changeInfo({ contract: files[0] })}
            />
          </div>
        )}

        <p className="text-xl leading-normal text-midnight-100 font-bold">
          Cover Image
        </p>
        {context.commonInfo.cover ? (
          <MovableImage
            url={coverPreviewURL}
            onRemove={() => changeInfo({ cover: null, coverOffset: null })}
            offset={context.commonInfo.coverOffset}
            onChangeOffset={(value) => changeInfo({ coverOffset: value })}
          />
        ) : (
          <div className="flex flex-col gap-4">
            <span className="flex gap-2">
              <span className="text-midnight-100">
                Up to 5Mb. Min size 1024x320. Max size 4096x1280.
              </span>
              <span className="text-midnight-60">
                (Recommended aspect ratio 16/5)
              </span>
            </span>

            <DropFileArea
              accept="image/png, image/jpg, image/jpeg"
              className="h-40"
              onFile={(files) => changeInfo({ cover: files[0] })}
            />
          </div>
        )}
      </div>
    </>
  );
}
