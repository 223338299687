import DropFileArea from '@/components/DropFileArea';
import { OfferContext } from '@/contexts';
import { TrashIcon } from '@/icons';
import { humanFileSize } from '@/utils';
import { nanoid } from 'nanoid';
import { useContext } from 'react';

export default function OfferContentForm() {
  const context = useContext(OfferContext);

  function addFiles(files = []) {
    context.setContent(
      context.content.concat(
        files.map((file) =>
          Object.assign(file, {
            id: nanoid(),
            toUpload: true,
          }),
        ),
      ),
    );
  }

  function removeFiles(files = []) {
    context.setContent(
      context.content.reduce((result, file) => {
        if (files.includes(file)) {
          if (file instanceof File) {
            return result;
          } else {
            file.toDelete = true;
          }
        }

        result.push(file);
        return result;
      }, []),
    );
  }

  function restoreFiles(files = []) {
    context.setContent(
      context.content.map((file) => {
        if (files.includes(file) && !(file instanceof File)) {
          file.toDelete = false;
        }
        return file;
      }, []),
    );
  }

  return (
    <div className="flex flex-col gap-8 p-7 bg-gray-10 rounded-2.5xl">
      <p className="text-xl leading-normal text-midnight-100 font-bold">
        Content
      </p>
      <div className="flex flex-col gap-4">
        <span className="flex gap-2">
          <span className="text-midnight-100">
            Content that client can access after purchase this offer
          </span>
          <span className="text-midnight-60">(200 MB maximum)</span>
        </span>

        <DropFileArea multiple className="h-40" onFile={addFiles} />

        <ul className="flex flex-col gap-3">
          {context.content.map((file) => (
            <li
              key={file.id}
              className="flex items-center justify-between px-6 py-3 bg-midnight-10 rounded-xl"
            >
              <span>{file.name}</span>

              <div className="flex items-center gap-2.5">
                <span className="text-sm text-midnight-60">
                  {humanFileSize(file.size)}
                </span>

                {file.toDelete ? (
                  <button
                    type="button"
                    className="button-second px-2 py-1 text-sm"
                    onClick={() => restoreFiles([file])}
                  >
                    Restore
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button-clear p-1"
                    onClick={() => removeFiles([file])}
                  >
                    <TrashIcon
                      className="w-5 h-5 fill-current"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Remove file</span>
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
