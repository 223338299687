import { SelectUser } from '@/components';
import { AppContext } from '@/contexts';
import { SessionService, UserService } from '@/services';
import { Dialog, Disclosure } from '@headlessui/react';
import { ChevronDownIcon, TrashIcon } from '@icons';
import { Avatar } from '@ui';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { SessionSceneContext } from '../SessionSceneContext';

export default function SessionParticipantsSection({
  sessionId,
  defaultOpen = false,
}) {
  const [participants, setParticipants] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const appContext = useContext(AppContext);
  const sessionSceneContext = useContext(SessionSceneContext);

  const editable = useMemo(() => appContext.isCoach, [appContext]);

  const fetchData = useCallback(async () => {
    try {
      const data = await SessionService.getParticipants(sessionId);
      setParticipants(data);
    } catch (error) {
      toast.error('Session Participants retrieving error. See console (F12)');
      console.error(error);
    }
  }, [sessionId]);

  async function RemoveParticipant(userId) {
    await SessionService.deleteParticipants(sessionId, [userId]);
    await fetchData();
  }

  useEffect(() => {
    fetchData();
    // Trigger only on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    participants && (
      <Disclosure
        as="div"
        defaultOpen={defaultOpen}
        className="flex flex-col justify-between rounded-2.5xl bg-gray-10"
      >
        {({ open }) => (
          <>
            <div className="flex justify-between px-5 py-6 rounded-2.5xl bg-gray-10">
              <h2 className="text-2xl font-bold text-midnight-100">
                Participants
              </h2>

              <div className="flex items-center gap-8">
                {editable && (
                  <>
                    <button
                      type="button"
                      className="button-main button-xs"
                      onClick={() => setShowAddDialog(true)}
                    >
                      Add Participant
                    </button>
                    <AddParticipantsDialog
                      sessionId={sessionId}
                      isOpen={showAddDialog}
                      onClose={() => setShowAddDialog(false)}
                      onParticipantsUpdate={fetchData}
                    />
                  </>
                )}
                <Disclosure.Button className="p-0 focus:outline-none focus-visible:ring focus-visible:ring-orange-50 focus-visible:ring-opacity-75">
                  <ChevronDownIcon
                    className={`${
                      open && 'transform rotate-180'
                    } w-6 h-6 fill-midnight-60`}
                  />
                </Disclosure.Button>
              </div>
            </div>
            <Disclosure.Panel className="px-4 pt-2 pb-6">
              <ul className="flex flex-col gap-2">
                {participants.map((user) => (
                  <li
                    key={user.id}
                    className="flex justify-between items-center"
                  >
                    <div className="flex items-center">
                      <div>
                        <Avatar
                          abbreviation={
                            user.first_name.charAt(0) + user.last_name.charAt(0)
                          }
                          avatar={user.avatar}
                        />
                      </div>
                      <div>
                        <p className="ml-2.5 text-midnight-100 text-mdd font-bold">
                          {`${user.first_name} ${user.last_name}`}
                        </p>
                      </div>
                    </div>
                    {editable &&
                    sessionSceneContext.session.host_id !== user.id ? (
                      <button
                        type="button"
                        className="button-outline p-2"
                        onClick={() => RemoveParticipant(user.id)}
                      >
                        <TrashIcon
                          className="w-4 h-4 fill-current"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Remove user</span>
                      </button>
                    ) : (
                      <span className="px-2 py-1 text-sm text-midnight-60 border border-current rounded">
                        Host
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  );
}

function AddParticipantsDialog({
  sessionId,
  isOpen,
  onClose,
  onParticipantsUpdate,
} = {}) {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const addParticipants = useCallback(async () => {
    if (selectedUsers) {
      try {
        await SessionService.addParticipants(
          sessionId,
          selectedUsers.map((user) => user.id),
        );
        toast.success('Participants added');
        setSelectedUsers([]);
        onClose();
        onParticipantsUpdate();
      } catch (error) {
        console.log(`Add participants error`, error);
        toast.error('Unable add participants. See console (F12)');
      }
    }
  }, [onClose, onParticipantsUpdate, selectedUsers, sessionId]);

  async function findUsers(query) {
    const list = await UserService.search(query);
    return list.data;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black-500/80" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="flex flex-col gap-4 w-full max-w-2xl px-[30px] py-12 bg-gray-10 rounded-2.5xl">
          <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
            Add participants to this session
          </Dialog.Title>

          <ul className="flex flex-wrap gap-2">
            {selectedUsers.map((user) => (
              <li
                key={user.id}
                className="flex items-center gap-1 p-2 border border-midnight-40 rounded-lg"
              >
                <span>{`${user.first_name} ${user.last_name}`}</span>
                <button
                  type="button"
                  className="button-clear p-1"
                  onClick={() =>
                    setSelectedUsers(
                      selectedUsers.filter(({ id }) => id !== user.id),
                    )
                  }
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              </li>
            ))}
          </ul>

          <SelectUser
            value={selectedUsers}
            onChange={setSelectedUsers}
            loadData={findUsers}
            by="id"
            multiple
            placeholder="Search by full name, email"
          />
          <button
            type="button"
            className="flex justify-center button-main button-s"
            disabled={!selectedUsers.length}
            onClick={addParticipants}
          >
            {selectedUsers
              ? `Add ${selectedUsers.length} participants to this workspace`
              : 'Select a user above'}
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
