import { MessengerContext } from '@/contexts';
import { MessengerActiveRoomContext } from '@/contexts/MessengerActiveRoomContext';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';

export default function LeftRoom() {
  const { matrixClient } = useContext(MessengerContext);
  const { room } = useContext(MessengerActiveRoomContext);
  const [busy, setBusy] = useState(false);

  async function handleJoin() {
    setBusy(true);
    try {
      await matrixClient.joinRoom(room.roomId);
    } catch (error) {
      console.error('Join room error', error);
      toast.error('Cannot join the room');
    } finally {
      setBusy(false);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <h2 className="font-bold text-xl text-midnight-90">
        <span className="text-sea-70">{room.name}</span> can't be previewed. Do
        you want to join it?
      </h2>

      <button
        type="button"
        className="button-main button-m"
        disabled={busy}
        onClick={handleJoin}
      >
        Join the room
      </button>
    </div>
  );
}
