import { CategoryIcon } from '@/icons';
import classNames from 'classnames';
import { NavLink, Outlet } from 'react-router-dom';

const navigation = [
  { text: 'Offers', path: 'offers' },
  { text: 'History', path: 'history' },
];

export default function AcquisitionsLayout() {
  return (
    <div className="flex flex-col gap-7 px-12 py-7.5 min-h-full">
      <h1 className="text-4.5xl leading-tight text-midnight-100 font-bold">
        Acquisitions
      </h1>

      <nav aria-labelledby="workspace-navigation">
        <h2 id="workspace-navigation" className="sr-only">
          Acquisition navigation
        </h2>
        <ul className="relative inline-flex gap-9 items-center">
          <div className="absolute h-0.5 bottom-0 inset-x-0 bg-midnight-20 rounded-full"></div>

          {navigation.map(({ text, path }) => (
            <li key={path}>
              <NavLink to={path} replace>
                {({ isActive }) => (
                  <div className="relative flex flex-col gap-3">
                    <div
                      className={classNames(
                        'flex gap-1.5 items-center cursor-pointer',
                        isActive ? 'text-midnight-100' : 'text-midnight-70',
                      )}
                      data-active={isActive}
                    >
                      <CategoryIcon className="fill-transparent stroke-current" />
                      <span>{text}</span>
                    </div>
                    <div
                      className={classNames(
                        'h-0.5 w-full',
                        isActive ? 'bg-midnight-100' : 'bg-transparent',
                      )}
                    ></div>
                  </div>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>

      <Outlet />
    </div>
  );
}
