import { isNil } from './isNil';

/**
 * Check if something is a promise
 *
 * isPromise(new Promise(() => {}); //=> true
 * isPromise(() => {}); //=> false
 * ```
 */
export function isPromise(value) {
  return !isNil(value) && typeof value.then === 'function';
}
