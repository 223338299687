import {
  cloneElement,
  Component,
  createRef,
} from 'react';
import classNames from 'classnames';
import './DDropdown.scss';
import Tippy from '@tippyjs/react';
import maxSize from 'popper-max-size-modifier';
import { KeyboardCode, TOOLTIP_PLACEMENT } from '@constants';
import { noop } from '@utils';

function createPopperOptions(maxHeight) {
  const popperOptions = {
    modifiers: [
      maxSize,
      {
        name: 'applyMaxSize',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn({ state }) {
          const { height } = state.modifiersData.maxSize;
          if (state.styles.popper) {
            let resultHeight = height;
            if (typeof maxHeight === 'number' && maxHeight < height) {
              resultHeight = maxHeight;
            }

            state.elements.popper.style.setProperty('--popper-max-height', `${resultHeight}px`);
            state.styles.popper.display = 'flex';
          }
        }
      },
    ],
  };

  return popperOptions;
}

// interface Props {
//   content?: any;
//   children: any;
//   buttonOpenedClassName?: string;
//   trigger?: string;
//   placement?: TOOLTIP_PLACEMENT;
//   rounded?: boolean;
//   maxWidth?: number | 'none';
//   maxHeight?: number;
//   fullWidth?: boolean;
//   disabled?: boolean;
//   interactive?: boolean;
//   appendToBody?: boolean;
//   onBlur?: Callback;
//   onOpen?: Callback;
// }
//
// interface State {
//   opened: boolean;
//   disabled: boolean;
// }

export class DDropdown extends Component {
  static defaultProps = {
    trigger: 'click',
    placement: TOOLTIP_PLACEMENT.TOP,
    rounded: false,
    maxWidth: 350,
    disabled: false,
    interactive: false,
    appendToBody: false,
  };

  tooltipRef = createRef();
  buttonRef = createRef();
  forceClose = false;

  popperOptions;

  constructor(props) {
    super(props);

    this.popperOptions = createPopperOptions(props.maxHeight);

    this.state = {
      opened: false,
      disabled: props.disabled === true,
    };
  }

  componentDidUpdate(prevProps) {
    const { disabled } = this.props;

    if (disabled !== prevProps.disabled) {
      if (disabled) {
        this.close(() => this.setState({ disabled: true }));
      } else {
        this.setState({ disabled: false });
      }
    }
  }

  componentWillUnmount() {
    this.forceClose = true;
    document.removeEventListener('keyup', this.handleKeyDown);
  }

  handleTooltipCreate = (ref) => {
    this.tooltipRef.current = ref;
  };

  setAppendTo = (ref) => {
    const {
      appendToBody,
    } = this.props;

    if (appendToBody) {
      return document.body;
    }

    return ref.parentNode;
  };

  /**
   * @public
   */
  close(cb = noop) {
    if (this.state.opened && this.tooltipRef.current) {
      this.forceClose = true;
      this.tooltipRef.current.hide();
      this.setState({ opened: false }, () => cb());
    } else {
      cb();
    }
  }

  handleTooltipShown = () => {
    this.setState({ opened: true });
    document.addEventListener('keyup', this.handleKeyDown);

    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  // To support child dropdown
  handleTooltipHide = (tip) => {
    if (this.forceClose) {
      // After this callback will fired `handleTooltipHidden`
      return;
    }

    // `setTimeout` and `forceClose` because child tippy instance flags changes async
    setTimeout(() => {
      const anchors = tip.popper.querySelectorAll('[aria-haspopup="true"]');
      let hasOpenedChildDropdown = false;

      for (const anchor of anchors) {
        if (anchor?._tippy?.state?.isVisible) {
          hasOpenedChildDropdown = true;
          break;
        }
      }

      if (!hasOpenedChildDropdown) {
        this.forceClose = true;
        this.tooltipRef.current?.hide();
      }
    }, 200);

    return false;
  };

  handleTooltipHidden = () => {
    this.forceClose = false;
    this.setState({ opened: false });
    document.removeEventListener('keyup', this.handleKeyDown);

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleKeyDown = (e) => {
    if (e.code === KeyboardCode.Escape) {
      if (!e.defaultPrevented) {
        this.tooltipRef.current?.hide();

        // Close opened menu and still focus on dropdown button
        this.buttonRef.current.focus();
      }
    } else if (e.code === KeyboardCode.Tab) {
      // Close opened menu on focus lost
      if (!this.tooltipRef.current?.popper.contains(e.target)) {
        this.tooltipRef.current?.hide();
      }
    }
  };

  renderButton() {
    const {
      children,
      buttonOpenedClassName,
      fullWidth,
    } = this.props;

    const {
      opened,
    } = this.state;

    const additionalProps = {
      ref: this.buttonRef,
      fullWidth,
      opened,
    };

    const ariaAttributes = {
      'aria-haspopup': true,
      'aria-expanded': opened,
    };

    if (typeof children === 'function') {
      return children({
        ...ariaAttributes,
        ...additionalProps,
      });
    }

    return cloneElement(children, {
      ...additionalProps,
      ...ariaAttributes,
      className: classNames(children.props.className, opened && buttonOpenedClassName),
    });
  }

  renderContent() {
    const { opened } = this.state;
    const { content } = this.props;

    if (opened) {
      return typeof content === 'function' ? content() : content;
    }

    return '';
  }

  render() {
    const {
      trigger,
      placement,
      rounded,
      maxWidth,
      interactive,
    } = this.props;
    const {
      disabled,
    } = this.state;

    return (
      <Tippy
        content={this.renderContent()}
        className={classNames('dropdown', rounded && 'dropdown--rounded')}
        theme="dropdown"
        animation="shift-away"
        duration={200}
        trigger={trigger}
        placement={placement}
        maxWidth={maxWidth}
        disabled={disabled}
        interactive={interactive}
        arrow={false}
        ignoreAttributes={true}
        popperOptions={this.popperOptions}
        appendTo={this.setAppendTo}
        onShow={this.handleTooltipShown}
        onHide={this.handleTooltipHide}
        onHidden={this.handleTooltipHidden}
        onCreate={this.handleTooltipCreate}
      >
        {this.renderButton()}
      </Tippy>
    );
  }
}
