import { SessionService, WorkspaceService } from '@/services';
import { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export function useSessionSceneData(session_id) {
  const [session, setSession] = useState(null);
  const [workspaceInfo, setWorkspaceInfo] = useState(undefined);

  useEffect(() => {
    async function fetchSession() {
      try {
        const session = await SessionService.get(session_id, {
          expand: ['offer'],
        });
        setSession(session);
      } catch (error) {
        toast.error('Session retrieving error. See console (F12)');
        console.error(error);
      }
    }

    fetchSession();
  }, [session_id]);

  useEffect(() => {
    async function fetchWorkspace() {
      const workspace = await WorkspaceService.findBySession(session.id, true);
      setWorkspaceInfo(workspace);
    }
    // Fetch offer and workspace info after the session is loaded
    if (session) {
      fetchWorkspace();
    }
  }, [session]);

  return {
    session,
    setSession,
    workspaceInfo,
    setWorkspaceInfo,
  };
}

/** @type {React.Context<ReturnType<typeof useSessionSceneData>>} */
export const SessionSceneContext = createContext();
