import { useRef, useState } from 'react';

export function useAudio(url, { onTimeUpdate } = {}) {
  /** @type {import("react").MutableRefObject<HTMLAudioElement | undefined>} */
  const audio = useRef();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  function init() {
    if (!audio.current) {
      audio.current = new Audio(url);
      audio.current.addEventListener('loadeddata', () => {
        audio.current.play();
        setIsLoaded(true);
      });
      audio.current.addEventListener('waiting', () => {
        setIsWaiting(true);
      });
      audio.current.addEventListener('play', () => {
        setIsPlaying(true);
      });
      audio.current.addEventListener('pause', () => {
        setIsPlaying(false);
      });
      if (typeof onTimeUpdate == 'function') {
        audio.current.addEventListener('timeupdate', onTimeUpdate);
      }
      setIsInitialized(true);
    }
  }

  function play() {
    if (!audio.current) {
      init();
    } else {
      audio.current.play();
    }
  }
  function pause() {
    if (audio.current) {
      audio.current.pause();
    }
  }

  /**
   * @param {number} value timeline progress from 0 to 1
   */
  function setProgress(value = 0) {
    if (audio.current) {
      const v = Math.min(Math.max(value, 0), 1);
      audio.current.currentTime = audio.current.duration * v;
    }
  }

  return {
    isInitialized,
    isWaiting,
    isLoaded,
    isPlaying,
    play,
    pause,
    setProgress,
  };
}
