import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

export async function getCurrent() {
  const response = await instance.get('/user');
  return response.data;
}

export async function updateCurrent(data) {
  const response = await instance.patch('/user', data);
  return response.data;
}

/**
 * @param {object} options
 * @param {object} options.filters
 * @param {ContainFilter} options.filters.email
 * @param {ContainFilter} options.filters.first_name
 * @param {ContainFilter} options.filters.last_name
 */
export async function list({ filters } = {}) {
  const response = await instance.get('/users', { params: { filters } });
  return response.data;
}

export async function get(userId) {
  const response = await instance.get(`/users/${userId}`);
  return response.data;
}

/**
 * @param {string} query
 * @param {object} options
 * @param {number} options.limit
 * @param {number} options.offset
 */
export async function search(query, { limit, offset } = {}) {
  const response = await instance.get(`/users/search`, {
    params: { q: query, limit, offset },
  });
  return response.data;
}

/**
 * @typedef {object} ContainFilter
 * @property {string} co
 * @property {string} nc
 */
