import { httpClient } from '@utils';

const login = ({ email, password }) => {
  return httpClient.post('/auth/login', { email, password });
};

const register = ({ email, password, firstName, lastName }) => {
  return httpClient.post('/auth/register', {
    email,
    password,
    first_name: firstName,
    last_name: lastName,
  });
};

const sendOtp = ({ email }) => {
  return httpClient.post('/auth/otp/send', { email });
};

const confirmOtp = ({ email, code }) => {
  return httpClient.post('/auth/otp/confirm', { email, code });
};

const getResetPassword = ({ email, returnPath }) => {
  return httpClient.post('/auth/reset-password/request', {
    email,
    return_path: returnPath,
  });
};

const resetPassword = ({ password, resetToken }) => {
  return httpClient.post('/auth/reset-password/confirm', {
    password,
    reset_token: resetToken,
  });
};

export {
  login,
  register,
  sendOtp,
  confirmOtp,
  getResetPassword,
  resetPassword,
};
