import { AppContext } from '@/contexts';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@icons';
import classNames from 'classnames';
import { Fragment, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function LaunchMeetingButton({
  session_id,
  meeting_id,
  password,
  disabled,
  className,
}) {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const joinLink = useMemo(() => {
    const url = new URL(`https://us05web.zoom.us/j/${meeting_id}`);
    if (password) {
      url.searchParams.append('pwd', password);
    }
    return url.toString();
  }, [meeting_id, password]);

  function launchMeeting() {
    if (session_id && (appContext.isCoach || appContext.isClient)) {
      navigate(`/dashboard/${appContext.type}/sessions/${session_id}/room`);
    }
  }

  const copyJoinLink = useCallback(() => {
    navigator.clipboard.writeText(joinLink);
    toast.success('Copied!');
  }, [joinLink]);

  return (
    <div className={classNames('flex relative', className)}>
      <button
        type="button"
        className="button-main rounded-r-none"
        disabled={!session_id || !meeting_id || disabled}
        onClick={launchMeeting}
      >
        Launch Meeting
      </button>
      <Menu>
        <Menu.Button
          className="button-outline button-icon-rect rounded-l-none"
          disabled={!meeting_id}
        >
          <span className="sr-only">Launch Options</span>
          <ChevronDownIcon
            className="h-4 w-4 fill-orange-50"
            aria-hidden="true"
          />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 top-full mt-2 py-2 w-full origin-top-right rounded-lg bg-gray-10 shadow-lg focus:outline-none z-10">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={`${
                    active ? 'bg-orange-10 text-orange-50' : 'text-midnight-100'
                  } group flex w-full items-center rounded-none px-4 py-2 text-sm font-semibold`}
                  href={joinLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Join in Zoom App
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'bg-orange-10 text-orange-50' : 'text-midnight-100'
                  } group flex w-full items-center rounded-none px-4 py-2 text-sm`}
                  onClick={copyJoinLink}
                >
                  Copy a link
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
