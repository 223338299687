import { DAvatar } from '@components';
import { SIZE } from '@constants';
// import { DotsIcon, SvgIcon } from '@icons';

const ChatHeader = ({ avatar, name }) => {
  return (
    <div className="flex flex-row justify-between w-full p-4 border-b border-gray-20">
      <div className="flex">
        <DAvatar avatar={avatar} size={SIZE.M} />
        <div className="ml-2 flex flex-col justify-center">
          <h3 className="text-lg text-midnight-100 font-bold">{name}</h3>
          {/*<p className="text-midnight-60 font-normal text-md">*/}
          {/*  {isOnline ? 'Online' : 'Offline'}*/}
          {/*</p>*/}
        </div>
      </div>
      {/*<button className="hover:bg-gray-20 rounded-full p-4">*/}
      {/*  <SvgIcon size={SIZE.S} icon={<DotsIcon />} />*/}
      {/*</button>*/}
    </div>
  );
};

export default ChatHeader;
