import classNames from 'classnames';
import { BUTTON_THEME, SIZE } from '@constants';
import './styles.scss';
import { DButton } from '@components';
import { PlusIcon } from '@icons';

export const DAvatar = ({
  className,
  size = SIZE.S,
  avatar,
  onChangeAvatar,
  abbreviation,
}) => {
  return (
    <div className={classNames('d-avatar', `d-avatar--size-${size}`, className)}>
      {avatar ? (
        <img
          className="w-full h-full z-1 mx-auto md:mx-0 bg-gray-50 rounded-full"
          src={avatar}
          alt="Personal Avatar"
        />
      ) : (
        <div className="w-full h-full mx-auto md:mx-0 bg-gray-50 rounded-full flex justify-center items-center">
          {abbreviation}
        </div>
      )}

      {onChangeAvatar && (
        <DButton
          className="absolute bottom-0 right-3"
          theme={BUTTON_THEME.SECONDARY}
          size={SIZE.XS}
          rounded={true}
          iconComponent={(<PlusIcon/>)}
          onClick={onChangeAvatar}
        />
      )}
    </div>
  );
};
