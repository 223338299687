import AvatarInput from '@/components/AvatarInput';
import {
  MATRIX_HOMESERVER,
  MessengerContext,
} from '@/contexts/MessengerContext';
import { EventType } from '@/utils/matrixClient';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext, useState } from 'react';

/**
 * @param {object} params
 * @param {import("matrix-js-sdk").Room} params.room
 */
export default function ManageRoomDialog({ room, show, onClose }) {
  const { matrixClient } = useContext(MessengerContext);
  const [roomAvatar, setRoomAvatar] = useState(
    room.getAvatarUrl(MATRIX_HOMESERVER, 120, 120, 'scale'),
  );

  /** @param {Event} event */
  async function handleSubmit(event) {
    event.preventDefault();
    const fd = new FormData(event.target);
    const newName = fd.get('roomName');
    if (newName != room.name) {
      await matrixClient.setRoomName(room.roomId, fd.get('roomName'));
    }
    if (!roomAvatar) {
      // Clear avatar
      await matrixClient.sendStateEvent(
        room.roomId,
        EventType.RoomAvatar,
        {},
        '',
      );
    } else if (roomAvatar instanceof File) {
      const { content_uri } = await matrixClient.uploadContent(roomAvatar);
      await matrixClient.sendStateEvent(
        room.roomId,
        EventType.RoomAvatar,
        { url: content_uri },
        '',
      );
    }
    typeof onClose == 'function' && onClose();
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog open={show} onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-500/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="flex flex-col gap-4 w-full h-fit max-w-2xl px-[30px] py-12 bg-gray-10 rounded-2.5xl ">
              <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
                Manage the Room
              </Dialog.Title>

              <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                <AvatarInput
                  value={roomAvatar}
                  className="w-30 self-center"
                  onChange={setRoomAvatar}
                />
                <label
                  htmlFor="room-name-input"
                  className="text-xl leading-normal text-midnight-100 font-bold"
                >
                  Room Name
                </label>
                <input
                  id="room-name-input"
                  type="text"
                  name="roomName"
                  required
                  className="input-main input-m"
                  defaultValue={room.name}
                />
                <button
                  type="sumbit"
                  className="button-main button-m self-center"
                >
                  Save
                </button>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
