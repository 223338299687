import { encode } from 'blurhash';

/**
 * @param {HTMLImageElement} image
 */
export default function getBlurhash(image) {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext('2d');
  context.drawImage(image, 0, 0);
  const imageData = context.getImageData(0, 0, image.width, image.height);
  return encode(imageData.data, imageData.width, imageData.height, 4, 4);
}
