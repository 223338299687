import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/session-requests`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

/**
 * @param {{ limit: number, offset: number }} params
 * @returns {Promise<{ total: number, data: Array<SessionRequest>}>}
 */
export async function listSended(params) {
  const resonse = await instance.get('/sended', { params });
  return resonse.data;
}
/**
 * @param {{ limit: number, offset: number }} params
 * @returns {Promise<{ total: number, data: Array<SessionRequest>}>}
 */
export async function listReceived(params) {
  const resonse = await instance.get('/received', { params });
  return resonse.data;
}
/**
 * @param {CreateSessionRequestData} data
 * @returns {Promise<SessionRequest>}
 */
export async function create(data) {
  const resonse = await instance.post('', data);
  return resonse.data;
}

/**
 * @returns {Promise<SessionRequest>}
 */
export async function approve(id) {
  const resonse = await instance.put(`/${id}/approve`);
  return resonse.data;
}
/**
 * @returns {Promise<SessionRequest>}
 */
export async function disapprove(id) {
  const resonse = await instance.put(`/${id}/disapprove`);
  return resonse.data;
}

/**
 * @typedef {object} SessionRequest
 * @property {string} id
 * @property {string} requester_id
 * @property {string} offer_id
 * @property {string} timestamp
 * @property {string?} approved
 * @property {string?} session_id
 * @property {string} created_at
 * @property {string?} updated_at
 */
/**
 * @typedef {object} CreateSessionRequestData
 * @property {string} offer_id
 * @property {string} timestamp
 */
