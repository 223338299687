import { Link } from 'react-router-dom';

export default function BecomeCoachScreen() {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex flex-col gap-10">
        <h1 className="text-2xl font-bold">⚠ Tepmorary Page</h1>

        <div>
          <span>This page should:</span>
          <ul className="list-disc pl-6">
            <li>explain what is means to be a coach</li>
            <li>give examples when the user wants to become a coach</li>
            <li>
              explain how to become a coach (tell about DrreamZ subscription
              system)
            </li>
            <li>
              tell about future required steps: Stripe onboarding, Zoom
              connection, etc.
            </li>
            <li>provide a link to the Pricing Table</li>
          </ul>
        </div>

        <Link
          to="/pricing_table"
          className="button-main button-m justify-center"
        >
          Pricing Table
        </Link>
      </div>
    </div>
  );
}
