import { EmailValidator } from './EmailValidator';
import { NameValidator } from './NameValidator';
import { EmptyStringValidator } from './EmptyStringValidator';

const emailValidator = new EmailValidator();
const nameValidator = new NameValidator();
const emptyStringValidator = new EmptyStringValidator();

export {
  emailValidator,
  nameValidator,
  emptyStringValidator,
  EmailValidator,
  NameValidator,
  EmptyStringValidator,
};
