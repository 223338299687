import { NoEnteredData } from '@/components';
import EditScheduleForm from '@/components/EditScheduleForm';
import { MyScheduleService } from '@/services';
import getErrorMessage from '@/utils/getErrorMessage';
import { SystemZone } from 'luxon';
import { useCallback, useEffect, useState } from 'react';

export function ScheduleManagerScene() {
  const [schedules, setSchedules] = useState(undefined);
  const [editableSchedule, setEditableSchedule] = useState();
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    async function loadSchedules() {
      const list = await MyScheduleService.list();
      setSchedules(list.data);
    }
    loadSchedules();
  }, []);

  const createSchedule = useCallback(() => {
    setEditableSchedule({
      name: 'New Schedule',
      timezone: SystemZone.instance.name,
      schedule: Array.from({ length: 7 }, () => ({
        enabled: false,
        intervals: [],
      })),
    });
  }, []);
  const editSchedule = useCallback((schedule) => {
    setEditableSchedule(schedule);
  }, []);
  const cancelEditing = useCallback(() => {
    setEditableSchedule(null);
  }, []);
  const saveSchedule = useCallback(async () => {
    try {
      setBusy(true);
      if (editableSchedule.id) {
        const updated = await MyScheduleService.update(
          editableSchedule.id,
          editableSchedule,
        );
        setSchedules(
          schedules.map((item) => (item.id === updated.id ? updated : item)),
        );
      } else {
        const created = await MyScheduleService.create(editableSchedule);
        setSchedules([...schedules, created]);
      }
      cancelEditing();
      setBusy(false);
    } catch (error) {
      console.error(getErrorMessage(error));
      setBusy(false);
    }
  }, [cancelEditing, editableSchedule, schedules]);

  return (
    <div className="pt-6 pb-9 px-8 flex flex-col gap-8 bg-gray-10 rounded-2.5xl">
      {editableSchedule ? (
        <>
          <div className="flex justify-between">
            <h2 className="text-2xl leading-normal text-midnight-100 font-bold">
              {editableSchedule.id ? 'Edit Schedule' : 'Create Schedule'}
            </h2>

            <div className="flex gap-4">
              <button
                type="button"
                className="button-outline button-m"
                onClick={cancelEditing}
                disabled={busy}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button-main button-m"
                onClick={saveSchedule}
                disabled={busy}
              >
                Save
              </button>
            </div>
          </div>
          <EditScheduleForm
            value={editableSchedule}
            onChange={setEditableSchedule}
            disabled={busy}
          />
        </>
      ) : (
        <>
          <button
            type="button"
            className="button-main button-m self-start"
            onClick={createSchedule}
          >
            Create new schedule
          </button>
          <hr className="border-midnight-30" />
          <SchedulesList schedules={schedules} onEdit={editSchedule} />
        </>
      )}
    </div>
  );
}

function SchedulesList({ schedules, onEdit }) {
  if (!schedules) return;
  if (schedules.length == 0)
    return <NoEnteredData title="You haven't created a schedule yet" />;

  return (
    <ul className="flex flex-col gap-2">
      {schedules.map((schedule) => (
        <li key={schedule.id} className="flex gap-6 items-center">
          <button
            type="button"
            className="button-ghost button-xs"
            onClick={() => onEdit(schedule)}
          >
            Edit
          </button>
          <span className="font-semibold">{schedule.name}</span>
        </li>
      ))}
    </ul>
  );
}
