import { AttentionIcon } from '@/icons';

export default function EditOfferTabFallback() {
  return (
    <div className="flex flex-col items-center justify-center grow">
      <div className="p-3 flex items-center gap-2 border border-warning bg-orange-10 rounded">
        <AttentionIcon />
        <p>This tab is inaccessible for this offer type</p>
      </div>
    </div>
  );
}
