import moment from 'moment';

export const formatFullDate = (timestamp) =>
  moment(timestamp).format('YYYY/MM/DD  LT');

export const formatDDMMYYYY = (timestamp) =>
  moment(timestamp).format('DD.MM.YYYY');

export const formatShortDate = (timestamp) =>
  moment(timestamp).format('MMM YYYY');

export const calcTimeDiff = (duration, timestamp, format) => {
  const durationMills = moment.duration(duration).asMilliseconds();
  return {
    start: moment(timestamp).format(format),
    end: moment(timestamp).add(durationMills, 'milliseconds').format(format),
  };
};
