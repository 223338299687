import { AppContext } from '@/contexts';
import { NoEnteredData } from '@components';
import { TrashIcon } from '@icons';
import { WorkspaceService } from '@services';
import { Avatar } from '@ui';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { WorkspaceAddMemberDialog } from '../components';
import { WorkspaceContext } from './WorkspaceContext';

export default function WorkspaceTabMembers() {
  const appContext = useContext(AppContext);
  const workspace = useContext(WorkspaceContext);
  const [members, setMembers] = useState(undefined);
  const [showPopup, setShowPopup] = useState(false);

  const canEdit = useMemo(() => {
    return appContext.isCoach;
  }, [appContext]);

  const handleRemove = useCallback(
    async (memberId) => {
      await WorkspaceService.removeMembers(workspace.id, [memberId]);
      toast.success('Member removed');
      setMembers((state) => state.filter((s) => s.id !== memberId));
    },
    [workspace.id],
  );

  useEffect(() => {
    async function fetchData() {
      const data = await WorkspaceService.listMembers(workspace.id);
      setMembers(data);
    }
    fetchData();
  }, [workspace.id]);

  return (
    members && (
      <>
        {canEdit && (
          <button
            type="button"
            className="button-main button-s self-start"
            onClick={() => setShowPopup(true)}
          >
            Add Members
          </button>
        )}
        {members.length ? (
          <ul className="flex flex-col gap-5 px-5 py-6 bg-gray-10 rounded-2.5xl">
            {members.map((member) => (
              <li key={member.id} className="flex items-center justify-between">
                <div className="flex items-center gap-2.5">
                  <Avatar
                    abbreviation={
                      member.first_name.charAt(0) + member.last_name.charAt(0)
                    }
                    avatar={member.avatar}
                  />
                  <p className="text-midnight-100 text-mdd font-bold">{`${member.first_name} ${member.last_name}`}</p>
                </div>

                {canEdit && workspace.owner_id !== member.id ? (
                  <button
                    type="button"
                    className="button-outline button-xs button-icon-rect"
                    onClick={() => handleRemove(member.id)}
                  >
                    <TrashIcon className="fill-current" aria-hidden="true" />
                    <span className="sr-only">Remove member</span>
                  </button>
                ) : (
                  <span className="px-2 py-1 text-sm text-midnight-60 border border-current rounded">
                    Admin
                  </span>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <NoEnteredData title="No members" />
        )}

        <WorkspaceAddMemberDialog
          isOpen={showPopup}
          onClose={() => setShowPopup(false)}
        />
      </>
    )
  );
}
