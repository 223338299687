import classNames from 'classnames';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import Tippy from '@tippyjs/react';
import { TOOLTIP_PLACEMENT } from '@constants';
import './Tooltip.scss';
import { isString } from '@utils';

// interface Props {
//   content?: React.ReactNode | React.ReactNode[];
//   // Enable tooltip pointer events. Use with `trigger="click"`
//   interactive?: boolean;
//   trigger?: string;
//   children: any;
//
//   // Show/Hide delay
//   delay?: any;
//   placement?: TOOLTIP_PLACEMENT;
//   theme?: THEME;
//   preformatted?: boolean;
// }

export const Tooltip = forwardRef(({
  content,
  interactive = false,
  children,
  trigger = 'mouseenter focus',
  delay,
  placement = TOOLTIP_PLACEMENT.TOP,
  theme,
  preformatted = false,
}, ref) => {
  const tooltipRef = useRef();

  useImperativeHandle(ref, () => ({
    show() {
      if (tooltipRef.current) {
        tooltipRef.current.show();
      }
    },

    hide() {
      if (tooltipRef.current) {
        tooltipRef.current.hide();
      }
    },
  }));

  const handleTooltipCreate = useCallback((ref) => {
    tooltipRef.current = ref;
  }, []);

  if (!content) {
    return children;
  }

  return (
    <Tippy
      content={content}
      theme={classNames('cr', {
        [`${theme}`]: Boolean(theme),
        interactive,
        preformatted,
      })}
      animation="shift-away"
      duration={200}
      delay={delay}
      trigger={trigger}
      placement={placement}
      ignoreAttributes={true}
      arrow={true}
      onCreate={handleTooltipCreate}
    >
      {isString(children) ? (<span>{children}</span>) : children}
    </Tippy>
  );
});

Tooltip.displayName = 'Tooltip';
