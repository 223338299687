import { useFormik } from 'formik';
import { string, object } from 'yup';
import { FormControl, ProgressButton } from '@components';
import { useState } from 'react';
import { sendCustomerSupportRequest } from '@services';
import { toast } from 'react-toastify';
import { EnvelopeIcon, PhoneIcon, SvgIcon } from '@icons';
import { THEME } from '@constants';

const validationSchema = object({
  email: string().required().email(),
  phone: string().required(),
  message: string().required(),
});

const CustomerSupportForm = () => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      message: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await setLoading(true);

      try {
        await sendCustomerSupportRequest(values);
        formik.resetForm();
        toast('Successful!', {
          type: 'success',
        });
      } finally {
        await setLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl
        className="my-2 w-full"
        id="email"
        name="email"
        type="email"
        label="Email Address"
        placeholder="example@gmail.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={!!formik.errors.email}
        helperText={formik.errors.email}
        startAdornment={(
          <SvgIcon
            icon={<EnvelopeIcon/>}
            theme={THEME.SECONDARY}
          />
        )}
      />
      <FormControl
        className="my-2 w-full"
        id="phone"
        name="phone"
        type="text"
        label="Phone Number"
        placeholder="+1 548 9856"
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={!!formik.errors.phone}
        helperText={formik.errors.phone}
        startAdornment={(
          <SvgIcon
            icon={<PhoneIcon/>}
            theme={THEME.SECONDARY}
          />
        )}
      />
      <FormControl
        className="my-2 w-full"
        id="message"
        name="message"
        type="textarea"
        label="Message"
        placeholder="Type your question here..."
        textareaComponentProps={{
          rows: 6,
        }}
        value={formik.values.message}
        onChange={formik.handleChange}
        error={!!formik.errors.message}
        helperText={formik.errors.message}
      />
      <ProgressButton
        className="mt-12"
        loading={loading}
        type="submit"
      >
        Send
      </ProgressButton>
    </form>
  );
};

export default CustomerSupportForm;
