import classNames from 'classnames';
import { LoadingWrapper } from '../LoadingWrapper';
import './styles.scss';

export const TextareaResize = {
  NONE: 'none',
  BOTH: 'both',
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

// export interface TextareaProps {
//   rows?: number;
//   resize?: TextareaResize;
// }
//
// export interface Props extends ClassName, DataAttr, TextareaProps {
//   inputRef?: any;
//   value: string;
//   name?: string;
//   placeholder?: string;
//
//   // Flags
//   disabled?: boolean;
//   readOnly?: boolean;
//   required?: boolean;
//   loading?: boolean;
//
//   /**
//    * If `true`, the input will be focused during the first mount.
//    */
//   autoFocus?: boolean;
//   // Callbacks
//   onChange?: Callback;
//   onBlur?: Callback;
//   onKeyDown?: Callback;
//   onKeyUp?: Callback;
// }

export const TextareaInput = ({
  inputRef,
  dataId,
  className,
  name,
  placeholder,
  value,
  rows = 3,
  resize = 'vertical',

  autoFocus,
  disabled = false,
  readOnly = false,
  required = false,
  loading = false,

  onChange,
  onBlur,
  onKeyDown,
  onKeyUp,
  ...other
}) => {
  return (
    <LoadingWrapper loading={loading}>
      <textarea
        ref={inputRef}
        data-id={dataId}
        className={classNames(
          'cr-textarea-input',
          `cr-textarea-input--resize-${resize}`,
          className,
        )}
        placeholder={placeholder}
        name={name}
        value={value}
        rows={rows}
        autoFocus={autoFocus}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        {...other}
      />
    </LoadingWrapper>
  );
};
