import { Switch } from '@/components';
import CreateScheduleDialog from '@/components/CreateScheduleDialog';
import SelectSchedule from '@/components/SelectSchedule';
import { OfferContext } from '@/contexts';
import { PlusIcon } from '@/icons';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import { useContext, useState } from 'react';

export default function OfferDiscoveryCallForm() {
  const context = useContext(OfferContext);
  const [showScheduleEditor, setShowScheduleEditor] = useState(false);

  return (
    <div className="flex flex-col gap-8 p-7 bg-gray-10 rounded-2.5xl">
      <HeadlessSwitch.Group as="div" className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <HeadlessSwitch.Label className="text-xl leading-normal text-midnight-100 font-bold">
            Enable Discovery Call
          </HeadlessSwitch.Label>
          <Switch
            value={context.enableDiscoveryCall}
            onChange={context.setEnableDiscoveryCall}
          />
        </div>
        <HeadlessSwitch.Description>
          Enable this feature to allow users of the platform book a discovery
          session. Also you can select a schedule that users can book within. If
          no schedule is selected, the user can book for any time.
        </HeadlessSwitch.Description>
      </HeadlessSwitch.Group>

      <div className="flex gap-2">
        <div className="flex flex-col gap-8 grow">
          <SelectSchedule
            label={
              <span className="text-xl leading-normal text-midnight-100 font-bold">
                Schedule
              </span>
            }
            value={context.discoveryCallSchedule}
            onChange={context.setDiscoveryCallSchedule}
            className="grow"
          />
        </div>
        <button
          type="button"
          className="button-outline button-s self-end"
          onClick={() => setShowScheduleEditor(true)}
        >
          <span>Create new schedule</span>
          <PlusIcon className="h-5 fill-current" aria-hidden="true" />
        </button>
      </div>

      <CreateScheduleDialog
        open={showScheduleEditor}
        onClose={() => setShowScheduleEditor(false)}
        onCreate={(schedule) => context.setDiscoveryCallSchedule(schedule.id)}
      />
    </div>
  );
}
