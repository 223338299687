import { ChevronLeftIcon, ChevronRightIcon } from '@/icons';
import classNames from 'classnames';
import { DateTime } from 'luxon';

/**
 * @param {object} params
 * @param {import('luxon').DateTime} params.value
 * @param {Function} params.onChange
 * @param {'day' | 'month'} params.scope
 */
export default function CalendarTimeControl({
  value,
  onChange,
  scope = 'day',
  compact,
}) {
  function shiftDate(direction = 1) {
    onChange && onChange(value.plus(Object.fromEntries([[scope, direction]])));
  }
  return (
    <div
      className={classNames(
        'w-full flex items-center text-midnight-90 text-[1.375rem] font-bold leading-snug',
        compact ? 'justify-between' : 'justify-evenly',
      )}
    >
      <button
        className={classNames('button-clear py-1', compact ? 'px-1' : 'px-12')}
        onClick={() => shiftDate(-1)}
      >
        <ChevronLeftIcon className="fill-midnight-60" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </button>
      <span>
        {value.toLocaleString(
          scope == 'month'
            ? { month: 'short', year: 'numeric' }
            : DateTime.DATE_SHORT,
        )}
      </span>
      <button
        className={classNames('button-clear py-1', compact ? 'px-1' : 'px-12')}
        onClick={() => shiftDate(1)}
      >
        <ChevronRightIcon className="fill-midnight-60" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
}
