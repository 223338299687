import { AppContext } from '@/contexts';
import { CategoryIcon, SettingsIcon } from '@icons';
import classNames from 'classnames';
import { useContext } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { WorkspaceContext } from './WorkspaceContext';

const navigation = [
  { text: 'Sessions', path: 'sessions' },
  { text: 'Files', path: 'files' },
  { text: 'Members', path: 'members' },
];

const WorkspaceScene = () => {
  const appContext = useContext(AppContext);
  const workspace = useContext(WorkspaceContext);

  return (
    <div className="flex flex-col gap-7 px-12 py-7.5">
      <div className="flex flex-col gap-2">
        <div className="flex gap-4 justify-between">
          <div className="flex gap-6 items-baseline">
            <h1 className="text-4.5xl font-bold text-midnight-90">
              {workspace.name || 'Workspace'}
            </h1>
            <span className="text-2xl font-bold text-midnight-70">
              {workspace.offer.name}
            </span>
          </div>
          {appContext.isCoach && (
            <Link
              to="settings"
              className="button-main button-s button-icon-rect"
            >
              <SettingsIcon
                className="w-5 h-5 fill-current"
                aria-hidden="true"
              />
              <span className="sr-only">Workspace settings</span>
            </Link>
          )}
        </div>
        <nav aria-labelledby="workspace-navigation">
          <h2 id="workspace-navigation" className="sr-only">
            Workspace navigation
          </h2>
          <ul className="relative inline-flex gap-9 items-center">
            <div className="absolute h-0.5 bottom-0 inset-x-0 bg-midnight-20 rounded-full"></div>

            {navigation.map(({ text, path }) => (
              <li key={path}>
                <NavLink to={path} replace>
                  {({ isActive }) => (
                    <div className="relative flex flex-col gap-3">
                      <div
                        className={classNames(
                          'flex gap-1.5 items-center cursor-pointer',
                          isActive ? 'text-midnight-100' : 'text-midnight-70',
                        )}
                        data-active={isActive}
                      >
                        <CategoryIcon className="fill-transparent stroke-current" />
                        <span>{text}</span>
                      </div>
                      <div
                        className={classNames(
                          'h-0.5 w-full',
                          isActive ? 'bg-midnight-100' : 'bg-transparent',
                        )}
                      ></div>
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <Outlet />
    </div>
  );
};

export default WorkspaceScene;
