import { OfferingsService, WorkspaceService } from '@services';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateWorkspaceScene = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceOffer, setWorkspaceOffer] = useState('');

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await OfferingsService.getAll();
      setOffers(data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const createWorkspace = useCallback(async () => {
    if (workspaceOffer && workspaceOffer !== '') {
      await WorkspaceService.create({
        name: workspaceName ?? 'Workspace',
        offer_id: workspaceOffer,
      });
      toast.success('Workspace has been created!');
      navigate('/dashboard/coach/workspaces');
    }
  }, [workspaceOffer, workspaceName, navigate]);

  return (
    loading || (
      <div className="flex flex-col gap-7 px-12 py-8">
        <h1 className="text-4.5xl font-bold text-midnight-90">
          Create Workspace
        </h1>

        <div className="flex flex-col gap-6 p-5 rounded-2.5xl bg-gray-10">
          <div className="flex gap-20">
            <div className="flex flex-col gap-1">
              <label htmlFor="create-workspace-input-name">
                Workspace name
              </label>
              <input
                id="create-workspace-input-name"
                type="text"
                placeholder="Type workspace name"
                name="workspace_name"
                value={workspaceName}
                onChange={(event) => setWorkspaceName(event.target.value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="create-workspace-input-offer">Offer</label>
              <select
                id="create-workspace-input-offer"
                name="workspace_offer_id"
                value={workspaceOffer}
                onChange={(event) => setWorkspaceOffer(event.target.value)}
              >
                <option value="" disabled>
                  Select an offer
                </option>
                {offers.length
                  ? offers.map((offer) => (
                    <option value={offer.id} key={offer.id}>
                      {offer.name}
                    </option>
                  ))
                  : ''}
              </select>
            </div>
          </div>
          <button
            className="button-main button-m self-start"
            onClick={createWorkspace}
          >
            Create workspace
          </button>
        </div>
      </div>
    )
  );
};

export default CreateWorkspaceScene;
