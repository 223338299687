import SelectUser from '@/components/SelectUser';
import { MessengerContext } from '@/contexts';
import { UserService } from '@/services';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

export default function CreateNewChatDialog({ show, onClose }) {
  const { matrixClient } = useContext(MessengerContext);
  const [selectedUser, setSelectedUsers] = useState(null);

  const createChat = useCallback(async () => {
    if (selectedUser) {
      try {
        onClose();
        const inviteeId = `@${selectedUser.id}:drreamz.com`;
        let roomId;
        try {
          const { room_id } = await matrixClient.createRoom({
            visibility: 'private',
            is_direct: true,
            invite: [inviteeId],
          });
          roomId = room_id;
        } catch (error) {
          toast.error('Unable to create a room.');
          console.error('Room creation error', error);
        }

        try {
          const directEventContent = matrixClient
            .getAccountData('m.direct')
            ?.getContent();

          const chatIds = directEventContent?.[inviteeId] ?? [];
          chatIds.push(roomId);
          await matrixClient.setAccountData('m.direct', {
            ...directEventContent,
            [inviteeId]: chatIds,
          });
        } catch (error) {
          toast.error('Unable to update account data.');
          console.error('Set AccountData error', error);
          return;
        }
      } catch (error) {
        console.log(`Create room error`, error);
        toast.error('Unable create a chat. See console (F12)');
      }
    }
  }, [matrixClient, onClose, selectedUser]);

  async function findUsers(query) {
    const list = await UserService.search(query);
    return list.data;
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog open={show} onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-500/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="flex flex-col gap-4 w-full h-fit max-w-2xl px-[30px] py-12 bg-gray-10 rounded-2.5xl ">
              <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
                Create Chat
              </Dialog.Title>

              <div className="flex flex-col gap-4">
                <SelectUser
                  value={selectedUser}
                  onChange={setSelectedUsers}
                  loadData={findUsers}
                  by="id"
                  placeholder="Search by full name, email"
                />

                <button
                  type="button"
                  className="button-main button-m self-center"
                  disabled={!selectedUser}
                  onClick={createChat}
                >
                  {selectedUser
                    ? `Chat with ${selectedUser.first_name} ${selectedUser.last_name}`
                    : 'Select a user above'}
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
