import { useEffect, useState } from 'react';

export default function useTempState(defaultState, timeout = 1000) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (state !== defaultState) {
      const tm = setTimeout(() => {
        setState(defaultState);
      }, timeout);

      return () => clearTimeout(tm);
    }
  }, [state, defaultState, timeout]);

  return [state, setState];
}
