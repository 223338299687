import { WorkspaceService } from '@services';
import { createContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const WorkspaceContext = createContext(undefined);

export default function WorkspaceContextProvider() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [workspace, setWorkspace] = useState(undefined);

  useEffect(() => {
    async function fetchWorkspace() {
      try {
        setWorkspace(undefined);
        const data = await WorkspaceService.get(id, { expand: 'offer' });
        setWorkspace(data);
      } catch (err) {
        toast.error('Unable to load workspace data. See console (F12)');
        console.error(err);
        navigate('../', { replace: true });
      }
    }

    if (workspace === undefined) fetchWorkspace();
  }, [id, workspace, navigate]);

  return (
    workspace && (
      <WorkspaceContext.Provider value={workspace}>
        <Outlet />
      </WorkspaceContext.Provider>
    )
  );
}
