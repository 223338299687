import { LandingTemplate, LetsGetEmpowered } from '@components';

const EmpoweredScene = () => {
  return (
    <LandingTemplate src="/images/empowered/lets_get_empowered.svg">
      <div className="flex flex-col justify-center h-full max-w-lg mx-auto">
        <LetsGetEmpowered />
      </div>
    </LandingTemplate>
  );
};
export default EmpoweredScene;
