import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ContentContainer } from '@components';
import { SearchStore } from '@stores';
import { NoResults, OffersResults, UsersResults } from './components';

export function SearchScene() {
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const query = searchParams.get('q');
        await SearchStore.search(query);
      } finally {
        setLoading(false);
      }
    }

    void fetchData();
  }, [searchParams]);

  return (
    <ContentContainer
      title="Back"
      loading={loading}
      showBackground={false}
      backTo={'/dashboard'}
    >
      {SearchStore.otherUsers.length ||
      SearchStore.coaches.length ||
      SearchStore.offers.length ? (
        <>
          <UsersResults />
          <OffersResults />
        </>
      ) : (
        <NoResults />
      )}
    </ContentContainer>
  );
}
