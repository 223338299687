import { makeAutoObservable } from 'mobx';
import {
  createChat,
  deleteChat,
  getChats,
  getChat,
  sendMessageToChat,
} from '@services';

class ChatsStore {
  constructor() {
    this.currentChat = null;
    this.chats = [];
    makeAutoObservable(this);
  }

  setCurrentChatInfo(chatInfo) {
    this.currentChatInfo = chatInfo;
  }

  setChats(chats) {
    this.chats = chats;
  }

  prepareChatsForUser(userId) {
    const chats = this.chats.map((chat) => {
      if (chat.type === 'private') {
        const member = chat.members.filter((member) => member.id !== userId)[0];
        chat.avatar = member.avatar;
        chat.name = `${member.first_name} ${member.last_name}`;
      }

      return chat;
    });

    this.setChats(chats);
  }

  async deleteChatById(id) {
    await deleteChat(id);
  }

  async getChat(chatId) {
    const { data } = await getChat(chatId);
    this.setCurrentChatInfo(data);
  }

  async getChatWithUser(userId) {
    await this.getChats();
    const chats = this.chats
      .filter((chat) => chat.type === 'private')
      .filter((chat) => {
        const member = chat.members.filter((member) => member.id === userId);

        if (member.length) {
          return chat;
        }
      });

    if (chats.length) {
      return chats[0];
    } else {
      return null;
    }
  }

  async createNewChat({ type = 'private', userId }) {
    await createChat({ type, userId });
  }

  async getChats() {
    const { data } = await getChats();
    this.setChats(data);
  }

  async sendMessage({ chatId, text }) {
    await sendMessageToChat({ chatId, text });
  }
}

export default new ChatsStore();
