import { SIZE, THEME } from '@constants';
import { LoadingSpinner } from '../LoadingWrapper/LoadingSpinner';
import classNames from 'classnames';
import './Loader.scss';

export const Loader = ({
  className,
  size = SIZE.M,
  theme = THEME.INFO,
}) => {
  return (
    <LoadingSpinner
      className={classNames('loader', className)}
      size={size}
      theme={theme}
    />
  );
};
