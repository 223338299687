import { NoEnteredData } from '@components';
import { AcquisitionService } from '@services';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export default function AcquisitionScene() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const acquisitions = await AcquisitionService.list();
        setData(acquisitions.data);
      } catch (err) {
        toast.error('Unable to get acquisitions. See conslole (F12)');
        console.error('Get acquisition list error', err);
      }
    }

    fetchData();
  }, []);

  return data.length ? (
    <div className="flex flex-wrap gap-4 p-7 bg-gray-10 rounded-2.5xl">
      {data.map((item) => (
        <AcquisitionCard key={item.id} data={item} />
      ))}
    </div>
  ) : (
    <div className="flex flex-col gap-4 p-7 bg-gray-10 rounded-2.5xl">
      <NoEnteredData
        title="No acquisitions"
        customImage={
          <img
            src="/images/svg-woman-shopping.svg"
            alt="Woman with shopping cart"
          />
        }
      />
    </div>
  );
}

function AcquisitionCard({ data }) {
  return (
    <div className="flex flex-col gap-2 p-4 border text-midnight-100 border-midnight-30 rounded-lg">
      <h2 className="flex justify-between gap-2">
        <span className="font-semibold">Acquisition ID: </span>
        <span>{data.id}</span>
      </h2>

      <div>
        <p className="font-semibold">Data</p>
        <ul className="flex flex-col text-sm">
          <li className="flex justify-between gap-2">
            <span>Offer ID: </span>
            <span>{data.offer_id}</span>
          </li>
          <li className="flex justify-between gap-2">
            <span>User ID: </span>
            <span>{data.user_id}</span>
          </li>
          <li className="flex justify-between gap-2">
            <span>Timestamp: </span>
            <span>{data.timestamp}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
