import { formatFullDate } from '@utils';

export const NotificationItem = ({ notification }) => {
  return (
    <div className="flex py-3 px-6 gap-2 rounded-xl bg-midnight-10 hover:shadow-card duration-150">
      {notification.image && (
        <img
          className="w-10 h-10 bg-gray-50 rounded-full object-cover"
          aria-hidden="true"
          src={notification.image}
        />
      )}
      <div className="flex flex-col flex-grow">
        <div className="font-bold text-base text-midnight-100">
          {notification.title}
        </div>
        <div className="mt-0.5 text-sm text-midnight-100">
          {notification.text}
        </div>
      </div>
      <div className="mt-0.5 text-midnight-70 text-sm">
        {formatFullDate(notification.timestamp)}
      </div>
    </div>
  );
};
