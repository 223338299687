import { createContext } from 'react';

class AppContextInstance {
  constructor(type) {
    this.type = type;
  }

  get isCoach() {
    return this.type === 'coach';
  }

  get isClient() {
    return this.type === 'client';
  }

  get isPublic() {
    return this.type === 'public';
  }
}

export const AppCoachContext = new AppContextInstance('coach');
export const AppClientContext = new AppContextInstance('client');
export const AppPublicContext = new AppContextInstance('public');

/** @type {import("react").Context<AppContextInstance>} */
export const AppContext = createContext(undefined);
