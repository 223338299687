import { UserStoreV2 } from '@/stores';
import { LandingTemplate, ProgressButton } from '@components';
import { SIZE } from '@constants';
import { ChevronRightIcon, SvgIcon } from '@icons';
import { AuthService } from '@services';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import OtpInput from 'react-otp-input';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';

const OTP_COOLDOWN_MS = 30000; // 30 sec

const validationSchema = object({
  code: string().required(),
});

export default function VerifyCodeScene() {
  const userStore = UserStoreV2.useState();

  const [loading, setLoading] = useState(false);
  const [sendCooldown, setSendCooldown] = useState(
    sessionStorage.getItem('otp_cooldown') &&
      Number.parseInt(sessionStorage.getItem('otp_cooldown')),
  );
  const navigate = useNavigate();
  const location = useLocation();

  // If no userdata - probably manual direct navigation
  const userdata = location.state?.userdata;
  if (!userdata) return <Navigate to="/auth/sign_up" />;

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ code }) => {
      setLoading(true);
      try {
        await AuthService.OtpConfirm({
          code,
          email: userdata.email,
        });
        sessionStorage.removeItem('otp_cooldown');
        sessionStorage.removeItem('otp_data_hash');
      } catch (error) {
        toast.error('Incorrect Code');
        setLoading(false);
        return;
      }

      try {
        await AuthService.register({
          email: userdata.email,
          first_name: userdata.firstName,
          last_name: userdata.lastName,
          password: userdata.password,
        });
        await userStore.load();
        navigate('/profile_type');
      } catch (error) {
        toast.error('Registration error. See console (F12)');
        console.error('Registration error', error);
      } finally {
        setLoading(false);
      }
    },
  });

  const getDataHash = useCallback(async () => {
    const dataUint8 = new TextEncoder().encode(
      [
        userdata.email,
        userdata.firstName,
        userdata.lastName,
        userdata.password,
      ].join(''),
    );
    const hashBuffer = await crypto.subtle.digest('SHA-256', dataUint8);
    const hashHex = Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''); // convert bytes to hex string
    return hashHex;
  }, [userdata]);

  const SendOTP = useCallback(async () => {
    try {
      setLoading(true);
      await AuthService.OtpRequest({ email: userdata.email });
      sessionStorage.setItem('otp_cooldown', Date.now() + OTP_COOLDOWN_MS);
      setSendCooldown(Date.now() + OTP_COOLDOWN_MS);
      formik.resetForm();
      // Store data hash for comparison
      sessionStorage.setItem('otp_data_hash', await getDataHash());
      setLoading(false);
    } catch (error) {
      toast.error('Unable to send OTP. See console (F12)');
      console.error('OTP sending error', error);
    }
  }, [formik, userdata.email, getDataHash]);

  useEffect(
    () => {
      async function check() {
        // Send OTP automatically only if it's first time (no cooldown set yet)
        // or the data hash is different
        const prevDataHash = sessionStorage.getItem('otp_data_hash');
        const currDataHash = await getDataHash();
        if (sendCooldown === null || prevDataHash !== currDataHash) {
          await SendOTP();
        }
      }
      check();
    },
    // Call only on initial render, no need to rerun
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const disabledButton =
    !(formik.isValid && formik.dirty) || formik.values.code.length !== 6;

  return (
    <LandingTemplate src="/images/verify_code.svg">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col justify-center h-full max-w-lg mx-auto"
      >
        <div className="flex flex-col justify-center h-full">
          <div>
            <img src="/images/logo_black.svg" className="h-8 mb-1" alt="logo" />
          </div>
          <h1 className="text-3xl text-midnight-100 font-bold my-4">
            Verification Code
          </h1>
          <p className="text-sm font-normal text-midnight-90">
            Enter the verification code we sent you in email.
          </p>

          <OtpInput
            containerStyle="flex justify-between my-14"
            isDisabled={loading}
            disabledStyle={{
              borderColor: '#dbe5f1',
              backgroundColor: '#f8f8f8',
            }}
            inputStyle={{
              border: '1px solid',
              borderColor: '#9AB1D4',
              borderRadius: '8px',
              backgroundColor: '#F9FBFF',
              width: '54px',
              height: '54px',
              fontSize: '20px',
              color: '#212E42',
              fontWeight: '700',
              caretColor: 'black',
            }}
            focusStyle={{
              border: '1px solid',
              borderColor: '#9AB1D4',
              borderRadius: '8px',
              backgroundColor: '#FFFFFF',
              width: '54px',
              height: '54px',
              caretColor: 'blue',
            }}
            id="email"
            name="email"
            numInputs={6}
            separator={null}
            value={formik.values.code}
            onChange={(otp) => {
              formik.setValues({ code: otp });
            }}
          />
          <ProgressButton
            loading={loading}
            type="submit"
            disabled={disabledButton}
          >
            Verify&nbsp;
            <SvgIcon icon={<ChevronRightIcon />} size={SIZE.S} />
          </ProgressButton>
          <ResendCodeTimer
            disabled={loading}
            timer={sendCooldown - Date.now()}
            onResend={SendOTP}
          />
        </div>
      </form>
    </LandingTemplate>
  );
}

function ResendCodeTimer({ onResend, disabled, timer }) {
  const [counter, setCounter] = useState(0);

  const cooldownText = useMemo(() => {
    let min = Number.parseInt(counter / 60000);
    let sec = Number.parseInt((counter % 60000) / 1000);
    if (min < 10) min = '0' + min;
    if (sec < 10) sec = '0' + sec;
    return `Resend in ${min}:${sec}`;
  }, [counter]);

  useEffect(() => {
    setCounter(timer);
  }, [timer]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1000);
      }, 1000);
    }
  }, [counter]);

  return (
    <div className="mt-4 flex justify-center">
      {counter > 0 ? (
        <p className="text-sm text-midnight-80">{cooldownText}</p>
      ) : (
        <button
          className="text-sm text-sea-70 hover:text-sea-80 focus-visible:text-sea-90 disabled:text-midnight-40 p-0 flex justify-start"
          onClick={onResend}
          disabled={disabled}
        >
          Resend code
        </button>
      )}
    </div>
  );
}
