import { AppContext } from '@/contexts';
import {
  CheckIcon,
  ClockIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

export default function SessionRequestListItem({
  request,
  offerName,
  onApprove,
  onReject,
}) {
  const dt_start = useMemo(
    () => DateTime.fromISO(request.timestamp),
    [request.timestamp],
  );
  const dt_created = useMemo(
    () => DateTime.fromISO(request.created_at),
    [request.created_at],
  );
  const dt_updated = useMemo(
    () => request.updated_at && DateTime.fromISO(request.updated_at),
    [request.updated_at],
  );

  const appContext = useContext(AppContext);

  return (
    <li
      className="flex md:items-center flex-col md:flex-row gap-y-4 gap-x-2 py-3 px-6 bg-midnight-10 rounded-xl"
      data-session-id={request.id}
    >
      <div className="grow">
        <div className="flex flex-row flex-wrap gap-x-2">
          <p>
            <span>Request for </span>
            <span className="text-midnight-100 font-bold">{offerName}</span>
          </p>
          <p>
            <span>For </span>
            <time
              dateTime={dt_start.toISO()}
              className="text-midnight-100 font-bold"
            >
              {dt_start.toLocaleString({
                dateStyle: 'medium',
                timeStyle: 'short',
              })}
            </time>
          </p>
        </div>
        <div className="flex gap-2">
          <div className="text-midnight-70 text-sm">
            <span>Sent at </span>
            <time dateTime={dt_created.toISO()}>
              {dt_created.toLocaleString({
                dateStyle: 'short',
                timeStyle: 'short',
              })}
            </time>
          </div>

          {dt_updated && (
            <div className="text-midnight-70 text-sm">
              <span>Updated at </span>
              <time dateTime={dt_updated.toISO()}>
                {dt_updated.toLocaleString({
                  dateStyle: 'short',
                  timeStyle: 'short',
                })}
              </time>
            </div>
          )}
        </div>
      </div>

      {request.approved !== null ? (
        request.approved ? (
          <div className="flex items-center gap-4">
            <Link
              to={`../${request.session_id}`}
              className="button-second button-xs"
            >
              View Session
            </Link>

            <span className="flex items-center gap-2 font-bold text-secondary">
              Approved
              <CheckIcon className="w-6" />
            </span>
          </div>
        ) : (
          <span className="flex items-center gap-2 font-bold text-danger">
            Rejected
            <NoSymbolIcon className="w-6" />
          </span>
        )
      ) : (
        <div className="flex items-center gap-4">
          {appContext.isCoach && (
            <div className="flex flex-col sm:flex-row gap-4 sm:items-center">
              <button
                type="button"
                className="button-main button-xs flex-1 justify-center"
                onClick={() =>
                  onApprove instanceof Function && onApprove(request)
                }
              >
                Approve
              </button>
              <button
                type="button"
                className="button-outline button-xs flex-1 justify-center"
                onClick={() =>
                  onReject instanceof Function && onReject(request)
                }
              >
                Reject
              </button>
            </div>
          )}

          <span className="flex items-center gap-2 font-bold text-midnight-60">
            Pending
            <ClockIcon className="w-6" />
          </span>
        </div>
      )}
    </li>
  );
}
