import { UserStoreV2 } from '@/stores';
import { FormControl, LandingTemplate, ProgressButton } from '@components';
import { FORM_CONTROL_TYPE, SIZE } from '@constants';
import { ChevronRightIcon, EnvelopeIcon, LockIcon, SvgIcon } from '@icons';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import DevBackdoor from './DevBackdoor';

export default function SignInScene() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userStore = UserStoreV2.useState();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email, password }) => {
      await handleSubmit({ email, password });
    },
  });

  const disabledForm = !(formik.isValid && formik.dirty);

  const handleSubmit = async ({ email, password }) => {
    setLoading(true);

    try {
      await userStore.login(email, password);
      navigate(searchParams.get('return_url') || 'dashboard');
    } catch (error) {
      if (error.response?.status === 400) {
        toast.error('Incorrect email or password!');
      } else {
        toast.error('Login error. See console (F12)');
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LandingTemplate src="/images/sign_in.svg">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col justify-center h-full max-w-lg mx-auto"
      >
        <div className="flex flex-col justify-center h-full">
          <div>
            <img src="/images/logo_black.svg" className="h-8 mb-1" alt="logo" />
          </div>
          <h1 className="text-3xl text-midnight-100 font-bold mt-4">
            Keep Track of Your Clients Effortlessly
          </h1>
          <p className="text-sm font-normal text-midnight-90 mt-2">
            All-in-one platform, powered by AI, to streamline communications and
            organize your coaching business
          </p>
          <FormControl
            className="mt-9"
            id="email"
            name="email"
            type="email"
            placeholder="Enter email address"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
            startAdornment={<SvgIcon icon={<EnvelopeIcon />} />}
          />
          <FormControl
            className="mt-6"
            id="password"
            name="password"
            type={FORM_CONTROL_TYPE.PASSWORD}
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={!!formik.errors.password}
            helperText={formik.errors.password}
            startAdornment={<SvgIcon icon={<LockIcon />} />}
          />
          <Link
            to="/auth/forgot_password"
            className="text-right text-xs text-sea-70 mt-2 mb-6 hover:underline"
          >
            Forgot Password?
          </Link>

          <ProgressButton
            type="submit"
            loading={loading}
            disabled={disabledForm}
          >
            Sign in&nbsp;
            <SvgIcon size={SIZE.S} icon={<ChevronRightIcon />} />
          </ProgressButton>
        </div>
        <div className="flex justify-center font-normal text-sm text-midnight-90 mb-3">
          New User?
          <Link
            to="/auth/sign_up"
            className="text-sea-70 hover:underline font-bold ml-1"
          >
            Create an account
          </Link>
        </div>
        <DevBackdoor />
      </form>
    </LandingTemplate>
  );
}

const validationSchema = object({
  email: string()
    .required('Email is required')
    .email('Please check your email'),
  password: string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
});
