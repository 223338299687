import { ZoomService } from '@services';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export function ZoomOAuthScene() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  if (!code) {
    console.error('Do not open this page without the code parapmeter');
    navigate('/dashboard/coach/settings/personal_info');
  }

  useEffect(() => {
    async function sendOauthCode() {
      try {
        await ZoomService.OAuth(code, location.origin + location.pathname);
        navigate('/dashboard/coach/settings/personal_info');
      } catch (error) {
        toast.error('OAuth error. See console (F12)');
        console.error('Zoom OAuth error', error);
      }
    }
    sendOauthCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full h-screen flex items-center justify-center text-3xl">
      <div className="flex flex-col items-center">Please wait...</div>
    </div>
  );
}
