import {
  AuthService,
  SubscriptionService,
  UserService,
  UploadService,
} from '@services';
import { useSyncExternalStore } from 'react';

class UserStore {
  /** @type {UserStore} */
  static instance = null;
  /** @type {Promise<any>} */
  static _initialization = null;
  /** @type {Promise<any>} */
  static _loading = null;

  static update(state) {
    UserStore.instance = new UserStore(state);
    // Trigger React synchronization
    window.dispatchEvent(new Event('user-store-update'));
  }

  constructor({
    user = {
      id: undefined,
      email: undefined,
      phone: undefined,
      first_name: undefined,
      last_name: undefined,
      avatar: undefined,
      contacts: undefined,
    },
    subscription = {
      status: undefined,
      customer_id: undefined,
      subscription_id: undefined,
    },
    initialized = false,
  } = {}) {
    this.state = {
      user,
      subscription,
      initialized,
    };
  }

  get isAuthorized() {
    return this.state.user.id !== undefined;
  }

  get hasSubscription() {
    return (
      this.state.subscription?.status !== undefined ||
      process.env.REACT_APP_IGNORE_PAYWALL === 'true'
    );
  }

  get hasActiveSubscription() {
    return (
      ['trialing', 'active', 'past_due'].includes(
        this.state.subscription?.status,
      ) || process.env.REACT_APP_IGNORE_PAYWALL === 'true'
    );
  }

  get isInitialized() {
    return this.state.initialized;
  }

  async login(email, password) {
    if (!this.isAuthorized) {
      await AuthService.login({ email, password });
      await this.load();
    } else {
      console.warn('The user is authorized aleady');
    }
  }

  async logout() {
    if (this.isAuthorized) {
      await AuthService.logout();
      this.clear();
    } else {
      console.warn('The user is not authorized yet');
    }
  }

  /**
   * @param {{email?: string, password?: string, phone?: string, first_name?: string, last_name?: string, avatar?: string }} data
   */
  async update(data) {
    if (data.avatar instanceof File) {
      try {
        const fileData = await UploadService.upload(data.avatar);
        data.avatar = fileData.link;
      } catch (error) {
        throw new Error('File uploading error', { cause: error });
      }
    }

    const newData = await UserService.updateCurrent(data);
    UserStore.update(Object.assign(this.state, { user: newData }));
  }

  /**
   * Load the data. Calling this method will override the current state.
   * Use this method only if you want perform to force an update.
   *
   * It's guaranteed that there's only one loading process at a time.
   * You can await the method to wait until the loading is complete.
   * @returns {Promise<void>}
   */
  async load() {
    if (UserStore._loading) return UserStore._loading;

    async function fetch() {
      try {
        const user = await UserService.getCurrent();
        const subscription = await SubscriptionService.getCurrent();
        UserStore.update({ user, subscription, initialized: true });
      } catch (error) {
        UserStore.update({ initialized: true });
      }
    }

    UserStore._loading = fetch();
    await UserStore._loading;
    UserStore._loading = null;
  }

  clear() {
    UserStore.update({
      initialized: true,
    });
  }

  /**
   * Use this method to initialize the store state.
   * It's safe for multiple calls, the loading is triggered only once.
   *
   * You can await the method to wait until the initialization is complete.
   * @returns {Promise<void>}
   */
  async init() {
    if (!this.isInitialized && !UserStore._initialization) {
      UserStore._initialization = this.load();
      await UserStore._initialization;
      UserStore._initialization = null;
    }
  }
}

// Create first store instance
UserStore.instance = new UserStore();

export function subscribe(listener) {
  window.addEventListener('user-store-update', listener);
  return () => window.removeEventListener('user-store-update', listener);
}
export function snapshot() {
  return UserStore.instance;
}

/**
 * @returns {ReturnType<typeof snapshot>}
 */
export function useState(options = { noInit: false }) {
  if (!options.noInit) UserStore.instance.init();
  return useSyncExternalStore(subscribe, snapshot);
}
