import { FormControl, LandingTemplate, ProgressButton } from '@components';
import { BUTTON_TYPE, SIZE } from '@constants';
import {
  ChevronRightIcon,
  EnvelopeIcon,
  LockIcon,
  PersonIcon,
  SvgIcon,
} from '@icons';
import { AuthService } from '@services';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bool, object, string } from 'yup';

const validationSchema = object({
  acceptTerms: bool().oneOf([true], 'You must agree to the site policy.'),
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  email: string()
    .required('Email is required')
    .email('Email must be a valid email: "example@gmail.com"'),
  password: string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'The password can contain Latin letters and numbers.'),
});

const SignUpScene = observer(() => {
  const [loading, setLoading] = useState(false);

  if (localStorage.getItem('accessToken')) return <Navigate to="/dashboard" />;

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      acceptTerms: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await handleSubmit(values);
    },
  });

  const disabledForm = !(formik.isValid && formik.dirty);

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const validEmail = await AuthService.CheckEmail(values.email);

      if (!validEmail) {
        toast.error('The Email already exists. Try another one!');
        return;
      }

      navigate('/auth/verify_code', { state: { userdata: values } });
    } finally {
      setLoading(false);
    }
  };

  return (
    <LandingTemplate src="/images/sign_up.svg">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col justify-center h-full max-w-lg mx-auto"
      >
        <div className="flex flex-col justify-center h-full">
          <div>
            <img src="/images/logo_black.svg" className="h-8 mb-1" alt="logo" />
          </div>
          <h1 className="text-3xl text-midnight-100 font-bold mt-4">
            Keep Track of Your Clients Effortlessly
          </h1>
          <p className="text-sm font-normal text-midnight-90 mt-2">
            All-in-one platform, powered by AI, to streamline communications and
            organize your coaching business
          </p>

          <FormControl
            id="firstName"
            name="firstName"
            type="text"
            className="mt-9"
            placeholder="First name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={!!formik.errors.firstName}
            helperText={formik.errors.firstName}
            startAdornment={<SvgIcon icon={<PersonIcon />} />}
          />
          <FormControl
            id="lastName"
            name="lastName"
            type="text"
            className="mt-6"
            placeholder="Last name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={!!formik.errors.lastName}
            helperText={formik.errors.lastName}
            startAdornment={<SvgIcon icon={<PersonIcon />} />}
          />
          <FormControl
            id="email"
            name="email"
            type="email"
            className="mt-6"
            placeholder="Enter email address"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
            startAdornment={<SvgIcon icon={<EnvelopeIcon />} />}
          />
          <FormControl
            id="password"
            name="password"
            type="password"
            className="mt-6"
            placeholder="Enter password"
            minLength={8}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={!!formik.errors.password}
            helperText={formik.errors.password}
            startAdornment={<SvgIcon icon={<LockIcon />} />}
          />
          {/* TODO: add lint to T&C */}
          <FormControl
            label={
              <>
                By continuing, you agree to Drreamz&nbsp;
                <Link
                  to="/terms_conditions"
                  className="text-sea-70 hover:text-sea-80"
                >
                  Terms & Conditions
                </Link>
                <span> and </span>
                <Link
                  to="/privacy_policy"
                  className="text-sea-70 hover:text-sea-80"
                >
                  Privacy Policy
                </Link>
                .
              </>
            }
            id="acceptTerms"
            name="acceptTerms"
            type="checkbox"
            className="mt-6"
            value={formik.values.acceptTerms}
            onChange={formik.handleChange}
            error={!!formik.errors.acceptTerms}
            helperText={formik.errors.acceptTerms}
          />
          <ProgressButton
            loading={loading}
            className="mt-12"
            disabled={disabledForm}
            type={BUTTON_TYPE.SUBMIT}
          >
            Sign Up&nbsp;
            <SvgIcon size={SIZE.S} icon={<ChevronRightIcon />} />
          </ProgressButton>
        </div>
        <div className="flex justify-center font-normal text-sm text-midnight-90 mb-3">
          Already have an account?
          <Link
            to="/auth/sign_in"
            className="text-sea-70 hover:underline font-bold ml-1"
          >
            Sign In
          </Link>
        </div>
      </form>
    </LandingTemplate>
  );
});

export default SignUpScene;
