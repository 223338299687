export default function OfferPageCover({ url, offset = 0 }) {
  return (
    url && (
      <div
        style={{
          backgroundImage: `url('${url}')`,
          backgroundPositionY: offset === 0 ? 'center' : offset,
          backgroundPositionX: '50%',
          backgroundSize: '1920px',
        }}
        className="rounded-xl md:rounded w-full h-[320px] object-cover bg-midnight-20 bg-no-repeat"
      />
    )
  );
}
