import classNames from 'classnames';
import './styles.scss';

export const FormControlLabel = ({
  className,
  label,
  control,
  labelPlacement = 'top',
  component = 'label',
}) => {
  const LabelComponent = component;

  return (
    <LabelComponent className={classNames(
      'form-control-label',
      Boolean(labelPlacement) && `form-control-label--placement-${labelPlacement}`,
      className,
    )}>
      <span className="form-control-label__label">
        {label}
      </span>

      {control}
    </LabelComponent>
  );
};
