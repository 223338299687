import { FormControl, LandingTemplate, ProgressButton } from '@components';
import { FORM_CONTROL_TYPE, SIZE } from '@constants';
import { ChevronRightIcon, LockIcon, SvgIcon } from '@icons';
import { resetPassword } from '@services';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, ref, string } from 'yup';

const validationSchema = object({
  password: string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword: string()
    .oneOf([ref('password'), null], 'Password needs to be match!')
    .required('Password confirm is required'),
});

const ResetPasswordScene = observer(() => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const search = useLocation().search;
  const resetToken = new URLSearchParams(search).get('reset_token');

  if (localStorage.getItem('accessToken')) return <Navigate to="/dashboard" />;

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ password }) => {
      setLoading(true);

      try {
        await resetPassword({ password, resetToken });

        toast('Password changed successfully!', {
          type: 'success',
        });

        navigate('/auth/sign_in');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <LandingTemplate src="/images/sign_in.svg">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col justify-center h-full max-w-lg mx-auto"
      >
        <div className="flex flex-col justify-center h-full">
          <div>
            <img src="/images/logo_black.svg" className="h-8 mb-1" alt="logo" />
          </div>
          <h1 className="text-3xl text-midnight-100 font-bold mt-4">
            New password
          </h1>
          <p className="text-sm font-normal text-midnight-90 my-4">
            Enter a new password
          </p>
          <FormControl
            className="mt-4 mb-1"
            id="password"
            name="password"
            type={FORM_CONTROL_TYPE.PASSWORD}
            placeholder="New password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={!!formik.errors.password}
            helperText={formik.errors.password}
            startAdornment={<SvgIcon icon={<LockIcon />} />}
          />
          <FormControl
            className="mt-1 mb-8"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="Confirm password"
            type={FORM_CONTROL_TYPE.PASSWORD}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={!!formik.errors.confirmPassword}
            helperText={formik.errors.confirmPassword}
            startAdornment={<SvgIcon icon={<LockIcon />} />}
          />
          <ProgressButton
            type="submit"
            loading={loading}
            disabled={!(formik.isValid && formik.dirty)}
          >
            Update&nbsp;
            <SvgIcon icon={<ChevronRightIcon />} size={SIZE.S} />
          </ProgressButton>
        </div>
      </form>
    </LandingTemplate>
  );
});

export default ResetPasswordScene;
