import { SelectOffer } from '@/components';
import { AppContext } from '@/contexts';
import Calendar from '@components/Calendar';
import { ChevronRightIcon } from '@icons';
import { AcquisitionService, MyOfferService, SessionService } from '@services';
import { DateTime } from 'luxon';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

export default function SessionCalendarScene() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sessions, setSessions] = useState([]);
  const [monthSessions, setMonthSessions] = useState([]);
  const selectedDate = useMemo(
    () =>
      searchParams.has('date')
        ? DateTime.fromISO(searchParams.get('date'))
        : DateTime.now(),
    [searchParams],
  );
  const [activeDate, setActiveDate] = useState(selectedDate);
  const [filterByOffer, setFilterByOffer] = useState(null);
  const appContext = useContext(AppContext);

  const calendarVisibleStart = useMemo(
    () => activeDate.startOf('month').startOf('week').toISO(),
    [activeDate],
  );
  const calendarVisibleEnd = useMemo(
    () => activeDate.endOf('month').endOf('week').toISO(),
    [activeDate],
  );

  const metadata = useMemo(() => {
    const map = new Map();
    for (const session of monthSessions) {
      const key = DateTime.fromISO(session.timestamp).toISODate();
      if (!map.has(key)) {
        map.set(key, { has_sessions: true });
      }
    }
    return Object.fromEntries(map);
  }, [monthSessions]);

  const updateSelectedDate = useCallback(
    (newDate) => {
      setSearchParams((params) => {
        params.set('date', newDate.toISODate());
        return params;
      });
    },
    [setSearchParams],
  );

  // Load sessions for selected date
  useEffect(() => {
    async function fetchSessions() {
      const result = await SessionService.list({
        timestampAfter: selectedDate.startOf('day').toISO(),
        timestampBefore: selectedDate.endOf('day').toISO(),
        offerId: filterByOffer?.id,
        expand: ['offer'],
      });
      setSessions(result.data);
    }
    fetchSessions();
  }, [selectedDate, filterByOffer]);

  // Load sessions for calendar's visible interval
  useEffect(() => {
    async function fetchSessions() {
      const result = await SessionService.list({
        timestampAfter: calendarVisibleStart,
        timestampBefore: calendarVisibleEnd,
        offerId: filterByOffer?.id,
      });
      setMonthSessions(result.data);
    }
    fetchSessions();
  }, [calendarVisibleStart, calendarVisibleEnd, filterByOffer]);

  const loadOffers = useCallback(async () => {
    return appContext.isClient
      ? await AcquisitionService.offers()
      : await MyOfferService.list();
  }, [appContext.isClient]);

  return (
    <div className="flex flex-col gap-4 px-5 py-6 bg-gray-10 rounded-2.5xl">
      <SelectOffer
        value={filterByOffer}
        onChange={(offer) => setFilterByOffer(offer)}
        loadData={loadOffers}
        allowNull={true}
        placeholder="Filter by offer"
        className="z-10"
      />
      <div className="grid grid-cols-2 gap-5">
        <Calendar
          selected={selectedDate}
          onChangeSelected={updateSelectedDate}
          active={activeDate}
          onChangeActive={setActiveDate}
          metadata={metadata}
        />

        <div className="flex flex-col gap-4 px-5 py-6 bg-midnight-10 rounded-2.5xl">
          <h2 className="text-midnight-90 text-2xl font-bold">
            Sessions for {selectedDate.toLocaleString(DateTime.DATE_MED)}
          </h2>
          <SessionList sessions={sessions} />
        </div>
      </div>
    </div>
  );
}

function SessionList({ sessions = [] }) {
  return (
    <ul className="flex flex-col gap-4">
      {sessions.map((session) => (
        <li
          key={session.id}
          data-session-id={session.id}
          className="flex items-center justify-between bg-white rounded-xl"
        >
          <div className="flex items-center gap-8 px-5 py-3">
            <time className="text-midnight-100" dateTime={session.timestamp}>
              {DateTime.fromISO(session.timestamp).toLocaleString({
                timeStyle: 'short',
              })}
            </time>
            <div className="text-midnight-70">{session.offer.name}</div>
          </div>

          <Link
            to={`../${session.id}`}
            className="button-clear rounded-l-none rounded-r-xl self-stretch py-0 px-4"
          >
            <ChevronRightIcon
              className="w-8 h-8 p-1 fill-midnight-60"
              aria-hidden="true"
            />
            <span className="sr-only">Session Info</span>
          </Link>
        </li>
      ))}
    </ul>
  );
}
