import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';
import * as qs from 'qs';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/user/workspaces`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

/**
 * @param {{ limit?: number, offset?: number, expand?: "offer", filters?: object }} params
 */
export async function list(params) {
  params ??= { limit: 10, offset: 0 };
  const { data } = await instance.get('', {
    params,
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return data;
}

/**
 * @param {{ offer_id: string, name?: string }} params
 */
export async function create(params) {
  const { data } = await instance.post('', params);
  return data;
}

/**
 * @param {string} id
 * @param {{ expand?: "offer" }} params
 */
export async function get(id, params) {
  const { data } = await instance.get(`/${id}`, {
    params,
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return data;
}

/**
 * @param {string} id
 * @param {{ name?: string }} data
 */
export async function update(id, data) {
  const { data: workspace } = await instance.patch(`/${id}`, data);
  return workspace;
}

/**
 * @param {string} id
 */
export async function remove(id) {
  const { data } = await instance.delete(`/${id}`);
  return data;
}

//#region FILES

/**
 * @param {string} id
 */
export async function listFiles(id) {
  const { data } = await instance.get(`/${id}/files`);
  return data;
}

/**
 * @param {string} id
 * @param {Array<string>} fileIds
 */
export async function addFiles(id, fileIds) {
  const { data } = await instance.put(`/${id}/files`, fileIds);
  return data;
}

/**
 * @param {string} id
 * @param {Array<string>} fileIds
 */
export async function removeFiles(id, fileIds) {
  const { data } = await instance.delete(`/${id}/files`, {
    data: fileIds,
  });
  return data;
}

//#endregion
//#region MEMBERS

/**
 * @param {string} id
 */
export async function listMembers(id) {
  const { data } = await instance.get(`/${id}/members`);
  return data;
}

/**
 * @param {string} id
 * @param {Array<string>} userIds
 */
export async function addMembers(id, userIds) {
  const { data } = await instance.put(`/${id}/members`, userIds);
  return data;
}

/**
 * @param {string} id
 * @param {Array<string>} userIds
 */
export async function removeMembers(id, userIds) {
  const { data } = await instance.delete(`/${id}/members`, {
    data: userIds,
  });
  return data;
}

//#endregion
//#region SESSIONS

/**
 * @param {string} id
 */
export async function listSessions(id) {
  const { data } = await instance.get(`/${id}/sessions`);
  return data;
}

/**
 * @param {string} id
 * @param {Array<string>} sessionIds
 */
export async function addSessions(id, sessionIds) {
  const { data } = await instance.put(`/${id}/sessions`, sessionIds);
  return data;
}

/**
 * @param {string} id
 * @param {Array<string>} sessionIds
 */
export async function removeSessions(id, sessionIds) {
  const { data } = await instance.delete(`/${id}/sessions`, {
    data: sessionIds,
  });
  return data;
}

/**
 * @param {string} sessionId
 * @param {boolean} expand
 * @returns {Promise<string | null>} workspace id
 */
export async function findBySession(sessionId, expand) {
  const { data } = await instance.get('/search-by-session', {
    params: {
      session_id: sessionId,
      expand,
    },
  });
  return data;
}

//#endregion
