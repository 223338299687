import { OfferContext } from '@/contexts';
import { useContext } from 'react';

export default function OfferSessionInfoForm() {
  const context = useContext(OfferContext);

  return (
    <div className="flex flex-col gap-8 p-7 bg-gray-10 rounded-2.5xl">
      <label
        htmlFor="offer-session-amount-input"
        className="text-xl leading-normal text-midnight-100 font-bold"
      >
        Number of sessions
        <span className="text-primary">*</span>
      </label>

      <div className="flex flex-col gap-4">
        <p
          id="offer-session-amount-input-description"
          className="text-midnight-100"
        >
          Number of sessions a user gets after purchase
        </p>
        <input
          id="offer-session-amount-input"
          type="number"
          value={context.sessionAmount}
          min="1"
          required
          placeholder="Number of sessions"
          className="input-main input-m input-custom-invalid number-no-spinner"
          onChange={(event) => context.setSessionAmount(event.target.value)}
          aria-describedby="offer-session-amount-input-description"
        />
      </div>

      <label
        htmlFor="offer-session-duration-input"
        className="text-xl leading-normal text-midnight-100 font-bold"
      >
        Session duration
        <span className="text-primary">*</span>
      </label>

      <div className="flex flex-col gap-4">
        <p
          id="offer-session-duration-input-description"
          className="text-midnight-100"
        >
          Duration of one session in minutes
        </p>
        <input
          id="offer-session-duration-input"
          type="number"
          value={context.sessionDuration}
          required
          placeholder="Session duration"
          className="input-main input-m input-custom-invalid number-no-spinner"
          onChange={(event) => context.setSessionDuration(event.target.value)}
          aria-describedby="offer-session-duration-input-description"
        />
      </div>

      {context.offerType === 'group' && (
        <>
          <label
            htmlFor="offer-session-capacity-input"
            className="text-xl leading-normal text-midnight-100 font-bold"
          >
            Session capacity
            <span className="text-primary">*</span>
          </label>

          <div className="flex flex-col gap-4">
            <p
              id="offer-session-capacity-input-description"
              className="text-midnight-100"
            >
              Number of clients in a session
            </p>
            <input
              id="offer-session-capacity-input"
              type="number"
              value={context.sessionCapacity}
              min="1"
              required
              placeholder="Session capacity"
              className="input-main input-m input-custom-invalid number-no-spinner"
              onChange={(event) =>
                context.setSessionCapacity(event.target.value)
              }
              aria-describedby="offer-session-capacity-input-description"
            />
          </div>
        </>
      )}
    </div>
  );
}
