import MessageBox from '../MessageBox';
import MessageSearch from '../MessageSearch';

function MessageTab() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
      <div className="md:col-span-1 bg-whit rounded-2xl w-full h-full">
        <MessageSearch />
      </div>
      <div className="md:col-span-2 bg-white rounded-2xl w-full h-full">
        <MessageBox />
      </div>
    </div>
  );
}

export default MessageTab;
