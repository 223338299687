import useSilentRefresh from '@utils/silent-refresh';
import useBearerToken from '@utils/use-bearer';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/user/private-notes`,
  withCredentials: true,
});

useSilentRefresh(instance);
useBearerToken(instance);

/**
 * @param {ListParams} props
 * @returns {Promise<Array<PrivateNote>>}
 */
export async function list({ filters = {} } = {}) {
  const resonse = await instance.get('', {
    params: { filters },
  });
  return resonse.data;
}

/**
 * @param {PrivateNote['id']} noteId
 * @returns {Promise<PrivateNote | null>}
 */
export async function get(noteId) {
  const resonse = await instance.get(`/${noteId}`);
  return resonse.data ?? null;
}

/**
 * @param {CreateNoteData} data
 * @returns {Promise<PrivateNote>}
 */
export async function create(data) {
  const resonse = await instance.post('', data);
  return resonse.data;
}

/**
 * @param {PrivateNote['id']} noteId
 * @param {PrivateNote['content']} content
 * @returns {Promise<PrivateNote>}
 */
export async function update(noteId, content) {
  const resonse = await instance.patch(`/${noteId}`, { content });
  return resonse.data;
}

/**
 * @param {PrivateNote['id']} noteId
 * @returns {Promise<PrivateNote>}
 */
export async function remove(noteId) {
  const resonse = await instance.delete(`/${noteId}`);
  return resonse.data;
}


/**
 * @typedef {object} ListParams
 * @property {ListFilters} filters
 */
/**
 * @typedef {object} ListFilters
 * @property {{ eq?: PrivateNote['user_id'], ne?: PrivateNote['user_id'] } | undefined} user_id
 * @property {{ eq?: PrivateNote['context'], ne?: PrivateNote['context'] } | undefined} context
 */
/**
 * @typedef {Pick<PrivateNote, "user_id" | "context" | "content">} CreateNoteData
 */

/**
 * @typedef {object} PrivateNote
 * @property {string} id
 * @property {string} owner_id
 * @property {string} user_id Target user for whom this note is linked
 * @property {'user' | 'coach'} context Is this note for coach profile or user profile
 * @property {object} content The content for rich text editor
 * @property {string} timestamp
 */
