import { NoEnteredData } from '@/components';
import { SessionRequestService } from '@/services';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SessionRequestDialog from './src/SessionRequestDialog';
import SessionRequestListItem from './src/SessionRequestListItem';

const ListSize = 10;

export default function SessionRequestListSentScene() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(undefined);
  const [requestDialog, setRequestDialog] = useState(false);
  const [query, setQuery] = useSearchParams();

  const loadOffset = useMemo(() => +query.get('load_after') || 0, [query]);
  const hasBefore = useMemo(() => loadOffset > 0, [loadOffset]);
  const hasMore = useMemo(
    () => total && total > loadOffset + ListSize,
    [loadOffset, total],
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await SessionRequestService.listSended({
          limit: ListSize,
          offset: loadOffset,
        });
        setList(result.data);
        setTotal(result.total);
      } catch (err) {
        toast.error('Retrieve session requests error. See console (F12)');
        console.error(err);
      }
    }
    fetchData();
  }, [loadOffset]);

  return (
    <>
      <div>
        <button
          type="button"
          className="button-main button-m"
          onClick={() => setRequestDialog(true)}
        >
          Request a Session
        </button>
      </div>

      {list ? (
        <div className="flex flex-col gap-8 p-5 rounded-2.5xl bg-gray-10">
          {list.length > 0 ? (
            <>
              {hasBefore && (
                <button
                  type="button"
                  className="button-ghost button-s self-center"
                  onClick={() =>
                    setQuery({ load_after: Math.max(loadOffset - ListSize, 0) })
                  }
                >
                  Show Previous
                </button>
              )}

              <ul className="flex flex-col gap-5">
                {list.map((item) => (
                  <SessionRequestListItem
                    key={item.id}
                    request={item}
                    offerName={item.offer_id}
                  />
                ))}
              </ul>

              {!!hasMore && (
                <button
                  type="button"
                  className="button-ghost button-s self-center"
                  onClick={() =>
                    setQuery({ load_after: loadOffset + ListSize })
                  }
                >
                  Show More
                </button>
              )}
            </>
          ) : (
            <NoEnteredData
              title="No requests sent yet"
              customImage={
                <img
                  src="/images/svg-schedule-clock-calendar.svg"
                  alt="Woman writing on a tablet near to giant hourglass, calendar and clock."
                />
              }
            />
          )}
        </div>
      ) : (
        <span className="sr-only">Loading, please wait</span>
      )}

      <SessionRequestDialog
        open={requestDialog}
        onClose={() => setRequestDialog(false)}
        onRequest={(request) => {
          setList([...list, request]);
          setTotal(total + 1);
        }}
      />
    </>
  );
}
