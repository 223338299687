import { MessengerContext } from '@/contexts';
import { MessengerActiveRoomContext } from '@/contexts/MessengerActiveRoomContext';
import { PlusIcon } from '@/icons';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useCallback, useContext, useState } from 'react';
import CreateNewChatDialog from './CreateNewChatDialog';
import CreateNewRoomDialog from './CreateNewRoomDialog';
import { toast } from 'react-toastify';

const WhatsappBot = '@whatsappbot:drreamz.com';
const FacebookBot = '@facebookbot:drreamz.com';
const InstagramBot = '@instagrambot:drreamz.com';
const TelegramBot = '@telegrambot:drreamz.com';
const TwitterBot = '@twitterbot:drreamz.com';
const LinkedinBot = '@linkedinbot:drreamz.com';

export default function NewRoomButton() {
  console.log('[NewRoomButton] <render call>');
  const { matrixClient } = useContext(MessengerContext);
  const { setRoom } = useContext(MessengerActiveRoomContext);

  const [showNewRoomDialog, setShowNewRoomDialog] = useState(false);
  const [showNewChatDialog, setShowNewChatDialog] = useState(false);

  const openChatWithBot = useCallback((roomId) => setRoom(roomId), [setRoom]);

  const createChatWithBot = useCallback(
    /**
     * @param {string} botId 
     */
    async (botId) => {
      let roomId;
      try {
        const { room_id } = await matrixClient.createRoom({
          visibility: 'private',
          is_direct: true,
          invite: [botId],
        });
        roomId = room_id;
      } catch (error) {
        toast.error('Unable to create a chat with bot.');
        console.error('Room creation error', error);
        return;
      }

      try {
        const directEventContent = matrixClient
          .getAccountData('m.direct')
          ?.getContent();

        const chatIds = directEventContent?.[botId] ?? [];
        chatIds.push(roomId);
        await matrixClient.setAccountData('m.direct', {
          ...directEventContent,
          [botId]: chatIds,
        });
      } catch (error) {
        toast.error('Unable to update account data.');
        console.error('Set AccountData error', error);
        return;
      }

      // Bot IDs follow next pattern @<protocol>bot:<domain>
      // Extract protocol part from ID
      const protocol = botId.split(':')[0]?.slice(1, -3);
      if(protocol) {
        try {
          matrixClient.setRoomAccountData(roomId, 'drreamz.bridge', {
            botId,
            protocol,
          });
        } catch (error) {
          toast.error('Cannot set account data for new room.');
          console.error('Set RoomAccountData error', error);
        }
      } else {
        console.error('Failed to extract protocol from "%s". Expected pattern is "@<protocol>bot:<domain>".', botId);
      }

      try {
        if (botId == TelegramBot) {
          await matrixClient.sendHtmlNotice(
            roomId,
            "#### ⚠ IMPORTANT NOTE ⚠\nWhile we're using the official client API, Telegram is known to ban suspicious users, and a brand new account using a 3rd party client is considered suspicious.\nUsing a well-established account is **perfectly safe**.\nIf you do get banned, Telegram usually reverts incorrect bans fairly quickly after emailing [recover@telegram.org](recover@telegram.org).",
            `<h4>⚠ IMPORTANT NOTE ⚠</h4>\n<p>While we're using the official client API, Telegram is known to ban suspicious users, and a brand new account using a 3rd party client is considered suspicious.<br />Using a well-established account is <strong>perfectly safe</strong>.</p>\n<p>If you do get banned, Telegram usually reverts incorrect bans fairly quickly after emailing <a href="mailto:recover@telegram.org">recover@telegram.org</a>.</p>`,
          );
        } else {
          await matrixClient.sendTextMessage(roomId, 'help');
        }
      } catch (error) {
        toast.error(
          'Unable to start conversation. Send "help" to the bot manually.',
        );
        console.error('Send message error', error);
      }

      return roomId;
    },
    [matrixClient],
  );

  const getChatIdWithBot = useCallback(
    (botId) => {
      const directEventContent = matrixClient
        .getAccountData('m.direct')
        ?.getContent();

      if (!directEventContent) return undefined;
      const chatsWithBot = directEventContent[botId];
      if (!chatsWithBot || chatsWithBot.length == 0) return undefined;
      return chatsWithBot[chatsWithBot.length - 1];
    },
    [matrixClient],
  );

  const isChatWithBotValid = useCallback(
    (chatId) => {
      const room = matrixClient.getRoom(chatId);
      return room && room.getMyMembership() == 'join';
    },
    [matrixClient],
  );

  const chatWithBot = useCallback(
    async (botId) => {
      let chatId = getChatIdWithBot(botId);
      if (!chatId || !isChatWithBotValid(chatId)) {
        chatId = await createChatWithBot(botId);
      }

      if (chatId) {
        openChatWithBot(chatId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChatWithBotValid, createChatWithBot, getChatIdWithBot, openChatWithBot],
  );

  return (
    <div>
      <Menu>
        <div className="relative">
          <Menu.Button className="button-clear p-1" title="Add a room">
            <PlusIcon className="fill-midnight-60" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 top-full mt-2 py-2 w-max max-h-64 overflow-auto origin-top-right rounded-lg bg-gray-10 border border-midnight-10 shadow-lg focus:outline-none z-10">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-orange-10' : 'text-midnight-100'
                    } group flex w-full items-center rounded-none px-4 py-2 text-sm`}
                    onClick={() => setShowNewChatDialog(true)}
                  >
                    Direct Chat
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-orange-10' : 'text-midnight-100'
                    } group flex w-full items-center rounded-none px-4 py-2 text-sm`}
                    onClick={() => setShowNewRoomDialog(true)}
                  >
                    Group Room
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-orange-10' : 'text-midnight-100'
                    } group flex w-full items-center gap-1 rounded-none pl-4 pr-2 py-2 text-sm`}
                    onClick={() => chatWithBot(WhatsappBot)}
                  >
                    <span className="grow text-left">WhatsApp</span>
                    <img
                      src="/images/messengers/whatsup.svg"
                      className="shrink-0 w-6 h-6 rounded-full"
                    />
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-orange-10' : 'text-midnight-100'
                    } group flex w-full items-center gap-1 rounded-none pl-4 pr-2 py-2 text-sm`}
                    onClick={() => chatWithBot(InstagramBot)}
                  >
                    <span className="grow text-left">Instagram</span>
                    <img
                      src="/images/messengers/instagram.svg"
                      className="shrink-0 w-6 h-6 rounded-full"
                    />
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-orange-10' : 'text-midnight-100'
                    } group flex w-full items-center gap-1 rounded-none pl-4 pr-2 py-2 text-sm`}
                    onClick={() => chatWithBot(FacebookBot)}
                  >
                    <span className="grow text-left">Facebook</span>
                    <img
                      src="/images/messengers/messenger.svg"
                      className="shrink-0 w-6 h-6 rounded-full"
                    />
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-orange-10' : 'text-midnight-100'
                    } group flex w-full items-center gap-1 rounded-none pl-4 pr-2 py-2 text-sm`}
                    onClick={() => chatWithBot(TwitterBot)}
                  >
                    <span className="grow text-left">Twitter</span>
                    <img
                      src="/images/messengers/twitter.svg"
                      className="shrink-0 w-6 h-6 rounded-full"
                    />
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-orange-10' : 'text-midnight-100'
                    } group flex w-full items-center gap-1 rounded-none pl-4 pr-2 py-2 text-sm`}
                    onClick={() => chatWithBot(TelegramBot)}
                  >
                    <span className="grow text-left">Telegram</span>
                    <img
                      src="/images/messengers/telegram.svg"
                      className="shrink-0 w-6 h-6 rounded-full"
                    />
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-orange-10' : 'text-midnight-100'
                    } group flex w-full items-center gap-1 rounded-none pl-4 pr-2 py-2 text-sm`}
                    onClick={() => chatWithBot(LinkedinBot)}
                  >
                    <span className="grow text-left">LinkedIn</span>
                    <img
                      src="/images/messengers/linkedin.svg"
                      className="shrink-0 w-6 h-6 rounded-full"
                    />
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </div>
      </Menu>

      <CreateNewRoomDialog
        show={showNewRoomDialog}
        onClose={() => setShowNewRoomDialog(false)}
      />
      <CreateNewChatDialog
        show={showNewChatDialog}
        onClose={() => setShowNewChatDialog(false)}
      />
    </div>
  );
}
