const THEME = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  PRIMARY_DARK: 'primary-dark',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger',
  WHITE: 'white',
  BLACK: 'black',
};

const SIZE = {
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
  XL: 'xl',
  XXL: 'xxl',
};

const KeyboardCode = {
  Enter: 'Enter',
  Tab: 'Tab',
  Escape: 'Escape',
  Space: 'Space',
  KeyA: 'KeyA',
};

const TOOLTIP_PLACEMENT = {
  TOP: 'top',
  TOP_END: 'top-end',
  TOP_START: 'top-start',
  RIGHT: 'right',
  LEFT: 'left',
  RIGHT_START: 'right-start',
  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  BOTTOM_END: 'bottom-end',
};

const BUTTON_TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  CHEVRON: 'chevron',
};

const BUTTON_THEME = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  OUTLINE_PRIMARY: 'outline-primary',
  CLEAR: 'clear',
  GHOST: 'ghost',
  DANGER: 'danger',
  OUTLINE_DANGER: 'outline-danger',
};

const FORM_CONTROL_TYPE = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
  SWITCHER: 'switcher',
  NUMBER: 'number',
  PASSWORD: 'password',
};

export {
  THEME,
  SIZE,
  KeyboardCode,
  TOOLTIP_PLACEMENT,
  BUTTON_TYPE,
  BUTTON_THEME,
  FORM_CONTROL_TYPE,
};
