import { SessionInfoService } from '@/services';
import {
  SessionInfoDescription,
  SessionInfoFiles,
  SessionInfoTasks,
} from '@components/SessionInfo';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, EditIcon } from '@icons';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';

export function WorksapceSessionListItem({ session, readonly }) {
  const dt = useMemo(
    () => DateTime.fromISO(session.timestamp),
    [session.timestamp],
  );
  const [sessionInfo, setSessionInfo] = useState(undefined);

  const fetchInfo = useCallback(async () => {
    if (sessionInfo === undefined) {
      try {
        const { data } = await SessionInfoService.get(session.id);
        console.log(data);
        setSessionInfo(data);
        return true;
      } catch (error) {
        setSessionInfo(null);
        return false;
      }
    }
  }, [session.id, sessionInfo]);

  return (
    <>
      <Disclosure
        as="div"
        className="flex flex-col justify-between rounded-2.5xl select-none bg-gray-10"
      >
        {({ open }) => (
          <>
            <div className="flex w-full justify-between px-5 py-3 rounded-2.5xl bg-gray-10">
              <h2 className="flex items-center gap-3 text-base text-midnight-70">
                <span>
                  {dt.toLocaleString({
                    dateStyle: 'medium',
                  })}
                </span>
                <span>{dt.toLocaleString({ timeStyle: 'short' })}</span>
              </h2>

              <div className="flex items-center gap-8">
                {!readonly && (
                  <button
                    type="button"
                    className="flex gap-2 items-center button-clear button-xs"
                  >
                    <EditIcon /> <span>Edit</span>
                  </button>
                )}
                <Disclosure.Button className="p-0 focus:outline-none focus-visible:ring focus-visible:ring-orange-50 focus-visible:ring-opacity-75">
                  <ChevronDownIcon
                    className={classNames(
                      'w-6 h-6 fill-midnight-60',
                      open && 'transform rotate-180',
                    )}
                  />
                </Disclosure.Button>
              </div>
            </div>
            <Disclosure.Panel className="px-4 pt-2 pb-6">
              {open && fetchInfo() && sessionInfo && (
                <div className="flex flex-col gap-4">
                  <div className="w-full h-0.5 bg-midnight-20"></div>
                  <h3 className="sr-only">Session Info</h3>
                  <SessionInfoDescription text={sessionInfo.description} />
                  <SessionInfoFiles files={sessionInfo.files} />
                  <SessionInfoTasks tasks={sessionInfo.tasks} />
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
