import classNames from 'classnames';

const SOURCE_IMAGES_BY_PAGE = {
  notifications: '/images/svg-no-notifications.svg',
  messages: '/images/svg-no-messages.svg',
  bookings: '/images/svg-no-bookings.svg',
  calendar: '/images/svg-no-calendar-bookings.svg',
};

export const NoEnteredData = ({
  title = 'No entered data.',
  pageName,
  customImage,
  className,
}) => (
  <div
    className={classNames(
      'flex justify-center items-center flex-col',
      className,
    )}
  >
    {Boolean(pageName) && <img src={SOURCE_IMAGES_BY_PAGE[pageName]} alt="" />}
    {customImage}
    <div className="text-midnight-60 text-lg text-center">{title}</div>
  </div>
);
