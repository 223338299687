import { SearchIcon, SvgIcon } from '@icons';
import { SIZE } from '@constants';
import ChatItem from './ChatItem';
import { nanoid } from 'nanoid';
import { observer } from 'mobx-react-lite';
import { ChatsStore } from '@stores';
import moment from 'moment';
import { InboxStore } from '../stores';

const ChatSidebar = observer(() => {
  return (
    <div className="lg:border-r lg:border-gray-20 h-full flex flex-col">
      <div className="py-4 px-8 flex justify-between lg:border-b lg:border-gray-20">
        <div className="flex flex-col justify-center">
          <h2 className="text-midnight-90 font-bold text-xl">Message</h2>
        </div>

        <button className="hover:bg-gray-20 rounded-full p-4">
          <SvgIcon icon={<SearchIcon />} size={SIZE.S} />
        </button>
      </div>
      {ChatsStore.chats.length ? (
        ChatsStore.chats.map((chat) => (
          <ChatItem
            key={nanoid()}
            avatar={chat.avatar}
            name={chat.name}
            time={moment(chat.last_message?.timestamp).format('hh:mm')}
            latestMessage={chat.last_message}
            isActive={chat.id === InboxStore.chat?.id}
            chatId={chat.id}
          />
        ))
      ) : (
        <div className="p-4 h-full flex flex-col justify-center">
          <p className="text-center text-midnight-90 font-normal">No chats</p>
        </div>
      )}
    </div>
  );
});

export default ChatSidebar;
