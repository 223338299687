import { SelectTimeslots } from '@/components';
import Calendar from '@/components/Calendar';
import { ArrowLeftIcon } from '@/icons';
import getErrorMessage from '@/utils/getErrorMessage';
import { MyOfferService, SessionService, WorkspaceService } from '@services';
import { DateTime, Duration } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateScene = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [offer, setOffer] = useState(null);
  const [workspace, setWorkspace] = useState(null);
  const [date, setDate] = useState(DateTime.now().startOf('day'));
  const [timeslot, setTimeslot] = useState(null);
  const [duration, setDuration] = useState(10);

  const [offers, setOffers] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);

  const timestamp = useMemo(
    () => timeslot && date.plus(timeslot),
    [date, timeslot],
  );

  const fetchTimeslots = useCallback(() => {
    let dt = date;
    const duration = Duration.fromObject({ minutes: 10 });

    return Array.from(
      { length: Duration.fromObject({ days: 1 }) / duration },
      () => {
        const result = {
          milliseconds: dt % date,
          text: dt.toLocaleString(DateTime.TIME_SIMPLE),
          disabled: false,
        };
        dt = dt.plus(duration);
        return result;
      },
    );
  }, [date]);

  const handleSubmit = async () => {
    try {
      setDisabled(true);
      const new_session = await SessionService.create({
        offer_id: offer,
        timestamp: timestamp.startOf('minute').toISO(),
        duration: Duration.fromObject({ minutes: duration }).toISO(),
      });

      if (workspace) {
        try {
          await WorkspaceService.addSessions(workspace, [new_session.id]);
        } catch (error) {
          toast.error('Unable to add session to workspace!');
          console.log(getErrorMessage(error));
        }
      }

      navigate(`/dashboard/coach/sessions/${new_session.id}`);
      toast.success('Session created!');
    } catch (err) {
      console.log(err);
    } finally {
      setDisabled(false);
    }
  };

  const validData = useMemo(() => {
    return offer && timestamp && duration && duration >= 10;
  }, [offer, timestamp, duration]);

  useEffect(() => {
    async function fetchData() {
      try {
        const list = await MyOfferService.list();
        setOffer(list[0]?.id);
        setOffers(list);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchWorkspaces() {
      try {
        const list = await WorkspaceService.list({
          filters: { offer_id: { eq: offer } },
        });
        setWorkspaces(list.data);
      } catch (err) {
        console.log(err);
      }
    }

    if (offer) {
      fetchWorkspaces();
    } else {
      setWorkspaces([]);
    }
    setWorkspace(null);
  }, [offer]);

  return (
    <div className="flex flex-col gap-8 px-12 py-8 relative">
      <div className="flex flex-col gap-8">
        <Link to=".." className="flex gap-2.5 text-midnight-80 self-start">
          <ArrowLeftIcon className="fill-current" aria-hidden="true" />
          <span className="font-semibold">Sessions</span>
        </Link>

        <div className="flex flex-col gap-8 px-5 py-6 bg-gray-10 rounded-2.5xl">
          <h1 className="text-4.5xl font-bold text-midnight-100">
            Create a Session
          </h1>

          <div className="flex flex-col gap-6">
            <div className="grid max-lg:grid-rows-3 lg:grid-cols-3 gap-4">
              <div>
                <label htmlFor="create-session-offer-select">
                  Select an offer
                </label>
                <select
                  id="create-session-offer-select"
                  className="input-main input-s block w-full"
                  name="offer_id"
                  onChange={(event) => setOffer(event.target.value)}
                >
                  {offers.length
                    ? offers.map((offer) => (
                        <option value={offer.id} key={offer.id}>
                          {offer.name}
                        </option>
                      ))
                    : ''}
                </select>
              </div>
              <div>
                <label htmlFor="create-session-workspace-select">
                  Select a workspace
                  <span className="text-midnight-60"> (optional)</span>
                </label>
                <select
                  id="create-session-workspace-select"
                  className="input-main input-s block w-full"
                  name="offer_id"
                  disabled={!workspaces.length}
                  onChange={(event) => setWorkspace(event.target.value)}
                >
                  <option value={null} key={null}>
                    {workspaces.length ? 'None' : 'No avaliable workspace'}
                  </option>
                  {workspaces.length &&
                    workspaces.map((workspace) => (
                      <option value={workspace.id} key={workspace.id}>
                        {workspace.name || 'Untitled workspace'}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label htmlFor="create-session-duration-input">
                  Duration (in minutes)
                </label>
                <input
                  id="create-session-duration-input"
                  placeholder="Session duration in minutes"
                  type="number"
                  min="10"
                  name="duration"
                  className="input-main input-s block w-full"
                  value={duration}
                  onChange={(event) => setDuration(event.target.value)}
                />
              </div>
            </div>

            <div className="grid max-lg:grid-rows-2 lg:grid-cols-2 gap-4">
              <Calendar selected={date} onChangeSelected={setDate} />

              <div className="flex flex-col h-full">
                <SelectTimeslots
                  value={timeslot}
                  onChange={setTimeslot}
                  loadData={fetchTimeslots}
                  className="p-2 rounded-2.5xl grow basis-64 overflow-auto"
                />
              </div>
            </div>
          </div>

          <button
            type="button"
            className="button-main button-m justify-center"
            disabled={disabled || !validData}
            onClick={handleSubmit}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateScene;
