import { SelectOffer, SelectTimeslots } from '@/components';
import { SessionRequestService } from '@/services';
import Calendar from '@/components/Calendar';
import { AttentionIcon, SpinnerIcon } from '@/icons';
import { AcquisitionService } from '@/services';
import { Dialog } from '@headlessui/react';
import { DateTime, Duration } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

export default function SessionRequestDialog({ open, onClose, onRequest }) {
  const [offer, setOffer] = useState(undefined);
  const [disabled, setDisabled] = useState(false);
  const [timeslot, setTimeslot] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(
    DateTime.now().startOf('day'),
  );

  const fetchOffers = useCallback(async () => {
    // TODO: filter offers that do not allow session request
    return await AcquisitionService.offers();
  }, []);

  const fetchTimeslots = useCallback(() => {
    let dt = selectedDate;
    const duration = Duration.fromObject({ minutes: 10 });

    return Array.from(
      { length: Duration.fromObject({ days: 1 }) / duration },
      () => {
        const result = {
          milliseconds: dt % selectedDate,
          text: dt.toLocaleString(DateTime.TIME_SIMPLE),
          disabled: false,
        };
        dt = dt.plus(duration);
        return result;
      },
    );
  }, [selectedDate]);

  const timestamp = useMemo(
    () => selectedDate && timeslot !== undefined && selectedDate.plus(timeslot),
    [selectedDate, timeslot],
  );

  async function sendRequest() {
    setDisabled(true);
    try {
      const request = await SessionRequestService.create({
        offer_id: offer.id,
        timestamp: timestamp.toISO(),
      });
      onRequest instanceof Function && onRequest(request);
      toast.success('Request has been sent');
    } catch (error) {
      toast.error('Session request error. See console (F12)');
      console.error(error);
    }
    setDisabled(false);
    setOffer(undefined);
    setDisabled(true);
    setTimeslot(undefined);
    setSelectedDate(DateTime.now().startOf('day'));
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black-500/80" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="flex flex-col gap-6 w-full px-7.5 py-12 max-w-2xl bg-gray-10 rounded-2.5xl max-h-screen overflow-auto">
          <Dialog.Title className="text-2xl font-bold text-center text-midnight-100">
            Request a Session
          </Dialog.Title>

          {!disabled ? (
            <>
              <div className="flex flex-col gap-1">
                <p>For which offer do you want to request a session?</p>
                <SelectOffer
                  value={offer}
                  onChange={setOffer}
                  loadData={fetchOffers}
                  disabled={disabled}
                />
                <div className="px-3 py-1 flex items-center gap-2 text-sm border border-warning bg-orange-10 rounded">
                  <AttentionIcon className="w-6" />
                  <p>
                    It does not filter offers that don't allow a session request
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <p>Select date and time</p>

                <div className="grid max-sm:grid-rows-2 md:grid-cols-2 gap-4 ">
                  <Calendar
                    selected={selectedDate}
                    onChangeSelected={(value) => {
                      setSelectedDate(value);
                    }}
                    compact
                  />
                  <div className="flex flex-col h-full">
                    <SelectTimeslots
                      value={timeslot}
                      onChange={setTimeslot}
                      loadData={fetchTimeslots}
                      className="p-2 rounded-2.5xl grow basis-64 overflow-auto"
                    />
                  </div>
                </div>
              </div>

              <button
                type="button"
                className="button-main button-m justify-center"
                disabled={!offer || timestamp === undefined}
                onClick={sendRequest}
              >
                {offer && timestamp
                  ? `Request a session on ${timestamp.toLocaleString(
                      DateTime.DATETIME_SHORT,
                    )}`
                  : 'Select offer and date'}
              </button>
            </>
          ) : (
            <div className="flex items-center justify-center h-16">
              <SpinnerIcon className="fill-midnight-50 animate-spin" />
              <span className="sr-only">Please wait</span>
            </div>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
