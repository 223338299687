import { UserStoreV2 } from '@stores';
import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const userStore = UserStoreV2.useState();
  const location = useLocation();

  return !userStore.isInitialized ? (
    <div className="flex justify-center h-screen">
      <img src="/images/logo-black.svg" alt="logo" className="w-1/3 lg:w-1/6" />
    </div>
  ) : userStore.isAuthorized ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: '/auth/sign_in',
        search: `?return_url=/${location.pathname.slice(1)}`,
      }}
    />
  );
};

export default AuthGuard;
