import Input from '../Input';
import Label from '../Label';

function LabelInputIcons(props) {
  const {
    mainclass,
    type,
    inputClass,
    placeholder,
    iconstart,
    iconend,
    labelValue,
    labelClass,
    register,
    errors,
    rules,
    name,
    MainLabel,
    onClick,
  } = props;

  return (
    <div className={MainLabel}>
      <Label value={labelValue} className={labelClass} />
      <div className={mainclass}>
        {/* <div className={`${mainclass} ${errors[name] ? "border-red-100" : ""}`}> */}
        <div
          className={`items-center bg-gray-50 flex space-x-3 w-full border rounded-lg ${
            errors[name] ? `border-red-100` : ''
          }`}
        >
          <Input
            type={type}
            register={register}
            errors={errors}
            rules={rules}
            name={name}
            placeholder={placeholder}
            className={inputClass}
            iconstart={iconstart}
            iconend={iconend}
            onClick={onClick}
          />
        </div>
        {errors[name] ? (
          <span className="text-red-100 text-xs ml-0">
            {errors[name].message}
          </span>
        ) : null}
        {/* </div> */}
      </div>
    </div>
  );
}

export default LabelInputIcons;
